import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { orderService } from '../../services/order';
import { returnExtFromImage } from '../../helper/helperFunctions';
import { frontEndUserConstants } from '../constant/front-end-constant';
import { toast } from 'react-toastify';
import User from '../../helper/User';
import $ from 'jquery'
import { Roles } from '../../constants/Roles';
import { generalActions } from './generalActions';
import { history } from 'helper/history';
import {BiLoaderAlt} from 'react-icons/bi';
import React from 'react';

export function storeOrder(AgencyData) {
    return dispatch => {
        dispatch(request());
        return orderService.storeOrder(AgencyData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error);
                }
            );
    }

    function request() { return { type: adminConstants.STORE_ORDER_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ORDER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ORDER_FAILURE, payload: error } }
}


export function getOrdersPagination(page, filter, sort, pageSize, searchData) {
    return dispatch => {
        // dispatch(request())
        return orderService.getOrdersPagination(page, filter, sort, pageSize, searchData)
            .then(
                response => {
                    return response;
                    // dispatch(success(response));
                },
                error => {
                    return Promise.reject(error);
                    // dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    // function request() { return { type: adminConstants.PAGINATION_ORDERS_REQUEST } }
    // function success(response) { return { type: adminConstants.PAGINATION_ORDERS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.PAGINATION_ORDERS_FAILURE, payload: error } }
}

export function getOrdersModeratorPagination(page, filter, sort, pageSize, searchData, role) {
    return dispatch => {

        return orderService.getOrdersModeratorPagination(page, filter, sort, pageSize, searchData, role)
            .then(
                response => {
                    return response;

                },
                error => {
                    return Promise.reject(error);

                }
            );
    };

}


export function getOrderResourceFormats(slug) {
    return dispatch => {
        dispatch(request())
        return orderService.getOrderResourceFormats(slug).then(response => {
            dispatch(success(response));
            return response.resourceType;
        }, error => {
            dispatch(failure(error));
            return Promise.reject(error)
        })
    }
    function request() { return { type: adminConstants.ORDER_RESOURCE_FORMATS_REQUEST } }
    function success(response) { return { type: adminConstants.ORDER_RESOURCE_FORMATS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_RESOURCE_FORMATS_FAILURE, payload: error } }
}

export function getOrder(id, LPR = null) {
    return dispatch => {
        dispatch(request());
        return orderService.getOrder(id, LPR)
            .then(
                Agency => {
                    // console.log('Order data', Agency);
                    dispatch(success(Agency));
                    return Agency
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.GET_ORDER_REQUEST } }
    function success(Agency) {
        console.log('order-actions: Agency', Agency);
        return { type: adminConstants.GET_ORDER_SUCCESS, payload: Agency }
    }
    function failure(error) { return { type: adminConstants.GET_ORDER_FAILURE, payload: error } }

}

export function storeOrderResource(resource, id) {
    return dispatch => {
        dispatch(request());
        return orderService.storeOrderResource(resource, id).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(alertActions.error(error))
                Promise.reject(error);
                return Promise.reject(error);
            }
        );
    }
    function request() { return { type: adminConstants.UPLOAD_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.UPLOAD_FILE_SUCCESS, payload: response.resourceTypes.order_resources_count } }
}


export function orderDetailEdit(orderDetailData, orderId) {
    return dispatch => {
        dispatch(request(orderDetailData))
    }
    function request(Agency) { return { type: adminConstants.ORDER_DETAIL_EDIT_REQUEST, order_details: orderDetailData } }
}

export function shippingDetailEdit(shippingDetailData) {
    return dispatch => {
        dispatch(request(shippingDetailData))
    }
    function request(shippingDetailData) { return { type: adminConstants.SHIPPING_DETAIL_EDIT_REQUEST, shipping_details: shippingDetailData } }
}

export function uploadFile(files) {
    return dispatch => {
        const resource = {};
        resource['file_name'] = files[0].name;
        resource['file_size'] = files[0].size;
        resource['file_extension'] = returnExtFromImage(files[0].name);
        dispatch(request(files, resource));
    }
    function request(files, resource) { return { type: adminConstants.UPLOAD_FILE, files: files, resource: resource } }
}

export function getOrderResources(orderId, type) {
    return dispatch => {
        dispatch(request())
        return orderService.getOrderResources(orderId, type).then(
            response => {
                dispatch(success(response.data))
                return response.data;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    function request() { return { type: adminConstants.LOADING_ORDER_RESOURCES_REQUEST } }
    function success(response) { return { type: adminConstants.LOADING_ORDER_RESOURCES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.LOADING_ORDER_RESOURCES_FAILURE, payload: error } }
}
export function getFolderOrderResources(orderId) {
    return dispatch => {
        dispatch(request())
        return orderService.getFolderOrderResources(orderId).then(
            response => {
                dispatch(success(response.data))
                return response.data;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    function request() { return { type: adminConstants.LOADING_ORDER_FOLDER_RESOURCES_REQUEST } }
    function success(response) { return { type: adminConstants.LOADING_ORDER_FOLDER_RESOURCES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.LOADING_ORDER_FOLDER_RESOURCES_FAILURE, payload: error } }
}
export function uuidFolderCall(orderId) {
    return dispatch => {
        dispatch(request())
        return orderService.uuidCall(orderId).then(
            response => {
                dispatch(success(response.data))
                return response.data;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    function request() { return { type: adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_REQUEST } }
    function success(response) { return { type: adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_FAILURE, payload: error } }
}
export function getAgencyFolderResources(agencyId) {
    return dispatch => {
        dispatch(request())
        return orderService.getAgencyFolderResources(agencyId).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    function request() { return { type: adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_REQUEST } }
    function success(response) { return { type: adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_FAILURE, payload: error } }
}

export function orderResourceDelete(resources, id) {
    return dispatch => {
        dispatch(request())
        return orderService.deleteOrderResource(id).then(
            response => {
                dispatch(success(resources))
                dispatch(alertActions.success(response.message))
                return resources;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error)
            }

        )

    }
    function request() { return { type: adminConstants.ORDER_RESOURCE_DELETE_REQUEST } }
    function success(resources) { return { type: adminConstants.ORDER_RESOURCE_DELETE_SUCCESS, payload: resources } }
    function failure(error) { return { type: adminConstants.LOADING_ORDER_RESOURCES_FAILURE, payload: error } }
}

export function orderResourceUploadCancel() {
    return dispatch => {
        dispatch(request())
    }
    function request() { return { type: adminConstants.ORDER_UPLOAD_CANCEL } }
}

export function getTripOrderUsers(id, page, filter, sort, pageSize) {
    return dispatch => {
        dispatch(request())
        return orderService.getTripOrderUsers(id, page, filter, sort, pageSize).then(response => {
            dispatch(success(response))
            return response;
        },
            error => {
                dispatch(failure(error))
            }
        );
    }
    function request() { return { type: adminConstants.GET_TRIP_ORDER_USERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_TRIP_ORDER_USERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_TRIP_ORDER_USERS_FAILURE, payload: error } }
}

export function getFollowingOrderUsers(id, page, filter, sort, pageSize) {
    return dispatch => {
        dispatch(request())
        return orderService.getFollowingOrderUsers(id, page, filter, sort, pageSize).then(response => {
            dispatch(success(response))
            return response
        },
            error => {
                dispatch(failure(error))
            });
    }
    function request() { return { type: adminConstants.GET_FOLLOWING_ORDER_USERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_FOLLOWING_ORDER_USERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_FOLLOWING_ORDER_USERS_FAILURE, payload: error } }
}

export function deleteOrderUser(id, users, userType, countColumn) {
    return dispatch => {
        dispatch(request())
        return orderService.deleteOrderUser(id).then(response => {
            dispatch(success(users, userType, countColumn))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function request() { return { type: adminConstants.DELETE_ORDER_USER_REQUEST } }
    function success(users, userType, countColumn) { return { type: adminConstants.DELETE_ORDER_USER_SUCCESS, users: users, userType: userType, countColumn: countColumn } }
    function failure(error) { return { type: adminConstants.DELETE_ORDER_USER_FAILURE, payload: error } }
}

export function updateOrderUser(id, userData, userType) {
    let tripUser;
    let followingUser;
    return dispatch => {
        if (userType === 'tripUser') {
            tripUser = true;
            followingUser = false;
        } else if (userType === 'followingUser') {
            tripUser = false;
            followingUser = true;
        }
        dispatch(request(tripUser, followingUser))
        return orderService.updateOrderUser(id, userData).then(
            response => {
                dispatch(success())
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
                return Promise.reject(error)
            }
        )
    }
    function request(tripUser, followingUser) { return { type: adminConstants.ORDER_USER_UPDATE_REQUEST, tripUser: tripUser, followingUser: followingUser } }
    function success() { return { type: adminConstants.ORDER_USER_UPDATE_SUCCESS } }
    function failure(error) { return { type: adminConstants.ORDER_USER_UPDATE_FAILURE, payload: error } }
}

export function getSpecialUser(orderId) {
    return dispatch => {
        dispatch(request())
        orderService.getSpecialUser(orderId).then(response => {
            dispatch(success(response))
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    function request() { return { type: adminConstants.GET_SPECIAL_ORDER_USER_REQUEST } }
    function success(response) { return { type: adminConstants.GET_SPECIAL_ORDER_USER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SPECIAL_ORDER_USER_FAILURE, payload: error } }
}

export function createSpecialUser(userData) {
    return dispatch => {
        return orderService.createSpecialUser(userData).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
                return response
            }
        ).catch(error => {
            dispatch(alertActions.error(error))
            return Promise.reject(error)
        })
    }
    // function request() { return { type: adminConstants.GET_SPECIAL_ORDER_USER_REQUEST } }
    function success(response) { return { type: adminConstants.CREATE_SPECIAL_ORDER_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_SPECIAL_ORDER_USER_FAILURE, payload: error } }
}

export function updateSpecialUser(userData) {
    return dispatch => {
        return orderService.updateSpecialUser(userData).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
            return response
        }, error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
            return Promise.reject(error)
        })
    }
    function success(response) { return { type: adminConstants.UPDATE_SPECIAL_ORDER_USER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_SPECIAL_ORDER_USER_FAILURE, payload: error } }
}
export function approveOrderUpload(orderId, value) {
    return dispatch => {
        dispatch(request())
        orderService.approveOrderUpload(orderId, value).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function request() { return { type: adminConstants.APPROVE_ORDER_UPLOAD_REQUEST } }
    function success(response) { return { type: adminConstants.APPROVE_ORDER_UPLOAD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.APPROVE_ORDER_UPLOAD_FAILURE, payload: error } }
}
export function updateIsInvoiced(orderId, value) {
    return dispatch => {
        dispatch(request())
        orderService.updateIsInvoiced(orderId, value).then(response => {
            console.log("response", response);
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function request() { return { type: adminConstants.UPDATE_IS_INVOICED_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_IS_INVOICED_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_IS_INVOICED_FAILURE, payload: error } }
}

export function getIsInvoiced(orderId) {
    return dispatch => {
        dispatch(request())
        orderService.getIsInvoiced(orderId).then(response => {
            console.log("getIsInvoiced", response);
            dispatch(success(response))
        }).catch(
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function request() { return { type: adminConstants.GET_IS_INVOICED_REQUEST } }
    function success(response) { return { type: adminConstants.GET_IS_INVOICED_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_IS_INVOICED_FAILURE, payload: error } }
}
export function getOrderSettings(orderId) {
    return dispatch => {
        dispatch(request())
        orderService.getOrderSettings(orderId).then(response => {
            dispatch(success(response))
        }).catch(
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function request() { return { type: adminConstants.GET_ORDER_SETTINGS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ORDER_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDER_SETTINGS_FAILURE, payload: error } }
}

export function changeOrderHashTag(orderId, hashTag) {
    return dispatch => {
        orderService.changeOrderHashTag(orderId, hashTag).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }, error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        });
    }
    function success(response) { return { type: adminConstants.ORDER_HASH_TAG_CHANGE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDER_SETTINGS_FAILURE, payload: error } }
}

export function changeOrderMessaging(orderId, data) {
    return dispatch => {
        orderService.changeOrderMessaging(orderId, data).then(response => {
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(alertActions.error(error))
        })
    }
    // function request() { return { type: adminConstants.GET_ORDER_SETTINGS_REQUEST } }
    // function success(response) { return { type: adminConstants.ORDER_MESSAGING_CHANGE_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_ORDER_SETTINGS_FAILURE, payload: error } }
}

export function updateOrderOpenPhotoUpload(orderId, value) {
    return dispatch => {
        orderService.updateOrderOpenPhotoUpload(orderId, value).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
            }
        ).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_OPEN_PHOTO_UPLOAD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_OPEN_PHOTO_UPLOAD_FAILURE, payload: error } }
}

export function updateOrderMaxPhotoUpload(orderId, value) {
    return dispatch => {
        orderService.updateOrderMaxPhotoUpload(orderId, value).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_MAX_PHOTO_UPLOAD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_MAX_PHOTO_UPLOAD_FAILURE, payload: error } }
}

export function updateOrderAutoApprovePhotos(orderId, value) {
    return dispatch => {
        orderService.updateOrderAutoApprovePhotos(orderId, value).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
            }
        ).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_AUTO_APPROVE_PHOTOS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDER_SETTINGS_FAILURE, payload: error } }
}

export function updateApproveOrderVideoUpload(orderId, value) {
    return dispatch => {
        orderService.updateApproveOrderVideoUpload(orderId, value).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
            }
        ).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_APPROVE_VIDEO_UPLOAD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_APPROVE_VIDEO_UPLOAD_FAILURE, payload: error } }
}

export function updateOrderUploadCutOffDate(orderId, value) {
    return dispatch => {
        return orderService.updateOrderUploadCutOffDate(orderId, value).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_FAILURE, payload: error } }
}

export function updateOrderUploadCutOffDateByEmail(orderId, value) {
    return dispatch => {
        return orderService.updateOrderUploadCutOffDateByEmail(orderId, value).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
            return response;
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_FAILURE, payload: error } }
}

export function allowReorderStatus(orderId, value) {
    return dispatch => {
        return orderService.allowReorderStatus(orderId, value).then(response => {
            dispatch(success(response))
            dispatch(alertActions.success(response.message))
        }).catch(error => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.ALLOW_REORDER_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ALLOW_REORDER_STATUS_FAILURE, payload: error } }
}

export function getAllowReorder(orderId) {
    return dispatch => {
        return orderService.getAllowReorder(orderId).then(response => {
            dispatch(success(response))
            // dispatch(alertActions.success(response.message))
        }).catch(error => {
            console.log("error==>", error);
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        })
    }
    function success(response) { return { type: adminConstants.GET_ALLOW_REORDER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALLOW_REORDER_FAILURE, payload: error } }
}

export function updateShippingDetails(orderId, Data) {
    return dispatch => {
        return orderService.updateShippingDetails(orderId, Data).then(
            response => {
                dispatch(successShippingDetail(response))
                dispatch(successPricingDetail(response))
                dispatch(alertActions.success(response.message))
                return response
            }, error => {
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }

    function successShippingDetail(response) { return { type: adminConstants.UPDATE_SHIPPING_DETAIL_SUCCESS, payload: response } }
    function successPricingDetail(response) { return { type: adminConstants.UPDATE_PRICING_DETAIL_SUCCESS, payload: response } }
}

export function updateProjectCameraShipDate(orderId, Data) {
    return dispatch => {
        return orderService.updateProjectCameraShipDate(orderId, Data).then(
            response => {
                dispatch(successProjectedPTMDate(response));
                dispatch(alertActions.success(response.message));
                return response;
            }, error => {
                dispatch(alertActions.error(error));
            }
        )
    }
    function successProjectedPTMDate(response) { return { type: adminConstants.UPDATE_PROJECTED_PTM_DATE_SUCCESS, payload: response } }
}

export function updateOrderVideoTitles(orderId, Data) {
    return dispatch => {
        return orderService.updateOrderVideoTitles(orderId, Data).then(
            response => {
                // dispatch(success(response))
                dispatch(successOrderDetail(response))
                dispatch(successProductionDetail(response))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }

    function successOrderDetail(response) { return { type: adminConstants.UPDATE_ORDER_DETAIL_SUCCESS, payload: response } }
    function successProductionDetail(response) { return { type: adminConstants.UPDATE_PRODUCTION_DETAIL_SUCCESS, payload: response } }
    // function success(response) { return { type: adminConstants.UPDATE_ORDER_VIDEO_TITLES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_VIDEO_TITLES_FAILURE, payload: error } }
}

export function toggleOrderEquipmentStatus(orderId, data) {
    return dispatch => {
        orderService.toggleOrderEquipmentStatus(orderId, data).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.UPDATE_ORDER_EQUIPMENT_UPLOAD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_EQUIPMENT_UPLOAD_FAILURE, payload: error } }
}

export function processShipStatus(orderId, data) {
    // console.log('order id', orderId);
    // console.log('data', data);
    return dispatch => {
        return orderService.processShipStatus(orderId, data).then(
            response => {
                if ('camera_ship_status' in response.data) {
                    dispatch(changeCameraSuccess(response))
                }
                if ('video_ship_status' in response.data) {
                    dispatch(changeVideoSuccess(response))
                }
                if ('mosaic_ship_status' in response.data) {
                    dispatch(changeMosaicSuccess(response))
                }
                if ('return_label_ship_status' in response.data) {
                    dispatch(changeReturnLabelSuccess(response))
                }
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function changeCameraSuccess(response) { return { type: adminConstants.UPDATE_ORDER_CAMERA_SHIP_STATUS_SUCCESS, payload: response } }
    function changeVideoSuccess(response) { return { type: adminConstants.UPDATE_ORDER_VIDEO_SHIP_STATUS_SUCCESS, payload: response } }
    function changeMosaicSuccess(response) { return { type: adminConstants.UPDATE_ORDER_MOSAIC_SHIP_STATUS_SUCCESS, payload: response } }
    function changeReturnLabelSuccess(response) { return { type: adminConstants.UPDATE_ORDER_RETURN_SHIP_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_CAMERA_SHIP_STATUS_FAILURE, payload: error } }
}

export function updateOrderDetail(data, id) {
    return dispatch => {
        return orderService.updateOrderDetail(data, id).then(
            response => {
                dispatch(successOrderDetail(response))
                dispatch(successShippingDetail(response))
                dispatch(successProductionDetail(response))
                dispatch(successCsrSteps(response))
                dispatch(alertActions.success(response.message))
                return response.data.order_details;
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function successOrderDetail(response) { return { type: adminConstants.UPDATE_ORDER_DETAIL_SUCCESS, payload: response } }
    function successShippingDetail(response) { return { type: adminConstants.UPDATE_SHIPPING_DETAIL_SUCCESS, payload: response } }
    function successProductionDetail(response) { return { type: adminConstants.UPDATE_PRODUCTION_DETAIL_SUCCESS, payload: response } }
    function successCsrSteps(response) { return { type: adminConstants.UPDATE_ORDER_CSR_STEPS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_DETAIL_FAILURE, payload: error } }
}


export function updatePricingDetail(data, id) {
    return dispatch => {
        return orderService.updatePricingDetail(data, id).then(
            response => {
                dispatch(successPricingDetail(response))
                dispatch(successOrderDetail(response))
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function successPricingDetail(response) { return { type: adminConstants.UPDATE_PRICING_DETAIL_SUCCESS, payload: response } }
    function successOrderDetail(response) { return { type: adminConstants.UPDATE_ORDER_DETAIL_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_PRICING_DETAIL_FAILURE, payload: error } }
}

export function processCamerasToShip(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) {
    return dispatch => {
        return orderService.processCamerasToShip(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters).then(
            response => {
                dispatch(success(response))
                return response
            }, error => {
                dispatch(failure(error))
                return Promise.reject(error)
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_CAMERAS_TO_SHIP_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_CAMERAS_TO_SHIP_FAILURE, payload: error } }
}


export function orderImages(order_id, image_version, tabName, page, filter, sort, pageSize, userRole) {
    return dispatch => {
        dispatch(request())
        return orderService.orderImages(order_id, image_version, tabName, page, filter, sort, pageSize, userRole).then(
            response => {
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ORDER_IMAGES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ORDER_IMAGES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDER_IMAGES_FAILURE, payload: error } }
}

// orderImagesWR order images without redux
export function orderImagesWR(order_id, image_version, tabName, page, filter, sort, pageSize, userRole) {
    return dispatch => {
        // dispatch(request())
        return orderService.orderImages(order_id, image_version, tabName, page, filter, sort, pageSize, userRole).then(
            response => {
                return response;
                // dispatch(success(response))
            }).catch(error => {
                return Promise.reject(error);
                // dispatch(failure(error))
            })
    }
    // function request() { return { type: adminConstants.GET_ORDER_IMAGES_REQUEST } }
    // function success(response) { return { type: adminConstants.GET_ORDER_IMAGES_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_ORDER_IMAGES_FAILURE, payload: error } }
}
export function getRequiredAssets(orders) {
    return dispatch => {
        orderService.getRequiredAssets(orders).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_REQUIRED_ASSESTS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_REQUIRED_ASSESTS_FAILURE, payload: error } }
}

export function allOrderImagesByPhase(order_id, image_version, tabName) {
    return dispatch => {
        dispatch(request())
        orderService.allOrderImagesByPhase(order_id, image_version, tabName).then(
            response => {
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ORDER_PHASE_IMAGES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ORDER_PHASE_IMAGES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ORDER_PHASE_IMAGES_FAILURE, payload: error } }
}

export function editedImagesAi(order_id) {
    return dispatch => {
        dispatch(request())
        orderService.editedImagesAi(order_id).then(
            response => {
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ORIGINAL_IMAGES_AI_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ORIGINAL_IMAGES_AI_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORIGINAL_IMAGES_AI_FAILURE, payload: error } }
}

export function producedImagesAi(order_id) {
    return dispatch => {
        dispatch(request())
        return orderService.producedImagesAi(order_id).then(
            response => {
                dispatch(success(response))
                return response
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_WORKING_IMAGES_AI_REQUEST } }
    function success(response) { return { type: adminConstants.GET_WORKING_IMAGES_AI_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_WORKING_IMAGES_AI_FAILURE, payload: error } }
}


export function recentlyProcessedOrder(page, filtered, sort, pageSize) {
    return dispatch => {
        return orderService.recentlyProcessedOrder(page, filtered, sort, pageSize).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_RECENTLY_PROCESSED_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_RECENTLY_PROCESSED_ORDERS_FAILURE, payload: error } }
}

export function storeEditorOrderImages(imagesData, orderId, folder) {
    return dispatch => {
        let toastIdImg = null;
        let toastImgProcess = null;
        return orderService.storeEditorOrderImages(imagesData, orderId, folder, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            if (toastIdImg === null) {
                toastIdImg = toast('Images uploading in Progress ' + percentCompleted + '%', {
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });
            } else {
                toast.update(toastIdImg, {
                    render: 'Images uploading in Progress ' + percentCompleted + '%',
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });

                if ((percentCompleted / 100) === 1) {
                    toast.done(toast.id);
                    if (!toastImgProcess) {
                        toastImgProcess = toast(<React.Fragment>Images are Processing. Please wait! <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
                            autoClose: false,
                            type: toast.TYPE.INFO,
                            position: "bottom-right",
                        });
                    }
                }
            }
        }).then(
            response => {
                console.log('toast images');
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                if (toastIdImg)
                    toast.dismiss(toastIdImg.id);
                dispatch(success(response));
                dispatch(alertActions.successAutoCloseFalse(response.message));
                return response;
            },
            error => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                // dispatch(alertActions.error(error.message));
                return Promise.reject(error);
            }
        )
    }
    // function request() { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_FAILURE, payload: error } }
}
export function storeOrderImagesInDB(imagesData, orderId, folder) {
    return dispatch => {
        let toastIdImg = null;
        let toastImgProcess = null;
        return orderService.storeOrderImagesInDB(imagesData, orderId, folder, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            if (toastIdImg === null) {
                toastIdImg = toast(<React.Fragment>Images are Processing. Please wait! <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
                // toastIdImg = toast('Images uploading in Progress ' + percentCompleted + '%', {
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });
            } else {
                toast.update(toastIdImg, {
                    render: 'Images uploading in Progress ' + percentCompleted + '%',
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });

                if ((percentCompleted / 100) === 1) {
                    toast.done(toast.id);
                    if (!toastImgProcess) {
                        toastImgProcess = toast(<React.Fragment>Images are Processing. Please wait! <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
                            autoClose: false,
                            type: toast.TYPE.INFO,
                            position: "bottom-right",
                        });
                    }
                }
            }
        }).then(
            response => {
                console.log('toast images');
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                if (toastIdImg)
                    toast.dismiss(toastIdImg.id);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                // dispatch(alertActions.error(error.message));
                return Promise.reject(error);
            }
        )
    }
    // function request() { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ORDER_IMAGES_IN_DB_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ORDER_IMAGES_IN_DB_FAILURE, payload: error } }
}

export function allowSave(flag,) {
    return dispatch => {
        dispatch({ type: adminConstants.ALLOW_SAVE_WORKING_IMAGES, payload:flag });
    }
}

export function disableSave(flag) {
    return dispatch => {
        dispatch({ type: adminConstants.DISABLE_SAVE_WORKING_IMAGES, payload:flag });
    }
}

export function replaceImage(imagesData) {
    return dispatch => {
        let toastIdImg = null;
        let toastImgProcess = null;
        return orderService.replaceImage(imagesData, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            if (toastIdImg === null) {
                toastIdImg = toast('Images uploading in Progress ' + percentCompleted + '%', {
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });
            } else {
                toast.update(toastIdImg, {
                    render: 'Images uploading in Progress ' + percentCompleted + '%',
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });

                if ((percentCompleted / 100) === 1) {
                    toast.done(toast.id);
                    if (!toastImgProcess) {
                        toastImgProcess = toast(<React.Fragment>Images are Processing. Please wait! <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
                            autoClose: false,
                            type: toast.TYPE.INFO,
                            position: "bottom-right",
                        });
                    }
                }
            }
        }).then(
            response => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                if (toastIdImg)
                    toast.dismiss(toastIdImg.id);
                // dispatch(success(response));
                dispatch(alertActions.successAutoCloseFalse(response.message));
                return response;


            },
            error => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                // dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                // dispatch(alertActions.error(error.message));
                return Promise.reject(error);
            }
        )
    }
    // function request() { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_REQUEST } }
    // function success(response) { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.STORE_EDITOR_ORDER_IMG_FAILURE, payload: error } }
}
export function replaceImageAi(imagesData, orderId) {
    return dispatch => {
        let toastIdImg = null;
        let toastImgProcess = null;
        return orderService.replaceImageAi(imagesData, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            if (toastIdImg === null) {
                toastIdImg = toast('Images uploading in Progress ' + percentCompleted + '%', {
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });
            } else {
                toast.update(toastIdImg, {
                    render: 'Images uploading in Progress ' + percentCompleted + '%',
                    progress: percentCompleted / 100,
                    type: toast.TYPE.INFO,
                    position: "bottom-right",
                });

                if ((percentCompleted / 100) === 1) {
                    toast.done(toast.id);
                    if (!toastImgProcess) {
                        toastImgProcess = toast(<React.Fragment>Images are Processing. Please wait! <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
                            autoClose: false,
                            type: toast.TYPE.INFO,
                            position: "bottom-right",
                        });
                    }
                }
            }
        }).then(
            response => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                if (toastIdImg)
                    toast.dismiss(toastIdImg.id);
                // dispatch(success(response));
                setTimeout(() => {
                    dispatch(producedImagesAi(orderId));
                    dispatch(editedImagesAi(orderId));
                }, 1000);
                dispatch(alertActions.successAutoCloseFalse(response.message));
                return response;


            },
            error => {
                if (toastImgProcess)
                    toast.dismiss(toastImgProcess.id);
                // dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                // dispatch(alertActions.error(error.message));
                return Promise.reject(error);
            }
        )
    }
}


export function assignUserToOrder(orderId, data) {
    return dispatch => {
        return orderService.assignUserToOrder(orderId, data).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.message))
                return Promise.reject(error);
            }
        )
    }
    // function request() { return { type: adminConstants.ASSIGN_USER_TO_ORDER_REQUEST } }
    function success(response) { return { type: adminConstants.ASSIGN_USER_TO_ORDER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.ASSIGN_USER_TO_ORDER_FAILURE, payload: error } }
}

export function updateOrderSettings(orderId, value, key) {
    return dispatch => {
        const data = {
            value: value,
            key: key
        }
        return orderService.updateOrderSettings(orderId, data).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error);
            }
        )
    }
    function success(response) { return { type: adminConstants.UPDATE_ORDER_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_SETTINGS_FAILURE, payload: error } }
}
export function updateOrderSettingsForTtk(orderId, value, key) {
    return dispatch => {
        const data = {
            value: value,
            key: key
        }
        return orderService.updateOrderSettingsForTtk(orderId, data).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error);
            }
        )
    }
    function success(response) { return { type: adminConstants.UPDATE_ORDER_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_SETTINGS_FAILURE, payload: error } }
}

export function updateSettingsInOrderDetail(orderId, value, key) {
    return dispatch => {
        const data = {
            value: value,
            key: key
        }
        return orderService.updateOrderSettings(orderId, data).then(
            response => {
                dispatch(success(response))
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                return Promise.reject(error);
            }
        )
    }
    function success(response) { return { type: adminConstants.UPDATE_SETTINGS_IN_ORDER_DETAIL_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_SETTINGS_IN_ORDER_DETAIL_FAILURE, payload: error } }
}

export function storeOrderImages(data, orderId, source) {
    return (dispatch, getState) => {
        const file = data.get('images[]').name.replace(/[- .()]/g, '');
        const start_time = new Date().getTime();
        let timer;
        return orderService.storeOrderImages(data, orderId, (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            const total_size = ((progress.total) / 1024 / 1024).toFixed(2);
            let uploaded = ((progress.loaded) / 1024 / 1024).toFixed(2)
            let minutes = 0;
            let seconds = 0;
            let elapsed_time = 0;
            let speed = Math.round((progress.total - progress.loaded) / 1024); //in kb
            timer = setInterval(() => {
                const time_now = new Date().getTime();
                seconds = Math.round((time_now - start_time) / 1000);
                minutes = Math.floor(seconds / 60);
                elapsed_time = (seconds === 60 ? (minutes) + ":00" : minutes + ":" + ((seconds - (minutes * 60)) < 10 ? "0" + (seconds - (minutes * 60)) : (seconds - (minutes * 60))))
                $(`#${file}_time`).html(elapsed_time + " | ");
            }, 0);
            $(`#${file}_total`).html(total_size + " MB");
            $(`#${file}_loaded`).html(uploaded + " MB / ");
            $(`#${file}_speed`).html(speed + " Kb/s | ");
            $(`#${file}`).css('width', percentCompleted + "%");
            $(`#${file}`).html(percentCompleted + "%");
        }, source).then(
            response => {
                // console.log('response', response);
                clearInterval(timer);
                // dispatch(orderImages(orderId, 'original', 'Uploaded-images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage, User.user.role.name))
                // dispatch(getOrderUploadInfo(orderId))
                // dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                console.log('error file name', file);
                clearInterval(timer);
                $(`#${file}`).removeClass('progress-bar-success').addClass('progress-bar-danger');
                dispatch(alertActions.error(error))
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.UPDATE_ORDER_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_IMAGE_SUCCESS, payload: error } }
}
export function storeOrderImagesInDbByFrontEnd(data, orderId, source) {
    return (dispatch, getState) => {

        const file = data.images[0].file_title.replace(/[- .()]/g, '');
        const start_time = new Date().getTime();
        let timer;
        return orderService.storeOrderImagesInDbByFrontEnd(data, orderId, (progress) => {

            let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            const total_size = ((progress.total) / 1024 / 1024).toFixed(2);
            let uploaded = ((progress.loaded) / 1024 / 1024).toFixed(2)
            let minutes = 0;
            let seconds = 0;
            let elapsed_time = 0;
            let speed = Math.round((progress.total - progress.loaded) / 1024); //in kb
            timer = setInterval(() => {
                const time_now = new Date().getTime();
                seconds = Math.round((time_now - start_time) / 1000);
                minutes = Math.floor(seconds / 60);
                elapsed_time = (seconds === 60 ? (minutes) + ":00" : minutes + ":" + ((seconds - (minutes * 60)) < 10 ? "0" + (seconds - (minutes * 60)) : (seconds - (minutes * 60))))
                $(`#${file}_time`).html(elapsed_time + " | ");
            }, 0);
            $(`#${file}_total`).html(total_size + " MB");
            $(`#${file}_loaded`).html(uploaded + " MB / ");
            $(`#${file}_speed`).html(speed + " Kb/s | ");
            $(`#${file}`).css('width', percentCompleted + "%");
            $(`#${file}`).html(percentCompleted + "%");
        }, source).then(
            response => {
                // console.log('response', response);
                clearInterval(timer);
                // dispatch(success(response));
                // toast.dismiss(toastIdImg);
                // dispatch(orderImages(orderId, 'original', 'Uploaded-images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage, User.user.role.name))
                // dispatch(getOrderUploadInfo(orderId))
                // dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                console.log('error file name', file);
                clearInterval(timer);
                $(`#${file}`).removeClass('progress-bar-success').addClass('progress-bar-danger');
                dispatch(alertActions.error(error))
                dispatch(failure(error));
                return Promise.reject(error)
            }
        )
    }
    function success(response) { return { type: adminConstants.STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_FAILURE, payload: error } }
}

export function getOrderUploadInfo(orderId) {
    return dispatch => {
       return orderService.getOrderUploadInfo(orderId).then(
            response => {
                dispatch(success(response));
                return response;
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_ORDER_UPLOAD_INFO_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ORDER_UPLOAD_INFO_FAILURE, payload: error } }
}

export function getSingleImage(imageId) {
    return dispatch => {
        orderService.getSingleImage(imageId).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_SINGLE_IMAGE_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_SINGLE_IMAGE_FAILURE, payload: error } }
}

export function getOrderImagesPath(orderId, folder) {
    return dispatch => {
      return  orderService.getOrderImagesPath(orderId, folder).then(
            response => {
                // console.log("response action: " , response);
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ORDER_IMAGE_PATH_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ORDER_IMAGE_PATH_FAILURE, payload: error } }
}


export function storePMOrderVideo(videoData, orderId, folder, key, loaderTitle) {
    return dispatch => {
        let toastIdVid = null;
        return orderService.storePMOrderVideo(videoData, orderId, folder, key, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            if (toastIdVid === null) {
                toastIdVid = toast(
                    loaderTitle + ' uploading in Progress ' + percentCompleted + '%',
                    {
                        progress: percentCompleted / 100,
                        type: toast.TYPE.INFO,
                        position: "bottom-right",
                    }
                );
            } else {
                toast.update(toastIdVid,
                    {
                        render: loaderTitle + ' uploading in Progress ' + percentCompleted + '%',
                        progress: percentCompleted / 100,
                        type: toast.TYPE.INFO,
                        position: "bottom-right",
                    }
                );
            }
        }).then(
            response => {
                toast.done(toast.id);
                dispatch(success(response));
                // dispatch(alertActions.success(response.message))
                dispatch(alertActions.successAutoCloseFalse(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                return Promise.reject(error);
            }
        )
    }

    function success(response) { return { type: adminConstants.STORE_PM_ORDER_VIDEO_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PM_ORDER_VIDEO_FAILURE, payload: error } }
}

export function getOrderItems(orderId) {
    return dispatch => {
        orderService.getOrderItems(orderId).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_ORDER_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ORDER_ITEMS_FAILURE, payload: error } }
}

export function updateTitlesMusic(data, id) {
    let status = false;
    return dispatch => {
        return orderService.updateOrderDetail({ order_details: data.order_details }, id).then(
            response => {
                orderService.updateShippingDetails(id, { shipping_details: data.shipping_details }).then(
                    response => {
                        orderService.updateOrderVideoTitles(id, { videoTitleData: data.production_details.video_title_templates, vt_agency_type_id: data.order_details.vt_agency_type_id }).then(
                            response => {
                                if (User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U])) {
                                    dispatch(alertActions.success('Order information updated successfully'));
                                } else {
                                    dispatch(alertActions.success('Order titles updated successfully'));
                                }
                                status = true;
                                return status
                            },
                            error => {
                                dispatch(alertActions.error(error))
                                status = false;
                                return status
                            }
                        )
                        status = true;
                        return status
                    },
                    error => {
                        dispatch(alertActions.error(error))
                        status = false;
                        return status
                    }
                )
                status = true;
                return status
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                status = false;
                return status
            }
        )
    }
    // function success(response) { return { type: frontEndUserConstants.GET_ORDER_ITEMS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ORDER_ITEMS_FAILURE, payload: error } }
}

export function storeKeepSakeOrder(data) {
    return dispatch => {
        return orderService.storeKeepSakeOrder(data).then(
            res => {
                dispatch(alertActions.success(res.message))
                return res;
            },
            err => {
                dispatch(alertActions.error(err))
                return Promise.reject(err)
            }
        )
    }
}

export function getLPRData(id) {
    return dispatch => {
        dispatch(request());
        orderService.getLPRData(id).then(
            res => {
                console.log('response lpr data', res)
                dispatch(success(res));
            },
            err => {
                dispatch(failure(err));
                dispatch(alertActions.error(err));
            }
        )
    }

    function request() { return { type: adminConstants.GET_LPR_DATA_REQUEST } }
    function success(response) { return { type: adminConstants.GET_LPR_DATA_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_LPR_DATA_FAILURE, payload: error } }
}

export function receiveOrder(data) {
    return dispatch => {
        orderService.receiveOrder(data).then(
            res => {
                dispatch(success(res));
            },
            err => {
                dispatch(alertActions.error(err))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ORDER_RECEIVE_STATUS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_ORDER_RECEIVE_STATUS_FAILURE, payload: error } }
}

export function getReOrders(page, pageSize, filter, sort, orderType, orderId, reorder_type) {
    return dispatch => {
        return orderService.getReOrders(page, pageSize, filter, sort, orderType, orderId, reorder_type).then(
            res => {
                dispatch(success(res));
                return res
            },
            err => {
                dispatch(failure(err));
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_REORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_REORDERS_FAILURE, payload: error } }
}

export function getReOrdersForProduction(page, pageSize, filter, sort, orderType, orderId, reorder_type) {
    return dispatch => {
        return orderService.getReOrdersForProduction(page, pageSize, filter, sort, orderType, orderId, reorder_type).then(
            res => {
                dispatch(success(res));
                return res
            },
            err => {
                dispatch(failure(err));
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_REORDERS_FOR_PRODUCTION_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_REORDERS_FOR_PRODUCTION_FAILURE, payload: error } }
}

export function getReorder(id) {
    return dispatch => {
        orderService.getReOrder(id).then(
            res => {
                dispatch(success(res));
            },
            err => {
                dispatch(failure(err))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_REORDER_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_REORDER_FAILURE, payload: error } }
}

export function clearOrderResources() {
    return dispatch => {
        dispatch(generalActions.success(frontEndUserConstants.CLEAR_ORDER_RESOURCES_SUCCESS, []))
    }
}

export function getCustomerServicesOrders(data) {
    return dispatch => {
        return orderService.getCustomerServicesOrders(data).then(
            response => {
                return response;
            }).catch(error => {
                return Promise.reject(error);
            })
    }
}

export function getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return orderService.getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response;
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_ORDERS_WITH_STATUS_FAILURE, payload: error } }
}
export function getOrderbyType(type, constant, pageSize, sort, orderType, filter, matchFilterWith) {
    return dispatch => {
        return orderService.getOrderbyType(type, pageSize, sort, orderType, filter, matchFilterWith).then(
            res => {
                dispatch(generalActions.success(constant, res))
                return res;
            },
            err => {
                dispatch(generalActions.failure(adminConstants.GET_FLAGGED_ORDERS_FAILURE, err))
            }
        )
    }
}

export function updateOrderDate(orderId, data) {
    return dispatch => {
        return orderService.updateOrderDate(orderId, data)
    }
}

export function holdOrderAddComment(id) {
    return dispatch => {
        return orderService.holdOrderAddComment(id)
    }
}

export function contactList(data) {
    return dispatch => {
        dispatch(request())
        return orderService.contactList(data).then(
            response => {
                dispatch(success(response))
                return response;
            },
            error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.GET_CONTACT_LIST_REQUEST } }
    function success(response) { return { type: adminConstants.GET_CONTACT_LIST_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_CONTACT_LIST_FAILURE, payload: error } }
}

export function toggleInventoryStatus(id, barcode, data) {
    return dispatch => {
        return orderService.toggleInventoryStatus(id, barcode, data).then(
            res => {
                dispatch(generalActions.success(res.message))
            },
            err => {
                dispatch(generalActions.failure(err))
            }
        )
    }
}

export function documentGenerator(data) {
    return dispatch => {
        return orderService.documentGenerator(data).then(
            res => {

            }
        )
    }
}

export function showOrdersFlyersList(data) {
    return dispatch => {
        return orderService.showOrdersFlyersList(data).then(
            res => {

                dispatch(generalActions.success(adminConstants.ORDERS_FLYERS_LIST_SUCCESS, res))
                return res;
            },
            err => {
                return err;
            }
        )
    }
}

export function addContactLogEntries(data) {
    return dispatch => {
        return orderService.addContactLogEntries(data).then(
            res => {
                dispatch(alertActions.success('Contact Log Entries added successfully'))
            }
        )
    }
}

export function getPreAwarenessFlyersHistory(data) {
    return dispatch => {
        return orderService.getPreAwarenessFlyersHistory(data).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_PREAWARENESS_FLYERS_HISTORY_SUCCESS, res))
                return res
            },
            err => {

            }
        )
    }
}

export function allowRegeneration(id) {
    return dispatch => {
        return orderService.allowRegeneration(id).then(
            res => {
                dispatch(alertActions.success(res.message))
            },
            err => {

            }
        )
    }
}

export function getOrderMosaic(id) {
    return dispatch => {
        return orderService.getOrderMosaic(id).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_ORDER_MOSAIC_SUCCESS, res))
            }
        )
    }
}

export function changeMosaicImage(data) {
    return dispatch => {
        return orderService.changeMosaicImage(data).then(
            res => {
                dispatch(alertActions.success(res.message))
            },
            err => {
                dispatch(alertActions.error(err))
            }
        )
    }
}

export function generateMosaicInstructions(data) {
    return dispatch => {
        return orderService.generateMosaicInstructions(data).then(
            res => {
                dispatch(alertActions.success(res.message))
            },
            err => {
                dispatch(alertActions.error(err))
            }
        )
    }
}

export function getOrderMaterials(data) {
    return dispatch => {
        return orderService.getOrderMaterials(data).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_ORDER_MATERIALS_SUCCESS, res))
                // dispatch(alertActions.success(res.message))
            },
            err => {
                dispatch(alertActions.error(err))
                dispatch(generalActions.failure(adminConstants.GET_ORDER_MATERIALS_FAILURE, err))
            }
        )
    }
}

export function getOrderMosaics(data) {
    return dispatch => {
        return orderService.getOrderMosaics(data).then(
            res => {
                // dispatch(generalActions.success(adminConstants.GET_ORDER_MOSAICS_SUCCESS, res))
                return res
            },
            err => {
            }
        )
    }
}

export function updateOrderProshowFile(orderId, data) {
    return dispatch => {
        dispatch(request())
        orderService.updateOrderProshowFile(orderId, data).then(
            response => {
                dispatch(alertActions.success(response.message))
                dispatch(success(response))
                // return response;
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.UPDATE_ORDER_PROSHOW_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_ORDER_PROSHOW_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_ORDER_PROSHOW_FAILURE, payload: error } }
}

export function unprocessOrders(data) {
    return dispatch => {
        dispatch(request())
        return orderService.unprocessOrders(data).then(
            response => {
                dispatch(alertActions.success(response.message))
                dispatch(success(response))
                return response;
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.SET_UNPROCESS_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.SET_UNPROCESS_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.SET_UNPROCESS_ORDERS_FAILURE, payload: error } }
}

export function deleteOrder(orderId) {
    return dispatch => {
        dispatch(request())
        return orderService.deleteOrder(orderId).then(
            response => {
                dispatch(alertActions.success(response.message))
                dispatch(success(response))
            }).catch(error => {
                dispatch(failure(error))
            })
    }
    function request() { return { type: adminConstants.DELETE_ORDER_REQUEST } }
    function success(resources) { return { type: adminConstants.DELETE_ORDER_SUCCESS, payload: resources } }
    function failure(error) { return { type: adminConstants.DELETE_ORDER_FAILURE, payload: error } }
}

export function OrdersTOMissingResouce(data) {
    return dispatch => {
        // dispatch(request())
        return orderService.OrdersTOMissingResouce(data).then(
            response => {
                // dispatch(alertActions.success(response.message))
                // dispatch(success(response))
                return response;
            }).catch(error => {
                return Promise.reject(error);
                // dispatch(failure(error))
            })
    }
    // function request() { return { type: adminConstants.ORDER_TO_MISSING_REQUEST } }
    // function success(response) { return { type: adminConstants.ORDER_TO_MISSING_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.ORDER_TO_MISSING_FAILURE, payload: error } }
}

export function getPendingOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return orderService.getPendingOrders(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                dispatch(failure(error))
            });
    }
    function request() { return { type: adminConstants.GET_PENDING_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PENDING_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PENDING_ORDERS_FAILURE, payload: error } }
}

export function getTtkPendingOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return dispatch => {
        dispatch(request())
        return orderService.getTtkPendingOrders(value, fromDate, toDate, page, pageSize, sorted).then(
            response => {
                dispatch(success(response))
                return response
            },
            error => {
                dispatch(failure(error))
            });
    }
    function request() { return { type: adminConstants.GET_TTK_PENDING_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_TTK_PENDING_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_TTK_PENDING_ORDERS_FAILURE, payload: error } }
}

export function submitPendingOrders(value) {
    return dispatch => {
        dispatch(request())
        return orderService.submitPendingOrders(value).then(
            response => {
                console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.STORE_PENDING_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_PENDING_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PENDING_ORDERS_FAILURE, payload: error } }
}

export function updateOrderStatusByTitle(orderId, status) {
    return dispatch => {
        // dispatch(request())
        return orderService.updateOrderStatusByTitle(orderId, status).then(
            response => {
                // dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                // dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
}

export function updateProgramType(orderId, programTypeId) {
    return dispatch => {
        return orderService.updateProgramType(orderId, programTypeId).then(
            response => {
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
}

export function videoTitlesByAgencyType(value, order_id) {
    return dispatch => {
        dispatch(request())
        return orderService.videoTitlesByAgencyType(value, order_id).then(
            response => {
                console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    function request() { return { type: adminConstants.VT_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.VT_ORDERS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.VT_ORDERS_FAILURE, payload: error } }
}


export function updateTravelerPassword(orderId, data) {
    return dispatch => {
        // dispatch(request())
        return orderService.updateTravelerPassword(orderId, data).then(
            response => {
                // console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    // function request() { return { type: adminConstants.VT_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.TRAVELER_PASSWORD_UPDATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.TRAVELER_PASSWORD_UPDATE_FAILURE, payload: error } }
}

export function updatePhotoAdminPassword(orderId, data) {
    return dispatch => {
        // dispatch(request())
        return orderService.updatePhotoAdminPassword(orderId, data).then(
            response => {
                // console.log('response', response.message);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error);
            })
    }
    // function request() { return { type: adminConstants.VT_ORDERS_REQUEST } }
    function success(response) { return { type: adminConstants.PHOTO_ADMIN_PASSWORD_UPDATE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.PHOTO_ADMIN_PASSWORD_UPDATE_FAILURE, payload: error } }
}

export function getPreShipmentEmailList(page, filter, sort, pageSize, orderType, matchFilterWith) {
    return dispatch => {
        return orderService.getPreShipmentEmailList(page, filter, sort, pageSize, orderType, matchFilterWith).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS, res));
                // dispatch(alertActions.success(res.message));
                return res;
            }
        ).catch(error => {
            dispatch(generalActions.failure(error));
            dispatch(alertActions.error(error));
            return Promise.reject(error);
        })
    }
}

export function getPreDepartureList(page, pageSize, sort, orderType, filter, matchFilterWith) {
    return dispatch => {
        return orderService.getPreDepartureList(page, pageSize, sort, orderType, filter, matchFilterWith).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_PRE_DEPARTURE_LIST_SUCCESS, res));
                return res;
            }
        ).catch(error => {
            dispatch(generalActions.failure(error));
            dispatch(alertActions.error(error));
            return Promise.reject(error);
        })
    }
}

export function getOrderCheckInList(pageSize, sort, orderType, filter, matchFilterWith) {
    return dispatch => {
        return orderService.getOrderCheckInList(pageSize, sort, orderType, filter, matchFilterWith).then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_ORDER_CHECKIN_LIST_SUCCESS, res));
                return res;
            }
        ).catch(err => {
            dispatch(generalActions.failure(adminConstants.GET_ORDER_CHECKIN_LIST_FAILURE, err));
            dispatch(alertActions.error(err));
            return Promise.reject(err);
        })
    }
}

export function saveSearchParams(data) {
    return dispatch => {
        dispatch(generalActions.success(adminConstants.SAVE_SEARCH_PARAMS, { data: data }))
    }
}

export function orderCheckout(data) {
    return dispatch => {
        return orderService.orderCheckout(data).then(
            res => {
                // dispatch(generalActions.success(adminConstants.GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS, res));
                dispatch(alertActions.success(res.message));
                history.push('/photo-gallery');
                return res;
            }
        ).catch(error => {
            // dispatch(generalActions.failure(error));
            dispatch(alertActions.error(error));
            return Promise.reject(error);
        })
    }
}

export function getOrderLatestComment(data) {
    return dispatch => {
        return orderService.getOrderLatestComment(data).then(
            res => {
                // dispatch(generalActions.success(adminConstants.GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS, res));
                // dispatch(alertActions.success(res.message));
                return res;
            }
        ).catch(error => {
            // dispatch(generalActions.failure(error));
            // dispatch(alertActions.error(error));
            return Promise.reject(error);
        })
    }
}

export function storePMFullVideo(videoData, orderId, folder, key) {
    return dispatch => {
        return orderService.storePMFullVideo(videoData, orderId, folder, key).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.successAutoCloseFalse(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.errorAutoCloseFalse(error.message));
                return Promise.reject(error);
            }
        )
    }
    function success(response) { return { type: adminConstants.STORE_PM_ORDER_VIDEO_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PM_ORDER_VIDEO_FAILURE, payload: error } }
}