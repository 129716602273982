import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getOrderUploadInfo, orderImages, getSingleImage} from 'redux/actions/order-actions';
import User from 'helper/User';
import ImageControls from 'components/FrontEnd/PhotoGallery/ImageControls';
import Image from 'components/FrontEnd/PhotoGallery/Image';
import {SortableContainer, arrayMove, SortableElement} from 'react-sortable-hoc';
import {Slider, Rail, Handles, Tracks, Ticks} from "react-compound-slider";
import {SliderRail, Handle, Track, Tick} from "components/FrontEnd/PhotoGallery/SliderStyle"; // example render components - source below

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import {rotateImage, deleteImage} from 'redux/actions/imageActions';
import {Roles} from 'constants/Roles';
import {isMobile} from 'react-device-detect'
import {confirmAlert} from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import {toggleModal, s3StaticPath} from 'helper/helperFunctions';
import {deleteImages} from '../../../services/order';
import {cloneDeep} from 'lodash'


const SortableItem = SortableElement(({
                                          image,
                                          openPopup,
                                          item_index,
                                          showData,
                                          imageSize,
                                          selectImageHandler,
                                          selectedImages
                                      }) => {
        return (
            <Image showData={showData} selected={selectedImages.includes(item_index) ? 'selected' : 'not selected'}
                   selectImageHandler={selectImageHandler} imageSize={imageSize} openPopup={openPopup} image={image}
                   index={item_index}/>)
    }
);

const ImagesList = SortableContainer(({
                                          imagesData,
                                          openPopup,
                                          disabled,
                                          imageSize,
                                          showData,
                                          selectImageHandler,
                                          selectedImages
                                      }) => {
    return (
        <div>
            {
                imagesData.map((image, i) => {
                    return (
                        <SortableItem showData={showData} selectedImages={selectedImages}
                                      selectImageHandler={selectImageHandler} imageSize={imageSize}
                                      disabled={(disabled === 1 || disabled === true) ? true : false}
                                      openPopup={openPopup} key={image.id} index={i} item_index={i} image={image}/>
                    );
                })
            }
        </div>
    )
});


class PhotoGallery extends Component {

    constructor(props) {
        super(props)

        this.state = {
            imagesData: this.props.imagesData,
            pageSize: '',
            photoIndex: 0,
            isOpen: false,
            saveDragDrop: false,
            singleImageOpen: false,
            imageSize: 3,
            selectedImages: [],
            saveAlert: false,
            showImgData: false,
            imageVersion: 'original',
            enableSave: false,
            sizes: ['thumb', 'thumb_mid', 'thumb_large', 'thumb_xlarge', 'thumb_xxlarge', 'thumb_xxxlarge', 'full'],
            fullIndex: 6,
            isChecked: false
        }
        this.toggleModal = toggleModal.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.imagesData !== prevProps.imagesData) {
            this.setState({
                imagesData: this.props.imagesData,
                enableSave: false
            });
        }
        if ((this.props.orderUploadInfo.finalize_by_editor.value !== prevProps.orderUploadInfo.finalize_by_editor.value)) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
    }

    componentDidMount() {
        var self = this;
        setInterval(function (ind = 0) {
            if (self.state.isOpen && self.state.singleImageOpen === false) {
                if (self.props.imagesData.length > 1) {
                    $(".ril-next-button").trigger("click");
                }
            }
        }, 8000);
        if (this.props.orderUploadInfo.finalize_by_editor.value !== undefined) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
        // this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
    }

    openPopup = (index, singleImage = false) => {
        if (singleImage) {
            this.setState({isOpen: true, photoIndex: index, singleImageOpen: singleImage})
        } else {
            this.setState({isOpen: true, photoIndex: index})
        }
    }

    sortStart = ({index}) => {
        console.log("Sort start", index);
        let sizes = this.state.sizes;
        $('.moving').find('.uploadedImageSize').find('img').after(() => {
            return (this.state.selectedImages.map(item => {
                console.log("item: " , item);
                if (item !== index){
                    console.log("item !== ", item);
                    return `<img class="appendedImgs" src=${this.state.imagesData[item].resized_image_path.replace('SIZE', sizes[this.state.imageSize - 1])} />`
                }
                else
                    return null
            }) + (this.state.selectedImages.length > 0 ? `<span class="selectedCount purple_blue_gradient text_18">${this.state.selectedImages.length}</span>` : ''))
        })
        return null;
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            let imagesData = this.state.imagesData
            if (this.state.selectedImages.length > 0) {
                const newElement = imagesData[newIndex];
                const tempArr = [];
                this.state.imagesData.forEach((item, index) => {
                    if (!this.state.selectedImages.includes(index)) {
                        tempArr.push(item)
                    } 
                });
                let newElementIndex = tempArr.findIndex(item => {
                    return item.id === newElement.id
                })
                if (newIndex > oldIndex) {
                    console.log('increasing index')
                    newElementIndex += 1
                }
                this.state.selectedImages.forEach((item, index) => {
                    imagesData[item] = { ...imagesData[item], moved: 1 };
                    tempArr.splice(newElementIndex + index, 0, imagesData[item]);
                })
                imagesData = tempArr
            } else {
                imagesData[oldIndex] = { ...imagesData[oldIndex], moved: 1 };
                imagesData = arrayMove(imagesData, oldIndex, newIndex)
            }
            this.setState({
                imagesData: imagesData,
                // saveAlert: true
            }, () => {
                if (this.state.selectedImages.length > 0) {
                    this.setState({
                        selectedImages: [],
                    })
                }
            });
        }
        this.setState({
            enableSave: true
        })
    }
      

    selectImageHandler = (index) => {
        let selectedImages = this.state.selectedImages;
        if (selectedImages.includes(index)) {
            selectedImages = selectedImages.filter(imageIndex => {
                return imageIndex !== index
            })
        } else {
            selectedImages.push(index);
        }
        var selectedImagesSorted = selectedImages.sort((a, b) => a - b);
        this.setState({
            selectedImages: selectedImagesSorted
        })
    }

    showMoreImagesPagination = (e, page) => {
        e.preventDefault();
        this.setState({
            selectedImages: []
        });
        this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', page, '', '', this.state.pageSize, User.user.role.name);
    }

    changePageSize = (e) => {
        this.setState({
            // pageSize: e.target.value
            pageSize: e.target.value
        }, () => {
            this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
        })
    }

    saveDragDropHandler = (e) => {
        this.setState({
            saveDragDrop: true
        }, () => {
            this.setState({
                saveDragDrop: false
            })
        })
    }

    deleteImage = (id, photoIndex) => {
        this.props.deleteImageCall(id, this.state.imageVersion).then(
            res => {
                if (photoIndex === this.state.imagesData.length - 1) {
                    this.setState({
                        photoIndex: photoIndex - 1
                    })
                }
            }
        );
    }

    deleteSelected = () => {
        const images = [];
        this.state.selectedImages.forEach(item => {
            images.push(this.state.imagesData[item].id);
        });
        const data = {images, version: this.state.imageVersion}
        deleteImages(data).then(
            res => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', this.props.current_page, '', '', this.state.pageSize, User.user.role.name);
                this.setState({
                    selectedImages: []
                })
            }
        )
    }

    rotateImage = (id, direction) => {
        this.props.rotateImageCall(id, direction);
    }

    sliderUpdate = (update) => {
        const newSize = update[0];
        if (this.state.imageSize !== newSize) {
            this.setState({
                imageSize: newSize
            })
        }
    }

    sliderChange = (values) => {
        const newSize = values[0];
        if (this.state.imageSize !== newSize) {
            this.setState({
                imageSize: newSize
            })
        }
    }

    closeLightBox = () => {
        if (this.state.singleImageOpen) {
            this.setState({isOpen: false, singleImageOpen: false})
        } else {
            this.setState({isOpen: false})
        }
    }

    confirmImagesDelete = () => {
        confirmAlert({
            customUI: ({onClose}) => <ConfirmAlertForDelete typeName='Images' onClosePro={onClose} deleteType={() => {
                this.deleteSelected()
            }}/>
        })
    }

    confirmImageDelete = (id, photoIndex) => {
        confirmAlert({
            customUI: ({onClose}) => <ConfirmAlertForDelete typeName='Image' onClosePro={onClose} deleteType={() => {
                this.deleteImage(id, photoIndex)
            }}/>
        })
    }

    downloadImage = (file) => {
        const downloadUrl = file.edited_image_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', "download")
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    getLightBoxImagePath = (index) => {
        if (index === this.state.imagesData.length || index < 0)
            return null;
        const image = this.state.imagesData[index];
        return image.edited_image_path.replace('SIZE', this.state.sizes[this.state.imageSize]) + "?updated_at=" + image.updated_at;
    }

    renderNewImage = (index) => {
        let imageSrc = this.getLightBoxImagePath(index);
        if (imageSrc.match(/\.jpg/)) {
            imageSrc = imageSrc.replace(/\.jpg/, '.JPG');
        } else {
            imageSrc = imageSrc.replace(/\.JPG/, '.jpg');
        }
        return <img className='resolvedImage' src={imageSrc} alt=""/>
    }
    getImagePath = (src, index) => {
        const images = cloneDeep(this.state.imagesData);
        const image = cloneDeep(images[index]);
        if (!image.edited_image_path.includes(src)) {
            return;
        }
        if (Number(this.props.orderUploadInfo.finalize_by_qa.value) === 1) {
            if ('file_path' in image.image_versions) {
                image.edited_image_path = src.replace(/\.jpg/, '.JPG');
            } else {
                image.edited_image_path = s3StaticPath('img/image-placeholder.png');
            }
        } else {
            if (src.match(/\.jpg/)) {
                image.edited_image_path = src.replace(/\.jpg/, '.JPG');
            } else {
                image.edited_image_path = src.replace(/\.JPG/, '.jpg');
            }
        }
        setTimeout(() => {
            images[index] = image;
            this.setState({
                imagesData: images
            })
        }, 1000)

        // if (Number(this.props.orderUploadInfo.finalize_by_qa.value) === 1) {
        //     const images = cloneDeep(this.state.imagesData);
        //     const image = images.find(item => item.edited_image_path.replace('SIZE', this.state.sizes[this.state.imageSize]) === src.replace(`?updated_at=${item.updated_at}`, ''));
        //     if ('file_path' in image.image_versions) {
        //         image.edited_image_path = src.replace(/\.jpg/, '.JPG');
        //     } else {
        //         image.edited_image_path = s3StaticPath('img/image-placeholder.png');
        //     }
        //     this.setState({
        //         imagesData: images
        //     })
        // } else {
        //     return src
        // }
    }
    checkAll = (e) => {
		const check = e.target.checked;
		const selectedImages = [];
		const data = this.state.imagesData
		if (check){
			data.forEach((item, index) => {
				selectedImages.push(index);
			})
			this.setState({
				selectedImages,
				isChecked: true,
			})
			// console.log("selectedImgArr", selectedImgArr);
		}else{
				this.setState({
					selectedImages: [],
					isChecked: false
				})
			}
	}
    render() {
        const {photoIndex, isOpen} = this.state;
        const {imagesData} = this.state;
        const {orderUploadInfo, pages, current_page, total_images, from_page} = this.props;
        const paginationLinks = [];
        const sliderStyle = {
            position: "relative",
            width: "100%"
        };
        const domain = [1, 6];
        const defaultValues = [this.state.imageSize];
        let sizes = this.state.sizes;
        for (let index = 1; index <= pages; index++) {
            paginationLinks.push(index);
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div>
                        <h2 style={{textAlign: 'left !important', 'fontSize': 'xxx-large !important'}}
                            className="panel_heading mt-xlg text-left">{this.props.extraHtml.galleryHeading}</h2>
                    </div>
                    {/* {
                        Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && User.hasRole(Roles.PHOTO_ADMIN) &&
                        <ImageControls imageVersion={this.state.imageVersion} saveDrag={this.state.saveDragDrop}
                                       images={this.state.imagesData}/>
                    } */}

                    <div className="row mb-xlg mt-xlg gallery_view_box">
                        <div className="col-md-12 mb-md">
                            <p className="gallery_view_box_heading">Gallery Viewing Options</p>
                        </div>
                        <div className="col-md-12 mb-md">
                            <div className="alignCenter spaceBetween flexElem respFlex mb-md">
                                <div className="flexElem justify_left_center_991 zoomerContainer">
                                    <span className="mr-md text_16">Zoom</span>
                                    <div className="flexElem alignCenter zoomer">
                                        <div className="mr-md"><img src={s3StaticPath('img/zoom_out.png')}
                                                                    alt="Zoom Out"/></div>
                                        <div style={{maxWidth: "100%", width: "200px"}}>
                                            <Slider
                                                mode={1}
                                                step={1}
                                                domain={domain}
                                                rootStyle={sliderStyle}
                                                onUpdate={this.sliderUpdate}
                                                onChange={this.sliderChange}
                                                values={defaultValues}
                                            >
                                                <Rail>
                                                    {({getRailProps}) => <SliderRail getRailProps={getRailProps}/>}
                                                </Rail>
                                                <Handles>
                                                    {({handles, getHandleProps}) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                                <Handle
                                                                    key={handle.id}
                                                                    handle={handle}
                                                                    domain={domain}
                                                                    getHandleProps={getHandleProps}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Handles>
                                                <Tracks left={false} right={false}>
                                                    {({tracks, getTrackProps}) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({id, source, target}) => (
                                                                <Track
                                                                    key={id}
                                                                    source={source}
                                                                    target={target}
                                                                    getTrackProps={getTrackProps}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Tracks>
                                                <Ticks count={5}>
                                                    {({ticks}) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                                <Tick key={tick.id} tick={tick} count={ticks.length}/>
                                                            ))}
                                                        </div>
                                                    )}
                                                </Ticks>
                                            </Slider>
                                        </div>
                                        <div className="ml-lg"><img src={s3StaticPath('img/zoom_in.png')}
                                                                    alt="Zoom In"/></div>
                                    </div>
                                </div>

                                <div className="inlineFlexElem respFlex resp_767 alignCenter text_left_center_767 flexGap">
                                    <div className="inlineFlexElem alignCenter" style={{width: 'auto'}}>
                                        <div className="text_16">
                                            Show Gallery Info &nbsp;
                                        </div>
                                        <div>
                                            <input type="checkbox"
                                                   onChange={() => this.setState({showImgData: !this.state.showImgData})}
                                                   id="switch" checked={this.state.showImgData ? 'checked' : ''}
                                                   className="cssSwitch"/><label className="cssSwitch"
                                                                                 htmlFor="switch">Toggle</label>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            imagesData.length > 0 &&
                                            <button onClick={() => this.setState({isOpen: true, photoIndex: 0})}
                                                    className="themeBtn_14 pl-xlg pr-xlg blue_theme_button hover_dark">Photo
                                                Slideshow</button>
                                        }
                                    </div>

                                    {/* <div>
                                        <select className='form-control' value={this.state.pageSize}
                                                style={{'marginLeft': 'auto'}} onChange={this.changePageSize}
                                                name="pageSize" id="">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value=''>Show All</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {
                            User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-md">
                                        <ImageControls
                                            imageVersion={this.state.imageVersion}
                                            saveDrag={this.state.saveDragDrop}
                                            images={this.state.imagesData}
                                            orderUploadInfo={orderUploadInfo}
                                        />
                                    </div>
                                    <div className="col-md-5 mb-md flexElem spaceBetween">
                                        {
                                        <div className="text-center">
                                            <button onClick={this.confirmImagesDelete} type='button' style={{visibility: `${this.state.selectedImages.length > 1 ? '' : 'collapse'}`}}
                                                    className="themeBtn_14 pl-xlg pr-xlg blue_theme_button hover_dark">
                                                Delete Selected
                                            </button>
                                        </div>}
                                        {/* {
                                        <div className="text-center">
                                            <input type="checkbox" name="is_active" className="checkbox"  onChange={(e) =>{this.checkAll(e)}} checked={this.state.isChecked}
                                            readOnly={true} /><span className="checkmark"></span>
                                        </div>} */}
                                        <div className="text-center">
                                            <button style={{
                                                cursor: this.state.enableSave === false ? 'not-allowed' : 'default'
                                            }} disabled={this.state.enableSave === false} onClick={this.saveDragDropHandler}
                                                    type='button'
                                                    className="themeBtn_14 pl-xlg pr-xlg blue_theme_button hover_dark">
                                                Save Photo Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            // <React.Fragment>
                            //     <div className="col-md-3  mb-xlg">
                            //         <div className="text-center mt-xlg">
                            //             <button style={{
                            //                 cursor: this.state.enableSave === false ? 'not-allowed' : 'default'
                            //             }} disabled={this.state.enableSave === false} onClick={this.saveDragDropHandler}
                            //                     type='button'
                            //                     className="themeBtn_14 block blue_theme_button hover_dark">
                            //                 Save Photo Changes
                            //             </button>
                            //         </div>
                            //     </div>
                            //     {
                            //         this.state.selectedImages.length > 1 &&
                            //         <div className="col-md-3  mb-xlg">
                            //             <div className="text-center mt-xlg">
                            //                 <button onClick={this.confirmImagesDelete} type='button'
                            //                         className="themeBtn_14 block red_gradient hover_dark">
                            //                     Delete Selected
                            //                 </button>
                            //             </div>
                            //         </div>
                            //     }
                            //     <br/>
                            // </React.Fragment>
                        }
                    </div>

                    <div className={`companyImagesGrid hasMargin grid_${this.state.imageSize - 1}`}>
                        <ImagesList showData={this.state.showImgData} onSortStart={this.sortStart} distance={5}
                                    selectedImages={this.state.selectedImages}
                                    selectImageHandler={this.selectImageHandler}
                                    imageSize={sizes[this.state.imageSize - 1]}
                                    disabled={Number(orderUploadInfo.photos_submitted_for_video_production.value) === 1 || isMobile || User.hasRole([Roles.TRAVELER])}
                                    helperClass='moving' transitionDuration={400} lockToContainerEdges={true}
                                    openPopup={this.openPopup} axis='xy' imagesData={imagesData}
                                    onSortEnd={this.onSortEnd}/>
                    </div>

                    <div className="text-center">
                        <ul className="pagination mt-lg">
                            {
                                paginationLinks.length > 1 &&
                                paginationLinks.map(page =>
                                    <li key={page} className={current_page === page ? 'active' : ''}>
                                        <a href={`/?page=${page}`} type='button' onClick={(e) => {
                                            this.showMoreImagesPagination(e, page)
                                        }}>{page}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="row mb-xlg">
                        {
                            User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                            <div className="col-md-3  mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    <button style={{
                                        cursor: this.state.enableSave === false ? 'not-allowed' : 'default'
                                    }} disabled={this.state.enableSave === false} onClick={this.saveDragDropHandler}
                                            type='button' className="themeBtn_14 block blue_theme_button hover_dark">
                                        Save Photo Changes
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            (Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && User.hasRole(['PHOTO_ADMIN'])) &&
                            <div className="col-md-3 mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    <a onClick={this.props.toggleUploader ? null : this.props.uploadMore} href="#top"
                                       className="themeBtn_14 block blue_theme_button hover_dark">
                                        Upload More Photos
                                    </a>
                                </div>
                            </div>
                        }
                        {
                            this.props.extraHtml.submitBtn &&
                            <div className="col-md-4 mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    {this.props.extraHtml ? this.props.extraHtml.submitBtn : ""}
                                </div>
                            </div>
                        }
                    </div>
                    <ThemeModal title='Save Drag Drop' modalClass='section-frontEnd frontEndModal'
                                show={this.state.saveAlert} hide={() => {
                        this.toggleModal('saveAlert')
                    }}>
                        <button className='themeBtn_14 no_radius blue_theme_button hover_dark'>Save Drag Drop</button>
                        <button className='themeBtn_14 no_radius blue_theme_button hover_dark ml-md'>Continue
                            Dragging
                        </button>
                    </ThemeModal>
                </div>

                {(isOpen && imagesData.length > 0) && (
                    <Lightbox
                        mainSrc={this.getLightBoxImagePath(photoIndex)}
                        nextSrc={this.getLightBoxImagePath(photoIndex + 1)}
                        prevSrc={this.getLightBoxImagePath(photoIndex - 1)}
                        onCloseRequest={this.closeLightBox}
                        onImageLoadError={(src) => this.getImagePath(src, photoIndex)}
                        imageLoadErrorMessage={this.renderNewImage(photoIndex)}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: photoIndex - 1,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: photoIndex + 1,
                            })
                        }
                        toolbarButtons={
                            [
                                <div className="fb-share-button"
                                     data-href={imagesData[photoIndex].edited_image_path.replace('SIZE', 'full') + ''}
                                     data-layout="button" data-size="large">
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${imagesData[photoIndex].edited_image_path.replace('SIZE', 'full')}`, 'popup', 'height=600,width=600')
                                    }} style={{
                                        color: '#fff',
                                        background: '#3b5998',
                                        padding: '5px',
                                        borderRadius: '5px'
                                    }}
                                       href={`https://www.facebook.com/sharer/sharer.php?u=${imagesData[photoIndex].edited_image_path + imagesData[photoIndex].file_title + "." + imagesData[photoIndex].image_format.extension}`}
                                       className="fb-xfbml-parse-ignore"><i className='fa fa-facebook'></i> Share</a>
                                </div>,
                                Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && !User.hasRole(Roles.TRAVELER) &&
                                <i onClick={() => {
                                    this.confirmImageDelete(imagesData[photoIndex].id, photoIndex)
                                }} className='fa fa-trash ml-md lightBoxDownload'></i>,
                                <i onClick={() => {
                                    this.downloadImage(imagesData[photoIndex])
                                }} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                            ]
                        }
                        imageCaption={
                            this.state.singleImageOpen &&
                            <React.Fragment>
                                <div className='text-center'>
                                    <p>Photos {from_page + photoIndex} of {total_images}</p>
                                </div>
                                {
                                    Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                                    <div className='flexElem' style={{justifyContent: 'center'}}>
                                        <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                            this.rotateImage(imagesData[photoIndex].id, 'left')
                                        }}>
                                            <i style={{fontSize: '40px', width: '100%'}} className="fa fa-undo"
                                               aria-hidden="true"></i>
                                            <p>Rotate Left</p>
                                        </div>
                                        <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                            this.rotateImage(imagesData[photoIndex].id, 'right')
                                        }}>
                                            <i style={{fontSize: '40px', width: '100%'}} className="fa fa-repeat"
                                               aria-hidden="true"></i>
                                            <p>Rotate Right</p>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    />
                )}
                <div id="companyImagesInfo"></div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {orderUploadInfo} = state.frontEndUserReducer;
    const {
        loadingOrder,
        imagesData,
        pages,
        current_page,
        perPage,
        order_details,
        total_images,
        from_page
    } = state.ordersReducer;
    return {
        orderUploadInfo,
        loadingOrder,
        imagesData,
        pages,
        current_page,
        perPage,
        order_details,
        total_images,
        from_page
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderUploadInfoCall: (orderId) => {
            dispatch(getOrderUploadInfo(orderId))
        },
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => {
            dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole))
        },
        getSingleImageCall: (imageId) => {
            dispatch(getSingleImage(imageId))
        },
        rotateImageCall: (imageId, direction) => {
            dispatch(rotateImage(imageId, direction))
        },
        deleteImageCall: (id) => {
            return dispatch(deleteImage(id))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery)
