import React, { Component } from 'react';
// import footer_logo from "assets/img/footer_logo.png";
import Logo from 'components/FrontEnd/Brand/Logo';
// import MyImage from 'components/Upload/MyImage';
import { Link } from "react-router-dom";
import { hasRole } from 'helper/helperFunctions';
import FooterLogo from '../Brand/FooterLogo';

class Footer extends Component {
  constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
        this.fbLike = this.fbLike.bind(this);
    }

    fbLike(){
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.0";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }

    componentDidMount(){
      this.fbLike();
    }

  render() {
    return (
      <footer id="footer">
        <div className="container">
          <div className="flexElem respFlex">
            <div className="footerCol">
              <div className="text-center agencyLogoContainer">
                <FooterLogo />
                {/* <img src={footer_logo} alt="footerLogoImage" /> */}
              </div>
              {/* <div className="text-center socialIcons">
                <a href="#!" className="link_hover"><i className="fa fa-twitter"></i></a>
                <a href="#!" className="link_hover"><i className="fa fa-linkedin"></i></a>
                <a href="#!" className="link_hover"><i className="fa fa-youtube"></i></a>
              </div> */}
            </div>

            <div className="footerCol">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <ul className="footer_menus">
                    <li className="link_hover"><Link to="/">Home</Link></li>
                    <li className="link_hover"><Link to="/gtv-how-it-works">Group Travel Videos App</Link></li>
                    <li className="link_hover"><Link to="/Keepsake_Video">Keepsake Video</Link></li>
                    <li className="link_hover"><Link to="/Mozaix">PhotoMozaix&trade;</Link></li>
                  </ul>
                </div>

                <div className="col-md-4 col-sm-6">
                  <ul className="footer_menus">
                    {/* <li className="link_hover"><Link to="/About_Us">Planning Your Own Trip</Link></li> */}
                    {
                      !hasRole(['Photo Admin', 'Traveler']) &&
                      <React.Fragment>
                        <li className="link_hover"><Link to="/Advertise_GTV">Advertise GTV on Your Website</Link></li>
                        <li className="link_hover"><Link to="/Tour_Operator_Branding">Tour Operator Branding</Link></li>
                      </React.Fragment>
                    }
                    <li className="link_hover"><Link to="/About_Us">About Us</Link></li>
                    <li className="link_hover"><Link to="/Contact_Us">Contact Us</Link></li>
                  </ul>
                </div>

                <div className="col-md-4 col-sm-6">
                  <ul className="footer_menus">
                    <li className="link_hover"><Link to="Customer_Testimonials">Testimonials</Link></li>
                    <li className="link_hover"><Link to="Member_Affiliations">Member Affiliations </Link></li>
                    {
                      !hasRole(['Photo Admin', 'Traveler']) &&
                        <li className="link_hover"><Link to="Clients">Clients</Link></li>
                    }
                    <li className="link_hover"><Link to="Terms_and_Conditions">Terms</Link></li>
                    <li className="link_hover"><Link to="Privacy_Policy">Privacy</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sec_line mt-mb-sl mt-0"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-6 text_left_center_991">
              <div>
                &copy; {new Date().getFullYear()} Group Travel Videos&trade;
              </div>

              <div className="flexElem alignCenter">
                <div className="display_ib mt-sm" style={{maxWidth: '84px', overflow: 'hidden'}}>
                  <div id="fb-root"></div>

                  <div className="fb-like" data-href="https://www.facebook.com/pages/Group-Travel-Videos/141674772531085"
                  data-layout="standard" data-action="like" data-show-faces="false" data-share="false"></div>
                </div>

                <div style={{marginTop: '1px'}}>
                <span style={{fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '12px'}}>likes</span>
                </div>

                <div className="socialIcons ml-sm" style={{marginTop: '1px'}}>
                  <a href={'https://www.facebook.com/pages/Group-Travel-Videos/141674772531085'} target={'_blank'} className="facebookIcon"><i className="fa fa-facebook"></i></a>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <ul className="privacy">
                {/* <li className="link_hover"><a href="#!">Sitemap</a></li>
                <li>-</li> */}
                <li className="link_hover"><Link to="/Terms_and_Conditions">Terms &amp; Conditions</Link></li>
                <li>-</li>
                <li className="link_hover"><Link to="/Privacy_Policy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="sec_line mt-mb-sl mt-0"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-lg text-center">
              Made with <span style={{color: 'tomato', fontSize: 'larger'}}>♥</span> in Charlotte, NC.
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
