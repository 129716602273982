import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAgencyType, getSalesRep, getAgencySalesRep, getDestinations } from 'redux/actions/settings-actions';
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { inputChangeHandler, contactChangeHandler, selectChangeHandler, selectArrayChangeHandler, clean, toggleModal, openModal, getSum, s3StaticPath, returnKeyWithColumnMatch, returnLabelWithValue, closeModalAfterApi } from 'helper/helperFunctions';
import $ from 'jquery'
import User from 'helper/User';
import { Roles } from 'constants/Roles';
import { MdClose, MdAdd } from 'react-icons/md';
import InputMask from 'react-input-mask';
// import MyImage from 'components/Upload/MyImage';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import DestinationForm from "components/Admin/Destination/DestinationForm";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { settingsService } from 'services/settings';
import { storeDestination } from 'redux/actions/destination-actions';
import CustomPhoneNumber from '../../../ThemeComponents/CustomPhoneNumber';
import CustomCellPhoneNumber from '../../../ThemeComponents/CustomCellPhoneNumber';

var cloneDeep = require('lodash.clonedeep');

const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
        // console.log('inputValue', inputValue);
        setTimeout(() => {
            resolve(settingsService.getDestinationsAsync({ inputValue: inputValue }));
        }, 1000);
    }
    );
class GeneralInfo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            order_details: this.props.order_details,
            allowNullEmail: false,
            newDestination: OC.DESTINATION,
            addModalDestination: false,
            onCloseAfterApi: false,
            // is_france: this.props.is_france && this.props.is_france,
            // initial_country_France: false,
        }
        this.props.getGroupTypeCall();

        this.props.getAgencySalesRepCall(User.user.agency.id);
        this.props.getDestinations({ inputValue: null, defaultOptions: [] });
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.contactUpdateHandler = contactChangeHandler.bind(this);
        this.selectUpdateHandler = selectChangeHandler.bind(this);
        this.selectArrayChangeHandler = selectArrayChangeHandler.bind(this);
        this.clean = clean.bind(this);
        this.handleChangeGroupLeader = this.handleChangeGroupLeader.bind(this);

        /*Modal for destination code*/
        this.toggleModal = toggleModal.bind(this);
        this.openModal = openModal.bind(this);
        /*Modal for destination code*/

    }

    selectChangeHandler = (selected, action) => {
        // if (action.action === 'create-option') {
        //     if (action.name.match(/destinations/g) !== null) {
        //         const data = {
        //             title: selected[0].label
        //         }
        //         this.props.storeDestination(data).then(
        //             res => {
        //                 this.props.getDestinations();
        //             }
        //         );
        //     }
        // }
        if (action.action === 'remove-value') {
            this.selectArrayChangeHandler(selected, action);
        }
        if (action.action === 'select-option') {
            if (Array.isArray(selected)) {
                this.selectArrayChangeHandler(selected, action);
            } else {
                this.selectUpdateHandler(selected, action);
            }
        }
    }

    contactChangeHandler = (e, maxLength) => {
        const { value } = e.target;
        if (value.length > maxLength) {
            $(e.target).parent('div').next('div').find('input').focus();
            return;
        }
        this.contactUpdateHandler(e);
    }
    componentDidUpdate(prevProps, prevState) {

        // $( "body" ).find("#france_1").css("border-color","red");
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.state.order_details !== prevState.order_details) {
            this.returnState();
        }
        if (this.props.showErrorMessages && (this.props.showErrorMessages !== prevProps.showErrorMessages)) {
            this.props.validatorInstance.showMessages();
        }
        if (this.state.allowNullEmail !== prevState.allowNullEmail && this.state.allowNullEmail) {
            delete this.props.validatorInstance.fields.email
        }


        /*Modal for destination code*/
        if (!prevState.addModalDestination && this.state.addModalDestination) {
            this.setState({ onCloseAfterApi: false })
        }
        if (prevState.addModalDestination && !this.state.addModalDestination && this.state.onCloseAfterApi) {
            this.props.getDestinations();
        }
        if (prevProps.storedDestination !== this.props.storedDestination) {
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: [...prevState.order_details.destinations, this.props.storedDestination.id]
                },
                newDestination: OC.newDestination
            }));
        }


    }

    returnState = () => {
        this.props.returnState(this.state.order_details, 'order_details');
    }

    handleChangeGroupLeader(e, key) {
        console.log("key: ", key);


        const { name, value } = e.target;

        const order_detailsTemp = cloneDeep(this.state.order_details);


        // const order_details = this.state.order_details;
        // const group_leader = { ...order_details.group_leader_contact }
        order_detailsTemp['group_leader_contacts'][key][name] = value;
        this.setState(
            {
                order_details: order_detailsTemp
            }
        );

        // $('#france_1').trigger("click");
    }
    addNewGL = () => {
        const order_detailsTemp = cloneDeep(this.state.order_details);
        order_detailsTemp.group_leader_contacts.push(
            {
                name: '',
                phone1: '',
                phone1_ext: '',
                phone2: '',
                phone2_ext: '',
                email: '',
                type: 'Secondary'
            }
        );
        this.setState(
            {
                order_details: order_detailsTemp
            }
        );
    }

    removeLastGL = () => {
        const order_detailsTemp = cloneDeep(this.state.order_details);
        order_detailsTemp.group_leader_contacts.pop();
        this.props.validatorInstance.purgeFields()
        this.setState(
            {
                order_details: order_detailsTemp
            }
        );
    }

    handleChangeDestination = (selectedOption, name) => {
        if (name.action === 'clear') {
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: []
                }
            }));
        }
        if (name.action === 'select-option') {
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: [...prevState.order_details.destinations, name.option.value]
                }
            }));
        }
        if (name.action === 'remove-value') {
            const destinationsTemp = this.state.order_details.destinations;
            destinationsTemp.splice(destinationsTemp.indexOf(name.removedValue.value), 1);
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    destinations: destinationsTemp
                }
            }))
        }
        /*Modal for destination code*/
        if (name.action === 'create-option') {
            var lastSelectedOption = selectedOption.slice(-1).pop()
            // var key = returnKeyWithColumnMatch('__isNew__', selectedOption, true);
            this.props.storeDestinationCall({ title: lastSelectedOption['label'] });

            // this.setState(prevState => ({
            //     newDestination: {
            //         ...prevState.newDestination,
            //         title: selectedOption[key]['label']
            //     }
            // }), () => {
            //     this.openModal('addModalDestination');
            // })
        }
        /*Modal for destination code*/
    }
    titleCase = (str, separator) => {
        if (separator == ' ') {
            var splitStr = str.toLocaleLowerCase().split(separator);
        }
        else {
            var splitStr = str.split(separator);
        }
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toLocaleUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(separator);
    }
    toTitlesCase = (e, key = null) => {
        if (key !== null) {
            const { name, value } = e.target;
            var sep = ['']
            let val = this.titleCase(value, ' ');
            val = this.titleCase(val, '-');
            val = this.titleCase(val, '.');

            const order_detailsTemp = cloneDeep(this.state.order_details);
            order_detailsTemp['group_leader_contacts'][key][name] = val;
            this.setState(
                {
                    order_details: order_detailsTemp
                }
            );
        } else {
            var value = e.target.value;
            var sep = [' ', '-', '.']
            let val = this.titleCase(value, ' ');
            val = this.titleCase(val, '.');
            val = this.titleCase(val, '-');
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    group_name: val
                }
            }));
        }
    }
    // toTitlesCase = (e, key = null) => {
    //     if (key !== null) {
    //         const { name, value } = e.target;
    //         var val = value.replace(/\w\S*/g, function(t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); });
    //         const order_detailsTemp = cloneDeep(this.state.order_details);
    //         order_detailsTemp['group_leader_contacts'][key][name] = val;
    //         this.setState(
    //             {
    //                 order_details: order_detailsTemp
    //             }
    //         );
    //     } else {
    //         var value = e.target.value;
    //         var val = value.replace(/\w\S*/g, function(t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); });
    //         this.setState(prevState => ({
    //             order_details: {
    //                 ...prevState.order_details,
    //                 group_name: val
    //             }
    //         }));
    //     }
    // }

    render() {
        // this.props.validatorInstance.purgeFields();
        const { order_details, newDestination, } = this.state;
        const { agency_type_id, salesRep, destinations, notEditable, pricing_details, agencySalesRep, notEditableFrance, initial_country_France, is_france, vsa_is_france, ptma_is_france, vsa_initial_country_France, ptma_initial_country_France } = this.props;

        let frontend_order_is_France = false;

        frontend_order_is_France = ptma_is_france || vsa_is_france || ptma_initial_country_France || vsa_initial_country_France
        // const groupLeaderPhone1 = order_details.group_leader_contact.phone1.split('-');
        // const groupLeaderPhone2 = order_details.group_leader_contact.phone2 ? order_details.group_leader_contact.phone2.split('-') : "--".split('-');




        let agencyType = '', agencySalesRepName = '';
        agency_type_id.forEach(item => {
            if (item.value === order_details.agency_type_id) {
                agencyType = item.label;
            }
        })
        agencySalesRep.forEach(item => {
            if (item.value === order_details.agency_sales_rep_id) {
                agencySalesRepName = item.label
            }
        })
        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img src={s3StaticPath('img/gen_information.png')} alt="General Information" />
                    <span>General Information</span>
                </div>

                <div className="form_tile_content">
                    <div className="infoBox redBox mb-lg">
                        <span className="red_bold">Order information accuracy is VERY important.</span> The information entered for Group Name and Destination could be used in the completed video. Please do not use abbreviations.
                    </div>

                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4">
                            Agency
                        </div>
                        <div className="col-md-8 text-primary">
                            {order_details.agency.name || User.user.agency.name}
                        </div>
                    </div>
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4 red_bold">
                            Group Name *
                        </div>
                        <div className="col-md-8 text-primary">
                            {
                                notEditable ?
                                    order_details.group_name
                                    :
                                    <input onChange={this.toTitlesCase} name='order_details.group_name' value={order_details.group_name} type="text" autoComplete="off" placeholder="Group Name" className="form_tile_input block" />
                            }
                            {this.props.validatorInstance.message('group name', order_details.group_name, 'required')}
                        </div>
                    </div>
                    {
                        notEditable &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4 red_bold">
                                Order #
                            </div>
                            <div className="col-md-8 text-primary">
                                { /*
                                    User.hasRole(Roles.AGENCY_USER) ?
                                        order_details.barcode
                                        :
                                        order_details.order_number
                                */}

                                {
                                    order_details.barcode
                                }
                            </div>
                        </div>
                    }
                    {
                        Object.keys(order_details.group_leader_contacts).map((value, key) => {
                            return (

                                <React.Fragment key={key}>
                                    {
                                        User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row" id={"GroupLeaderName_" + getSum(key, 1)}>
                                            <div className="col-md-4 ">
                                                Group Leader *
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {
                                                    notEditable ?
                                                        order_details.group_leader_contacts[key].name
                                                        :
                                                        <input
                                                            // onChange={(e) => this.handleChangeGroupLeader(e, key)}
                                                            onChange={(e) => this.toTitlesCase(e, key)}
                                                            name='name' value={order_details.group_leader_contacts[key].name || ''}
                                                            type="text" autoComplete="off" placeholder="Group Leader" className="form_tile_input block" />
                                                }
                                                {
                                                    this.props.validatorInstance.message('GroupLeaderName_' + getSum(key, 1), order_details.group_leader_contacts[key].name, 'required')
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        /*
                                        <div className="col-md-3" id={"GroupLeaderName_"+getSum(key, 1) }>
                                            <div className='form-group'>
                                                <label htmlFor="gname">
                                                    Group Leader Name
                                                    <span className="requiredClass">*</span>
                                                </label>
                                                <input type="text" name="name" className="form-control" onChange={(e)=>this.handleChangeGroupLeader(e, key)} value={order_details.group_leader_contacts[key].name || ''} />
                                                {
                                                    this.props.validatorInstance.message('GroupLeaderName_'+getSum(key, 1), order_details.group_leader_contacts[key].name, 'required')
                                                }
                                            </div>
                                        </div>
                                        */
                                    }





                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-4">
                                            GL Phone*
                                        </div>
                                        <div className="col-md-8 text-primary" id={"GroupLeaderPhone_" + getSum(key, 1)}>
                                            {
                                                notEditable ?

                                                    frontend_order_is_France
                                                        ?
                                                        <InputMask
                                                            mask="99-9-99-99-99-99"
                                                            maskChar={null}
                                                            type="text"
                                                            // id="france_1"
                                                            name="phone2"
                                                            className="labelPhone"
                                                            placeholder="12-3-45-67-89-10"
                                                            value={order_details.group_leader_contacts[key].phone1 || ''}
                                                            disabled={true}
                                                        />
                                                        :
                                                        <InputMask
                                                            mask="999-999-9999-999999"
                                                            maskChar={null}
                                                            type="text"
                                                            // id="france_1"
                                                            name="phone2"
                                                            className="labelPhone"
                                                            placeholder="123-456-7890"
                                                            value={order_details.group_leader_contacts[key].phone1 || ''}
                                                            disabled={true}
                                                        />
                                                    :
                                                    <div className="">
                                                        <div>
                                                            {/*
                                                            <InputMask
                                                                mask={"999-999-9999-999999"}
                                                                maskChar={null}
                                                                type="text"
                                                                name="phone1"
                                                                className="form-control phone_icon"
                                                                placeholder="123-456-7890-111111"
                                                                value={order_details.group_leader_contacts[key].phone1 || ''}
                                                                onChange={(e) => this.handleChangeGroupLeader(e, key)}
                                                            />
                                                            */}
                                                            {
                                                                // is_france || initial_country_France ?

                                                                <CustomPhoneNumber
                                                                    value={order_details.group_leader_contacts[key].phone1 || ''}
                                                                    handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                                                    validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                                                    validator={this.props.validatorInstance}
                                                                    required={true}
                                                                    elemId="france_1"
                                                                    // id="france_1"
                                                                    name={'phone1'}
                                                                    frontend_order_is_France={frontend_order_is_France}
                                                                />
                                                                //         <FrancePhoneNumber
                                                                //             value={order_details.group_leader_contacts[key].phone1 || ''}
                                                                //             handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                                                //             validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                                                //             validator={this.props.validatorInstance}
                                                                //             required={true}
                                                                //             elemId="france_1"
                                                                //             name={'phone1'}
                                                                //         />
                                                                //     :
                                                                //         <PhoneNumber
                                                                //             value={order_details.group_leader_contacts[key].phone1 || ''}
                                                                //             handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                                                //             validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                                                //             validator={this.props.validatorInstance}
                                                                //             required={true}
                                                                //             elemId="france_1"
                                                                //             name={'phone1'}
                                                                //         />
                                                            }
                                                        </div>
                                                    </div>
                                            }

                                            {/*
                                                this.props.validatorInstance.message('GroupLeaderPhone_' + getSum(key, 1), order_details.group_leader_contacts[key].phone1, ['required', 'regex:^([0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}-([0-9]{6})?$'])
                                            */}
                                        </div>
                                    </div>
                                    {
                                        /*

                                        <div className="col-md-3" id={"GroupLeaderPhone_"+getSum(key, 1)}>
                                            <div className='form-group'>
                                                <label htmlFor="glphone1">Group Leader Phone<span className="requiredClass">*</span></label>
                                                <InputMask
                                                    mask={"999-999-9999-999999"}
                                                      maskChar={null}
                                                      type="text"
                                                      name="phone1"
                                                      className="form-control phone_icon"
                                                      placeholder="123-456-7890-111111"
                                                      value={order_details.group_leader_contacts[key].phone1 || ''}
                                                      onChange={(e)=>this.handleChangeGroupLeader(e,key)}
                                                />
                                                {
                                                    this.props.validatorInstance.message('GroupLeaderPhone_'+getSum(key, 1), order_details.group_leader_contacts[key].phone1, ['required', 'regex:^([0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}-([0-9]{6})?$'])
                                                }
                                            </div>
                                        </div>
                                        */
                                    }


                                    {
                                        this.props.orderForm &&
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row" id={"GroupLeaderCell_" + getSum(key, 1)}>
                                            <div className="col-md-4">
                                                GL Cell Phone
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip id='gl_cell'>
                                                            <span style={{ fontSize: '12px' }}>
                                                                We only call this number when we can not reach the GL on their other number.
                                                                We do not distribute this information. It is only used to discuss their Group Travel Videos.
                                                            </span>
                                                        </Tooltip>
                                                    }>
                                                    <span className="tipIcon">?</span>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {
                                                    notEditable
                                                        ?
                                                        frontend_order_is_France
                                                            ?
                                                            <InputMask mask="99-9-99-99-99-99" maskChar={null} type="text" name="phone2" className="labelPhone" placeholder="12-3-45-67-89-10" value={order_details.group_leader_contacts[key].phone2 || ''} disabled={true} />
                                                            :
                                                            <InputMask mask="999-999-9999-999999" maskChar={null} type="text" name="phone2" className="labelPhone" placeholder="123-456-7890" value={order_details.group_leader_contacts[key].phone2 || ''} disabled={true} />
                                                        :
                                                        <div className="">
                                                            <div>
                                                                {
                                                                    // is_france || initial_country_France
                                                                    // ?
                                                                    // <InputMask mask="99-99-99-99-99" maskChar={null} type="text" id="france_2" name="phone2" className="form-control phone_icon" placeholder="12-34-56-78-90" value={order_details.group_leader_contacts[key].phone2 || ''} onChange={(e) => this.handleChangeGroupLeader(e, key)} />
                                                                    // :
                                                                    // <InputMask mask="999-999-9999" maskChar={null} type="text" id="france_2" name="phone2" className="form-control phone_icon" placeholder="123-456-7890" value={order_details.group_leader_contacts[key].phone2 || ''} onChange={(e) => this.handleChangeGroupLeader(e, key)} />

                                                                    <CustomCellPhoneNumber
                                                                        value={order_details.group_leader_contacts[key].phone2 || ''}
                                                                        handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                                                        validationName={'GroupLeaderCell_' + getSum(key, 1)}
                                                                        validator={this.props.validatorInstance}
                                                                        required={false}
                                                                        elemId="france_2"
                                                                        // id="france_1"
                                                                        name={'phone2'}
                                                                        frontend_order_is_France={frontend_order_is_France}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                                {/* {
                                                    is_france || initial_country_France
                                                    ?

                                                    this.props.validatorInstance.message('GroupLeaderCell_' + getSum(key, 1), order_details.group_leader_contacts[key].phone2, ['regex:^(([0-9]{2})|[0-9]{2})[-]?[\0-9]{2}[-]?[0-9]{2}[-]?[0-9]{2}[-]?[0-9]{2}$'])
                                                    :
                                                    this.props.validatorInstance.message('GroupLeaderCell_' + getSum(key, 1), order_details.group_leader_contacts[key].phone2, ['regex:^(([0-9]{3})|[0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}$'])
                                                } */}
                                            </div>
                                        </div>
                                    }
                                    {
                                        /*

                                        <div className="col-md-3" id={"GroupLeaderCell_"+getSum(key, 1)}>
                                            <div className='form-group'>
                                                <label htmlFor="glphone2">Group Leader Cell</label>
                                                <InputMask mask="999-999-9999" maskChar={null} type="text" name="phone2" className="form-control phone_icon" placeholder="123-456-7890" value={order_details.group_leader_contacts[key].phone2 || ''} onChange={(e)=>this.handleChangeGroupLeader(e,key)} />
                                                {
                                                    this.props.validatorInstance.message('GroupLeaderCell_'+getSum(key, 1), order_details.group_leader_contacts[key].phone2, ['regex:^(([0-9]{3})|[0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}$'])
                                                }
                                            </div>
                                        </div>
                                        */
                                    }

                                    {
                                        User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row" id={"GroupLeaderEmail_" + getSum(key, 1)}>
                                            <div className="col-md-4">GL Email*</div>
                                            <div className="col-md-8 text-primary">
                                                {
                                                    notEditable ?
                                                        this.clean(order_details.group_leader_contacts[key].email)
                                                        :
                                                        <input onChange={(e) => this.handleChangeGroupLeader(e, key)} name='email' value={order_details.group_leader_contacts[key].email || ''} type="email" placeholder="Email" className="form_tile_input block" />
                                                }
                                                {
                                                    this.props.validatorInstance.message('GroupLeaderEmail_' + getSum(key, 1), order_details.group_leader_contacts[key].email, 'required|email')
                                                    /*this.state.allowNullEmail === false
                                                    ?
                                                        this.props.validatorInstance.message('GroupLeaderEmail_'+getSum(key, 1), order_details.group_leader_contacts[key].email, 'required|email')
                                                    :
                                                         null
                                                    */
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        /*
                                            <div className="col-md-3" id={"GroupLeaderEmail_"+getSum(key, 1)}>
                                                <div className='form-group'>
                                                    <label htmlFor="glemail">Group Leader Email<span className="requiredClass">*</span></label>
                                                    <input type="text" name="email" className="form-control email_icon" onChange={(e)=>this.handleChangeGroupLeader(e,key)} value={order_details.group_leader_contacts[key].email || ''} />
                                                    {
                                                        this.props.validatorInstance.message('GroupLeaderEmail_'+getSum(key, 1), order_details.group_leader_contacts[key].email, 'required|email')
                                                    }
                                                </div>
                                            </div>
                                        */
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                    {
                        notEditable
                            ?
                            null
                            :
                            <div className="flexElem respFlex alignCenter justifyCenter form_tile_row">
                                <div>
                                    <button type="button" className="actionBtn m-sm primaryBtn" onClick={this.addNewGL}>
                                        <MdAdd />
                                        <span>Add New</span>
                                    </button>
                                </div>

                                <div>
                                    {
                                        Object.keys(order_details.group_leader_contacts).length > 1 &&
                                        <button type="button" className="actionBtn m-sm infoBtn" onClick={this.removeLastGL}>
                                            <MdClose />
                                            <span>Delete Last</span>
                                        </button>
                                    }
                                </div>
                            </div>
                    }
                    {
                        /*

                        {
                            User.hasRole(Roles.AGENCY_USER) &&
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4 ">
                                    Group Leader *
                                </div>
                                <div className="col-md-8 text-primary">
                                    {
                                        notEditable ?
                                            order_details.group_leader_contact.name
                                            :
                                            <input onChange={this.inputChangeHandler} name='order_details.group_leader_contact.name' value={order_details.group_leader_contact.name} type="text" autoComplete="off" placeholder="Group Leader" className="form_tile_input block" />
                                    }
                                    {this.props.validatorInstance.message('group leader contact name', order_details.group_leader_contact.name, 'required')}
                                </div>
                            </div>
                        }
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">
                                GL Phone*
                        </div>
                            <div className="col-md-8 text-primary">
                                {
                                    notEditable ?
                                        order_details.group_leader_contact.phone1
                                        :
                                        <div className="flexElem flexThreeCols spaceBetween">
                                            <div>
                                                <input onChange={(e) => { this.contactChangeHandler(e, 3) }} value={groupLeaderPhone1[0]} name='order_details.group_leader_contact.phone1.0' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                            </div>

                                            <div>
                                                <input onChange={(e) => { this.contactChangeHandler(e, 3) }} value={this.clean(groupLeaderPhone1[1])} name='order_details.group_leader_contact.phone1.1' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                            </div>

                                            <div>
                                                <input onChange={(e) => { this.contactChangeHandler(e, 12) }} value={this.clean(groupLeaderPhone1[2])} name='order_details.group_leader_contact.phone1.2' type="text" autoComplete="off" placeholder="0000(000000)" className="form_tile_input block" />
                                            </div>
                                        </div>
                                }

                                {this.props.validatorInstance.message('group leader phone', order_details.group_leader_contact.phone1, 'required')}
                            </div>
                        </div>
                        {
                            this.props.orderForm &&
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">
                                    GL Cell Phone
                                <OverlayTrigger
                                        overlay={
                                            <Tooltip id='gl_cell'>We only call this number when we can not reach the GL on their other number.
                                        We do not distribute this information. It is only used to discuss their Group Travel Videos.</Tooltip>
                                        }>
                                        <span className="tipIcon">?</span>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-8 text-primary">
                                    {
                                        notEditable ?
                                            this.clean(order_details.group_leader_contact.phone2)
                                            :
                                            <div className="flexElem flexThreeCols spaceBetween">
                                                <div>
                                                    <input onChange={(e) => { this.contactChangeHandler(e, 3) }} value={this.clean(groupLeaderPhone2[0])} name='order_details.group_leader_contact.phone2.0' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                                </div>

                                                <div>
                                                    <input onChange={(e) => { this.contactChangeHandler(e, 3) }} value={this.clean(groupLeaderPhone2[1])} name='order_details.group_leader_contact.phone2.1' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                                </div>

                                                <div>
                                                    <input onChange={(e) => { this.contactChangeHandler(e, 12) }} value={this.clean(groupLeaderPhone2[2])} name='order_details.group_leader_contact.phone2.2' type="text" autoComplete="off" placeholder="0000(000000)" className="form_tile_input block" />
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            User.hasRole(Roles.AGENCY_USER) &&
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">GL Email*</div>
                                <div className="col-md-8 text-primary">
                                    {
                                        notEditable ?
                                            this.clean(order_details.group_leader_contact.email)
                                            :
                                            <input onChange={this.inputChangeHandler} name='order_details.group_leader_contact.email' value={this.clean(order_details.group_leader_contact.email)} type="email" placeholder="Email" className="form_tile_input block" />
                                    }
                                    {
                                        this.state.allowNullEmail === false ?
                                            this.props.validatorInstance.message('email', order_details.group_leader_contact.email, 'required') : null
                                    }
                                </div>
                            </div>
                        }
                        */
                    }
                    {
                        /*
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-12">
                                {
                                    (notEditable === undefined && this.props.orderOptions === undefined) &&
                                    <div className="customChk">
                                        <input onChange={this.inputChangeHandler} name='allowNullEmail' checked={this.state.allowNullEmail} value={!this.state.allowNullEmail} type="checkbox" id="glEmail" />
                                        <label htmlFor="glEmail">
                                            Check this box if you do not have a GL Email.
                                </label>
                                    </div>
                                }
                            </div>
                        </div>

                        */
                    }
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                        <div className="col-md-4 red_bold">Destinations*</div>
                        <div className="col-md-8 text-primary">
                            {/* <input onChange={this.inputChangeHandler} name='order_details.destinations' value={order_details.destinations} type="text" autoComplete="off" placeholder="Destination" className="form_tile_input block" /> */}
                            {
                                notEditable ?
                                    order_details.destinationText
                                    :
                                    <AsyncCreatableSelect
                                        key={order_details.destinationDefault}
                                        isMulti={true}
                                        // cacheOptions
                                        defaultOptions
                                        // value={returnLabelsWithValues(order_details.destinations, destinations ? destinations : [])}
                                        // defaultInputValue
                                        defaultValue={order_details.destinationDefault}

                                        loadOptions={promiseOptions}
                                        onChange={this.handleChangeDestination}
                                        className="selectUser"
                                        placeholder="Select Destination"
                                    />

                            }
                            {this.props.validatorInstance.message('destinations', order_details.destinations, 'required|min:1,array')}
                        </div>
                    </div>
                    {
                        User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">Group Type*</div>
                            <div className="col-md-8">
                                {
                                    notEditable ?
                                        agencyType
                                        :
                                        <Select
                                            value={returnLabelWithValue(order_details.agency_type_id, agency_type_id) || null}
                                            onChange={this.selectChangeHandler}
                                            options={agency_type_id}
                                            isSearchable={true}
                                            isMulti={false}
                                            name='order_details.agency_type_id'
                                            placeholder={'Select Group'}
                                        />
                                }

                                {this.props.validatorInstance.message('group type', order_details.agency_type_id, 'required')}
                            </div>
                        </div>
                    }
                    {
                        User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">Sales Rep*</div>
                            <div className="col-md-8">
                                {
                                    notEditable ?
                                        agencySalesRepName
                                        :
                                        <Select
                                            value={returnLabelWithValue(order_details.agency_sales_rep_id, agencySalesRep) || null}
                                            onChange={this.selectChangeHandler}
                                            options={agencySalesRep}
                                            isSearchable={true}
                                            isMulti={false}
                                            name='order_details.agency_sales_rep_id'
                                            placeholder={'Select Agency Sales Rep'}
                                        />
                                }
                                {this.props.validatorInstance.message('agency sales rep', order_details.agency_sales_rep_id, 'required')}
                            </div>
                        </div>
                    }
                    {
                        User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">Client Tour Code</div>
                            <div className="col-md-8 text-primary">
                                {
                                    notEditable ?
                                        order_details.client_tour_code
                                        :
                                        <input onChange={this.inputChangeHandler} name='order_details.client_tour_code' value={order_details.client_tour_code} type="text" autoComplete="off" placeholder="Client Tour Code" className="form_tile_input block" />
                                }
                            </div>
                        </div>
                    }
                    {
                        notEditable &&
                        <React.Fragment>
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">Traveler Username</div>
                                <div className="col-md-8 text-primary">
                                    {
                                        order_details.username
                                    }
                                </div>
                            </div>
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">Traveler Password</div>
                                <div className="col-md-8 text-primary">
                                    {
                                        order_details.password
                                    }
                                </div>
                            </div>
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">Photo Admin Username</div>
                                <div className="col-md-8 text-primary">
                                    {
                                        order_details.photo_admin_username
                                    }
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id='Photo_Admin_Username'>
                                                <span style={{ fontSize: '12px' }}>
                                                    The Photo Admin is usually the Group Leader. They have extra photo permissions that the regular group login does not have like deleting, rearranging, and submitting finalized photos for video production.
                                                </span>
                                            </Tooltip>
                                        }>
                                        <span className="tipIcon">?</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                <div className="col-md-4">Photo Admin Password</div>
                                <div className="col-md-8 text-primary">
                                    {
                                        order_details.photo_admin_password
                                    }
                                </div>
                            </div>
                            <div className="flexElem alignCenter spaceBetween mt-md">
                                <div className="alignCenter">
                                    <div className="">
                                        <a target="_blank" href={`${order_details.base_url}/generateAppCard1Up/${order_details.id}`} className="">Single App Card</a>
                                    </div>
                                    <div className="mt-sm">
                                        <a target="_blank" href={`${order_details.base_url}/generateAppCardOnlyBack/${order_details.id}`} className="">App Card Sheet</a>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        this.props.orderForm === undefined &&
                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                            <div className="col-md-4">
                                Videos
                            </div>
                            <div className="col-md-8 text-primary">
                                {pricing_details.order_items['Videos/Travelers'] ? pricing_details.order_items['Videos/Travelers'].quantity : 0}
                            </div>
                        </div>
                    }
                </div>

                {/*Add Destination Modal Start*/}
                <ThemeModal modalClass="" title='Add Destination' show={this.state.addModalDestination} hide={() => { this.toggleModal('addModalDestination') }} >
                    <DestinationForm
                        formTypePro="add"
                        destinationPro={newDestination}
                        closeModel={closeModalAfterApi.bind(this, "addModalDestination", "onCloseAfterApi")}
                    />
                </ThemeModal>
                {/*Add Destination Modal End*/}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { agency_type_id, salesRep, destinations, agencySalesRep } = state.settingsReducer;
    const { loadingDestination, storedDestination } = state.destinationsReducer;
    const { pricing_details } = state.ordersReducer;
    return {
        agency_type_id,
        salesRep,
        destinations,
        loadingDestination,
        storedDestination,
        pricing_details, agencySalesRep
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getGroupTypeCall: () => { dispatch(getAgencyType()) },
        getPVSalesRepCall: () => { dispatch(getSalesRep()) },
        getAgencySalesRepCall: (agency_id) => { dispatch(getAgencySalesRep(agency_id)) },
        // getDestinations: () => dispatch(getDestinations()),
        getDestinations: (filter) => { dispatch(getDestinations(filter)) },
        storeDestinationCall: (destinationData) => { return dispatch(storeDestination(destinationData)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo)
