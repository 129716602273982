import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrder, getOrderItems } from 'redux/actions/order-actions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import User from 'helper/User';
// import { Link } from 'react-router-dom'
import moment from 'moment';
import { getOrdersResourcesByRole } from 'redux/actions/agency-resource-actions';
// import DownloadFile from 'components/ThemeComponents/downloadFile';
import { returnTimeStamp } from 'helper/helperFunctions';
import ImagePreview from 'components/Upload/ImagePreview';

class PrintOrderSummary extends Component {
    constructor(props) {
        super(props)
        if (!User.isAuthenticated()) {
            this.props.history.push('/');
        }
        if (User.isAuthenticated()) {
            this.props.getOrderCall(this.props.match.params.id);
            this.props.getOrderItemsCall(this.props.match.params.id);
            this.props.getOrderVideos(this.props.match.params.id);
        }
        this.state = {
            order_details: OC.ORDERDETAIL,
            pricing_details: OC.PRICINGDETAIL,
            shipping_details: OC.SHIPPINGDETAIL,
            options: {
                statespre_trip_material_address: [],
                statesvideo_shipping_address: [],
            },
            orderDocuments: [],
            OrderTitlesEditModal: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            });
        }
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({
                pricing_details: this.props.pricing_details
            });
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            });
        }
    }
    render() {

        const { orderItems, orderResources } = this.props;
        const { order_details } = this.state;
        const { order_dates } = order_details;
        const { pre_trip_material_address, video_shipping_address } = this.state.shipping_details;

        return (
            <section className="section">
                <div className="section_title">
                    <h1 className="headingPrint heading1">Group Travel Videos Order # <strong className="semibold">{order_details.barcode}</strong></h1>
                </div>

                <div className="container fullPrint">
                    <div className="row printRow">
                        <div className="col-md-5">
                            <h2 className="headingPrint">General Information</h2>
                            <table className="printTable">
                                <tbody>
                                    <tr>
                                        <td> Agency:</td>
                                        <td> <span >{order_details.agency.name}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Group Name:</td>
                                        <td> <span >{order_details.group_name}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Order #:</td>
                                        <td> <span >{order_details.barcode}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Group Leader:</td>
                                        <td> <span >{order_details.group_leader_contact.name}</span></td>
                                    </tr>
                                    <tr>
                                        <td> GL Phone:</td>
                                        <td> <span >{order_details.group_leader_contact.phone1}</span></td>
                                    </tr>
                                    <tr>
                                        <td> GL Email:</td>
                                        <td> <span >{order_details.group_leader_contact.email}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Destination:</td>
                                        <td> <span >{order_details.destinationText}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Traveler Username:</td>
                                        <td> <span >{order_details.username}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Traveler Password:</td>
                                        <td> <span >{order_details.password}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Photo Admin Username:</td>
                                        <td> <span >{order_details.photo_admin_username}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Photo Admin Password:</td>
                                        <td> <span >{order_details.photo_admin_password}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2 className="headingPrint">Dates</h2>
                            <table className="printTable">
                                <tbody>
                                    <tr>
                                        <td> Departure Date:</td>
                                        <td> <span>{order_dates.departure_date.value ? moment(order_dates.departure_date.value).format('MM/DD/YYYY') : ''}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Return Date:</td>
                                        <td> <span>{order_dates.return_date.value ? moment(order_dates.return_date.value).format('MM/DD/YYYY') : ''}</span></td>
                                    </tr>
                                    {/* <tr>
                                        <td> Last Day Of School:</td>
                                        <td> <span>{order_dates.last_day_of_school.value ? moment(order_dates.last_day_of_school.value).format('MM/DD/YYYY') : ''}</span></td>
                                    </tr>
                                    <tr>
                                        <td> Video Need Date:</td>
                                        <td> <span>{order_dates.video_special_need_date.value ? moment(order_dates.video_special_need_date.value).format('MM/DD/YYYY') : ''}</span></td>
                                    </tr> */}
                                    <tr>
                                        <td> Pre-Trip Material Need Date:</td>
                                        <td> <span>{order_dates.video_special_need_date.value ? moment(order_dates.pre_trip_package_need_date.value).format('MM/DD/YYYY') : ''}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2 className="headingPrint">Shipping Information</h2>
                            <table className="printTable">
                                <tbody>
                                    <tr>
                                        <td> Pre-Trip Material Address:</td>
                                        <td>
                                            <span >{pre_trip_material_address.street_address_1} {' '}
                                                {pre_trip_material_address.city},{'  '}</span>
                                            <span> {pre_trip_material_address.state && pre_trip_material_address.state.code ? pre_trip_material_address.state.code + ', ' : ''}
                                                {pre_trip_material_address.zipcode}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Video Address:</td>
                                        <td>
                                            <span >{video_shipping_address.street_address_1}, {' '}
                                                {video_shipping_address.city},{'  '}</span>
                                            <span>  {video_shipping_address.state && video_shipping_address.state.code ? video_shipping_address.state.code + ', ' : ''}
                                                {video_shipping_address.zipcode}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-7">
                            <h2 className="headingPrint">Order Summary</h2>
                            <table className="printTable">
                                <tbody>
                                    {
                                        orderItems.map(item => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        {item.quantity} <span >{item.item.title}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            { /*
                            <h2 className="headingPrint">Photos &amp; Videos</h2>
                            <Link className="blue_link" to='/photo-gallery'><i className="fa fa-image" /> Photo Gallery</Link>
                            <table className="">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{ paddingTop: '15px' }}><Link className="blue_link" to={returnDynamicURL(typeof this.state.order_details.agency.single_account === 'undefined' || !this.state.order_details.agency.single_account ? null : this.state.order_details.agency.single_account.account.domain_slug, order_details.photo_admin_username, order_details.photo_admin_password)}>{returnDynamicURL(typeof this.state.order_details.agency.single_account === 'undefined' || !this.state.order_details.agency.single_account ? null : this.state.order_details.agency.single_account.account.domain_slug, order_details.photo_admin_username, order_details.photo_admin_password)}</Link> (Quick Login Link)</div>
                                            <p>
                                                Note: You can use the above Quick Login Link to provide to potential customers
                                                to give them an example of the Group Travel videos Photo Gallery and finished
                                                Videos
                                        </p>
                                        </td>
                                    </tr>
                                    {
                                        'highlight_video' in orderResources &&
                                        <tr>
                                            <td>
                                                <DownloadFile extraClass='blue_link' multiple={false} title={'Download Highlight Video (' + (orderResources.highlight_video.file_size / 1024 / 1024).toFixed(2) + ') MB'} files={{
                                                    'file_title': orderResources.highlight_video.file_title,
                                                    'file_path': orderResources.highlight_video.file_path
                                                }} />
                                                <p>
                                                    Note: If download link does not work, try right-clicking and "Save Link As" (or "Save Target As")
                                        </p>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        'full_video' in orderResources &&
                                        <tr>
                                            <td>
                                                <DownloadFile extraClass='blue_link' multiple={false} title={'Download Full Video (' + (orderResources.full_video.file_size / 1024 / 1024).toFixed(2) + ') MB'} files={{
                                                    'file_title': orderResources.full_video.file_title,
                                                    'file_path': orderResources.full_video.file_path
                                                }} />
                                                <p>
                                                    Note: If download link does not work, try right-clicking and "Save Link As" (or "Save Target As")
                                        </p>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            */}
                            {
                                '20x30_mosaic_preview' in orderResources &&
                                    <React.Fragment>
                                    <h2 className="headingPrint">Mozaix Preview</h2>
                                    <ImagePreview
                                        filesToPreview={{
                                            preview: orderResources['20x30_mosaic_preview'].file_path + '?rand=' + returnTimeStamp(orderResources['20x30_mosaic_preview'].updated_at),
                                            name: orderResources['20x30_mosaic_preview'].file_title,
                                            type: orderResources['20x30_mosaic_preview'].mime_type,
                                            id: orderResources['20x30_mosaic_preview'].id,
                                        }}
                                        width={{width:'auto'}}
                                        height={{ height:'auto'}}
                                    />
                                    </React.Fragment>
                            }


                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    const { order_details,
        shipping_details,
        pricing_details,
        orderResources
    } = state.ordersReducer;
    const { orderItems } = state.ordersReducer;
    return {
        order_details,
        shipping_details,
        pricing_details,
        orderItems,
        orderResources
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (id) => { dispatch(getOrder(id)) },
        getOrderItemsCall: (id) => { dispatch(getOrderItems(id)) },
        getOrderVideos: (orderId) => { dispatch(getOrdersResourcesByRole(orderId)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOrderSummary);
