import { smsServices } from '../../services/sms';
import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { generalActions } from './generalActions';

export function getSMSPagination(page, filter, sort, pageSize, where) {
    return dispatch => {
        dispatch(request())
        return smsServices.getSMSPagination(page, filter, sort, pageSize, where)
            .then(
                response => {
                    dispatch(generalActions.success(response));
                    return response;
                },
                err => {
                    dispatch(generalActions.failure(adminConstants.GET_SMS_LIST_FAILURE, err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request() { return { type: adminConstants.GET_SMS_LIST_REQUEST } }
}


export function getOrderWithSMS(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) {
    return dispatch => {
        return smsServices.getOrderWithSMS(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters).then(
            response => {
                dispatch(success(response))
                return response
            }, error => {
                dispatch(failure(error))
                return Promise.reject(error)
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ALL_CAMERAS_TO_SHIP_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ALL_CAMERAS_TO_SHIP_FAILURE, payload: error } }
}
