import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid, OverlayTrigger, Popover } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, hasRole } from 'helper/helperFunctions';
import Pagination from 'components/ThemeComponents/Pagination';
import { hasPermission } from 'helper/hasPermission';
import { colorForBardcodeWithDate } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import MyImage from 'components/Upload/MyImage';
import { reportService } from "../../../services/report";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { orderService } from 'services/order';
import { adminLabels } from 'redux/constant/admin-label-constant';

const queryString = require('query-string');
const moment = window.moment;

class MozaixPrintedOrderByUser extends Component {
	constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        const roleParam = this.props.match ? this.props.match.params.roleParam : null;
        const idParam = this.props.match ? this.props.match.params.id : null;
		this.validator = new ReactValidator();
		this.state = {
			pageSizeTotal: 0,
            idParam: idParam,
            sorted: '',
            filtered: '',
            orderPage: 0,
            roleParam: roleParam,
            order_ids: parsed.order_ids,
			collapseUiSettings: true,
			user: '',
            admin_user_id: ((this.props.userAdmin && this.props.userAdmin.user) ? this.props.userAdmin.user.id : null)
		}
		
	}
	
    paginationCall = (data) => {
        var searchData = {};
        return orderService.getMozaixPrintedOrderByUser(data.page, this.state.filtered, data.sort, data.pageSize, this.state.idParam, this.state.order_ids ).then(response => {
            // console.log(response.data.pagination.data.length, 'responseee');
            this.setState({
                orderData: response.data.pagination.data,
                user: response.data.user
            })
          return response;
        });
    }
	render() {
		//const { collapseUiSettings, filtered, numOfDays } = this.state;
		const { collapseUiSettings, numOfDays,roleParam, admin_user_id, idParam, user } = this.state;
        console.log("role: " , this.props.location.search);
        console.log("parsed " , this.state.order_ids);
        console.log("idParam " , idParam);
		const columns = [
			{
                Header: "Orders Listing",
                headerClassName: 'headerPagination',
                columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
						width: 200,
						className: "action-center",
						show: roleParam !== 'mozaix' ? true : false,
						Cell: ({ value, original }) => (
							/*add true in below conditions just for time to ignore condition */
							<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ? 'Edit Order' : adminLabels.DONT_ALLOW}</Popover>}>
							{

								(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ?
								<Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode" target="_blank" >
									<span className={
									colorForBardcodeWithDate(original.order_dates.last_day_of_school.value, 'lastDayOfSchool') + " " +
									colorForBardcodeWithDate(original.order_dates.video_special_need_date.value, 'videoSpecialNeedDate')
									}
									>
									{value}
									</span>
								</Link>
								:
								<span className="barcode">{value}</span>
							}
							</OverlayTrigger>
						),
					},
					{
						Header: "Agency Name",
						accessor: "agency",
						Cell: ({ value }) => (
							<div>
							{value.name}
							</div>
						),
						className: "action-center",
						show: roleParam !== 'mozaix' ? true : false
					},
					{
						Header: "Group Type",
						accessor: `agency_type`,
						Cell: ({ value, original }) => (
							<div className={`${original.settings.on_hold.value === '1' ? 'yellowBackgroud' : null}`}>
							{value.title}
							{
								original.settings.on_hold.value === '1' &&
								TF.onHold(original.settings, original.on_hold_last_comment)
							}
							</div>
						),
						className: "action-center",
						width: 250,
						backgroundColor: 'pink',
						show: roleParam !== 'mozaix' ? true : false
					},
					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
						show: roleParam !== 'mozaix' ? true : false
					},
					{
						Header: "Destinations",
						accessor: "destinations",
						className: "action-center",
						show: roleParam !== 'mozaix' ? true : false
					},
					{
						id: "mozaix_printed_at",
						Header: "Mozaix Printed Date",
						accessor: "mozaix_printed_at",
						sortable: false,
						Cell: ({ value }) => (
								console.log("value", value),
							<div>
							{
								value ?
								dateFormatMy(value)
								:
								''
							}
							</div>
						),
						className: "action-center",
						show: roleParam !== 'mozaix' ? true : false
					},
					{
						Header: "Total number of photos",
						accessor: "originalImages",
						className: "action-center",
						sortable: false,
					},


					{
						id: "order_dates.video_special_need_date.value",
						Header: "Special Need Date",
						accessor: "order_dates",
						Cell: ({ value }) => (
							<div>
							{dateFormatMy(value.video_special_need_date.value)}
							</div>
						),
						className: "action-center"
					},
					{
						Header: "Edited Photo Count ",
						accessor: "workingImages",
						Cell: ({ original }) => (
						<div>
							{
							// console.log(original, 'Total # of Mozaix ordered'),
								<span>{original.workingImages}</span>
							}
						</div>
						),
						show: true
					},
                ]
              }
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">
														Mozaix Printed Orders By {user.name}
													</div>
												</Col>
												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.paginationCall}
														filterView={false}
														// getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MozaixPrintedOrderByUser);
