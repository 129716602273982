import { SC } from "./../helper/ServerCall";
export const commentServices = {
    getCommentCategories,
    getAllComments,
    deleteComment,
    addComment,
    editComment,
    toggleCommentHighlight,
    getContactLogTypes,
    getCommentCategoryByTitle,
    getCommentsByColumn,
    updateOrderCsrStep,
    getContent,
    getLatestCommentByCategoryTitle,
    addSMS,
    getAllSMS
}

function getCommentCategories() {
    return SC.getCall('getCommentCategory');
}

function getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to, uuid) {
    if (uuid === undefined) {
        return SC.getCall('comments/' + cat_id + '/' + modelId + '?page=' + page + '&filter=' + filter + '&pageSize=' + pageSize + '&sort=' + sort + '&from=' + from + '&to=' + to);
    } else {
        return SC.getCall('comments/' + cat_id + '/' + modelId + '?page=' + page + '&filter=' + filter + '&pageSize=' + pageSize + '&sort=' + sort + '&from=' + from + '&to=' + to + '&uuid=' + uuid);
    }
}

function deleteComment(id) {
    return SC.deleteCall('comments/' + id);
}

function addComment(data, modelId) {
    return SC.postCall('comments/' + modelId, data);
}

function editComment(data, id) {
    return SC.putCall('comments/' + id, data);
}

function toggleCommentHighlight(id) {
    return SC.putCall(`toggleCommentHighlgiht/${id}`, []);
}

function getCommentCategoryByTitle(titles) {
    return SC.postCall(`getCommentCategoryByTitle`, { titles: titles });
}

function getContactLogTypes(orderId) {
    return SC.getCall(`getContactLogTypes/${orderId}`);
}

function getCommentsByColumn(data) {
    return SC.postCall('getCommentsByColumn', data);
}
function updateOrderCsrStep(modelId, data) {
    return SC.putCall(`updateOrderCsrStep/${modelId}`, data);
}
function getContent(account_id, order_id, code) {
    return SC.getCall(`csrTemplate?code=${code}&account_id=${account_id}&order_id=${order_id}`);
}

function getLatestCommentByCategoryTitle(title, model_id) {
    return SC.getCall(`getLatestCommentByCategoryTitle?title=${title}&model_id=${model_id}`);
}

function addSMS(data, orderId) {
    return SC.postCall("sms_messages/" + orderId, data);
}

function getAllSMS(orderId) {
    return SC.getCall(`sms_messages/${orderId}`);
}