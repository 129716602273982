import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, returnLabelWithValue, hasRole } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Pagination from 'components/ThemeComponents/Pagination';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { reportService } from "../../../services/report";
const moment = window.moment;

class MozaixPrintedReports extends Component {
	constructor(props) {
        super(props);
		this.validator = new ReactValidator();
		this.state = {
			numOfDays: 'Current Week',
			collapseUiSettings: true,
            advancedSearchFilters: {
                date_start: '',
                date_stop: ''
            },
		}
		this.getMozaixPrintedReports = this.getMozaixPrintedReports.bind(this);
		this.getRowProps = this.getRowProps.bind(this);
	}
	getMozaixPrintedReports(data) {
		data = { ...data, numOfDays: this.state.numOfDays, date_start: this.state.advancedSearchFilters.date_start, date_stop: this.state.advancedSearchFilters.date_stop};
		return reportService.getMozaixPrintedReports(data);
	}
	getRowProps(rowData) {
		let data = {};
		if (parseInt(rowData.settings.finalize_by_editor.value, 10) === 1) {
			data = { color: 'red' }
		}
		if (rowData.order_dates.receive_date.value === "" && rowData.shippingItemsCase > 0) {
			data = { color: 'green' }
		}
		if (rowData.no_equipment_upload_only === 1) {
			data = { color: 'pink' }
		}
		if ((rowData.order_dates.receive_date.value !== "") && (parseInt(rowData.settings.finalize_by_editor.value, 10) === 0) && (parseInt(rowData.settings.on_hold.value, 10) === 1)) {
			if (moment(rowData.settings.photos_submitted_for_video_production.updated_at) < moment(rowData.order_dates.upload_cut_off_date.value)) {
				data = { color: 'blue' }

			}
		}
		return data;
	}


	componentDidUpdate(prevProps, prevState) {
        if (this.state.advancedSearchFilters.date_stop === "" && this.state.advancedSearchFilters.date_start === "" && this.state.numOfDays === 0) {
            this.setState({
                numOfDays: 'Current Week'
            })
        }
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
        console.log("selected",selectedOptionType);
		if (info['action'] === 'select-option') {
			this.setState({
                numOfDays: selectedOptionType['value']
            });
            this.setState(prevState => ({
                advancedSearchFilters: {
                    ...prevState.advancedSearchFilters,
                    date_start: '',
                    date_stop: '',
                }
            }),()=>{

            this.pagination.dataCall();
            })
		}
	}
    dateChangeHandler(dateType, date) {
        const input = dateType.split('.');
        const state = this.state[input[0]];
        date = date !== null ? date : '';
        state[input[1]] = date;
        this.setState({
            [input[0]]: state
        });
        this.setState({
            numOfDays: 0
        },()=>{

            // this.pagination.dataCall();
        })
    }
    searchWithDate = () => {
        this.pagination.dataCall();
    }
	render() {
		//const { collapseUiSettings, filtered, numOfDays } = this.state;
		const { collapseUiSettings, numOfDays } = this.state;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [

					{
						Header: "Name",
						accessor: "name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "# of Uploaded Edited Photos",
						accessor: "workingImages",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "# of Orders",
						accessor: "mozaixPrintedOrdersCount",
						className: "action-center",
                        Cell: ({ value, original }) => {
                            console.log("original", original);
                            console.log("value", value);
							return <Link to={'/Admin/' + original.id + '/mozaix-printed-order-by-user?order_ids=' + original.mozaixPrintedOrdersIds} className="blue_link" target="_blank">({value}) Orders</Link>
						},
						sortable: false,
					},
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
                                                    <div className="secHeading">
                                                        Mozaix Printed Report
                                                    </div>
												</Col>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className='form-group ' >
                                                            <div className='col-md-6 flexElem' >
                                                                <div >
                                                                    <label className='w-100' >Date Start</label>
                                                                    <DatePicker
                                                                        onChange={this.dateChangeHandler.bind(this, 'advancedSearchFilters.date_start')}
                                                                        className='form-control calender_icon' dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                                                        selected={this.state.advancedSearchFilters.date_start} />
                                                                </div>
                                                                <div  >
                                                                    <label >Date Stop</label>
                                                                    <DatePicker
                                                                        onChange={this.dateChangeHandler.bind(this, 'advancedSearchFilters.date_stop')}
                                                                        className='form-control calender_icon' dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                                                        selected={this.state.advancedSearchFilters.date_stop} />
                                                                </div>
                                                                <div>
                                                                <label >&nbsp;</label>
                                                                <button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info "
                                                                    onClick={this.searchWithDate}>Search</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
												<div className="col-md-4 col-md-offset-4  mt-md">
													<Select
														value={returnLabelWithValue(numOfDays, OC.UPLOADPHOTOSFORVIDEOPRODAYS)}
														onChange={this.handleChangeContactLogDays}
														options={OC.UPLOADPHOTOSFORVIDEOPRODAYS}
														isSearchable={true}
														isMulti={false}
														name='numOfDays'
														placeholder={'Select No Of Days'}
													/>
												</div>
												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.getMozaixPrintedReports}
														filterView={false}
														// getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MozaixPrintedReports);
