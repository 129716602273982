import React, { useRef, useState, useEffect, Fragment } from "react";
import { Modal, Row, Col, Grid, OverlayTrigger, Popover } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import {  updateShippingDetails } from 'redux/actions/order-actions';

import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import ShippingService from "../../../services/shipping";
import ReactToPrint from "react-to-print";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsFillExclamationTriangleFill, BsXCircleFill } from 'react-icons/bs';
import DatePicker from "react-datepicker";
import { alertActions } from "../../../redux/actions/alert-actions";
import { withRouter } from 'react-router-dom'
import { isEmptyCheck, returnChangedAddress, returnChangeAddressNote, hasRole } from "../../../helper/helperFunctions";
import { objectsConstants as OC } from "../../../redux/constant/objects-constant";
import ConfirmAlertForShipping from "../../ThemeComponents/ConfirmAlertForShipping";
import { confirmAlert } from "react-confirm-alert";



const PrintLabel = React.forwardRef((props, ref) => {
    return (
        <div className="row abc111" ref={ref}>
            {
                props.labelPreview &&
                <img className="" src={`data:image/jpeg;base64,${props.labelPreview}`} />
            }
        </div>
    )
});
const Shipping = (props) => {

    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));

    const shippingAddressID = "gtv";


    const moment = window.moment;
    // var date = moment(new Date()).format("YYYY-MM-DD");
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
    const [tabs, setTabs] = useState({
        showBarCode: true,
        showShippingAddress: false,
        showShippingMethods: false,
        showTrackingId: false
    });
    const barcodeInputRef = useRef(null);
    // const selectedRadioButtonRef = useRef(null);
    const [sameStreet, setSameStreet] = useState(false);
    const [sameZipcode, setSameZipcode] = useState(false);
    const [sameCity, setSameCity] = useState(false);
    // const [barCode, setBarCode] = useState("0020100008323"); // Staging Bar Code
    // const [barCode, setBarCode] = useState("0096500001123"); // local Bar Code
    // const [shippingDetails, setShippingDetails] = useState({...defaultValues});
    const [barCode, setBarCode] = useState("");
    const [referenceNumber, setReferenceNumber] = useState(""); // Staging Bar Code
    const [shippingDetails, setShippingDetails] = useState(null);
    const [fromShippingAddress, setFromShippingAddress] = useState([]);
    const [shippingRates, setShippingRates] = useState([]);
    const [labelPreview, setLabelPreview] = useState("");
    const [fromAddress, setFromAddress] = useState(null);
    const [defauldFromAddress, setDefaultFromAddress] = useState(OC.defauldFromAddress);
    const [mailClass, setMailClass] = useState(null);
    const [trackingObj, setTrackingObj] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [shippngNotesModal, setShippngNotesModal] = useState(false);
    const [addressMatch, setAddressMatch] = useState(false);
    const [endiciaAddressCopyObj, setEndiciaAddressCopyObj] = useState({});
    const [address, setAddress] = useState(false);
    const [totalWeight, setTotalWeight] = useState(false);
    const [validateMessage, setValidateMessage] = useState(false);
    const [endiciaAddress, setEndiciaAddress] = useState({});
    const [sameEndiciaAddress, setSameEndiciaAddress] = useState(false);
    const [sameOrderAddress, setSameOrderAddress] = useState(false);
    const [printLabelButton, setPrintLabelButton] = useState(false);
    const [labelNote, setLabelNote] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [print_reference_no, setPrint_reference_no] = useState(true);
    const [contacts, setContacts] = useState('');
    const [shipDate, setShipDate] = useState(date);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [dimensions, setDimensions] = useState({length:22, width:3, height:3});
    const [prevPtmAddressObject, setPrevPtmAddressObject] = useState({});
    const [pre_trip_material_address, setPre_trip_material_address] = useState({});
    const [video_shipping_address, setVideo_shipping_address] = useState({});


    const componentRef = useRef();

    const resetState = () => {

        setSameStreet(false);
        setSameCity(false);
        setSameZipcode(false);
        // setMailClass(null);
        setDimensions({length:22, width:3, height:3});
    };
    useEffect(() => {
        fetchShippingAddress();
    }, []);


    useEffect(() => {
        const getData = setTimeout(() => {
            if (barCode && (barCode.length === 13 || barCode.length === 8) ) {
                fetchOrderDetails();
            }
        }, 1000)

        return () => clearTimeout(getData)

    }, [barCode]);

    useEffect(() => {
        const getData = setTimeout(() => {
            createLabelPreview(mailClass);
        }, 2000)

        return () => clearTimeout(getData)

    }, [endiciaAddressCopyObj, mailClass]);

    // useEffect(() => {
    //     // Focus on the selected radio button after rendering is complete
    //     setTimeout(() => {
    //         if (selectedRadioButtonRef.current) {
    //             selectedRadioButtonRef.current.focus();
    //         }
    //     }, 100);
    // }, [shippingRates]);

    const returnShippingTypes = (data) => {
        if ((data.shipping_type.includes('ptm') && data.camera_ship_status !== null && data.camera_ship_status.tracking_number !== null) ||
            (data.shipping_type.includes('video') && data.video_ship_status !== null && data.video_ship_status.tracking_number !== null) ||
            (data.shipping_type.includes('mosaic') && data.mosaic_ship_status !== null && data.mosaic_ship_status.tracking_number !== null)) {
            setPrintLabelButton(true);
            setLabelNote(true);
        } else {
            setPrintLabelButton(false);
            setLabelNote(false);
        }

    }

    function validateEndiciaAddress(gt_address = {}, endicia_address = {}) {
        if ( gt_address.street_address_1 !== endicia_address.street_address_1 ||
             gt_address.country.code !== endicia_address.country.code ||
             gt_address.city !== endicia_address.city ||
             gt_address.state.code !== endicia_address.state.code ||
             gt_address.zipcode !== endicia_address.zipcode
            ) {
            setAddress(endicia_address);
            sameAddressCheck(gt_address, endicia_address);
        }
        // createLabelPreview(mailClass, endicia_address);
    }

    function incrementTotalWeightOz(shippingDetails) {
        let tweight = shippingDetails.total_weight;
        const tempObj = {...shippingDetails};
        if ( shippingDetails.shipping_type == 'ptm' && shippingDetails.depatureDateDiff < 30 ) {
            tweight = {
                title: tweight.title,
                lbs: tweight.lbs,
                oz: tweight.oz + 1
            }
            tempObj['total_weight'] = tweight;
        }
        setTotalWeight(tweight);
        
        fetchShippingRates(tempObj);
        setShippingDetails(tempObj);
    }
    const fetchOrderDetails = () => {
        resetState();
        console.log("BARCODE ", barCode);
        ShippingService.fetchOrderByBarCode({ barcode: barCode })
            .then(response => {
                // let refNo = response.shipping_type === 'reorder' ? response.data.id : response.data.barcode;
                // setReferenceNumber(refNo);
                if (response.data.shipping_type == 'reorder') {
                    if (response.data.is_agency_address)
                        setReferenceNumber(response.data.id + ' - ' + response.data.original_group_name);
                    else
                        setReferenceNumber(response.data.id);
                } else {
                    if (response.data.is_agency_address)
                        setReferenceNumber(response.data.barcode + ' - ' + response.data.original_group_name);
                    else
                        setReferenceNumber(response.data.barcode);
                }
                validateAddresses(response.data);
                incrementTotalWeightOz(response.data);
                setAddress(response.data.address);
                returnShippingTypes(response.data);
                setPrevPtmAddressObject(response.data.order.pre_trip_material_address);
                setPre_trip_material_address(response.data.order.pre_trip_material_address)
                setVideo_shipping_address(response.data.order.video_shipping_address)
                if (response.data.dvdMozaixComment !== null || response.data.ptmShippingComment !== null) {
                    if ((response.data.shipping_type.includes('mosaic') && response.data.dvdMozaixComment !== null) ||
                        (response.data.shipping_type.includes('ptm') && response.data.ptmShippingComment !== null))
                        setShippngNotesModal(true);
                }
                let contact = {
                    contact_name: response.data.contact_name,
                    group_name: response.data.group_name

                }
                setContacts(contact);
                setTabs({
                    showBarCode: true,
                    showShippingAddress: true,
                    showShippingMethods: false,
                    showTrackingId: false
                });
            })
            .catch(err => {
                console.log("ERROR OCCURED", err);
            });
    };

    const fetchShippingRates = (data) => {
        if (shippingDetails && totalWeight || data) {
            let obj = {
                total_weight: data && data.total_weight ? data.total_weight : totalWeight ? totalWeight : '',
                // total_weight: totalWeight ? totalWeight : data.total_weight,
                id: data && data.id ? data.id : shippingDetails.id,
                group_name: data && data.group_name ? data.group_name : shippingDetails.group_name,
                order_number: data && data.order_number ? data.order_number : shippingDetails.order_number,
                barcode: data && data.barcode ? data.barcode : shippingDetails.barcode,
                ship_date: shipDate,
                shipping_type: data && data.shipping_type ? data.shipping_type :shippingDetails.shipping_type,
                to_address: data && data.address ? data.address : !isEmptyCheck(endiciaAddressCopyObj) ? endiciaAddressCopyObj : address,
                from_address: fromAddress ? fromAddress : defauldFromAddress,
                length: data && data.dimensions ? data.dimensions.length : dimensions.length,
                width: data && data.dimensions ? data.dimensions.width : dimensions.width,
                height: data && data.dimensions ? data.dimensions.height : dimensions.height,
            };

            ShippingService.fetchShippingRates(obj)
                .then(response => {
                    setShippingRates(response);
                    setMailClass(null)
                    setTabs({
                        showBarCode: true,
                        showShippingAddress: true,
                        showShippingMethods: true,
                        showTrackingId: false
                    });
                })
                .catch(err => {
                    console.log("ERROR OCCURED", err);
                });
        }
    };

    const createLabelPreview = (mail, data = null) => {
        if (mail) {
            let obj = {
                total_weight: totalWeight ? totalWeight : shippingDetails.total_weight,
                id: shippingDetails ? shippingDetails.id : shippingDetails.id,
                group_name: data && data.group_name ? data.group_name : data && data.group_name == '' ? '' : contacts ? contacts.group_name : shippingDetails.group_name,
                contact_name: data && data.contact_name ? data.contact_name : data && data.contact_name == '' ? '' : contacts ? contacts.contact_name : shippingDetails.contact_name,
                order_number: shippingDetails ? shippingDetails.order_number : shippingDetails.order_number,
                barcode: shippingDetails ? shippingDetails.barcode : shippingDetails.barcode,
                to_address: data &&  data.city ? data : endiciaAddressCopyObj,
                from_address: fromAddress,
                shipping_type: shippingDetails ? shippingDetails.shipping_type : shippingDetails.shipping_type,
                heading: shippingDetails ? shippingDetails.heading : shippingDetails.heading,
                is_agency_address: shippingDetails ? shippingDetails.is_agency_address : shippingDetails.is_agency_address,
                mail_class: mail ? mail : mailClass,
                reference_number: data && data.refno ? data.refno : data && data.refno == '' ? '' : referenceNumber,
                // reference_number: (data && data.refno) ? 
                //   (data.refno === '' ? 
                //     '' : 
                //     (data.refno +  (shippingDetails.is_agency_address ? ' - ' + shippingDetails.group_name : ''))
                //   ) : 
                //   (referenceNumber +  (shippingDetails.is_agency_address ? ' - ' + shippingDetails.group_name : '')),
                send_shipping_email: sendEmail,
                ship_date: shipDate,
                show_dimensions: shippingDetails.show_dimensions,
                print_reference_no: print_reference_no,
                length: data && data.dimensions ? data.dimensions.length : dimensions.length,
                width: data && data.dimensions ? data.dimensions.width : dimensions.width,
                height: data && data.dimensions ? data.dimensions.height : dimensions.height,

            };
            ShippingService.createLabelPreview(obj)
                .then(response => {
                    console.log("DATA ", response);
                    if ( response[0].labels) {
                        setLabelPreview(
                            response[0].labels[0].label_data
                        );
                    }
                    if ( response[0].error_reference_id) {
                        props.error(response[0].errors[0].error_message)
                    }
                })
                .catch(err => {
                    console.log("ERROR OCCURED", err);
                });
        }
    };

    const createLabel = () => {
        let obj = {
            total_weight: totalWeight,
            id: shippingDetails.id,
            group_name: contacts.group_name,
            contact_name: contacts.contact_name,
            order_number: contacts.order_number,
            barcode: shippingDetails.barcode,
            shipping_type: shippingDetails.shipping_type,
            heading: shippingDetails.heading,
            is_agency_address: shippingDetails.is_agency_address,
            to_address: address,
            from_address: fromAddress,
            ship_date: shipDate,
            show_dimensions: shippingDetails.show_dimensions,
            mail_class: mailClass,
            reference_number: referenceNumber ? referenceNumber : shippingDetails.barcode,
            send_shipping_email: sendEmail,
            print_reference_no: print_reference_no,
            length: dimensions.length,
            width: dimensions.width,
            height: dimensions.height,

        };
        ShippingService.createLabel(obj)
            .then(response => {
                console.log("DATA ", response);
                if (response[0].errors) {
                    console.log("else if conditoin ");
                    props.error(response[0].errors[0].error_message)
                }
                else if (response[0].labels[0].label_data) {
                    console.log("if conditoin ");
                    setPrintLabelButton(true);
                } else {
                    console.log("else conditoin ");

                }
                setTrackingObj({
                    image: response[0].labels[0].label_data,
                    trackingId: response[0].tracking_number
                });
                setShowModal(true);
            })
            .catch(err => {
                console.log("ERROR OCCURED", err);
            });
    };
    const fetchShippingAddress = () => {
        ShippingService.fetchFromAddresses()
            .then(response => {
                console.log("DATA FROM SHIPPING ADDRESS ", response);
                response.forEach(el => {
                    if (el.account.domain_slug === shippingAddressID) {
                        setFromAddress(el);
                    }
                });

                setFromShippingAddress(response);
            })
            .catch(err => {
                console.log("ERROR OCCURED");
            });
    };

    const readOnlyShippingEl = (
        title = "No Title",
        value = "No Value",
        divide = true
    ) => {
        if (divide) {
            return (
                <React.Fragment>
                    <tr>
                        <td>{title}</td>
                        <td>{value.qty}</td>
                        <td>{value.oz}</td>
                    </tr>
                </React.Fragment>
            );
        }
    };
    function defaultOptionRadio() {
        let pack = '';
        let service = '';
        if (shippingDetails) {
            if (shippingDetails.shipping_type === "mozaix" || shippingDetails.shipping_type === "mosaic") {
                service = 'usps_ground_advantage';
                pack = 'package';
            } else if (shippingDetails.shipping_type === "ptm" && shippingDetails.depatureDateDiff >= 30 && shippingDetails.total_weight.oz < 16) {
                service = 'usps_first_class_mail';
                pack = 'package';
            } else if (shippingDetails.shipping_type === "ptm" && shippingDetails.depatureDateDiff < 30 && shippingDetails.total_weight.oz > 16) {
                service = 'usps_priority_mail';
                pack = 'usps_flat_rate_envelope';
            } else if (shippingDetails.shipping_type === "video" ) {
                service = 'usps_first_class_mail';
                pack = 'package';
            } else if (shippingDetails.shipping_type === "mozaix" || shippingDetails.shipping_type === "mosaic") {
                service = 'usps_priority_mail';
                pack = 'package';
            } else if (shippingDetails.depatureDateDiff >= 30) {
                service = 'usps_first_class_mail';
                pack = 'package';
            } else if (shippingDetails.depatureDateDiff < 30 && shippingDetails.weight[0].qty < 150) {
                service = 'usps_priority_mail';
                pack = 'usps_flat_rate_envelope';
            } else if (shippingDetails.depatureDateDiff < 30 && shippingDetails.weight[0].qty >= 150) {
                service = 'usps_priority_mail';
                pack = 'usps_small_flat_rate_box';
            }
        }
        return [service, pack]
    }

    const getColumnRadio = record => {
        const { shipment_cost, packaging_type, service_type } = record;
        let name = packaging_type.split('_').join(' ');
        let data = defaultOptionRadio();
        // if (record.service_type.includes(data[0]) && packaging_type.includes(data[1])) {
            if ( service_type === data[0] && packaging_type === data[1]) {
                if (!mailClass) {
                setMailClass(record);
                // createLabelPreview(record);
            }
        }
        if (!((name.match('letter') && service_type.match('usps_first_class_mail')) ||
            ((name.match('letter') || name.match('legal flat rate envelope')) && service_type === 'usps_priority_mail')
        )) {
            return (
                <Col md={6} className="mt-sm border-bottom">
                    <label className="container-radio font14 w-100">
                        <span
                            style={{
                                marginRight: "10px"
                            }}
                            className="td_barcode text-right font-weight-bold font16"
                        >
                            ${shipment_cost.total_amount}
                        </span>
                        <span className="text-nowrap" style={{ textTransform: 'capitalize' }}>
                            {`${name.split('usps ').join('')}`}
                        </span>
                        <input
                            // ref={record.service_type === data[0] && record.packaging_type === data[1] ? selectedRadioButtonRef : null}
                            value={JSON.stringify(record)}
                            type="radio"
                            name={service_type}
                            id={service_type}
                            className="radio font14"
                            defaultChecked={service_type === data[0] && packaging_type === data[1] ? true : false}
                            onClick={() => { setMailClass(record);}}
                        />
                        <span className="checkmark" />
                    </label>
                </Col>
            );
        }
    };
    const getAllColumnRadio = record => {
        return (
            <Col md={12} className="mt-sm border-bottom">
                <h5 htmlFor="name" style={{ padding: '5px', borderBottom: '1px dashed #bbb' }}>{`${record.mail_class_name} ( ${record.data[0].estimated_delivery_days}-days )`}</h5>
                {
                    record.data.map(el => { return getColumnRadio(el); })
                }
            </Col>
        );
    };

    function inputHandler(e) {
        const { name, value } = e.target;
        let newAddress = { ...address }; // create a copy of the address object

        if (name === 'state' || name === 'country') {
            newAddress[name] = {
                ...newAddress[name],
                ["code"]: value
            };
        } else {
            newAddress[name] = value;
        }
        setAddress({...newAddress}); // update the state with the new copy of address


        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(function () {
                let tempObject = { address: newAddress }
                validateAddresses(tempObject);
                // createLabelPreview(mailClass, newAddress);

            }, 2000)

        );
    }

    function groupNameInputHandler(e) {
        const { name, value } = e.target;
        let tempObj = { ...contacts }; // create a copy of the address object
            tempObj[name] = value;
        setContacts({...tempObj})

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(function () {
                createLabelPreview(mailClass, tempObj);

            }, 2000)

        );
    }

    function weightInputHandler(e) {
        const { name, value } = e.target;
        let tempObj = { ...totalWeight }; // create a copy of the address object
        tempObj[name] = value;
        setTotalWeight({...tempObj })
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }

            setTypingTimeout(
                setTimeout(function () {
                    let tempObject = { total_weight: tempObj }
                    fetchShippingRates(tempObject);
                    createLabelPreview(mailClass, tempObject);

                }, 2000)

            );
    }

    function dimensionsHandler(e) {
        const { name, value } = e.target;
        let tempObj = { ...dimensions }; // create a copy of the address object
        tempObj[name] = value;
        setDimensions({ ...tempObj})

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(function () {
                let tempObject = { dimensions: tempObj }
                fetchShippingRates(tempObject);
                createLabelPreview(mailClass, tempObject);

            }, 2000)

        );
    }
    function referenceNumerHandler(e) {
        const { value } = e.target;
        setReferenceNumber(value)

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(function () {
                let tempObject = { refno: value }
                createLabelPreview(mailClass, tempObject);

            }, 2000)

        );
    }


    const validateAddresses = (data) => {
        if (data.address || shippingDetails) {
            let obj = {
                group_name: contacts ? contacts.group_name : data.group_name,
                contact_name: contacts ? contacts.contact_name : data.contact_name,
                to_address: data ? data.address : address,
            };
            ShippingService.validateAddresses(obj)
                .then(response => {
                    setAddressMatch(response[0].validation_results.result_code)
                    setValidateMessage(response[0].validation_results.result_description)
                    setEndiciaAddress(response[0].matched_address)

                    let tempAddressObj = {
                        address_type_id: data.address ? data.address.address_type_id : address.address_type_id,
                        city: response[0].matched_address.city,
                        country: {
                            id: data.address ? data.address.country.id : address.country.id,
                            title: data.address ? data.address.title : address.country.title,
                            code: response[0].matched_address.country_code
                        },
                        country_id: data.address ? data.address.country_id : address.country_id,
                        csv_value: data.address ? data.address.csv_value : address.csv_value,
                        full_address: data.address ? data.address.full_address : address.full_address,
                        id: data.address ? data.address.id : address.id,
                        model_id: data.address ? data.address.model_id : address.model_id,
                        model_type: data.address ? data.address.model_type : address.model_type,
                        state: {
                            code: response[0].matched_address.state_province,
                            id: data.address ? data.address.state.id : address.state.id,
                            title: data.address ? data.address.state.title : address.state.title
                        },
                        state_id: data.address ? data.address.state_id : address.state_id,
                        street_address_1: response[0].matched_address.address_line1,
                        street_address_2: response[0].matched_address.address_line2,
                        zipcode: response[0].matched_address.postal_code
                    };
                    let gt_address = data.address ? data.address : data;
                    setEndiciaAddressCopyObj(tempAddressObj);
                    validateEndiciaAddress(gt_address, tempAddressObj);
                })
                .catch(err => {
                    console.log("validateAddresses", err);
                });
        }
    };
    function returnColor() {
        let html = '';
        if (addressMatch === "E100") {
            html =
                <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">{validateMessage}</Popover>}>
                    <BsXCircleFill size={30} color="red" />
                </OverlayTrigger>
        } else if (addressMatch === "V100") {
            html =
                <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">{validateMessage}</Popover>}>
                    <i className='fa fa-check shippingCheck'></i>
                </OverlayTrigger>
        } else {
            html =
                <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">{validateMessage}</Popover>}>
                    <BsFillExclamationTriangleFill size={30} color="orange" />
                </OverlayTrigger>
        }
        return html;
    }

    function sameAddressCheck(addressObj, endAddressObj) {
        if (addressObj && endAddressObj) {
            if (addressObj.street_address_1.toUpperCase() !== endAddressObj.street_address_1.toUpperCase()) {
                setSameStreet(true)
            }
            if (addressObj.zipcode.substring(0, 5) !== endAddressObj.zipcode.substring(0, 5)) {
                setSameZipcode(true)
            }
            if (addressObj.city.toUpperCase() !== endAddressObj.city.toUpperCase()) {
                setSameCity(true)
            }
        } else {

            if (address.street_address_1.toUpperCase() !== endiciaAddress.address_line1.toUpperCase()) {
                setSameStreet(true)
            }
            if (address.zipcode.substring(0, 5) !== endiciaAddress.postal_code.substring(0, 5)) {
                setSameZipcode(true)
            }
            if (address.city.toUpperCase() !== endiciaAddress.city.toUpperCase()) {
                setSameCity(true)
            }
        }
    }

    function sameEndiciaAddressHandler(e) {

        const target = e.target;
        setSameEndiciaAddress(target.checked)
        setSameOrderAddress(false)
        if (target.checked) {
            sameAddressCheck();
            // let enZip = endiciaAddress.PostalCode ;
            // if (endiciaAddress.Zip4) {
            //     enZip = enZip + '-' + endiciaAddress.Zip4
            // }
            const data = {
                address_type_id: address.address_type_id,
                city: endiciaAddress ? endiciaAddress.city : address.city,
                country: {
                    id: address.country.id,
                    title: address.title,
                    code: endiciaAddress ? endiciaAddress.country_code : address.country.code
                },
                country_id: address.country_id,
                csv_value: address.csv_value,
                full_address: address.full_address,
                id: address.id,
                model_id: address.model_id,
                model_type: address.model_type,
                state: {
                    code: endiciaAddress ? endiciaAddress.state_province : address.state.code,
                    id: address.state.id,
                    title: address.state.title
                },
                state_id: address.state_id,
                street_address_1: endiciaAddress ? endiciaAddress.address_line1 : address.street_address_1,
                street_address_2: endiciaAddress ? endiciaAddress.address_line2 : address.street_address_2,
                zipcode: endiciaAddress ? endiciaAddress.postal_code : address.zipcode
            };
            let tempObject = { address: data }
            setAddress(data)
            createLabelPreview(mailClass, data);
            validateAddresses(tempObject);
        } else {
            let tempObject = { address: data }
            const data = shippingDetails.address;
            setAddress(data)
            setSameStreet(false)
            setSameZipcode(false)
            setSameCity(false)
            createLabelPreview(mailClass, data);
            validateAddresses(tempObject);
        }
    }
    function sameOrderAddressHandler(e) {
        const target = e.target;
        setSameOrderAddress(target.checked)
        setSameEndiciaAddress(false)
        setSameStreet(false)
        setSameZipcode(false)
        setSameCity(false)
        if (target.checked) {
            const data = shippingDetails.address;
            setAddress(data)
            createLabelPreview(mailClass, data);
            let tempObject = { address: data }
            validateAddresses(tempObject);
        } else {
            // const data = shippingDetails.address;
            // setAddress(data)
            setSameStreet(false)
            setSameZipcode(false)
            setSameCity(false)
        }
    }

    function popoverEndiciaAddress(endiciaAddress) {
        return (
            <div>
                {endiciaAddress &&
                    <p style={{ fontSize: '12px' }}><strong>Street Address: </strong><span style={{ textTransform: "capitalize" }}>{'  ' + endiciaAddress && endiciaAddress.address_line1 && endiciaAddress.address_line1.toLowerCase()}</span><br />
                        <strong>Country: </strong>{'  ' + endiciaAddress.country_code}<br />
                        <strong>City: </strong><span style={{ textTransform: "capitalize" }}>{'  ' + endiciaAddress && endiciaAddress.city && endiciaAddress.city.toLowerCase()}</span><br />
                        <strong>State: </strong>{'  ' + endiciaAddress.state_province}<br />
                        <strong>PostalCode: </strong>{'  ' + endiciaAddress.postal_code}</p>
                    // : null
                }
            </div>
        )
    }

    function popoverOrderAddress() {
        return (
            <div>{
                    shippingDetails &&
                    <p style={{ fontSize: '12px' }}><strong>Street Address: </strong>{'  ' + shippingDetails.address.street_address_1} <br />
                        <strong>Country: </strong>{'  ' + shippingDetails.address.country.code}<br />
                        <strong>City: </strong>{'  ' + shippingDetails.address.city}<br />
                        <strong>State: </strong>{'  ' + shippingDetails.address.state.code}<br />
                        <strong>Zipcode: </strong>{'  ' + shippingDetails.address.zipcode}</p>
                }
            </div>
        )
    }

    function DateChangeHandler(value) {
        let shipDateValue = moment(value).format('YYYY-MM-DD 00:00:00');

        setShipDate(shipDateValue)
    }
    // function DateChangeHandler(value, field) {
    //     setShipDate(value === null ? '' : moment(value).format("YYYY-MM-DD"))
    // }

    function dateElement(label, name, value, rules) {
        var selectedDate = shipDate !== '' ? new Date(shipDate) : '';
        return (
            <div className='form-group fullCalendar flexElem' id={name}>
                <label style={{ fontSize: "15px", whiteSpace: 'nowrap', lineHeight: '36px', padding: '0 5px' }}>{label}</label>
                <DatePicker className="form-control calender_icon font14" dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} selected={selectedDate} name={name} onChange={(event) => DateChangeHandler(event, name)} />

            </div>
        );
    }

    function shippingComments(shippingDetails) {
        let comment = '';

        if (shippingDetails && shippingDetails.shipping_type.includes('mosaic') && shippingDetails.dvdMozaixComment !== null) {
            comment = shippingDetails.dvdMozaixComment.comment;

        } else if (shippingDetails && shippingDetails.shipping_type.includes('ptm') && shippingDetails.ptmShippingComment !== null) {
            comment = shippingDetails.ptmShippingComment.comment;
        }

        return comment;
    }
    
    function beforePrint() {
        resetState();
        setShowModal(false);
        setLabelPreview("");
        setShippingDetails(null);
        setMailClass(null);
        setTrackingObj(null);
        setAddressMatch(null);
        // setBarCode('');
        setTabs({
            showBarCode: true,
            showShippingAddress: false,
            showShippingMethods: false,
            showTrackingId: false
        });
    }

    function afterPrint() {
        barcodeInputRef.current.focus();
        barcodeInputRef.current.select();
    }

    function changeOrderAddress(type) {
        let orderId = shippingDetails.id;
        const details = {
                pre_trip_material_address: pre_trip_material_address,
                video_shipping_address: video_shipping_address,
                shipping_type: shippingDetails.shipping_type
            }
        let changedAddress = returnChangedAddress(details, address, type);
        if (orderId) {
            const data = {
                shipping_details: {
                    pre_trip_material_address: changedAddress[0],
                    video_shipping_address: changedAddress[1]
                }
            }
            // props.updateShippingDetailsCall(orderId, data).then(
                ShippingService.updateDetailsFromShipping(orderId, data).then(
                response => {

                    props.success(response.message)
                    if (type == "ptm") {
                        setPre_trip_material_address(response.data.shipping_details.pre_trip_material_address)
                    } else {
                        setVideo_shipping_address(response.data.shipping_details.video_shipping_address)
                    }
                    setSameStreet(false);
                    setSameCity(false);
                    setSameZipcode(false);

                    console.log("address", address);

                }
            );
         
        }
    }

    function showPopUp (type) {
        confirmAlert({
            customUI: ({ onClose }) => {
                let message = returnChangeAddressNote(shippingDetails, type, prevPtmAddressObject);
                return (
                    <ConfirmAlertForShipping typeName="Date" description={message.includes('not match') ? `${message}` : `${message} Are you sure you want to update the order address? `} 
                    okBtn={message.includes('not match') ? true : false } onClosePro={onClose} onYesPro={() => changeOrderAddress(type)}
                    currentAddress={type.includes('ptm') ? pre_trip_material_address : video_shipping_address} changeableAddress={address} 
                    />
                )
            }
        })
    }

    const convertToCamelCase = (address) => {
        return address.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return (
        <div className="content">
            <Grid fluid>
                <Row style={{ backgroundColor: 'white', marginTop: '15px', paddingTop: '15px' }}>
                    <Col xs={12} md={7}>
                        <Card
                            ctTableResponsive
                            content={
                                <div>
                                    {tabs.showBarCode && (
                                        <Row>
                                            <Col sm={6} md={4}>Please Scan barcode:</Col>
                                            <Col sm={6} md={4}>
                                                <input
                                                    className="form-control font14"
                                                    type="text"
                                                    id="barcode"
                                                    name="barcode"
                                                    placeholder="Scan Barcode"
                                                    value={barCode}
                                                    required
                                                    autoFocus
                                                    ref={barcodeInputRef}
                                                    onChange={({ target }) => { setBarCode(target.value.trim()); }}
                                                />
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <button
                                                    className="customBtn btn btn-primary block"
                                                    onClick={
                                                        fetchOrderDetails
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    )}

                                    {tabs.showShippingAddress && (
                                        <Fragment>
                                            <Row>
                                                <Col md={4} className="mt-lg">
                                                <label htmlFor="name">{"Ship From"}</label>
                                                <select
                                                        className="form-control font14"
                                                        // style={{padding:'0 12px'}}
                                                        onChange={event => {
                                                            setFromAddress(
                                                                JSON.parse(event.target.value)
                                                            );
                                                        }}
                                                    >
                                                        {fromShippingAddress && fromShippingAddress.length > 0 ?
                                                            fromShippingAddress.map(
                                                                (el, index) => {
                                                                    return el.account.domain_slug === shippingAddressID ? (
                                                                        <option
                                                                            selected
                                                                            key={index}
                                                                            value={JSON.stringify(el)}
                                                                        >
                                                                            {
                                                                                el.account.title + ' - ' + el.city + ', ' + el.state.code
                                                                            }
                                                                        </option>
                                                                    ) : (
                                                                        <option
                                                                            key={index}
                                                                            value={JSON.stringify(el)}
                                                                        >
                                                                            {
                                                                                el.account.title + ' - ' + el.city + ', ' + el.state.code
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )
                                                            : null}
                                                    </select>
                                                </Col>
                                                <Col md={5} className="mt-lg">
                                                    <label className="container-check font14" style={{paddingLeft: 30,marginBottom:2}}> Send Shipping Email
                                                        <input checked={sendEmail} onChange={({ target }) => { setSendEmail(target.checked); }} type='checkbox' className='checkbox' />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <div className="flexElem mb-sm mt-sm">
                                                            <label className="container-check font14" style={{paddingLeft: 30,marginBottom:0}}> Endicia Address
                                                                <input checked={sameEndiciaAddress} onChange={sameEndiciaAddressHandler} type='checkbox' className='checkbox' />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <OverlayTrigger trigger='click' placement='top' overlay={<Popover id='copyLinkMaterial' >{popoverEndiciaAddress(endiciaAddress)}</Popover>} >
                                                                <span className='blue_link' style={{ cursor: 'pointer', color: '#5d5d5d', fontSize: '14px' }}> &nbsp;(Compare)
                                                                </span>
                                                            </OverlayTrigger>
                                                    </div>
                                                    <div className="flexElem">
                                                        <label className="container-check font14" style={{paddingLeft: 30,marginBottom:0}}>     Order Address
                                                            <input checked={sameOrderAddress} onChange={sameOrderAddressHandler} type='checkbox' className='checkbox' />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <OverlayTrigger trigger='click' placement='top' overlay={<Popover id='copyLinkMaterial' >{popoverOrderAddress()}</Popover>} >
                                                            <span className='blue_link' style={{ cursor: 'pointer', color: '#5d5d5d', fontSize: '14px' }}> &nbsp;(Compare)
                                                            </span>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement='top' overlay={<Popover id='copyLinkMaterial' >Copy to clipboard</Popover>} >
                                                            <CopyToClipboard text={
                                                                `${contacts && contacts.group_name && contacts.group_name}\n` +
                                                                `${contacts && contacts.contact_name && contacts.contact_name}\n` +
                                                                `${address && address.street_address_1 && convertToCamelCase(address.street_address_1)}\n` +
                                                                `${address && address.city && convertToCamelCase(address.city)} ` +
                                                                `${address && address.state && address.state.code} ` +
                                                                `${address && address.zipcode && address.zipcode}`
                                                            }
                                                            >

                                                                <button className="orderDetailsAction copyColorAction ml-lg" type="button">
                                                                    <span className="">&nbsp;</span>
                                                                </button>
                                                            </CopyToClipboard>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="mt-lg">
                                                    <label htmlFor="name" style={{ float: 'right' }}>{"Barcode"}</label><br/>
                                                    <Link target="_blank" to={`/Admin/${shippingDetails && shippingDetails.id}/${shippingDetails && shippingDetails.shipping_type.includes('reorder') ? 'print_reorder_LPR' : 'order-edit'}`} className="blue_link" style={{ float: 'right' }}>{shippingDetails && shippingDetails.barcode}</Link>
                                                </Col>
                                                <div className="clearfix"></div>
                                                <div className="mt-sm"></div>                                               
                                                    <Col md={3}>
                                                        <label htmlFor="name">
                                                            <Link target="_blank" to={`/Admin/${shippingDetails && shippingDetails.id}/${shippingDetails && shippingDetails.shipping_type.includes('reorder') ? 'print_reorder_LPR' : 'order-edit#orderDetailsHeader'}`} className="blue_link">{"Ship To"}</Link>
                                                        </label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <button className="customBtn btn btn-primary btn-sm" style={{fontSize: '12px'}} disabled={shippingDetails && !shippingDetails.shipping_type.includes('ptm') ? true : false} log={console.log("ship_typeeeee", shippingDetails && shippingDetails.shipping_type)} onClick={() => {showPopUp('ptm')}}> Change Ptm Address</button>

                                                        <button className="customBtn btn btn-primary ml-md btn-sm" style={{fontSize: '12px'}} onClick={() => {showPopUp('video')}}> Change Video Address</button>
                                                    </Col>                                                    <Col md={1}>
                                                        <span style={{ float: 'right', marginBottom: '5px' }}>
                                                            {returnColor()}
                                                        </span>
                                                    </Col>
                                            </Row>
                                            <Row className="shippingAddress">
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label htmlFor="name">Group Name</label>
                                                            <input
                                                                className="form-control font14"
                                                                type="text"
                                                                id="group_name"
                                                                name="group_name"
                                                                value={contacts.group_name}
                                                                required
                                                                onChange={groupNameInputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label htmlFor="name">Contact Name</label>
                                                            <input
                                                                className="form-control font14"
                                                                type="text"
                                                                id="contact_name"
                                                                name="contact_name"
                                                                value={contacts.contact_name}
                                                                required
                                                                onChange={groupNameInputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label className="mt-sm" htmlFor="street_address_1">Street Address</label>
                                                            <span className="text-danger ml-sm">{sameStreet ? shippingDetails.address.street_address_1 : ''}</span>
                                                            <input
                                                                style={{ textTransform: "capitalize" }}
                                                                className="form-control font14"
                                                                type="text"
                                                                id="street_address_1"
                                                                name="street_address_1"
                                                                value={address.street_address_1.toLowerCase()}
                                                                required
                                                                onChange={(e) => {inputHandler(e)}}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label className="mt-sm" htmlFor="name">Country</label>
                                                            <input
                                                                className="form-control font14"
                                                                type="text"
                                                                id="country"
                                                                name="country"
                                                                value={address.country.code}
                                                                required
                                                                onChange={inputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label className="mt-sm" htmlFor="name">City</label>
                                                            <span className="text-danger ml-sm">{sameCity ? shippingDetails.address.city : ''}</span>
                                                            <input
                                                                style={{ textTransform: "capitalize" }}
                                                                className="form-control font14"
                                                                type="text"
                                                                id="city"
                                                                name="city"
                                                                value={address.city.toLowerCase()}
                                                                required
                                                                onChange={inputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label className="mt-sm" htmlFor="name">State</label>
                                                            <input
                                                                className="form-control font14"
                                                                type="text"
                                                                id="state"
                                                                name="state"
                                                                value={address.state.code}
                                                                required
                                                                onChange={inputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <label className="mt-sm" htmlFor="name">Zipcode</label>
                                                            <span className="text-danger ml-sm">{sameZipcode ? shippingDetails.address.zipcode : ''}</span>
                                                            <input
                                                                className="form-control font14"
                                                                type="text"
                                                                id="zipcode"
                                                                name="zipcode"
                                                                value={address.zipcode}
                                                                required
                                                                onChange={inputHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={12} className="mt-md">
                                                    <table className="table table-condensed table-responsive table-striped orderTable orderTableWeight">
                                                        <thead>
                                                            <tr>
                                                                <th className="font-weight-bold text-dark">Item Name</th>
                                                                <th className="font-weight-bold text-dark">Quantity</th>
                                                                <th className="font-weight-bold text-dark">Weight Oz</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {shippingDetails &&
                                                                shippingDetails.weight
                                                                ? shippingDetails.weight.map(
                                                                    item => {
                                                                        return readOnlyShippingEl(
                                                                            `${item.title}`,
                                                                            {
                                                                                lbs: item.line_weight.lbs,
                                                                                oz: item.line_weight.oz,
                                                                                qty: item.qty
                                                                            },
                                                                            true
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={9} sm={12} className="mb-10">
                                                    <h5>{totalWeight.title}</h5>
                                                </Col>
                                                <Col md={3} sm={12} className="mb-10">
                                                <button
                                                        className="customBtn btn btn-primary block"
                                                        onClick={() => {
                                                            createLabel();
                                                        }}
                                                        disabled={printLabelButton}
                                                    >
                                                        Print
                                                    </button>
                                                </Col>
                                                <div className="clearfix"></div>
                                                <Col md={4} sm={12} className="mb-10">
                                                    <div className="form-group flexElem mb0f flexColumn">
                                                        <label htmlFor="name" className="font14">
                                                            lbs
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="lbs"
                                                            className="form-control font14"
                                                            value={totalWeight.lbs}
                                                            onChange={weightInputHandler}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4} sm={12} className="mb-10">
                                                    <div className="form-group flexElem mb0f flexColumn">
                                                        <label htmlFor="name" className="font14">
                                                            oz
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="oz"
                                                            className="form-control font14"
                                                            value={totalWeight.oz}
                                                            onChange={weightInputHandler}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4} sm={12} className="mb-10">
                                                    <div className="form-group flexElem mb0f flexColumn fullCalendar" id="ship_date">
                                                        <label htmlFor="name" className="font14">
                                                        Ship Date
                                                        </label>
                                                        {/* // dateElement('Ship Date', 'shipDate', shipDate !== '' && moment(shipDate, 'YYYY-MM-DD'), '') */}
                                                        <DatePicker autoComplete='off' dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} className='form-control calender_icon font14' onChange={DateChangeHandler} selected={new Date(shipDate) || ''} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                shippingDetails && shippingDetails.show_dimensions &&
                                                <Row>
                                                    <Col md={4} sm={12}>
                                                        <div className="form-group flexElem mb0f flexColumn">
                                                            <label htmlFor="name" className="font14">
                                                            length
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="length"
                                                                className="form-control font14"
                                                                value={dimensions.length}
                                                                onChange={dimensionsHandler}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <div className="form-group flexElem mb0f flexColumn">
                                                            <label htmlFor="name" className="font14">
                                                                width
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="width"
                                                                className="form-control font14"
                                                                value={dimensions.width}
                                                                onChange={dimensionsHandler}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <div className="form-group flexElem mb0f flexColumn">
                                                            <label htmlFor="name" className="font14">
                                                            height
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="height"
                                                                className="form-control font14"
                                                                value={dimensions.height}
                                                                onChange={dimensionsHandler}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Fragment>
                                    )}

                                    {tabs.showShippingMethods && (
                                        <Grid fluid>
                                            <Row>
                                                <div className="flexElem gap10 flexWrap" style={{height:"300", overflow: "auto", marginTop: "10"}}>
                                                    {
                                                        shippingRates && shippingRates.length > 0 ?
                                                            shippingRates.map(
                                                                el => { return getAllColumnRadio(el); }
                                                            )
                                                            : null

                                                    }
                                                </div>
                                            </Row>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4 mt-lg">
                                                    <div className="text-center mb-xlg">
                                                        <button
                                                            className="customBtn btn btn-primary block"
                                                            onClick={() => {
                                                                createLabel();
                                                            }}
                                                            disabled={printLabelButton}
                                                        >
                                                            Print
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}

                                    {tabs.showTrackingId && (
                                        <Grid fluid>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4 mt-lg">
                                                    <tr className="">
                                                        <td>
                                                            {"Tracking ID :"}
                                                        </td>
                                                        <td>
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        "10px"
                                                                }}
                                                                className="td_barcode"
                                                            >
                                                                {trackingObj &&
                                                                    trackingObj.trackingId}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4 mt-lg">
                                                    <div className="text-center mb-xlg">
                                                        <button
                                                            className="customBtn btn btn-primary block mt-md"
                                                            onClick={() => {
                                                                setTabs({
                                                                    showBarCode: true,
                                                                    showShippingAddress: false,
                                                                    showShippingMethods: false,
                                                                    showTrackingId: false
                                                                });
                                                            }}
                                                        >
                                                            Enter Another
                                                            BarCode
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                </div>
                            }
                        />
                    </Col>
                    <Col xs={12} md={4} className="col-md-offset-1">
                        {
                            shippingDetails &&
                            <h4 className="mb-md shippingHeading">{shippingDetails.heading}</h4>
                        }
                        {
                            labelNote &&
                            <h5 style={{ color: 'red', fontWeight: 'bold' }}>Label is already printed for this order, please void that first</h5>
                        }
                        <h4>Preview</h4>
                        <img src={`data:image/jpeg;base64,${labelPreview}`} style={{ maxWidth: 340 }} />
                    </Col>
                    {
                        labelPreview &&
                        <Row>
                            <Col md={4} className="col-md-offset-1">
                                <div className='form-group'>
                                    <div className="clearfix">
                                        <label className="mt-sm" htmlFor="name">Reference Number</label>
                                        <input
                                            className="form-control font14"
                                            type="text"
                                            id="referenceNumber"
                                            name="referenceNumber"
                                            value={referenceNumber}
                                            required
                                            onChange={referenceNumerHandler}
                                            style={{maxWidth: '340px'}}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={2} className="col-md-offset-1">

                                <label className="container-check"> Print Reference #
                                    <input checked={print_reference_no} onChange={({ target }) => { setPrint_reference_no(target.checked); }} type='checkbox' className='checkbox' />
                                    <span className="checkmark"></span>
                                </label>
                            </Col>
                        </Row>
                    }
                </Row>

            </Grid>
            <Modal show={showModal}
                onHide={() => { setShowModal(!showModal) }}
                aria-labelledby="ModalHeader"
                backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader'
                        className="headerTitle">
                        <ReactToPrint
                            trigger={
                                () =>
                                 <a className='blue_link' to="#"><i className='fa fa-print'></i> Print Invoice</a>
                            }
                            content={() => componentRef.current}
                            onBeforePrint={beforePrint}
                            onAfterPrint={afterPrint}
                            // onBeforePrint={() => setShowModal(false)}
                        />
                        <div style={{ display: "none" }}><PrintLabel ref={componentRef} labelPreview={trackingObj ? trackingObj.image : ''} /></div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" style={{ textAlign: 'center' }}>
                        <img src={`data:image/jpeg;base64,${trackingObj ? trackingObj.image : ''}`} style={{ maxHeight: '6in' }} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={shippngNotesModal}
                onHide={() => { setShippngNotesModal(!shippngNotesModal) }}
                aria-labelledby="ModalHeader"
                backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">
                        Shipping Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <textarea type="text" rows={4} value={shippingComments(shippingDetails && shippingDetails)} disabled className="w-100 p-sm font14 text-grey mb-md" />
                        <button onClick={() => { setShippngNotesModal(!shippngNotesModal) }} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"> Close</button>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        error: (message) => { return dispatch(alertActions.error(message)) },
        success: (message) => { return dispatch(alertActions.success(message)) },
		updateShippingDetailsCall: (orderId, Data) => { return dispatch(updateShippingDetails(orderId, Data)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shipping));
