import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../Card/Card'
import { getReOrders, deleteOrder, processShipStatus } from '../../../redux/actions/order-actions'
import ThemeModal from '../../ThemeComponents/ThemeModal'
import ShipStatusForm from '../Order/ShipStatusForm'
import { toggleModal, selectChangeHandler, createSortStringReOrder } from '../../../helper/helperFunctions'
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from '../../ThemeComponents/confirmAlertForDelete'
import Pagination from '../../ThemeComponents/Pagination'
import { getAllShippingMethods } from '../../../redux/actions/shippingMethod-actions'
import { editComment, addComment, getCommentCategoryByTitle } from '../../../redux/actions/comment-actions'
import Select from 'react-select'
import { cloneDeep, orderBy } from 'lodash'
var moment = require("moment");
class ReorderList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageSize: 15,
            shipModal: false,
            orderId: '',
            showAll: false,
            ReOrders_total: 0,
            shippindMethods: [],
            editModal: false,
            editComment: {},
            reorder_type: 1,
            shippingDetails: {
                shipping_method_id: 4
            },
            comment: {
                comment: '',
                comment_category_id: 0
            },
            addCommentModel: false,
            orderCommentId: ''
        }
        // this.props.getReOrders(1, this.state.pageSize, '', '');
        this.toggleModal = toggleModal.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
    }

    // paginationCall = (page, filter, sort, pageSize) => {
    //     this.state.showAll ?
    //         this.props.getReOrders(1, this.state.ReOrders_total, '', '')
    //         :
    //         this.setState({ pageSize: pageSize }, function () {
    //             this.props.getReOrders(page, pageSize, sort, filter)
    //         });
    // }

    openTrackingModal = (orderId) => {
        this.setState({
            orderId: orderId
        }, () => {
            this.setState({
                shipModal: true
            })
        })
    }

    onOrderDeleteHandler = (id) => {
        this.props.deleteOrderCall(id).then(
            res => {
                this.pagination.dataCall();
            }
        );
    }

    confirmOrderDelete = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Order' onClosePro={onClose} deleteType={() => { this.onOrderDeleteHandler(id) }} />
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAll !== this.state.showAll) {
            this.paginationCall(1, '', '', this.state.pageSize);
        }
        if (this.props.ReOrders !== prevProps.ReOrders) {
            this.setState({
                ReOrders_total: this.props.ReOrders_total
            })
        }
        if (this.props.singleCommentCategory !== prevProps.singleCommentCategory) {
            const comment = cloneDeep(this.state.comment);
            comment.comment_category_id = this.props.singleCommentCategory.comment_category_id;
            this.setState({
                comment: comment
            })
        }
    }

    componentDidMount() {
        this.props.getAllShippingMethodsCall().then(
            res => {
                this.setState({
                    shippindMethods: res.data
                })
            }
        );
        this.props.getCommentCategoryByTitle(['Admin']);
    }

    markAsShipped = (id) => {
        const shippindMethods = [...this.state.shippindMethods]
        const otherMethod = shippindMethods.find(item => {
            return item.title === 'Other Method'
        })
        const data = {
            shipping_method_id: otherMethod.id,
            tracking_number: '',
            status: 1,
            slug: 'camera'
        }
        this.props.changeShipStatusCall(id, data).then(
            res => {
                this.pagination.dataCall();
            }
        );
    }


    // paginationCall = (data) => {
    //     let sorted = data.sort.split(',');
    //     let { sort, orderType } = createSortString(sorted);
    //     return this.props.getReOrders(data.page, data.pageSize, data.filter, sort, orderType, this.props.orderId, this.state.reorder_type);
    // }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = (data.sort.length == 0 || data.sort == '') ? createSortStringReOrder(sorted,'id','desc'): createSortStringReOrder(sorted);

    return this.props.getReOrders(data.page, data.pageSize, data.filter, sort, orderType, this.props.orderId, this.state.reorder_type);
}

    getRowProps = (rowInfo) => {
        let data = {}
        if (rowInfo !== undefined) {
            const shipped = rowInfo.video_ship_status
            if (shipped.length === 0) {
                data = {
                    color: 'red'
                }
            } else if (rowInfo.settings.length) {
                if (rowInfo.settings[0].value === '1') {
                    data = {
                        color: '#777'
                    }
                }
            } else {
                data = {
                    color: '#999'
                }
            }
        }
        return data;
    }

    addComment = (id) => {
        this.setState({
            addCommentModel: true,
        },()=> {
            this.setState({
                orderCommentId: id
            })
        })
    }

    storeCommentChangehandler = (e) => {
        const comment = { ...this.state.comment}
        comment.comment = e.target.value;
        this.setState({
            comment: comment
        })
    }

    storeComment = () => {
        const comment = { ...this.state.comment};
        if (comment.comment !== '') {
            this.props.addCommentCall(comment, this.state.orderCommentId).then(
                res => {
                    this.setState({
                        addCommentModel: false
                    });
                    this.pagination.dataCall();
                }
            );
        }
    }

    editComment = (comment) => {
        this.setState({
            editComment: comment,
        }, () => {
            this.setState({
                editModal: true
            })
        })
    }


    commentChangeHandler = (e) => {
        const comment = { ...this.state.editComment }
        comment.comment = e.target.value;
        this.setState({
            editComment: comment
        })
    }

    saveComment = () => {
        const data = this.state.editComment;
        this.props.editComment(data, data.id).then(
            res => {
                this.setState({
                    editModal: false
                });
                this.pagination.dataCall();
            }
        );
    }

    changeReorderType = (selected, action) => {
        this.setState({
            reorder_type: selected.value
        }, () => {
            this.pagination.dataCall();
        })
    }

    render() {
        const { ReOrders, shippingSettings } = this.props;
        const { /*showAll*/ } = this.state;
        const reordersTypeOptions = [
            { label: 'All', value: 1 },
            { label: 'Paid Online', value: 2 },
            { label: 'Not Paid Online', value: 3 },
        ]
        const columns = [
            {
                Header: 'Reorders List',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Reorder #',
                        accessor: 'id',
                        className: 'action-center flex-wrap',
                        excExp: true,
                        Cell: ({ value }) => {
                            return (
                                <div id={value}>
                                    {
                                        !('inTab' in this.props) ?
                                            <span>{value} </span> :
                                            <Link className='blue_link' target='_blank' to={{
                                                pathname: `/Admin/reorders-list`,
                                                hash: `#${value}`
                                            }}>{value}</Link>
                                    }
                                    <br />
                                    <span>
                                        <Link className='customBtn btn btn-primary btn btn-default' to={`/Admin/${value}/print_reorder_LPR`} target="_blank" >LPR</Link>
                                    </span>
                                    <br />
                                    <a onClick={() => this.confirmOrderDelete(value)} className='blue_link' rel="noopener noreferrer">Delete</a>
                                </div>
                            )
                        },
                        width: 170,
                        order: 1
                    },
                    {
                        Header: 'Original Barcode#',
                        accessor: 'parent_order.barcode',
                        className: 'action-center',
                        width: 200,
                        excExp: true,
                        Cell: ({ original, value }) => {
                            return (
                                <Link target='_blank' className='blue_link' to={`/Admin/${original.parent_order.id}/order-edit`}>{value}</Link>
                            )
                        },
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: 'Shipping Information',
                        accessor: 'video_shipping_address, group_leader_contact',
                        // accessor: 'video_shipping_address',
                        className: 'action-center',
                        excExp: true,
                        sortable: false,
                        Cell: ({ original }) => {
                            return <span>{original.group_leader_contact.first_name}<br />{original.video_shipping_address.street_address_1}<br />{original.video_shipping_address.city}, {original.video_shipping_address.state && original.video_shipping_address.state.title ? original.video_shipping_address.state.title : ''} {original.video_shipping_address.zipcode ? original.video_shipping_address.zipcode : ''}</span>
                        },
                        csvMod: true,
                        show: !('inTab' in this.props),
                        width: 220
                    },
                    {
                        Header: ('inTab' in this.props) ? "Customer Name" : "Contact Info",
                        accessor: 'group_leader_contact',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value }) => {
                            return (
                                ('inTab' in this.props) ?
                                    <span>{value.first_name} {value.last_name}</span>
                                    :
                                    <span>{value.phone1} <br />{value.email}</span>
                            )
                        },
                        csvMod: true,
                        sortable: false,
                        order: 2,
                        // width: ('inTab' in this.props) ? 100 : 200
                        width: 200
                    },
                    {
                        Header: 'Products',
                        accessor: 'order_items',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            return <div style={{ flexWrap: 'wrap' }}>
                                {value.map(item => {
                                    let is_ttk = original.agency.accounts[0].domain_slug === 'ttk' ? true : false;
                                    if ((!is_ttk && item.item.title !== 'Flash Drive with Gallery Photos') ||
                                        (is_ttk && item.item.title !== 'Flash Drive with Video and Photos' &&
                                            item.item.title !== 'Keepsake Video on DVD')){
                                        return (
                                            <React.Fragment key={item.id}>
                                                <span>{item.item.title} = {item.quantity}</span> <br />
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </div>
                        },
                        id: 'order_items.total_count',
                        csvMod: true,
                        // show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: 'Production & Shipping',
                        accessor: 'production',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            const item = shippingSettings.find(item => {
                                return parseFloat(item.value) === parseFloat(original.orderTotals.production.value)
                            });
                            return (
                                <div style={{ flexWrap: 'wrap' }}>
                                    <span className={`text-uppercase ` + (item.key !== 'normal_production' ? 'font-weight-bold' : '')}>{item.key.replace(/_/g, ' ')}</span>
                                    <br />
                                    <span>{item.description}</span>
                                </div>
                            )
                        },
                        csvMod: true,
                        sortable: false,
                        // show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: 'Overnight',
                        accessor: 'overnight',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            // if ('overnight' in original.orderTotals) {
                            //     return moment(original.orderSettings.payment_made.created_at).format('MMMM D Y')
                            // } else {
                            //     return ''
                            // }
                            const item = shippingSettings.find(item => {
                                let res
                                original.orderTotals.overnight ?
                                    res = parseFloat(item.value) === parseFloat(original.orderTotals.overnight.value)
                                :
                                    res = ''
                                return res
                            });
                            if (('overnight' in original.orderTotals) && Number(original.orderTotals.overnight.value) > 0) {
                                return (
                                    ('overnight' in original.orderTotals) ?
                                        <div style={{ flexWrap: 'wrap' }} className={Number('payment_made' in original.orderSettings && Number(original.orderSettings.payment_made.value) === 1) ? 'dangerText' : ''}>
    
                                            <span className="text-uppercase font-weight-bold">
                                                
                                                {   item.key === 'overnight_production' ?
                                                        'Overnight Shipping'
                                                    :
                                                        item.key.replace(/_/g, ' ')}
                                            </span>
                                            <br />
                                            <span>{item.description}</span>
                                        </div>
                                    :
                                        ''
    
                                )
                            } else  {
                                return null;
                            }
                        },
                        csvMod: true,
                        sortable: false,
                        // show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: 'Original Producer',
                        accessor: 'parent_order.producer.name',
                        className: 'action-center',
                        excExp: true,
                        id: 'parentOrder.producer',
                        show: !('inTab' in this.props)
                    },
                    // {
                    //     Header: 'Reorder Producer',
                    //     accessor: 'order_number',
                    //     className: 'action-center'
                    // },
                    {
                        Header: 'Total Charges',
                        accessor: 'orderTotals.grand_total.value',
                        className: 'action-center',
                        excExp: true,
                        id: 'order_totals',
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: ('inTab' in this.props) ? 'Date Submitted' : 'Date Payment Made',
                        accessor: 'payment_made',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            if ('payment_made' in original.orderSettings) {
                                return moment(original.orderSettings.payment_made.created_at).format('MMMM D Y')
                            } else {
                                return moment(original.created_at).format('MMMM D Y')
                            }
                        },
                        sortable: false,
                        excExp: true,
                        order: 4
                    },
                    {
                        Header: 'Date Shipped',
                        accessor: 'date_shipped',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            if (original.video_ship_status[0]) {
                                return moment(original.video_ship_status[0].process_date).format('MMMM D Y')
                            } else {
                                return ''
                            }
                        },
                        excExp: true,
                        sortable: false,
                        csvMod: true,
                        order: 5
                    },
                    {
                        Header: 'Tracking',
                        accessor: 'tracking_url',
                        className: 'action-center ',
                        minWidth: 220,
                        excExp: true,
                        sortable: false,
                        Cell: ({ value, original }) => {
                            return (
                                original.video_ship_status.length === 0 ?
                                    <div style={{ flexDirection: 'column' }} className='flexElem'>
                                        <span className='text-uppercase'>not shipped</span>
                                        <button style={{ width: '150px', margin: '0 auto' }} onClick={() => { this.openTrackingModal(original.id) }} className='customBtn btn btn-primary'>Add Tracking</button>
                                        {/* <span>If you have tracking #, enter in above field. If no tracking put in "shipped but no tracking"</span> */}
                                        <span>&nbsp;</span>
                                        <button style={{ minWidth: '180px', margin: '0 auto' }} onClick={() => { this.markAsShipped(original.id) }} className='customBtn btn btn-primary'>No Tracking-Shipped</button>
                                    </div>
                                    :
                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                        <span className='text-uppercase'>shipped</span>
                                        <div><strong>{original.video_ship_status[0].shipping_method.title}</strong></div>
                                        {
                                            original.tracking_url &&
                                            <div><a className='blue_link' rel="noopener noreferrer" target="_blank" href={original.tracking_url}>Tracking URL</a></div>
                                        }
                                        {/* {
                                            original.video_ship_status[0].shipping_method.tracking_url &&
                                            <div><a className='blue_link' rel="noopener noreferrer" target="_blank" href={original.video_ship_status[0].shipping_method.tracking_url}>Tracking URL</a></div>
                                        } */}
                                    </div>
                            )
                        },
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: 'Reorder Admin Comments',
                        accessor: 'comments[0].comment',
                        className: 'action-center',
                        excExp: true,
                        sortable: false,
                        getProps: () => {
                            return {
                                style: {
                                    flexDirection: 'column'
                                }
                            }
                        },
                        Cell: ({ value, original }) => {
                            if (value) {
                                return (
                                    <React.Fragment>
                                        <div>{value}</div>
                                        <button className='customBtn btn btn-primary btn btn-default' onClick={() => { this.editComment(original.comments[0]) }}>Edit Comments</button>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment>

                                        <button className='customBtn btn btn-primary btn btn-default' onClick={() => { this.addComment(original.id) }}>Add Comment</button>
                                    </React.Fragment>
                                )
                            }
                        },
                        show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: ('inTab' in this.props) ? "Payment Type" : 'Paid Online',
                        className: 'action-center',
                        sortable: false,
                        accessor: 'paid_online',
                        Cell: ({ value, original }) => {
                            if (Number('comp' in original.orderSettings && Number(original.orderSettings.comp.value) === 1)) {
                                return 'comp'
                            } else if ('payment_made' in original.orderSettings && Number(original.orderSettings.payment_made.value) === 1) {
                                return ('inTab' in this.props) ? 'Online Payment' : 'Yes'
                            } else {
                                return ('inTab' in this.props) ? 'Check or Credit by Phone' : 'No'
                            }
                        },
                        excExp: true,
                        order: 3
                    }
                ]
            }
        ]
        let reOrderedColumns;
        if ('inTab' in this.props) {
            reOrderedColumns = cloneDeep(columns);
            reOrderedColumns = orderBy(reOrderedColumns[0].columns, 'order', 'asc')
        } else {
            reOrderedColumns = cloneDeep(columns);
        }
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Reorders</div>
                                    {!('inTab' in this.props) &&
                                        <p>Your query resulted in {ReOrders.total} reorders.</p>
                                    }
                                    <div className='text-center'>
                                        {
                                            !('inTab' in this.props) &&
                                            <React.Fragment>
                                                <h4><strong>Top 15 Most Recent Reorders From GTV.com(Additional Copies of DVD, PhotoMozaix and CDs of Pictures)</strong></h4>

                                                <strong style={{ display: 'block' }} className='text-danger'>red = not shipped</strong>
                                                <strong style={{ display: 'block' }} className='text-muted'>gray = finished/shipped</strong>
                                                <strong style={{ display: 'block', color: '#777' }} className='text-secondary'>dark gray = finished and paid online</strong>
                                                <strong style={{ display: 'block', color: '#777' }}>Total Reorder Sum ...so far ${this.props.reorders_total_sum}</strong>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className='flexElem' style={{ justifyContent: 'end' }}>
                                        <div className='my-20 col-md-4'>
                                            {/* <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' /> */}
                                        </div>
                                        <div className='col-md-8'>
                                            {/* {
                                                <React.Fragment>
                                                    <Col md={2} className='pull-right mt-sm mr-sm text-right' >
                                                        <label className="container-check mb-none pl-xlg" style={{ marginTop: "10px" }}> Show All <input type="checkbox" name="is_default" className="checkbox" onChange={() => {
                                                            this.setState({ showAll: !showAll })
                                                        }} /><span className="checkmark"></span></label>
                                                    </Col>
                                                </React.Fragment>
                                            } */}
                                            {/* {
                                                <React.Fragment>
                                                    <Col md={2} className="mt-sm downloadCsv text-right pull-right">
                                                        <ReactDataToCSV
                                                            className="downloadbtn"
                                                            fileName={'Reorders'}
                                                            currentRecordsRef={this.reactTable}
                                                            columnsData={columns[0].columns}
                                                        />
                                                    </Col>
                                                </React.Fragment>
                                            } */}
                                        </div>
                                    </div>
                                    {/* <ReactTable
                                        ref={(r) => this.reactTable = r}
                                        showPagination={showAll ? false : true}
                                        columns={columns}
                                        defaultPageSize={this.state.pageSize}
                                        pages={ReOrders.last_page}
                                        data={ReOrders.data}
                                        pageData={this.paginationCall}
                                        manual
                                        onFetchData={(state, instance) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={reOrderedColumns}
                                            pageSize={!('inTab' in this.props) ? 20 : 100}
                                            getDataCall={this.paginationCall}
                                            filterView={!('inTab' in this.props) ? true : false}
                                            downloadData={!('inTab' in this.props) ? true : false}
                                            defaultSorted={
                                                [
                                                    {
                                                        id: 'id',
                                                        desc: true
                                                    }
                                                ]
                                            }
                                            downloadFileName={'Reorders'}
                                            // lowerContent = {null}
                                            filterPlaceHolder={'Orders'}
                                            getRowProps={this.getRowProps}
                                            showAllToggle={!('inTab' in this.props) ? true : false}
                                            upperContent={
                                                <React.Fragment>
                                                    <Select
                                                        className='pull-left'
                                                        value={reordersTypeOptions.filter(item => {
                                                            return item.value === this.state.reorder_type
                                                        })}
                                                        onChange={this.changeReorderType}
                                                        options={reordersTypeOptions}
                                                        styles={{
                                                            container: (provided) => ({
                                                                ...provided,
                                                                width: '100%',
                                                                maxWidth: '200px'
                                                            }),
                                                        }
                                                        }
                                                        name="reorder_type"
                                                    />
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                    <ThemeModal show={this.state.shipModal} hide={() => { this.toggleModal('shipModal') }}>
                        <ShipStatusForm shippingDetails={this.state.shippingDetails} dataCall={this.pagination ? this.pagination.dataCall : null} onHide={() => { this.toggleModal('shipModal') }} orderId={this.state.orderId} item='video' reOrder={true} />
                    </ThemeModal>
                    <ThemeModal title='Edit Comment' show={this.state.editModal} hide={() => { this.toggleModal('editModal') }}>
                        <textarea onChange={this.commentChangeHandler} className='form-control' value={this.state.editComment.comment} />
                        <div className='text-right'>
                            <button className='customBtn btn btn-primary btn btn-default mt-md' onClick={this.saveComment}>Save</button>
                        </div>
                    </ThemeModal>

                    <ThemeModal title='Add Comment' show={this.state.addCommentModel} hide={() => { this.toggleModal('addCommentModel') }}>
                        <textarea onChange={this.storeCommentChangehandler} className='form-control' value={this.state.comment.comment} />
                        <div className='text-right'>
                            <button className='customBtn btn btn-primary btn btn-default mt-md' onClick={this.storeComment}>Save</button>
                        </div>
                    </ThemeModal>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { ReOrders, shippingSettings, ReOrders_total, reorders_total_sum } = state.ordersReducer;
    const { singleCommentCategory } = state.commentReducer;
    return {
        ReOrders,
        shippingSettings,
        ReOrders_total,
        reorders_total_sum,
        singleCommentCategory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReOrders: (page, pageSize, filter, sort, orderType, orderId, reorder_type) => { return dispatch(getReOrders(page, pageSize, filter, sort, orderType, orderId, reorder_type)) },
        deleteOrderCall: (id) => dispatch(deleteOrder(id)),
        getAllShippingMethodsCall: () => dispatch(getAllShippingMethods()),
        changeShipStatusCall: (orderId, data) => { return dispatch(processShipStatus(orderId, data)) },
        editComment: (data, id) => { return dispatch(editComment(data, id)) },
        addCommentCall: (data, modelId) => dispatch(addComment(data, modelId)),
        getCommentCategoryByTitle: (titles) => { dispatch(getCommentCategoryByTitle(titles)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReorderList)
