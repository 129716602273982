import React from 'react';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getTripOrderUsers, getFollowingOrderUsers, getSpecialUser, deleteOrderUser, updateOrderUser, createSpecialUser, updateSpecialUser } from '../../../redux/actions/order-actions';
import ConfirmAlertForDelete from '../../ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from "react-confirm-alert";
import SimpleReactValidator from "simple-react-validator";
import 'react-table/react-table.css';
import { Card } from "components/Card/Card.jsx";
import Pagination from '../../ThemeComponents/Pagination';
import { dateTimeWithAMPM } from '../../../helper/helperFunctions';
import {Link} from 'react-router-dom';
import { BsChatDots } from 'react-icons/bs';
var Modal = require('react-bootstrap-modal');

class Travelers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            firstName: '',
            lastName: '',
            userId: '',
            specialUserFirstName: '',
            specialUserLastName: '',
            pageSize: 5,
            loading: false,
            tripFiltered: '',
            followingFiltered: '',
            specialModal: false,
        }
        // this.props.getOrderUsersCall(this.props.orderId);
        this.props.getSpecialUserCall(this.props.orderId);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.userChangeHandler = this.userChangeHandler.bind(this);
    }

    onDeleteUserHandler = (id, index, users, userType) => {
        const updatedUsersList = [...users];
        updatedUsersList.splice(index, 1);
        let countColumn = '';
        if (userType === 'tripUsers') {
            countColumn = 'going_on_trip_count';
        } else {
            countColumn = 'following_from_home_count'
        }
        this.props.deleteOrderUserCall(id, updatedUsersList, userType, countColumn).then(res => {
            if (userType === 'tripUsers') {
                this.tripPagination.dataCall();
            } else {
                this.pagination.dataCall();
            }
        });
    }

    onUpdateUserHandler = (id, firstName, lastName, userType) => {
        this.setState({
            modal: true,
            firstName: firstName,
            lastName: lastName,
            userId: id,
            userType: userType
        });
    }

    userChangeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    userChangedFormSubmit = (e) => {
        e.preventDefault();
        const updatedUserData = {
            first_name: this.state.firstName,
            last_name: this.state.lastName
        }
        this.props.userUpdateCall(this.state.userId, updatedUserData, this.state.userType).then(
            response => {
                this.modalCloseHandler();
            }
        )
    }

    modalCloseHandler = () => {
        this.setState({
            modal: false,
            specialModal: false,
            firstName: '',
            lastName: '',
            userId: '',
            userType: ''
        });
    }

    confirmOrderUserDelete = (id, index, users, userType) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='User' onClosePro={onClose} deleteType={this.onDeleteUserHandler.bind(this, id, index, users, userType)} />
        })
    }

    specialUserSubmitHandler = (e) => {
        e.preventDefault();
        if (1) {
            const user = {
                first_name: this.state.specialUserFirstName,
                last_name: this.state.specialUserLastName,
                order_id: this.props.orderId,
                user_type: 'special_user',
                account_id: 1
            }
            if (this.props.specialUser) {
                user.id = this.props.specialUser.id;
                this.props.updateSpecialUserCall(user).then(
                    response => {
                        this.setState({
                            specialModal: false
                        })
                    }
                )
            } else {
                this.props.createSpecialUserCall(user).then(
                    response => {
                        this.setState({
                            specialModal: false
                        })
                    }
                )
            }
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    fetchTripUserData = (data) => {
        return this.props.getTripOrderUsersCall(this.props.orderId, data.page, data.filter, data.sort, data.pageSize);
    }

    fetchFollowingUserData = (data) => {
        return this.props.getFollowingOrderUsersCall(this.props.orderId, data.page, data.filter, data.sort, data.pageSize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userTripOrderFormSubmit !== this.props.userTripOrderFormSubmit && this.props.loadingTripOrderUsers) {
            this.fetchTripUserData(1, this.state.tripFiltered, '', this.state.pageSize);
        }
        if (prevProps.userFollowingOrderFormSubmit !== this.props.userFollowingOrderFormSubmit && this.props.loadingFollowingOrderUsers) {
            this.fetchFollowingUserData(1, this.state.followingFiltered, '', this.state.pageSize);
        }
        if (prevProps.specialUser !== this.props.specialUser && this.props.specialUser) {
            this.setState({
                specialUserFirstName: this.props.specialUser.first_name,
                specialUserLastName: this.props.specialUser.last_name
            })
        }
    }

    specialUserModalHandler = () => {
        this.props.getSpecialUserCall(this.props.orderId);
        this.setState({
            specialModal: true
        })
    }

    inputFilterHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    paginationCall = (data) => {
        return this.props.getProgramTypesPaginationCall(data.page, data.filter, data.sort, data.pageSize);
    }

    getRowProps = (rowData) => {
            let data = {};
            if (rowData.is_admin === 1) {
                data = { background: 'lightYellow', fontWeight: 'bold' };
            }
            return data;
        }
    render() {
        const { tripUsers, followingUsers, specialUser } = this.props;
        this.validator.purgeFields();
        const tripUserColumns = [
            {
                Header: 'Going on Trip Users',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'first_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'last_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'First Logged in',
                        accessor: 'created_at',
                        className: 'action-center',
                        Cell: ({ original }) => (
                            <div >
                                <span>{dateTimeWithAMPM(original.created_at)}</span>
                            </div>
                        )
                    },
                    {
                        Header: 'Actions',
                        accessor: 'id',
                        Cell: ({ value, row, index }) => (<div >
                            <button onClick={this.onUpdateUserHandler.bind(this, value, row.first_name, row.last_name, 'tripUser')} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                            <button onClick={this.confirmOrderUserDelete.bind(this, value, index, tripUsers, 'tripUsers')} type='button' className="orderDetailsAction ml-md deleteIcon" >&nbsp;</button>
                            {/* <Button type='submit' bsSize="large" onClick={this.onUpdateUserHandler.bind(this, value, row.first_name, row.last_name, 'tripUser')} bsStyle="info" className="outOfTableButton" >Update</Button>
                            <Button bsSize="large" bsStyle="danger" onClick={this.confirmOrderUserDelete.bind(this, value, index, tripUsers, 'tripUsers')} className="outOfTableButton" >Delete</Button> */}
                        </div>)
                    }
                ]
            }
        ]
        const followingUserColumns = [
            {
                Header: 'Following from home Users',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'first_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'last_name',
                        className: 'action-center'
                    },
                    {
                        Header: 'First Logged in',
                        accessor: 'created_at',
                        className: 'action-center',
                        Cell: ({ original }) => (
                            <div >
                                <span>{dateTimeWithAMPM(original.created_at)}</span>
                            </div>
                        )
                    },
                    {
                        Header: 'Actions',
                        accessor: 'id',
                        Cell: ({ value, index, row }) => (<div >
                            <button onClick={this.onUpdateUserHandler.bind(this, value, row.first_name, row.last_name, 'followingUser')} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                            <button onClick={this.confirmOrderUserDelete.bind(this, value, index, followingUsers, 'followingUsers')} type='button' className="orderDetailsAction ml-md deleteIcon" >&nbsp;</button>
                            {/* <Button type='submit' bsSize="large" onClick={this.onUpdateUserHandler.bind(this, value, row.first_name, row.last_name, 'followingUser')} bsStyle="info" className="outOfTableButton" >Update</Button>
                            <Button bsSize="large" bsStyle="danger" onClick={this.confirmOrderUserDelete.bind(this, value, index, followingUsers, 'followingUsers')} className="outOfTableButton" >Delete</Button> */}
                        </div>)
                    }
                ]
            }
        ]
        return (
            <div className='mt-xlg'>
                <div>
                    <div className='secHeading hidden' >Travelers / Following From Home</div>
                    {
                        specialUser ?
                            <div className="flexElem flexResponsive" style={{ alignItems: "stretch" }}>
                                <Button bsSize="large" onClick={this.specialUserModalHandler} bsStyle="info" className="backButton pt-sm text-xs no_radius pb-sm primary" ><i className='fa fa-pencil' /> Update Special User</Button>
                                <div className="flexElem well p-none m-none ml-md" style={{ alignItems: "center", borderRadius: "0", boxShadow: "none" }}>
                                    <span className="ml-md">
                                        <strong className="innerLabel">First Name: </strong>
                                        {specialUser.first_name}
                                    </span>
                                    <span className="mr-md ml-lg">
                                        <strong className="innerLabel">Last Name: </strong>
                                        {specialUser.last_name}
                                    </span>
                                </div>
                            </div>
                            :
                            <Button bsSize="large" onClick={this.specialUserModalHandler} bsStyle="info" className="backButton pt-sm text-xs no_radius pb-sm primary" ><i className='fa fa-plus' /> Create Special User</Button>
                    }
                    <div style={{ float: "right" }}>
                        <Link target="_blank" style={{display: 'flex', alignItems: 'center', gap: '10px'}} to={'/Admin/chat/' + this.props.orderId} className="blue_link">
                            <span>
                                <BsChatDots size={20} />
                            </span> View Chat
                        </Link>
                    </div>
                    {/* <div className='form-inline' >
                        <div className='form-group' >
                            <label>First Name</label>
                            <input type='text' value={specialUser.first_name} className='form-control' />
                        </div>
                        <div className='form-group' >
                            <label>Last Name</label>
                            <input type='text' value={specialUser.last_name} className='form-control' />
                        </div>
                    </div> */}

                    <Card bsClass={["innerCard mt-lg"]} content={
                        <React.Fragment>
                            <div className='flexElem flexResponsive mb-sm' style={{ justifyContent: "space-between" }}>
                                <div >
                                    <h5>Going On The Trip</h5>
                                </div>
                                <div className="custom-search-input">
                                    <input type='text' name='tripFiltered' onChange={this.inputFilterHandler} className='form-control' placeholder='Search Going On The Trip User' />
                                </div>
                            </div>
                            {/* <ReactTable
                                noDataText='No travelers found'
                                manual
                                className="-striped listing mb-xs"
                                pages={this.props.tripUserPages}
                                filtered={this.state.tripFiltered}
                                defaultPageSize={this.state.pageSize}
                                loading={this.props.loadingTripOrderUsers}
                                loadingText={'Loading...'}
                                data={tripUsers}
                                columns={tripUserColumns}
                                pageData={this.fetchTripUserData}
                                onFetchData={(state) => {
                                    var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                    state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                                }} /> */}
                            <div className='row'>
                                <Pagination
                                    ref={(p) => this.tripPagination = p}
                                    showPagination={false}
                                    columns={tripUserColumns}
                                    pageSize={100}
                                    noDataText='No travelers found'
                                    getDataCall={this.fetchTripUserData}
                                    filterView={false}
                                    // downloadData={true}
                                    // downloadFileName={'Orders'}
                                    // lowerContent = {null}
                                    // filterPlaceHolder = {'Orders'}
                                    getRowProps={this.getRowProps}
                                // showAllToggle={true}
                                />
                            </div>
                        </React.Fragment>
                    } />



                    <Card bsClass={["innerCard mt-lg"]} content={
                        <React.Fragment>
                            <div className='flexElem flexResponsive mb-sm' style={{ justifyContent: "space-between" }}>
                                <div >
                                    <h5>Following From Home</h5>
                                </div>
                                <div className="custom-search-input">
                                    <input type='text' name='followingFiltered' onChange={this.inputFilterHandler} className='form-control' placeholder='Search Following From Home User' />
                                </div>
                            </div>

                            {/* <ReactTable
                                noDataText='No following from home user found'
                                manual
                                className="-striped listing mb-xs"
                                pages={this.props.followingUserPages}
                                defaultPageSize={this.state.pageSize}
                                data={followingUsers}
                                loading={this.props.loadingFollowingOrderUsers}
                                loadingText={'Loading...'}
                                filtered={this.state.followingFiltered}
                                columns={followingUserColumns}
                                pageData={this.fetchFollowingUserData}
                                onFetchData={(state) => {
                                    var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                    state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                                }} /> */}
                            <div className='row'>
                                <Pagination
                                    ref={(p) => this.pagination = p}
                                    showPagination={false}
                                    columns={followingUserColumns}
                                    pageSize={100}
                                    noDataText='No following from home user found'
                                    getDataCall={this.fetchFollowingUserData}
                                    filterView={false}
                                    // downloadData={true}
                                    // downloadFileName={'Orders'}
                                    // lowerContent = {null}
                                    // filterPlaceHolder = {'Orders'}
                                    getRowProps={this.getRowProps}
                                // showAllToggle={true}
                                />
                            </div>
                        </React.Fragment>
                    } />
                </div>
                <Modal backdrop={'static'} show={this.state.modal} onHide={this.modalCloseHandler} >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card bsClass={["innerCard"]} content={
                            <form id='userChangeForm' onSubmit={this.userChangedFormSubmit} >
                                <div className='form-group' >
                                    <label htmlFor="name">First Name: <span className="requiredClass">*</span></label>
                                    <input type='text' name='firstName' onChange={this.userChangeHandler} value={this.state.firstName} className='form-control' />
                                </div>
                                <div className='form-group' >
                                    <label htmlFor="name">Last Name: <span className="requiredClass">*</span></label>
                                    <input type='text' name='lastName' onChange={this.userChangeHandler} value={this.state.lastName} className='form-control' />
                                </div>
                            </form>
                        } />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='btn-toolbar'>
                            <Button form='userChangeForm' type='submit' bsSize="large" bsStyle="info" className="outOfTableButton" >Update</Button>
                            <Button bsSize="large" bsStyle="danger" onClick={this.modalCloseHandler} className="outOfTableButton" >Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal backdrop={'static'} show={this.state.specialModal} onHide={this.modalCloseHandler} >
                    <Modal.Header closeButton>
                        {
                            specialUser ?
                                <Modal.Title>Edit special user</Modal.Title>
                                :
                                <Modal.Title>Create special user</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <Card bsClass={["innerCard"]} content={
                            specialUser ?
                                <form id='specialUserForm' onSubmit={this.specialUserSubmitHandler} >
                                    <div className='form-group' >
                                        <label htmlFor="name">First Name: <span className="requiredClass">*</span></label>
                                        <input type='text' name='specialUserFirstName' value={this.state.specialUserFirstName} onChange={this.userChangeHandler} placeholder='First Name' className='form-control' />
                                        {this.validator.message('specialUserFirstName', this.state.specialUserFirstName, 'required')}
                                    </div>
                                    <div className='form-group' >
                                        <label htmlFor="name">Last Name: <span className="requiredClass">*</span></label>
                                        <input type='text' name='specialUserLastName' value={this.state.specialUserLastName} onChange={this.userChangeHandler} placeholder='Last Name' className='form-control' />
                                        {this.validator.message('specialUserLastName', this.state.specialUserLastName, 'required')}
                                    </div>
                                </form>
                                :
                                <form id='specialUserForm' onSubmit={this.specialUserSubmitHandler} >
                                    <div className='form-group' >
                                        <label htmlFor="name">First Name: <span className="requiredClass">*</span></label>
                                        <input type='text' name='specialUserFirstName' value={this.state.specialUserFirstName} onChange={this.userChangeHandler} placeholder='First Name' className='form-control' />
                                        {this.validator.message('specialUserFirstName', this.state.specialUserFirstName, 'required')}
                                    </div>
                                    <div className='form-group' >
                                        <label htmlFor="name">Last Name: <span className="requiredClass">*</span></label>
                                        <input type='text' name='specialUserLastName' value={this.state.specialUserLastName} onChange={this.userChangeHandler} placeholder='Last Name' className='form-control' />
                                        {this.validator.message('specialUserLastName', this.state.specialUserLastName, 'required')}
                                    </div>
                                </form>
                        } />

                        <div className='text-center'>
                            <Button onClick={this.specialUserSubmitHandler} form='specialUserForm' type='submit' className="backButton pt-sm no_radius pb-sm success btn btn-lg ml-sm btn-default btn btn-default" >Save</Button>
                            <Button onClick={this.modalCloseHandler} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm btn-info btn btn-default" >Cancel</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { tripUsers, followingUsers, userTripOrderFormSubmit, userFollowingOrderFormSubmit, loadingTripOrderUsers, loadingFollowingOrderUsers, specialUser, tripUserPages, followingUserPages } = state.ordersReducer;
    return { tripUsers, followingUsers, userTripOrderFormSubmit, userFollowingOrderFormSubmit, loadingTripOrderUsers, loadingFollowingOrderUsers, specialUser, tripUserPages, followingUserPages }
}

const mapDispatchToProps = dispatch => {
    return {
        getTripOrderUsersCall: (id, page, filter, sort, pageSize) => { return dispatch(getTripOrderUsers(id, page, filter, sort, pageSize)) },
        getFollowingOrderUsersCall: (id, page, filter, sort, pageSize) => { return dispatch(getFollowingOrderUsers(id, page, filter, sort, pageSize)) },
        getSpecialUserCall: (orderId) => { dispatch(getSpecialUser(orderId)) },
        deleteOrderUserCall: (userId, usersList, userType, countColumn) => { return dispatch(deleteOrderUser(userId, usersList, userType, countColumn)) },
        userUpdateCall: (userId, userData, userType) => { return dispatch(updateOrderUser(userId, userData, userType)) },
        createSpecialUserCall: (userData) => { return dispatch(createSpecialUser(userData)) },
        updateSpecialUserCall: (userData) => { return dispatch(updateSpecialUser(userData)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Travelers);
