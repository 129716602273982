import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import ChatWrapper from "./ChatWrapper";
import { connect } from 'react-redux';
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { getOrder } from "../../../redux/actions/order-actions";

function AdminChat(props) {
    
    useEffect(() => {
        console.log(props.match.params.order_id, "Order id");
        
        props.getOrderCall(props.match.params.order_id);
    }, []);

    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            content={
                                <React.Fragment>
                                    <h4><strong>Order Number: {props.order_details.barcode}</strong></h4>
                                    <ChatWrapper />
                                </React.Fragment>

                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { order_details } = state.ordersReducer;
    return {
        order_details
    }
}

const mapDispatchToProps = dispatch => {
    return {
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminChat));
