import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { processCamerasToShip } from "redux/actions/order-actions";
import Card from "components/Card/Card";
import { returnColumnValueWithColumnMatch, openModal, closeModal, hasRole } from "helper/helperFunctions";
import ThemeModal from "components/ThemeComponents/ThemeModal";
import OpenModalButton from "components/ThemeComponents/openModelButton";
import DatePicker from "react-datepicker";
import ProcessOrder from "components/Admin/Order/ProcessOrder";
import moment from 'moment';
import Calendar from "components/ThemeComponents/Calendar";
import { getBrandsCamerasToShip } from "redux/actions/account-action";
import { Link } from 'react-router-dom';
import Pagination from "components/ThemeComponents/Pagination";
import { alertActions } from "../../../redux/actions/alert-actions";
import { orderService } from "../../../services/order";

class ProcessOrderList extends React.Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 50,
            orders: [],
            filtered: '',
            processOrderModal: false,
            advancedSearchModal: false,
            advancedSearchFilters: {
                barcode: '',
                group_name: '',
                agency: '',
                departure_date: '',
                camera_need_date: '',
                projected_date: ''
            },
            brand_id: hasRole(['gm admin']) ? 23 : 1,
            orders_type: '2',
            brands: [],
        }
        this.checkChange = this.checkChange.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.applyAdvancedFilters = this.applyAdvancedFilters.bind(this);
        this.props.getBrandsCall().then(response => {
            this.setState({ brands: response.data });
        });
    }
    clearAdvanceSearch = () => {
        this.setState({
            advancedSearchFilters: {
                barcode: '',
                group_name: '',
                agency: '',
                departure_date: '',
                camera_need_date: '',
                projected_date: ''
            }
        }, () => {
            this.pagination.dataCall();;
        })
    }
    checkChange(id, e) {

        const check = e.target.checked;
        const orders = [...this.state.orders];
        // console.log('orders', orders);
        if (check) {
            orders.push(id);
            this.setState({
                orders: orders
            })
        } else {
            const i = orders.findIndex((el) => {
                return el === id;
            });
            orders.splice(i, 1);
            this.setState({
                orders: orders
            })
        }
    }


    checkAll() {
        const data = [...this.props.camerasToShip.data];
        const orders = [...this.state.orders];
        data.forEach(item => {
            const value = item.id;
            if (item.order_dates.projected_camera_ship_date.date_diff && item.status.title === 'Approved' && parseInt(item.settings.approve_for_shipping.value, 10) === 1) {
                if (!orders.includes(value)) {
                    orders.push(value);
                }
            }
        });
        this.setState({
            orders: orders
        })
    }

    unCheckAll() {
        this.setState({
            orders: []
        })
    }

    inputChange(e, call = false) {
        const input = e.target.name.split('.');
        switch (input.length) {
            case 1:
                this.setState({
                    [input[0]]: e.target.value
                }, () => {
                    if (call) {
                        this.pagination.dataCall();
                    }
                })
                break;
            case 2:
                const state = this.state[input[0]];
                state[input[1]] = e.target.value;
                this.setState({
                    [input[0]]: state
                }, () => {
                    if (call) {
                        this.pagination.dataCall();
                    }
                })
                break;
            default:
                break;
        }
    }

    dateChangeHandler(dateType, date) {
        const input = dateType.split('.');
        const state = this.state[input[0]];
        date = date !== null ? date : '';
        state[input[1]] = date;
        this.setState({
            [input[0]]: state
        })
    }

    applyAdvancedFilters(e) {
        e.preventDefault();
        this.setState({advancedSearchModal:false}, ()=>{
            this.pagination.dataCall();
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.camerasToShip.data !== this.props.camerasToShip.data) {

            this.setState({
                orders: []
            }, () => {


                const data = [...this.props.camerasToShip.data];
                const orders = [...this.state.orders];
                data.forEach(item => {
                    if ('approve_for_shipping' in item.settings) {
                        if (item.order_dates.projected_camera_ship_date.date_diff <= 0 && item.status.title === 'Approved' && parseInt(item.settings.approve_for_shipping.value, 10) === 1) {
                            if (!orders.includes(item.id)) {
                                orders.push(item.id);
                            }
                        }
                    }
                });
                this.setState({
                    orders: orders
                })
            })
        }
    }

    getRowProps = (state, rowInfo) => {
        let data = {}
        if (rowInfo !== undefined) {
            const date = rowInfo.original.order_dates.projected_camera_ship_date.date_diff

            if (date === 1 || date < 1) {
                data = {
                    color: 'green'
                }
            }
            if (date > 1) {
                data = {
                    color: 'black'
                }
            }
            if (rowInfo.original.agency.agency_logo === null) {
                data = {
                    ...data,
                    background: 'yellow'
                }
            }
        }
        return {
            style: data
        };
    }

    paginationCall = (data) => {
        const { brand_id, orders_type } = this.state;
        const newData = { ...this.state.advancedSearchFilters };
        newData.departure_date = newData.departure_date !== '' ? moment(newData.departure_date).format() : '';
        newData.projected_date = newData.projected_date !== '' ? moment(newData.projected_date).format() : '';
        newData.camera_need_date = newData.camera_need_date !== '' ? moment(newData.camera_need_date).format() : '';
        return this.props.getCamerasToShipCall(data.page, data.filter, data.sort, data.pageSize, brand_id, orders_type, newData);
    }

    sendPtmToPhotoAdmins = () => {
        orderService.sendPtmToPhotoAdmins(this.state.orders).then(response => {
            this.props.success(response.message);
            this.setState({ processOrderModal: false });
        });
    }
    render() {
        const columns = [
            {
                Header: 'Order Process',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Projected Pre-Trip Materials Ship Date',
                        accessor: 'order_dates.projected_camera_ship_date.value',
                        className: 'action-center',
                    },
                    {
                        Header: 'Departure Date',
                        accessor: 'order_dates.departure_date.value',
                        className: 'action-center',
                    },
                    {
                        Header: 'Pre Trip Materials Need Date',
                        accessor: 'order_dates.pre_trip_package_need_date.value',
                        className: 'action-center',
                    },
                    /*{
                        Header: 'Ship Time',
                        accessor: 'pre_trip_material_address.shipping_duration.days',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                <span>{value} days ({original.pre_trip_material_address.state.code}) {original.pre_trip_material_address.state_count === 0 ? <strong>Zipcode, State and City Do NOT Match (USA Zips)Verify Camera Shipping Address! <a target='_blank' rel="noopener noreferrer" href='https://tools.usps.com/go/ZipLookupAction!input.action' style={{ color: 'red', textDecoration: 'underline' }} >Zip Checker</a> </strong> : null}</span>
                            )
                        }
                    },*/
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        width: 200,
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return <a rel="noopener noreferrer" target='_blank' style={{ color: 'inherit', textDecoration: 'underline' }} href={`${original.id}/order-edit`} className="blue_link" >{value}</a>
                        }
                    },
                    {
                        Header: 'Agency',
                        accessor: 'agency.name',
                        className: 'action-center',
                        width: 140,
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'action-center',
                    },
                    {
                        Header: 'Username',
                        accessor: 'username',
                        className: 'action-center'
                    },
                    {
                        Header: 'Approved For Shipping',
                        accessor: 'settings',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                'approve_for_shipping' in original.settings ?
                                    parseInt(original.settings.approve_for_shipping.value, 10) === 1 ? 'Yes' : 'No'
                                :
                                    'No'
                            )
                        },
                    },
                    {
                        Header: 'Process',
                        accessor: 'id',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                // parseInt(original.settings.approve_shipping_still_need_contact.value, 10) === 0
                                'approve_for_shipping' in original.settings ?

                                    original.status.title === 'Approved'
                                        ?
                                        <label className="container-check">
                                            <input
                                                type="checkbox"
                                                checked={this.state.orders.includes(original.id)}
                                                className={`checkbox ${parseInt(original.settings.approve_for_shipping.value, 10) === 0 ? 'disabled' : ''}`}
                                                value={original.id}
                                                onChange={this.checkChange.bind(this, original.id)}
                                                disabled={parseInt(original.settings.approve_for_shipping.value, 10) === 0 ? true : false}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        :
                                        <span style={{ color: 'red' }}>ORDER NOT APPROVED</span>
                                    :
                                        ''
                            )
                        },
                    },
                    {
                        Header: 'Equipment Qty',
                        accessor: 'number_of_cameras',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            return original.program_type.item_category.title === 'Uploading' ? original.program_type.item_category.title : original.number_of_cameras + ' ' + original.program_type.item_category.title
                        }
                    },
                    {
                        Header: 'Video/Traveler Count',
                        accessor: 'order_item.quantity',
                        id: 'order_items.quantity',
                        className: 'action-center',
                    },
                    {
                        Header: "Contact Log",
                        accessor: "id",
                        Cell: ({ value }) => (
                            <div>
                                <Link target='_blank' className="orderDetailsAction editIcon" to={'/Admin/' + value + '/order-edit?commentCategoryCode=ContactLog#commentTabs'}>&nbsp;</Link>
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                    {
                        Header: 'PreTrip Materials Shipping Comments',
                        accessor: 'comments',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return (
                                <div>
                                    {
                                        value !== undefined ?
                                            value[0] !== undefined ?
                                                <div>
                                                    <p>{value[0].comment}</p>
                                                    <a target='_blank' href={`${original.id}/order-edit?commentCategoryCode=PreTripMaterialsShip#commentTabs`} className='blue_link' rel="noopener noreferrer" >View More</a>
                                                </div>
                                                : null
                                            : null
                                    }
                                </div>
                            );
                        }
                    },
                ]
            }
        ]
        const { camerasToShip/*, countAllOrders*/ } = this.props;
        const { orders_type, brands } = this.state;
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className='row' >
                                        <div className='col-md-6' >
                                            <h1 className='secHeading' >Process Order</h1>
                                            <div className='my-20' >
                                                <p>These are new orders that have not been processed for shipment and will need to ship in the next seven days.Orders marked in <span style={{ color: 'green', 'fontWeight': "bold" }} >GREEN</span> need to be processed for shipment immediately and have been auto-selected.Once you process these orders, TITLE PAGES will be generated and will need to be printed.View all orders processed for shipment using the View Processed Materials menu option</p>
                                                {
                                                    camerasToShip.projected_date !== 'All'
                                                        ?
                                                        <p>Orders That Ship Before <span className='font-weight-bold' >{camerasToShip.projected_date}</span></p>
                                                        :
                                                        <p>Complete All Process orders list</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-6' >
                                            <Calendar />
                                        </div>
                                    </div>
                                    <div >
                                        <Link to='/Admin/recently-processed-orders' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Recently Processed Order List</Link>
                                    </div>
                                    <div style={{ 'fontWeight': 'bold', 'fontSize': '20px' }} >
                                        {
                                            'countAllOrdersToday' in camerasToShip &&
                                            'Total Shipment count: ' + camerasToShip.countAllOrdersToday
                                        }
                                    </div>
                                    <div style={{ 'fontWeight': 'bold', 'fontSize': '20px', backgroundColor: '#fff8a8', padding: '3px 5px', display: 'flex' }} className="col-md-3">
                                        {
                                            returnColumnValueWithColumnMatch(brands, 'description', [parseInt(this.state.brand_id, 10)], 'value') &&
                                            'PRINT ON ' + returnColumnValueWithColumnMatch(brands, 'description', [parseInt(this.state.brand_id, 10)], 'value') + (parseInt(orders_type, 10) === 1 ? ' - Read Comments ' : '')
                                        }
                                    </div>
                                    <br />
                                    <div className='my-20 clearfix flexElem' style={{ justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                                        {/* <div className='pull-left'>
                                            <input type='text' placeholder='Search' className='form-control' onChange={this.inputChange} name='filtered' value={this.state.filtered} />
                                        </div> */}
                                        <OpenModalButton
                                            openModal={openModal.bind(this, 'advancedSearchModal')}
                                            classButton={['backButton', 'my-20', 'mr-20', 'pt-sm', 'no_radius', 'pb-sm', 'primary', 'text-xs', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-default']}
                                            buttonName='Advanced Search'
                                            tooltipText='Advanced Search'
                                            classIcon={[]}
                                        />
                                        <select
                                            onChange={(e) => this.inputChange(e, true)}
                                            defaultValue={this.state.brand_id}
                                            name='brand_id'
                                            className='form-control w-25 d-inline-block text-uppercase' >
                                            {
                                                brands.map(brand => {
                                                    return (
                                                        <option key={brand.value} className='text-uppercase' value={brand.value}> Process Materials To Ship - {brand.label} - ({brand.orders_count})</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className='d-inline-block mr-10' >
                                            <div className="radio radio-primary radio-inline m-0">
                                                <input onChange={(e) => this.inputChange(e, true)} type="radio" id="inlineRadio1" value="1" name="orders_type" />
                                                <label htmlFor="inlineRadio1"><strong>With Shipping Comments</strong></label>
                                            </div>
                                            <div className="radio radio-primary radio-inline m-0">
                                                <input onChange={(e) => this.inputChange(e, true)} type="radio" id="inlineRadio2" value="0" name="orders_type" />
                                                <label htmlFor="inlineRadio2"><strong>Without Shipping Comments</strong></label>
                                            </div>
                                            <div className="radio radio-primary radio-inline m-0">
                                                <input onChange={(e) => this.inputChange(e, true)} type="radio" id="inlineRadio3" value="2" name="orders_type" defaultChecked />
                                                <label htmlFor="inlineRadio3"><strong>All</strong></label>
                                            </div>
                                            <div className="radio radio-primary radio-inline m-0">
                                                <input onChange={(e) => this.inputChange(e, true)} type="radio" id="inlineRadio4" value="3" name="orders_type" />
                                                <label htmlFor="inlineRadio4"><strong>View Complete Processing List</strong></label>
                                            </div>
                                        </div>
                                        <div className='pull-right' >
                                            <button style={{ marginRight: '10px' }} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.checkAll} >Check All</button>
                                            <button style={{ marginRight: '10px' }} disabled={this.state.orders.length < 1} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.unCheckAll} >Un-Check All</button>
                                            <button style={{ marginRight: '10px' }} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' onClick={this.clearAdvanceSearch} >Clear Advance search All</button>
                                            <div className='pull-right' >
                                                <OpenModalButton
                                                    openModal={openModal.bind(this, 'processOrderModal')}
                                                    classButton={['backButton', 'my-20', 'mr-20', 'pt-sm', 'no_radius', 'pb-sm', 'primary', 'text-xs', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-default']}
                                                    buttonName='Process Orders'
                                                    tooltipText={this.state.orders.length < 1 ? 'Select At Least One Order' : 'Process Orders'}
                                                    classIcon={[]}
                                                    disabled={this.state.orders.length < 1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <ReactTable
                                        columns={columns}
                                        data={camerasToShip.data}
                                        noDataText='No Order Found'
                                        className='-striped listing'
                                        loadingText='loading'
                                        manual
                                        ref={this.table}
                                        filtered={this.state.filtered}
                                        defaultPageSize={this.state.pageSize}
                                        pages={camerasToShip.last_page}
                                        onFetchData={(state) => {
                                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                                            const data = { ...this.state.advancedSearchFilters };
                                            data.departure_date = data.departure_date !== '' ? moment(data.departure_date).format() : '';
                                            data.projected_date = data.projected_date !== '' ? moment(data.projected_date).format() : '';
                                            data.camera_need_date = data.camera_need_date !== '' ? moment(data.camera_need_date).format() : '';
                                            const brand_id = this.state.brand_id;
                                            const orders_type = this.state.orders_type;
                                            this.props.getCamerasToShipCall(state.page + 1, state.filtered, sort, state.pageSize, brand_id, orders_type, data).then(
                                                response => {
                                                    this.setState({
                                                        advancedSearchModal: false
                                                    })
                                                }
                                            );
                                        }}
                                        getTrProps={(state, rowInfo) => {
                                            let data = {}
                                            if (rowInfo !== undefined) {
                                                const date = rowInfo.original.order_dates.projected_camera_ship_date.date_diff

                                                if (date === 1 || date < 1) {
                                                    data = {
                                                        color: 'green'
                                                    }
                                                }
                                                if (date > 1) {
                                                    data = {
                                                        color: 'black'
                                                    }
                                                }
                                                if (rowInfo.original.agency.agency_logo === null) {
                                                    data = {
                                                        ...data,
                                                        background: 'yellow'
                                                    }
                                                }
                                            }
                                            return {
                                                style: data
                                            };
                                        }}
                                    /> */}
                                    <Pagination
                                        ref={(p) => this.pagination = p}
                                        showPagination={false}
                                        columns={columns}
                                        pageSize={50}
                                        noDataText='No Order Found'
                                        getDataCall={this.paginationCall}
                                        filterView={false}
                                        // downloadData={true}
                                        // downloadFileName={'Orders'}
                                        // lowerContent = {null}
                                        // filterPlaceHolder = {'Orders'}
                                        getRowProps={this.getRowProps}
                                    // showAllToggle={true}
                                    />
                                    <div className='my-20 clearfix' >
                                        <div className='pull-right mt-md' >
                                            You have selected {this.state.orders.length} orders to process
                                            <br />
                                            <OpenModalButton
                                                openModal={openModal.bind(this, 'processOrderModal')}
                                                classButton={['backButton', 'my-20', 'mr-20', 'pt-sm', 'no_radius', 'pb-sm', 'primary', 'text-xs', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-lg', 'btn-info', 'btn', 'btn-default']}
                                                buttonName='Process Orders'
                                                tooltipText={this.state.orders.length < 1 ? 'Select At Least One Order' : 'Process Orders'}
                                                classIcon={[]}
                                                disabled={this.state.orders.length < 1}
                                            />
                                        </div>
                                        <div style={{ float: 'left', marginRight: '10px' }}>
                                            <Link to='/Admin/recently-processed-orders' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Recently Processed Order List</Link>
                                        </div>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
                <ThemeModal size='lg' title='Process Materials to Ship' show={this.state.processOrderModal} hide={closeModal.bind(this, 'processOrderModal')} >
                    <ProcessOrder orders={this.state.orders} sendPtmToPhotoAdmins={this.sendPtmToPhotoAdmins}/>
                </ThemeModal>
                <ThemeModal title='Advanced Search' show={this.state.advancedSearchModal} hide={closeModal.bind(this, 'advancedSearchModal')} >
                    <form className='form' >
                        <div className='form-group' >
                            <div className='row' >
                                <div className='col-md-6' >
                                    <label className='w-100' >Barcode
                                        <input type='text' onChange={this.inputChange} name="advancedSearchFilters.barcode" className='form-control' value={this.state.advancedSearchFilters.barcode} />
                                    </label>
                                </div>
                                <div className='col-md-6' >
                                    <label className='w-100' >Group Name
                                        <input type='text' onChange={this.inputChange} name='advancedSearchFilters.group_name' className='form-control' value={this.state.advancedSearchFilters.group_name} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='form-group' >
                            <div className='row' >
                                <div className='col-md-6' >
                                    <label className='w-100' >Agency Name
                                        <input type='text' onChange={this.inputChange} name='advancedSearchFilters.agency' className='form-control' value={this.state.advancedSearchFilters.agency} />
                                    </label>
                                </div>
                                <div className='col-md-6' >
                                    <label className='w-100' >Departure Date</label>
                                    <DatePicker
                                        onChange={this.dateChangeHandler.bind(this, 'advancedSearchFilters.departure_date')}
                                        className='form-control calender_icon'
                                        dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                        selected={this.state.advancedSearchFilters.departure_date} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group' >
                            <div className='row' >
                                <div className='col-md-6' >
                                    <label className='w-100' >Projected Ship Date</label>
                                    <DatePicker
                                        onChange={this.dateChangeHandler.bind(this, 'advancedSearchFilters.projected_date')}
                                        className='form-control calender_icon'
                                        dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                        selected={this.state.advancedSearchFilters.projected_date} />
                                </div>
                                <div className='col-md-6' >
                                    <label >Camera Need Date</label>
                                    <DatePicker
                                        onChange={this.dateChangeHandler.bind(this, 'advancedSearchFilters.camera_need_date')}
                                        className='form-control calender_icon'
                                        dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                        selected={this.state.advancedSearchFilters.camera_need_date} />
                                </div>
                            </div>
                        </div>
                        <div className='clearfix pull-right' >
                            <button onClick={this.applyAdvancedFilters} className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Search</button>
                        </div>
                        <span className='clearfix' ></span>
                    </form>
                </ThemeModal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { camerasToShip, countAllOrders } = state.ordersReducer;
    // console.log('camerasToShip', camerasToShip);
    // const { brands } = state.accountReducer;
    return { camerasToShip, countAllOrders };
}

const mapDispatchToProps = dispatch => {
    return {
        getCamerasToShipCall: (page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) => { return dispatch(processCamerasToShip(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters)) },
        getBrandsCall: () => { return dispatch(getBrandsCamerasToShip()) },
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderList);
