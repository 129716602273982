import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card'
import { inputChangeHandler, createSortString, getFormattedDate, columnFilters } from 'helper/helperFunctions'
import { Link } from 'react-router-dom'
import { getPreDepartureList } from 'redux/actions/order-actions'
// import { getPreDepartureList, contactList } from 'redux/actions/order-actions'
import Pagination from '../../ThemeComponents/Pagination'


class preDeparturePostShippingList extends Component {
    constructor(props) {
        super(props);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.state = {
            filtered: '',
            pageSize: 100,
        }
    }
    getTrProps = (rowData) => {
		// console.log('data one', rowData);
		let data = {}
		if (rowData !== undefined) {
			// const rowData = rowInfo.original;
			// console.log('data');
			// if (this.props.haveBackgroundColor) {
				data = {
					color: 'textColor' in rowData ? rowData.textColor : (rowData.backgroundColor == 'grey' ? '#ededed' : null),
					backgroundColor: rowData.backgroundColor ? rowData.backgroundColor : '',
				}
				// console.log('data', data);
			// } else {
			// 	data = {
			// 		color: 'textColor' in rowData ? rowData.textColor : null,
			// 	}
			// }


		}
		return data;
	}
    paginationCall = (data) => {
        let matchFilterWith;
        if (this.pagination) {
            if (this.pagination.reactTable) {
                matchFilterWith = columnFilters(this.pagination.reactTable.state.columns[0].columns)
            }
        }
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getPreDepartureList(data.page, data.pageSize, sort, orderType, data.filter, matchFilterWith);
    }

    render() {
        const columns = [
            {
                Header: 'Program Types',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        className: 'action-center',
                        sortable: true,
                        width: 200,
                        Cell: ({ value, original }) => {
                            return <Link target='_blank' style={{color: (original.backgroundColor == 'grey') ? '#ededed' : '', textDecoration: (original.backgroundColor == 'grey') ? 'underline' : '' }}
                            to={`/Admin/${original.id}/order-edit?commentCategoryCode=ContactLog#commentTabs`} className={(original.backgroundColor == 'grey') ? '' : 'blue_link'}>{value}</Link>
                        }
                    },
                    {
                        Header: 'Agency',
                        accessor: 'agency.name',
                        className: 'justifyStart',
                        sortable: true,
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'justifyStart',
                        sortable: true,
                        Cell: ({ value, original }) => {
                            if (original.isDuplicate) {
                                return <Link target='_blank'
                                to={`/Admin/getOrdersSearchByContactList?code=orderIds&value=${original.duplicateOrderIds}`} className='blue_link'>{value}</Link>
                            } else {
                                return <span>{value}</span>
                            }
                        }
                    },
                    {
                        Header: 'Contact',
                        id: 'groupLeaderContact.first_name',
                        accessor: 'group_leader_contact.first_name',
                        className: 'justifyStart',
                        sortable: true,
                    },
                    {
                        Header: 'Departure Date',
                        id: 'orderDateSingle.departure_date.value',
                        accessor: 'order_dates.departure_date.value',
                        className: 'action-center',
                        sortable: true,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                    // {
                    //     Header: 'Camera Ship Date',
                    //     id: 'orderDateSingle.projected_camera_ship_date.value',
                    //     accessor: 'order_dates.projected_camera_ship_date.value',
                    //     className: 'action-center',
                    //     sortable: true,
                    //     Cell: ({ value }) => {
                    //         return getFormattedDate(value)
                    //     }
                    // },
                    {
                        Header: 'Number of Days Between PTM process and Departure',
                        accessor: 'numberOfDays',
                        className: 'action-center',
                        sortable: true,
                    },
                    {
                        Header: 'Ship State',
                        accessor: 'pre_trip_material_address.state.code',
                        className: 'action-center',
                        sortable: false,
                    },
                    // {
                    //     Header: 'Logged In Users',
                    //     accessor: 'order_app_users_count',
                    //     className: 'action-center',
                    //     sortable: false,
                    // },
                    {
                        Header: 'Contact Log',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ value, original }) => {
                            return <Link target='_blank' style={{color: (original.backgroundColor == 'grey') ? '#ededed' : '',  textDecoration: (original.backgroundColor == 'grey') ? 'underline' : '' }}
                             to={`/Admin/${original.id}/order-edit?commentCategoryCode=ContactLog#commentTabs`} className={(original.backgroundColor == 'grey') ? '' : 'blue_link'}>Contact Log</Link>
                        }
                    },
                    {
                        Header: 'Last Contact Log Entry',
                        accessor: 'contactLogLastComment',
                        className: 'action-center',
                        sortable: false,
                        Cell: ({ value }) => {
                            return(
                                <span>
                                    {
                                        getFormattedDate(value)
                                        // original.comments.length > 0 && original.comments[0] && original.comments[0].updated_at
                                        // ? 
                                        //     getFormattedDate(original.comments[0].updated_at) 
                                        // : 
                                        //     original.comments.length > 0 && original.comments[0] && original.comments[0].created_at
                                        //     ? 
                                        //         getFormattedDate(original.comments[0].created_at)
                                        //     : 
                                        //         ''
                                    }
                                </span>
                            )
                            // return  value.updated_at ?  getFormattedDate(value.updated_at) : getFormattedDate(value.created_at)
                        }
                    },
                    {
                        Header: 'PTM processed Date',
                        id: 'camera_ship_status[0].process_date',
                        accessor: 'camera_ship_status[0].process_date',
                        className: 'action-center',
                        sortable: true,
                        Cell: ({ value }) => {
                            return getFormattedDate(value)
                        }
                    },
                ]
            }
        ]
        const { /*preDepartureList, days_from_now*/ } = this.props;
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Pre Departure Post Shipping List</div>
                                    <div>
                                        <Link style={{position:'relative'}} target="_blank" className='customBtn btn btn-primary text-white mt-lg ml-lg' to={`/scheduler?admin=true`}><span style={{right:'0',position:'absolute',background: 'red',top: '-15px',borderRadius:'999px',padding: '2px 10px'}}>{this.props.preDepartureList ? this.props.preDepartureList.length : ''}</span>See Call Scheduler</Link>
                                    </div>

                                    {/* <div className='flexElem' style={{ justifyContent: 'space-between' }}>
                                        <div className='my-20 col-md-4'>
                                            <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' />
                                        </div>
                                    </div>
                                    <div>
                                        <p>Orders departure date by {getFormattedDate(days_from_now.date)} ({days_from_now.days} days from now)</p>
                                    </div>
                                    <ReactTable
                                        noDataText='No Email Found'
                                        manual
                                        columns={columns}
                                        data={preDepartureList.data}
                                        defaultPageSize={100}
                                        onFetchData={(state) => {
                                            let matchFilterWith = columnFilters(state.columns[0].columns);
                                            let filter = state.filtered;
                                            let { sort, orderType } = createSortString(state.sorted);
                                            this.props.getPreDepartureList(state.pageSize, sort, orderType, filter, matchFilterWith);
                                        }}
                                    /> */}
                                    <div className='row'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={100}
                                            noDataText='No Email Found'
                                            getDataCall={this.paginationCall}
                                            filterView={false}
                                            // downloadData={true}
                                            // downloadFileName={'Orders'}
                                            // lowerContent = {null}
                                            // filterPlaceHolder = {'Orders'}
                                            // getRowProps={this.getRowProps}
                                            getRowProps={this.getTrProps}
                                        // showAllToggle={true}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { preDepartureList, days_from_now } = state.ordersReducer;
    // const { preDepartureList, days_from_now, scheduledCalls } = state.ordersReducer;
    return {
        preDepartureList,
        days_from_now,
        // scheduledCalls
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getContactListCall: (data) => { return dispatch(contactList(data)) },
        getPreDepartureList: (pageSize, sort, orderType, filter, matchFilterWith) => { return dispatch(getPreDepartureList(pageSize, sort, orderType, filter, matchFilterWith)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(preDeparturePostShippingList)
