import { authHeader } from 'helper/authHeader';
import { baseUrl } from 'helper/ApiCall';
import axios from 'axios';
import moment from "moment";

export const SC = {
    getCall,
    postCall,
    putCall,
    deleteCall,
    postCallLoginAdmin,
    postCallWithoutAuth,
    getCallWithData,
}

function getCall(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return axios.get(baseUrl + url, requestOptions).then(response => {
        return response
    }).catch(function (error) {
        return Promise.reject(error);
    });
}
function getCallWithData(url,data) {
    let requestOptions = {
        method: 'GET',
        headers: authHeader(),
        params: data
    };
    return axios.get(baseUrl + url, requestOptions).then(response => {
        return response
    }).catch(function (error) {
        return Promise.reject(error);
    });
}

function postCall(url, data, callbackProgressUpload = null, source) {
    Date.prototype.toJSON = function () {
        return moment(this).format();
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
        onUploadProgress: function (progressEvent) {
            // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            if (callbackProgressUpload)
                callbackProgressUpload(progressEvent);
        }
    };
    if (source) {
        requestOptions.cancelToken = source.token;
    }
    return axios.post(baseUrl + url, data, requestOptions).then(
        response => {
            return response;
        }
    ).catch(function (error) {
        return Promise.reject(error);
    });
}

function putCall(url, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    if ( url === 'order/217' ) {
        console.log('data 999', data);
    }
    return axios.put(baseUrl + url, data, requestOptions).then(
        response => {
            return response;
        }
    ).catch(function (error) {
        return Promise.reject(error);
    });
}

function deleteCall(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return axios.delete(baseUrl + url, requestOptions).then(response => {
        return response
    }).catch(function (error) {
        return Promise.reject(error);
    });
}
function postCallLoginAdmin(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return axios.post(baseUrl + url, data, requestOptions).then(
        response => {
            if (response.token) {

                localStorage.setItem('user', JSON.stringify(response));
            }
            return response;
        }
    ).catch(function (error) {
        return Promise.reject(error);
    });
}


function postCallWithoutAuth(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return axios.post(baseUrl + url, data, requestOptions).then(
        response => {
            return response;
        }
    ).catch(function (error) {
        return Promise.reject(error);
    });
}