import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { orderService } from '../../../services/order';
import { Col, Row } from 'react-bootstrap';
import { alertActions } from '../../../redux/actions/alert-actions';

class AiModalTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aiModels: [],
            aiModelsClasses: [],
            aiModelsSubClasses: [],
            tabs: {
                showModels: true,
                showModelClasses: false,
                showModelSubClasses: false,
                showModelTrainingData: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false,
            },
            selectedModel: null,
            selectedModelsClasses: null,
            selectedModelsSubClasses: null,
            classes: [],
            // tiltDirection: '',
            teaxtValue: '',
            teaxtAreaHeading: '',
            correct_image: '',
            positionHeading: '',
        }
    }

    componentDidMount() {
        orderService.getAiModels().then((response) => {
            if (response) {
                this.setState({
                    aiModels: response.data
                })
            }
        })
    }
    
    componentDidUpdate(prevProps, prevState) { 
        if (this.props.image && this.props.image !== prevProps.image) {
            console.log("Image not same", this.props.image.id);
            this.initialState();
            
        }
    }
    
    initialState = () => {
        this.setState({
            tabs: {
                ...this.state.tabs, 
                showModels: true,
                showModelClasses: false,
                showModelSubClasses: false,
                showModelTrainingData: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false,
            },
            selectedModel: null,
            selectedModelsClasses: null,
            selectedModelsSubClasses: null,
            classes: [],
            teaxtValue: '',
            teaxtAreaHeading: '',
            correct_image: '',
            positionHeading: '',
        })
    }

    textAreaChangeHandler = (e) => {
		const { value } = e.target;
        this.setState({ teaxtValue: value})
        if (this.state.selectedModelsClasses && !this.state.selectedModelsSubClasses) {

            const newId = this.state.selectedModelsClasses.id;
                const newValue = value;
    
                // Call the function to update the state
                this.updateClasses(newId, newValue);
            
        } else if (this.state.selectedModelsClasses && this.state.selectedModelsSubClasses) {
            const newId = this.state.selectedModelsSubClasses.id;
                const newValue = value;
    
                // Call the function to update the state
                this.updateClasses(newId, newValue);
        }
    }

    clearState = () => {
        
        this.setState({
            selectedModelsSubClasses: null,
            // tiltDirection: '',
            teaxtValue: '',
            classes: [],
            tabs: {
                ...this.state.tabs, 
                showModels: true,
                showModelSubClasses: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false
            }
        })
    }

    updateClasses = (newId, newValue) => {
        this.setState((prevState) => {
            // Add a safety check to ensure classes is an array
            const updatedClasses = (prevState.classes || []).map((classItem) => {
                if (classItem.id === newId) {
                    // Update the value if the id already exists
                    return { ...classItem, value: newValue };
                }
                return classItem;
            });

            // Check if the id was already in the array
            const idExists = (prevState.classes || []).some(classItem => classItem.id === newId);

            // If id does not exist, push the new id-value pair
            if (!idExists) {
                updatedClasses.push({ id: newId, value: newValue });
            }

            return { classes: updatedClasses };
        });
    }

    handleRadioChange = (record, key) => {
        
        const { slug, is_description, title } = record;
        let data = {
            parent_id: null
        }
        if (key === 'ai_model' && !is_description) {
            orderService.getAiModelsClasses(record.id, data).then((response) => {
                if (response) {
                    this.clearState();
                    this.setState({
                        aiModelsClasses: response.data,
                        selectedModelsClasses: null,
                        selectedModel: record,
                        teaxtAreaHeading: record.title,
                        tabs: {
                            ...this.state.tabs, 
                            showModels: true,
                            showModelClasses: true,
                            showModelSubClasses: false,
                            showModelTrainingData: false,
                            tiltInput: false,
                            similarToPositions: false
                        }
                    })
                    console.log("results", response);
                    
                    if (response.data[0].slug === "too_dark") {
                        this.setState({ selectedModelsClasses: response.data[0]})
                    }
                }
            })
        } else if (key === 'ai_model' && is_description) {
            this.clearState();
            if (slug.includes('tilt')) {

                this.setState({
                    selectedModel: record,
                    teaxtAreaHeading: record.title,
                    selectedModelsClasses: null,
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false,
                        showModelClasses: false,
                        showModelSubClasses: false,
                        tiltInput: true
                    }
                })
            } else {
                this.setState({
                    selectedModel: record,
                    teaxtAreaHeading: record.title,
                    selectedModelsClasses: null,
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true,
                        showModelClasses: false,
                        showModelSubClasses: false,
                        tiltInput: false,
                        similarToPositions: false
                    }
                })

            }
        } else if ( key === 'ai_model_classes') {
            
            // this.clearState();
            this.setState({ selectedModelsClasses: record, teaxtAreaHeading: record.title, aiModelsSubClasses: [], classes: [], selectedModelsSubClasses: null })
            const newId = record.id;
            const newValue = null;

            // Call the function to update the state
            this.updateClasses(newId, newValue);
            
            if (slug && slug.includes('content')) {
                data = {
                    parent_id: record.id
                }
                orderService.getAiModelsClasses(this.state.selectedModel.id, data).then((response) => {
                    if (response) {
                        this.setState({
                            aiModelsSubClasses: response.data,
                            tabs: {
                                ...this.state.tabs, 
                                showModels: true,
                                showModelClasses: true,
                                showModelSubClasses: true,
                            }
                        })
                    }
                })
            } else if (slug && slug.includes('need_straighten')) {

                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false,
                        showModelSubClasses: false,
                        tiltInput: true
                    }
                })
            } else if (slug && slug.includes('similer_to_other')) {
                this.setState({
                    positionHeading: 'Similer To Position #',
                    tabs: {
                        ...this.state.tabs,
                        similarToPositions: true,
                        showTextArea: true
                    }
                })
            } else if (slug && slug.includes('wrong_placement')) {
                this.setState({
                    positionHeading: 'Correct Position #',
                    tabs: {
                        ...this.state.tabs,
                        similarToPositions: true,
                        showTextArea: true
                    }
                })
            } else if (is_description) {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true
                    }
                })
            } else {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false
                    }
                })
            }
            
        } else if (key === 'ai_model_sub_classes') {
            
            this.setState({ 
                selectedModelsSubClasses: record,
                teaxtAreaHeading: record.title,
                classes: []
            }, () => {
                const newId = this.state.selectedModelsClasses.id;
                const newValue = null;

                // Call the function to update the state
                this.updateClasses(newId, newValue);
            })
            setTimeout(() =>{
                
                const newId = record.id;
                const newValue = null;
    
                // Call the function to update the state
                this.updateClasses(newId, newValue);
            }, 200)
            if (title.includes('Other')) {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true
                    }
                })
            } else {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false
                    }
                })
            }
        }
    }

    getColumnRadio = (record, key) => {
        const { slug, id, title } = record;
        const { selectedModel, selectedModelsClasses, selectedModelsSubClasses } = this.state;
        
        let checked = null;
        if (key === 'ai_model') {
            checked = selectedModel && selectedModel.id
        } else if (key === 'ai_model_classes') {
            checked = selectedModelsClasses && selectedModelsClasses.id
        } else {
            checked = selectedModelsSubClasses && selectedModelsSubClasses.id
        }
        
    
        return (
            <Col xs={12} md={6}>
                <label key={id} className="container-radio checkBG font14 justifyEnd w-100 alignTop" style={{marginBottom: 5}}>
                    <span style={{ textTransform: 'capitalize' }}>
                        {`${title}`}
                    </span>
                    <input
                        value={JSON.stringify(record)}
                        type="radio"
                        name={key} // Same name for all radios
                        id={id}
                        className="radio font14"
                        checked={checked === id}
                        onChange={() => this.handleRadioChange(record, key)}
                    />
                    <span className="checkmark" />
                </label>
            </Col>
        );
    };

    saveModelHandler = () => {
        const {selectedModel, selectedModelsClasses, classes, teaxtValue, correct_image} = this.state;
        const { image, producedImagesData } = this.props;
        let correct_image_id = null;
        let wrong_image_id = null;
        if (correct_image && correct_image > 0) {
            console.log("condition true", correct_image, image.producer_sort_order -1);
            
            const c_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === Number(correct_image) - 1;
            });
            console.log("Correct image", c_image);
            
            const w_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === image.producer_sort_order - 1;
            });

            console.log("Wrong image", w_image);
            if (c_image && w_image) {
                correct_image_id = c_image[0] ? c_image[0].id : null;
                wrong_image_id = w_image[0] ? w_image[0].id : null;
            }
        }

        const data = {
            image_id: image.id,
            correct_image_id: correct_image_id,
            wrong_image_id: wrong_image_id,
            order_id: image.model_id,
            file_path: image.file_path + image.image_versions.original_file_name + '.' + image.image_versions.extension,
            model_id: selectedModel.id,
            classes: classes ? classes : null,
            // sub_class_id: selectedModelsSubClasses ? selectedModelsSubClasses.id : null,
            value: teaxtValue ? teaxtValue : null
        }
        orderService.storeModelTrainingData(data).then((response) => {
            console.log("Saving model response", response);
            if (response) {
                this.props.success(response.message);
            }
        })
        
        console.log("Saving model configuration", data);
    }


    render() {
        const { aiModels, tabs, aiModelsClasses, teaxtValue, selectedModel, teaxtAreaHeading, selectedModelsClasses, aiModelsSubClasses, positionHeading } = this.state;
        return (
            <React.Fragment>
                <h1 className='mb-md'>Data Collection For Ai Model Training</h1>
                <Row className="mb-2">
                    {
                        aiModels && aiModels.length > 0 
                        ?
                        <Fragment>
                                {
                                    aiModels.map(el => this.getColumnRadio(el, 'ai_model'))
                                }
                                </Fragment>
                        : 
                        null
                    }
                    
                    {
                        aiModelsClasses && aiModelsClasses.length > 0 && tabs.showModelClasses 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h6 className='mt-sm px15 textCapNone'>{selectedModel.title}</h6>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            {
                                aiModelsClasses.map(el => this.getColumnRadio(el, 'ai_model_classes'))
                            } 
                            </Row>
                        </Fragment>
                        : 
                        null
                    }     
                    {
                        aiModelsSubClasses && aiModelsSubClasses.length > 0 && tabs.showModelSubClasses 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h6 className='mt-sm px15 textCapNone'>{selectedModelsClasses.title}</h6>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            {
                                aiModelsSubClasses.map(el => this.getColumnRadio(el, 'ai_model_sub_classes'))
                            } 
                            </Row>
                        </Fragment>
                        : 
                        null
                    }     


                    {/* // input code*/}

                    {
                        tabs.tiltInput 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h6 className='mt-sm px15 textCapNone'>{teaxtAreaHeading}</h6>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.tiltInput &&
                                <Col xs={12} md={12} className='mt-md'>
                                    <input 
                                        placeholder='Tilt Direction' 
                                        type="number" 
                                        name="tilt_direction" 
                                        min="0" 
                                        className='form-control' 
                                        style={{width: 'auto'}} 
                                        value={this.state.teaxtValue}
                                        onChange={this.textAreaChangeHandler} 
                                        // onChange={(e) => this.setState({ teaxtValue: e.target.value })} />
                                    />
                                </Col>
                                
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }    
                    {
                        tabs.similarToPositions
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h6 className='mt-sm px15 textCapNone'>{positionHeading}</h6>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.similarToPositions &&
                                <Col xs={12} md={12} className='mt-md'>
                                    <input 
                                        placeholder='Position #' 
                                        type="number" 
                                        name="tilt_direction" 
                                        min="0" 
                                        pattern='\d*'
                                        className='form-control' 
                                        style={{width: 'auto'}} 
                                        value={this.state.correct_image}
                                        onChange={(e) => this.setState({ correct_image: e.target.value })}
                                    />
                                </Col>
                                
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }    




                    {/* // Text area code*/}

                    {
                        tabs.showTextArea 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h6 className='mt-sm px15 textCapNone'>{teaxtAreaHeading}</h6>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.showTextArea &&
                                <Col xs={12} md={12} className='mt-md'>
                                {/* <h3>Explanation</h3> */}
                                <textarea
                                    value={teaxtValue}
                                    name="teaxtValue"
                                    onChange={this.textAreaChangeHandler}
                                    className='form-control font14'
                                    placeholder={`Explain why ${teaxtAreaHeading}`}
                                    id="teaxtValue"
                                ></textarea>
                                </Col>
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }                  
                    {
                        selectedModelsClasses || tabs.showTextArea || tabs.tiltInput
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            {/* <h6 className='mt-sm px15 textCapNone'>{teaxtAreaHeading}</h6> */}
                            <Row className='py10 mt-xs  mx-0'>
                            
                            {
                                <Col xs={12} md={12} className='mt-md text-center'>
                                    <button 
                                        name='add' 
                                        type="button" 
                                        className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm' 
                                        onClick={this.saveModelHandler} 
                                    >Save</button>
                                                                
                                </Col>
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }                  
                </Row>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
    
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        
        success: (message) => { return dispatch(alertActions.success(message)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AiModalTraining);