import React, { Component } from 'react'
import { connect } from 'react-redux'
import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
import { s3StaticPath } from 'helper/helperFunctions';
import "bootstrap/dist/css/bootstrap.min.css";
import 'assets/fonts/sourceSansPro.css'
import "assets/css/reset.css";
import "assets/css/style.css";
import { getOrder } from 'redux/actions/order-actions';
import { getCountries } from 'redux/actions/settings-actions';
import { objectsConstants } from 'redux/constant/objects-constant';
// import MyImage from 'components/Upload/MyImage';

class PrintOrderOptions extends Component {

    constructor(props) {
        super(props)
        // if (!User.isAuthenticated() || this.props.location.state === undefined) {
        //     return <Redirect to='/' />;
        // } else {
        //     this.props.getOrderCall(this.props.match.params.id);
        // }
        this.state = {
            order_details: objectsConstants.ORDERDETAIL,
            production_details: this.props.production_details,
            shipping_details: objectsConstants.SHIPPINGDETAIL,
        }
        this.props.getOrderCall(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            })
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            })
        }
    }

    // componentDidMount() {
    //     window.onbeforeunload = function () {
    //         this.localStorage.removeItem('favOption');
    //         this.localStorage.removeItem('favOption2');
    //     }
    // }



    render() {
        let favOption1Img, favOption2Img, favOption2Title;
        const { order_details, shipping_details, production_details } = this.state;
        const { pre_trip_material_address, video_shipping_address } = shipping_details;
        const favOption = localStorage.getItem('favOption');
        const favOption2 = localStorage.getItem('favOption2');
        switch (favOption) {
            case 'desktop_app':
                favOption1Img = s3StaticPath('img/uploadPhotos.png');
                break;
            case 'not_sure':
                favOption1Img = s3StaticPath('img/contactMe.png');
                break;
            default:
                break;
        }
        switch (favOption2) {
            case 'camera':
                favOption2Img = s3StaticPath('img/digitalCamera.png');
                favOption2Title = 'Digital Camera'
                break;
            case 'card':
                favOption2Img = s3StaticPath('img/memoryCard.png');
                favOption2Title = 'Memory Card'
                break;
            case 'drive':
                favOption2Img = s3StaticPath('img/flashDrive.png');
                favOption2Title = 'Flash Drive'
                break;
            default:
                break;
        }
        return (
            <div className='section-frontEnd'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="panel_heading userLogin_heading">Your Options!</h2>
                        </div>
                    </div>

                    <div className="row mb-xlg">
                        <div className="col-md-12">
                            <div className="flexElem respFlex alignCenter spaceBetween">
                                <div className="text_16" style={{ width: "100%", maxWidth: "660px" }}>
                                Arrangements have been make for a fun keepsake video to be produced for your travelers to enjoy using the photos taken on your trip.
                                <br /><strong>No additional payment is required!</strong>
                                </div>

                                <div style={{ maxWidth: '230px' }}>
                                    <AgencyLogo />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                    <span>Order Information</span>
                                </div>

                                <div className="form_tile_content orderInformationBox">
                                    <div className="flexElem respFlex form_tile_row">
                                        <div className="col-md-6">
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Group Name:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.group_name}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Group Leader/Photo Admin:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.group_leader_contact.name}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Destination:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.destinationText}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Trip Dates:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {new Date(order_details.order_dates.departure_date.value).toDateString() + ' - ' + new Date(order_details.order_dates.return_date.value).toDateString()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <strong className="text_16">Photo Admin Login</strong>
                                            </div>

                                            <div className="text-primary text_14 mt-sm">Photo Admin Login is for GROUP LEADER ONLY!</div>

                                            <div className="flexElem alignCenter spaceBetween mt-md">
                                                <div className="flexElem alignCenter">
                                                    <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                                    <div className="ml-sm">Username:</div>
                                                </div>
                                                <div className="text-primary">
                                                    {order_details.photo_admin_username}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween mt-sm">
                                                <div className="flexElem alignCenter">
                                                    <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                                    <div className="ml-sm">Password:</div>
                                                </div>

                                                <div className="text-primary">
                                                    {order_details.photo_admin_password}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 trevlerLoginDetails">
                                            <div>
                                                <strong className="text_16">Traveler Login</strong>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween mt-md">
                                                <div className="flexElem alignCenter">
                                                    <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                                    <div className="ml-sm">Username:</div>
                                                </div>
                                                <div className="text-primary">
                                                    {order_details.username}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween mt-sm">
                                                <div className="flexElem alignCenter">
                                                    <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                                    <div className="ml-sm">Password:</div>
                                                </div>

                                                <div className="text-primary">
                                                    {order_details.password}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                    <span>Your Favorite Option Below</span>
                                </div>

                                <div className="form_tile_content favoriteOptions">
                                    <div className="favoriteOptionsGrid">
                                        <div>
                                            <div><label htmlFor="uploadPhotos"><img src={favOption1Img} alt="Upload Photos" /></label></div>
                                            <div>
                                                <strong style={{ textTransform: 'uppercase' }} className="text_16">{favOption.replace("_", " ")}</strong>
                                            </div>
                                        </div>
                                        {
                                            (favOption !== 'not_sure' && favOption2 !== "") &&
                                            <div>
                                                <div><label htmlFor="uploadPhotos"><img src={favOption2Img} alt="Upload Photos" /></label></div>
                                                <div>
                                                    <strong style={{ textTransform: 'uppercase' }} className="text_16">{favOption2Title}</strong>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.props.match.path !== '/print-order-options/:id' &&
                                <div className="form_tile">
                                    <div className="form_tile_heading">
                                        <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                        <span>FREE Group Travel Videos&trade; App!</span>
                                    </div>

                                    <div className="form_tile_content grpTravelApp">
                                        <div className="flexElem respFlex form_tile_row">
                                            {/* {
                                            data.allow_upload === 1 ?
                                                <div>I DO NOT want my group to be able to upload photos at all.</div>
                                                : null
                                        }
                                        {
                                            data.photo_admin_approval ?
                                                <div>Allow photos to go online for group's viewing without Photo Admin Approval.</div>
                                                : null
                                        } */}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/shipping_information.png')} alt="General Information" />
                                    <span>Shipping Details</span>
                                </div>

                                <div className="form_tile_content grpTravelApp">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div>
                                                <strong className="text_16">Equipment Shipping Address</strong>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Country:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {pre_trip_material_address.country.title}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Street:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {pre_trip_material_address.street_address_1}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    City:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {pre_trip_material_address.city}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    State:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {pre_trip_material_address.state.title}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Zipcode:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {pre_trip_material_address.zipcode}
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <strong className="text_16">Video Shipping Address</strong>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Country:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {video_shipping_address.country.title}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Street:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {video_shipping_address.street_address_1}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    City:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {video_shipping_address.city}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    State:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {video_shipping_address.state.title}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Zipcode:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {video_shipping_address.zipcode}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div>
                                                <strong className="text_16">Contact Information</strong>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Phone1:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.group_leader_contact.phone1}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Phone2:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.group_leader_contact.phone2}
                                                </div>
                                            </div>
                                            <div className="flexElem alignCenter spaceBetween form_tile_row">
                                                <div className="col-md-4">
                                                    Email:
                                            </div>
                                                <div className="col-md-8 text-primary">
                                                    {order_details.group_leader_contact.email}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/video_music.png')} alt="Shipping Information" />
                                    <span>Video Music Type</span>
                                </div>
                                <div className="form_tile_content">
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-12 text-center">
                                            <div>
                                                <strong className="text_16 text-uppercase">{order_details.music_selection_criteria.replace('_', " ")}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_tile mb-md">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/video_music.png')} alt="Shipping Information" />
                                    <span>Order Video Titles</span>
                                </div>
                                <div className="form_tile_content">
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-12 text-center">
                                            <div className="col-md-3">
                                                <div>
                                                    <strong className="text_16 text-uppercase">Opening Title</strong>
                                                </div>
                                                <div className='text-primary'>
                                                    {production_details.video_title_templates.ot1.pivot.value}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div>
                                                    <strong className="text_16 text-uppercase">Closing Title 1</strong>
                                                </div>
                                                <div className='text-primary'>
                                                    {production_details.video_title_templates.ct1.pivot.value}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div>
                                                    <strong className="text_16 text-uppercase">Closing Title 2</strong>
                                                </div>
                                                <div className='text-primary'>
                                                    {production_details.video_title_templates.ct2.pivot.value}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div>
                                                    <strong className="text_16 text-uppercase">Closing Title 3</strong>
                                                </div>
                                                <div className='text-primary'>
                                                    {production_details.video_title_templates.ct3.pivot.value}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { order_details, shipping_details, pricing_details, production_details } = state.ordersReducer;
    const { countries } = state.settingsReducer;
    return {
        order_details, shipping_details, pricing_details, production_details, countries
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        getCountriesCall: () => { dispatch(getCountries()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOrderOptions);
