import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { orderService } from 'services/order';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import { hasRole, colorForBardcodeWithDate, dateFormatMy, closeModal } from 'helper/helperFunctions';
import { getOrdersModeratorPagination, assignUserToOrder } from 'redux/actions/order-actions';
// import 'react-table/react-table.css';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { Link, withRouter, Redirect } from 'react-router-dom';
import { adminLabels } from 'redux/constant/admin-label-constant';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import MyImage from 'components/Upload/MyImage';
import Pagination from 'components/ThemeComponents/Pagination';
// import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { updateOrderModerator } from 'redux/actions/user-actions';
import { SC } from '../../../helper/ServerCall';
// import { history } from 'helper/history';
var Modal = require('react-bootstrap-modal');


class OrderListingForModeratorPage extends Component {
	constructor(props) {
		super(props);
		const roleParam = this.props.match ? this.props.match.params.roleParam : null;
		this.state = {
		pageSizeTotal: 0,
		sorted: '',
		filtered: '',
		orderPage: 0,
		roleParam: roleParam,
		unassignOrderModal: false,
		unassign: {},
		currentUser: JSON.parse(localStorage.getItem('user')),
		orderData: [],
		downloadMozaix: false,
		admin_user_id: ((this.props.userAdmin && this.props.userAdmin.user) ? this.props.userAdmin.user.id : null),
		typingTimeout: 0,
		alreadyAssigned: false,
		alreadyAssignedOrderId: null,
		alreadyAssignedUser: {}
		};
		this.ordersPaginationData = this.ordersPaginationData.bind(this);
		this.filterSearchHandle = this.filterSearchHandle.bind(this);
	};

	assignEditorToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.state.admin_user_id, role: 'editor' }).then(response => {
		if (hasRole(['admin', 'gm admin','Remote Editor','Remote Editor/Producer', 'Remote Producer'])) {
			const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=editor`;
			// window.open(url);
			console.log("if part execute");
			this.props.history.push(url);
		} else {
			const url = `/Admin/${orderId}/order-edit?imageCode=editor`;
			// window.open(url);
			this.props.history.push(url);
			console.log("ese part execute");
		}
		},
		error => {
			console.log("errrrrrrorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
			const alreadyAssigned = error.message.match('This order has already been assigned to different') ? true : false
			const alreadyAssignedOrderId = error.order_id;
			const alreadyAssignedUser = error.user.editor;
			if (alreadyAssigned && alreadyAssignedOrderId && alreadyAssignedUser) {
				this.openUnassignOrderModal(alreadyAssignedOrderId, alreadyAssignedUser.id, alreadyAssignedUser, 'edit')
			}
		});
	}
    assignMozaixToOrder = (orderId) => {
        this.props.assignUserToOrderCall(orderId, { userId: this.state.admin_user_id, role: 'mozaix' }).then(response => {
            if (hasRole(['admin', 'gm admin','Remote Editor','Remote Editor/Producer', 'Remote Producer'])) {
                const url = `/Admin/${orderId}/Manage-Mosaics`;
                // window.open(url);
                this.props.history.push(url);
            }
            else {
                const url = `/Admin/${orderId}/Manage-Mosaics`;
                // window.open(url);
                this.props.history.push(url);
            }
        }
        );
    }


	assignProductionToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.state.admin_user_id, role: 'producer' }).then(response => {
			if (hasRole(['admin', 'gm admin','Remote Editor','Remote Editor/Producer', 'Remote Producer'])) {
				const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`;
				// window.open(url);
				this.props.history.push(url);
			} else {
				const url = `/Admin/${orderId}/order-edit?imageCode=productionManager`;
				// window.open(url);
				this.props.history.push(url);
			}			
		},
		error => {
			console.log("errrrrrrorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
			const alreadyAssigned = error.message.match('This order has already been assigned to different') ? true : false
			const alreadyAssignedOrderId = error.order_id;
			const alreadyAssignedUser = error.user.producer;
			if (alreadyAssigned && alreadyAssignedOrderId && alreadyAssignedUser) {
				this.openUnassignOrderModal(alreadyAssignedOrderId, alreadyAssignedUser.id, alreadyAssignedUser, 'produce')
			}
		});
	}


	assignQAToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.state.admin_user_id, role: 'qa' }).then(response => {
			console.log("responseee");
			if (hasRole(['admin', 'gm admin','qa'])) {
				console.log("trueeee");
			const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=qa`;
			window.open(url);
			} else {
				console.log("falseee");
			const url = `/Admin/${orderId}/order-edit?imageCode=qa`;
			window.open(url);
			}

		});
	}

	assignTTKMozaixToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.state.admin_user_id, role: 'producer' }).then(response => {
			console.log("responseee");
			if (hasRole(['admin', 'gm admin'])) {
				console.log("trueeee");
			const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`;
			window.open(url);
			} else {
				console.log("falseee");
			const url = `/Admin/${orderId}/order-edit?imageCode=productionManager`;
			window.open(url);
			}

		});
	}
	ordersPaginationData(page, filter, sort, pageSize) {
		this.setState({ orderPage: page, filtered: filter, sorted: sort, pageSizeTotal: pageSize }, function () {
		var searchData = {};
		this.props.getOrdersPaginationCall(page, filter, sort, 10, searchData);
		});
	}

	paginationCall = (data) => {
		var searchData = {};
		return orderService.getOrdersModeratorPagination(data.page, this.state.filtered, data.sort, data.pageSize, searchData, this.state.roleParam).then(response => {
			// console.log(response.data.pagination.data.length, 'responseee');
			this.setState({
				orderData: response.data.pagination.data
			})
			return response;
		});
		// return this.props.getOrdersPaginationCall(data.page, this.state.filtered, data.sort, data.pageSize, searchData, this.state.roleParam);
	}


	filterSearchHandle(e) {
		this.setState({
			'filtered': e.target.value
		}
		// , () => {
		// 	this.pagination.dataCall();
		// }
	);

		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}

		this.setState({
			typingTimeout: setTimeout(() => {
				this.pagination.dataCall();
			}, 2000)
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.order_details !== this.props.order_details) {
			// var searchData = {};
			// const { orderPage, filtered, sorted, pageSizeTotal  } = this.state;
			this.pagination.dataCall();
			// this.props.getOrdersPaginationCall(orderPage, filtered, sorted, pageSizeTotal, searchData);
		}

		if (prevState.roleParam !== this.state.roleParam) {
			this.pagination.dataCall();
		}

		if (this.props.match) {
			if (this.props.match.params.roleParam !== prevProps.match.params.roleParam) {
			this.setState({ roleParam: this.props.match.params.roleParam });
			}
		}
	}
	openUnassignOrderModal = (orderId, id, user, type) => {
		console.log("orderId =>", orderId, "userId =>", id, "user => ",user, "type =>", type);
		this.setState({ unassign: { orderId: orderId, id: id, type: type, user: user } }, () => {
		this.setState({ unassignOrderModal: true });
		});
	}

	handleChangeModerator = () => {
		const { unassign } = this.state;
		// var data = { [unassign.type === 'edit' ? 'editor_id' : 'producer_id']: -1 }
		var data = '';
			if(unassign.type === 'edit'){
			unassign.type = 'editor_id'
			}else if (unassign.type === 'mozaix'){
				unassign.type = 'mozaix_manager_id'
			}else if (unassign.type === 'qa'){
				unassign.type = 'qa_id'
			} else{
				unassign.type = 'producer_id'
			}
			data = {[unassign.type] :-1};

		this.props.updateOrderModeratorCall(data, unassign.orderId).then(response => {
		this.setState({ unassignOrderModal: false }, () => {
			// this.pagination.dataCall();
		})
		});
	}

	continueEditing = (type) => {
		console.log('type', type);
		this.setState({ unassignOrderModal: false }, () => {
		if (hasRole(['admin', 'gm admin'])) {
			if (type === 'edit') {
			const url = `/Admin/${this.state.unassign.orderId}/order-edit?orderDetailCode=production&imageCode=editor`;
			window.open(url);
			} else if (type === 'produce') {
			const url = `/Admin/${this.state.unassign.orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`;
			window.open(url);
			} else if (type === 'qa') {
				const url = `/Admin/${this.state.unassign.orderId}/order-edit?orderDetailCode=production&imageCode=qa`;
				window.open(url);
			}else {
				const url = `/Admin/${this.state.unassign.orderId}/Manage-Mosaics`;
			window.open(url);
			}

		} else {
			if (type === 'edit') {
			const url = `/Admin/${this.state.unassign.orderId}/order-edit?imageCode=editor`;
			window.open(url);
			} else if (type === 'produce') {
			const url = `/Admin/${this.state.unassign.orderId}/order-edit?imageCode=productionManager`;
			window.open(url);
					} else {
						const url = `/Admin/${this.state.unassign.orderId}/Manage-Mosaics`;
						window.open(url);
			}
		}
		})
	}

//   handleDownloadMox = () => {

//         this.setState({
//             downloadMozaix: true
//         },() => {
//             let newData = {...this.state.orderData}
//              for(let i=0; i < newData.data.length; i++){
//                 console.log(newData.data[i], 'dataaa');
//              }

//         })

        // this.setState({
        //     downloadMozaix: true
        //                 }
        //                 ,() => {
        //                     console.log(this.state.downloadMozaix, " after print");
                        //      let newData = {...orderData}

                        // this.setState({
                        //     orderData
                        // })
                        // })
//   }

	updateOrderMozaixStatus(orderId, data) {
		return SC.putCall('updateOrderMozaix/' + orderId, data);
	}


	handleDownloadMozaix = orderId => (e) => {
		// return false;
		//   console.log(orderId, 'newww');
		// let newData = [...this.state.orderData.data]
		//   console.log(newData[orderId], 'newww');
		this.updateOrderMozaixStatus(orderId).then(
		response => {
			this.pagination.dataCall();
			// this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
			// this.props.usersPaginationData();
		}
		);
	}

	// getRowProps = (rowInfo) => {
	//   let data = {}
	//   if (rowInfo !== undefined) {
	//     const onHoldValue = rowInfo.settings.on_hold.value
	//     if (onHoldValue === '1') {
	//       data = {
	//         'backgroundColor': 'yellow'
	//       }
	//     }
	//   }
	//   return data;
	// }

	videoShipAndEstDate = (original) => {
			if (original && original.agency && original.agency.single_account && original.agency.single_account.account &&
				original.agency.single_account.account.domain_slug === 'ttk'
				){
			// console.log('4th condition call');
			return  "Mozaix only";
			}
			else if(
				original && original.settings &&
				original.settings.no_gl_copy &&
				original.settings.send_gl_fd_copy &&
				original.settings.no_gl_copy.value == "0" &&
				original.settings.send_gl_fd_copy.value == "0" &&
				original.video_ship_status &&
				original.video_ship_status.length > 0 &&
				Object.keys(original.video_ship_status[0]).length > 0 &&
				original.video_ship_status[0].tracking_number
			)
			{
				// console.log('1st condition call');
				return dateFormatMy(original.video_ship_status[0].created_at);

			}

			else if(
				original && original.settings &&
				original.settings.no_gl_copy &&
				original.settings.send_gl_fd_copy &&
				original.settings.no_gl_copy.value == "0" &&
				original.settings.send_gl_fd_copy.value == "0" &&
				original.video_ship_status &&
				original.video_ship_status.length > 0 &&
				Object.keys(original.video_ship_status[0]).length > 0 &&
				!original.video_ship_status[0].tracking_number
			)
			{
				// console.log('1st condition call');
				return "Not Shiped";

			}
			else if(
				original && original.settings &&
				original.settings.no_gl_copy &&
				original.settings.send_gl_fd_copy &&
				original.settings.no_gl_copy.value == "0" &&
				original.settings.send_gl_fd_copy.value == "0" &&
				original.video_ship_status &&
				original.video_ship_status.length < 1)
			{
				// console.log('2nd condition call');
				if(original.order_dates.finish_by_date.value != ""){
					return dateFormatMy(original.order_dates.finish_by_date.value) + " (Estimated)";
				}else {
					return "Not Shiped";
				}
			}
			else if(
				original && original.settings &&
				(
					(original.settings.no_gl_copy && original.settings.no_gl_copy.value == "1") ||
					(original.settings.send_gl_fd_copy && original.settings.send_gl_fd_copy.value == "1")
				) &&
				original.settings.finalize_by_qa &&
				original.settings.finalize_by_qa.value == "1"
			)
			{
				// console.log('4th condition call');
				return  "digital only ready";
			}
			else
			{
				//console.log('last condition call');
				return '';
			}
	}
	getRowProps = (rowData) => {
		let data = {};
		if (rowData.note !== null && this.state.roleParam === 'producer') {
			data = { color: 'red',};
		}
		if (rowData.agency.single_account.account.domain_slug === 'ttk' && this.state.roleParam === 'mozaix') {
		data = {  color: 'green'};
		}
		return data;
	}

	unassignOrderModal = () => {
		this.setState({ 
			unassignOrderModal: false, 
			alreadyAssigned: false, 
			alreadyAssignedOrderId: null, 
			alreadyAssignedUser: {} 
		});
	}

	render() {
		const {
		// filtered,
		roleParam, unassignOrderModal, unassign, currentUser, orderData, downloadMozaix, admin_user_id } = this.state;
		const { order_details} = this.props;
		//   console.log(orderData, 'orderDataa response');

		//   console.log(downloadMozaix , " before print");
		if (roleParam) {
			//   if (!hasPermission('admin-dashboard-' + roleParam)) {
			//       console.log("enterrrrr");
			//     return (<Redirect to='/Admin/dashboard' />);
			//   }
			if(!hasPermission('remote-dashboard')){
				console.log("remote dashboard---------------");
					return (<Redirect to='/Admin/dashboard' />);
			}

		}
		// const { ordersData, pages, loadingOrder} = this.props;
		let columns = [
		{
			Header: "Orders Listing",
			headerClassName: 'headerPagination',
			columns: [
			{
				Header: "Editor",
				accessor: "editor.name",
				Cell: ({ value, original }) => (
				<div >
					{
					original.settings.on_hold.value !== '1'
						?
						original.editor_id
						?
						original.editor.image
							?
							<button onClick={() => this.openUnassignOrderModal(original.id, original.editor_id, original.editor, 'edit')}><MyImage alt={original.editor.image.title} className="initialsLogo" src={original.editor.image.preview} /></button>
							:
							<button onClick={() => this.openUnassignOrderModal(original.id, original.editor_id, original.editor, 'edit')}> {TF.initialsLogo(original.editor.bgColor, original.editor.name)}</button>
						:
						<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for editing process'}</Popover>}>
							<button className="orderDetailsAction assignIcon" onClick={() => this.assignEditorToOrder(original.id)} >&nbsp;</button>
						</OverlayTrigger>
						:
						'On Hold'
					}
				</div>
				),
				className: "action-center",
				excExp: roleParam === 'editor' ? true : false,
				show: roleParam ?
				roleParam === 'editor'
					?
					true
					:
					false
				:
				hasRole(['editor'])
					?
					true
					:
					false
			},
			{
				Header: "Mozaix",
				accessor: "created_at",
				Cell: ({ value, original }) => (

				<div >
					{
					//   console.log(original, 'Total # of Mozaix ordered'),
					original.settings.on_hold.value !== '1'
						?
						original.mozaix_manager_id
						?
						original.mozaix_manager.image
							?
							<button onClick={() => this.openUnassignOrderModal(original.id, original.mozaix_manager_id, original.mozaix_manager, 'mozaix')}><MyImage alt={original.mozaix_manager.image.title} className="initialsLogo" src={original.mozaix_manager.image.preview} /></button>
							:
							<button onClick={() => this.openUnassignOrderModal(original.id, original.mozaix_manager_id, original.mozaix_manager, 'mozaix')}> {TF.initialsLogo(original.mozaix_manager.bgColor, original.mozaix_manager.name)}</button>
						:
						<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for mosaics process'}</Popover>}>
							<button className="orderDetailsAction assignIcon" onClick={() => this.assignMozaixToOrder(original.id)} >&nbsp;</button>
						</OverlayTrigger>
						:
						'On Hold'
					}
				</div>
				),
				className: "action-center",
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			{
				Header: "Producer",
				accessor: "created_at",
				Cell: ({ value, original }) => (
				<div >
					{
					original.producer_id
						?
						original.producer.image
						?
						<button onClick={() => this.openUnassignOrderModal(original.id, original.producer_id, original.producer, 'produce')}><MyImage alt={original.producer.image.title} className="initialsLogo" src={original.producer.image.preview} /></button>
						:
						<button onClick={() => this.openUnassignOrderModal(original.id, original.producer_id, original.producer, 'produce')}> {TF.initialsLogo(original.producer.bgColor, original.producer.name)} </button>
						:
						<OverlayTrigger placement="bottom" overlay={
						<Popover id="tooltip"  >
							{
							"finalize_by_editor" in original.settings
								?
								parseInt(original.settings.finalize_by_editor.value, 10) === 1
								?
								'Order assign me for production process'
								:
								'Order is yet to be finalized by editor'
								:
								'setting is not available'
							}
						</Popover>
						}>
						{
							"finalize_by_editor" in original.settings
							?
							parseInt(original.settings.finalize_by_editor.value, 10) === 1
								?
								<button className="orderDetailsAction assignIcon" onClick={() => this.assignProductionToOrder(original.id)} >&nbsp;</button>
								:
								<button className="orderDetailsAction assignIcon button_disabled" >&nbsp;</button>
							:
							'setting is not available'
						}

						</OverlayTrigger>
					}
				</div>
				),
				className: "action-center",
				show: roleParam ?
				roleParam === 'producer'
					?
					true
					:
					false
				:
				hasRole(['producer'])
					?
					true
					:
					false
			},
			{
				Header: "QC",
				accessor: "created_at",
				Cell: ({ value, original }) => (
				<div >
					{
					original.qa_id
						?
						original.qa.image
						?
						<button onClick={() => this.openUnassignOrderModal(original.id, original.qa_id, original.qa, 'qa')}><MyImage alt={original.qa.image.title} className="initialsLogo" src={original.qa.image.preview} /></button>
						:
						<button onClick={() => this.openUnassignOrderModal(original.id, original.qa_id, original.qa, 'qa')}> {TF.initialsLogo(original.qa.bgColor, original.qa.name)} </button>
							//   <MyImage className="initialsLogo" src={original.qa.image.preview} alt="imagepreview" />
							//   :
							//   TF.initialsLogo(original.qa.bgColor, original.qa.name)
						:
						<OverlayTrigger placement="bottom" overlay={
						<Popover id="tooltip"  >
							{
							"finalize_by_pm" in original.settings
								?
								parseInt(original.settings.finalize_by_pm.value, 10) === 1
								?
								'Order assign me for QA'
								:
								'Order is yet to be finalized by PM'
								:
								'setting is not available'
							}
						</Popover>
						}>
						{
							"finalize_by_pm" in original.settings
							?
							parseInt(original.settings.finalize_by_pm.value, 10) === 1
								?
								<button className="orderDetailsAction assignIcon" onClick={() => this.assignQAToOrder(original.id)} >&nbsp;</button>
								:
								<button className="orderDetailsAction assignIcon button_disabled" >&nbsp;</button>
							:
							'setting is not available'
						}

						</OverlayTrigger>
					}
				</div>
				),
				className: "action-center",
				show: roleParam ?
				roleParam === 'qa'
					?
					true
					:
					false
				:
				hasRole(['qa'])
					?
					true
					:
					false
			},
			{
				Header: "TTK Mozaix",
				accessor: "created_at",
				Cell: ({ value, original }) => (
				<div >
					{
					original.producer_id
						?
						original.producer.image
						?
						<button onClick={() => this.openUnassignOrderModal(original.id, original.producer_id, original.producer, 'ttk-mozaix')}><MyImage alt={original.producer.image.title} className="initialsLogo" src={original.producer.image.preview} /></button>
						:
						<button onClick={() => this.openUnassignOrderModal(original.id, original.producer_id, original.producer, 'ttk-mozaix')}> {TF.initialsLogo(original.producer.bgColor, original.producer.name)} </button>
							//   <MyImage className="initialsLogo" src={original.qa.image.preview} alt="imagepreview" />
							//   :
							//   TF.initialsLogo(original.qa.bgColor, original.qa.name)
						:
						<OverlayTrigger placement="bottom" overlay={
						<Popover id="tooltip"  >
							{
							"finalize_by_pm" in original.settings
								?
								parseInt(original.settings.finalize_by_editor.value, 10) === 1
								?
								'Order assign me for TTK Mozaix'
								:
								'Order is yet to be finalized by Editor'
								:
								'setting is not available'
							}
						</Popover>
						}>
						{
							"finalize_by_editor" in original.settings
							?
							parseInt(original.settings.finalize_by_editor.value, 10) === 1
								?
								<button className="orderDetailsAction assignIcon" onClick={() => this.assignTTKMozaixToOrder(original.id)} >&nbsp;</button>
								:
								<button className="orderDetailsAction assignIcon button_disabled" >&nbsp;</button>
							:
							'setting is not available'
						}

						</OverlayTrigger>
					}
				</div>
				),
				className: "action-center",
				show: roleParam ?
				roleParam === 'ttk-mozaix'
					?
					true
					:
					false
				:
				hasRole(['ttk-mozaix'])
					?
					true
					:
					false
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				width: 200,
				className: "action-center",
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
				Cell: ({ value, original }) => (
				/*add true in below conditions just for time to ignore condition */
				<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ? 'Edit Order' : adminLabels.DONT_ALLOW}</Popover>}>
					{

					(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ?
						<Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode" target="_blank" >
						<span className={
							colorForBardcodeWithDate(original.order_dates.last_day_of_school.value, 'lastDayOfSchool') + " " +
							colorForBardcodeWithDate(original.order_dates.video_special_need_date.value, 'videoSpecialNeedDate')
						}
						>
							{value}
						</span>
						</Link>
						:
						<span className="barcode">{value}</span>
					}
				</OverlayTrigger>
				),
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				width: 200,
				className: "action-center",
				show: roleParam == 'mozaix' ? true : false,
				Cell: ({ value, original }) => (
				/*add true in below conditions just for time to ignore condition */
				<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ? 'Edit Order' : adminLabels.DONT_ALLOW}</Popover>}>
					{

					(hasPermission('order-edit') && original.editor_id === admin_user_id) || true ?
						// <Link to={'/Admin/' + original.id + '/Manage-Mosaics'} className="blue_link barcode" target="_blank" >
						<Link to={'/Admin/' + original.id + '/order-edit'} className="blue_link barcode" target="_blank" >
						<span className={
							colorForBardcodeWithDate(original.order_dates.last_day_of_school.value, 'lastDayOfSchool') + " " +
							colorForBardcodeWithDate(original.order_dates.video_special_need_date.value, 'videoSpecialNeedDate')
						}
						>
							{value}
						</span>
						</Link>
						:
						<span className="barcode">{value}</span>
					}
				</OverlayTrigger>
				),
			},
			{
				Header: "Agency Name",
				accessor: "agency.name",
				Cell: ({ value }) => (
				<div>
					{value}
				</div>
				),
				className: "justifyStart",
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},
			{
				Header: "Group Type",
				accessor: `agency_type.title`,
				Cell: ({ value, original }) => (
				<div className={`${original.settings.on_hold.value === '1' ? 'yellowBackgroud' : null}`}>
					{value}
					{
					original.settings.on_hold.value === '1' &&
					TF.onHold(original.settings, original.on_hold_last_comment)
					}
				</div>
				),
				className: "justifyStart",
				width: 170,
				backgroundColor: 'pink',
				show: (roleParam !== 'mozaix' && roleParam !== 'qa') ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},
			{
				Header: "Group Name",
				accessor: "group_name",
				className: "justifyStart",
				sortable: false,
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},
			{
				Header: "Number of Photos(original/edited)",
				accessor: "id",
				Cell: ({ value, original }) => (
					<div >
					{
						<span>{original.originalImages + "/" + original.workingImages}</span>

					}
					</div>
				),
				className: (roleParam === 'qa' || hasRole(['qa'])) ? "" : "action-center",
				sortable: false,
				show: roleParam === 'qa' ? true : false
			},
			{
				Header: "Edited By/Produced By ",
				accessor: "id",
				width: 180,
				Cell: ({ value, original }) =>(
					<div >
					{
						<span>{(original.editor && original.editor.name ? original.editor.name : '') + "/" + (original.producer && original.producer.name ? original.producer.name : '')}</span>

					}
					</div>
				),
				className: (roleParam === 'qa' || hasRole(['qa'])) ? "" : "action-center",
				sortable: false,
				show: roleParam === 'qa' ? true : false
			},
			{
				// Header: (Number(order_details.settings.no_gl_copy.value) === 1 && Number(order_details.settings.send_gl_fd_copy.value) === 1) ? "Digital" : "DVD",
				Header: "Digital or DVD",
				accessor: "id",
				Cell: ({ value, original }) =>(
					<div >
					{
						(Number(original.settings.no_gl_copy.value) === 1) || (Number(original.settings.send_gl_fd_copy.value) === 1)
						?
						"Digital Only"
						:
						original.number_of_videos_travelers + original.free_additional_dvds_to_client + " " + "DVDs"

					}
					</div>
				),
				className: (roleParam === 'qa' || hasRole(['qa'])) ? "" : "action-center",
				sortable: false,
				show: roleParam === 'qa' ? true : false
			},
			{
				Header: "Destinations",
				accessor: "destinations",
				width: 180,
				className: "justifyStart",
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},
			{
				Header: "Edited By",
				accessor: "editor.name",
				className: "action-center",
				sortable: false,
				show: roleParam ?
				roleParam === 'producer'
					?
					true
					:
					false
				:
				hasRole(['producer'])
					?
					true
					:
					false
			},
			{
				Header: "Total number of photos",
				accessor: "originalImages",
				className: "action-center",
				sortable: false,
				excExp: roleParam === 'editor' ? true : false,
				show: roleParam ?
				roleParam === 'editor'
					?
					true
					:
					false
				:
				hasRole(['editor'])
					?
					true
					:
					false
			},
			{
				Header: "Total number of Edited photos",
				accessor: "workingImages",
				className: "action-center",
				sortable: false,
				show: roleParam ?
				roleParam === 'producer'
					?
					true
					:
					false
				:
				hasRole(['producer'])
					?
					true
					:
					false
			},
			{
				id: 'order_dates.return_date.value',
				Header: "Return Date",
				accessor: "order_dates.return_date.dateString",
				Cell: ({ value, original }) => (
				<div>
					{parseInt(original.order_dates.return_date.value) ?  original.order_dates.return_date.dateString : ''  }
				</div>
				),
				className: "action-center",
				excExp: roleParam === 'editor' ? true : false,
				show: roleParam ?
				roleParam === 'editor'
					?
					true
					:
					false
				:
				hasRole(['editor'])
					?
					true
					:
					false
			},
			{
				id: "order_dates.finish_by_date.value",
				Header: "Complete By Date",
				accessor: "order_dates.finish_by_date.dateString",
				Cell: ({ value, original}) => (
				<div>
					{parseInt(original.order_dates.finish_by_date.value) ?  original.order_dates.finish_by_date.dateString: '' }
				</div>
				),
				className: "action-center",
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},

			{
				id: "order_dates.video_special_need_date.value",
				Header: "Special Need Date",
				accessor: "order_dates.video_special_need_date.dateString",
				Cell: ({ value, original}) => (
				<div>
					{parseInt(original.order_dates.video_special_need_date.value) ?  original.order_dates.video_special_need_date.dateString: '--'  }
				</div>
				),
				className: "action-center redText",
				show: roleParam !== 'mozaix' ? true : false,
				excExp: roleParam === 'editor' ? true : false,
			},

			{
				Header: "Total # of Mozaix ordered",
				accessor: "number_of_mozaix_ordered",
				Cell: ({ original }) => (
					<div>
						{
						// console.log(original, 'Total # of Mozaix ordered'),
						<span>{original.number_of_mozaix_ordered}</span>
						}
					</div>
				),
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			{
				Header: "Video Producer",
				Cell: ({ original }) => (
					<div>
						{
						// console.log(original, 'Total # of Mozaix ordered'),
						<span>{original.producer ? original.producer.name : 'N/A'}</span>
						}
					</div>
				),
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			{
				id: "settings.finalize_by_qa.updated_at",
				Header: "Quality Control Finalized Date",
				accessor: "settings.finalize_by_qa.updated_atString",
				Cell: ({ original }) => (
					<div>
						{
						// console.log(original, 'Total # of Mozaix ordered'),
						"finalize_by_qa" in original.settings
						?
						dateFormatMy(original.settings.finalize_by_qa.updated_atString)
						:
						'Not Announced'
						}
					</div>
				),
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			{
				Header: "Edited Photo Count ",
				accessor: "workingImages",
				Cell: ({ original }) => (
					<div>
						{
						// console.log(original, 'Total # of Mozaix ordered'),
						<span>{original.workingImages}</span>
						}
					</div>
				),
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			{
				Header: "Video Ship Date/Est Complete Date",

				Cell: ({ original }) => (
					<div>
						{
							this.videoShipAndEstDate(original)
						}
					</div>
				),
				show: roleParam ?
				roleParam === 'mozaix'
					?
					true
					:
					false
				:
				hasRole(['mozaix'])
					?
					true
					:
					false
			},
			//   {
			//     Header: "Action",
			//     Cell: ({ original }) => (
			//       <div>
			//         <a className='ml-md' download onClick={this.handleDownloadMozaix(original.id)} href={original.order_resources && original.order_resources[0] && original.order_resources[0].file_path} >
			//             <i className='fa fa-download'></i>
			//         </a>
			//             <div>
			//                 {
			//                     original.order_resources && original.order_resources[0] && original.order_resources[0].file_path

			//                         ?
			//                         ''
			//                         :
			//                         <span className="text-muted mi" >No Mozaix</span>
			//                  }
			//             </div>
			//       </div>

			//     ),
			//     show: roleParam ?
			//     roleParam === 'mozaix'
			//       ?
			//       true
			//       :
			//       false
			//     :
			//     hasRole(['mozaix'])
			//       ?
			//       true
			//       :
			//       false
			// },

			]
		}
		];

		return (
		<div className="content">
			<Grid fluid>
			<Row>
				<Col md={12}>
				<Card
					ctTableResponsive
					content={
					<div>

						<Grid fluid>
						<Row>
							<Col md={12} className="mt-sm">
							<Pagination
								ref={(p) => this.pagination = p}
								columns={columns}
								pageSize={100}
								getDataCall={this.paginationCall}
								filterView={false}
								noDataText='No order found'
								upperContent={

								<Grid fluid>
									<div className="secHeading">
									{
										(hasRole(['editor']) || roleParam === 'editor') &&
										<span>Editing </span>
									}
									{
										(hasRole(['producer']) || roleParam === 'producer') &&
										<span>Production </span>
									}
									{
										(hasRole(['qa']) || roleParam === 'qa') &&
										<span>Quality Control </span>
									}
									{
										(hasRole(['mozaix']) || roleParam === 'mozaix') &&
										<span>Mozaix </span>
									}
									{
										(hasRole(['mozaix']) || roleParam === 'ttk-mozaix') &&
										<span>Production TTK Mozaix </span>
									}

									Dashboard</div>
									<div className="flexElem flexResponsive" style={{ "justifyContent": 'space-between' }}>
									<div className="custom-search-input" >
										<FormInputs
										ncols={["col-md-12"]}
										onChange={this.filterSearchHandle}
										proprieties={[
											{
											type: "text",
											bsClass: "form-control",
											placeholder: "Search Orders",
											onChange: this.filterSearchHandle,
											name: "filter"
											}
										]}
										/>
									</div>
									</div>
								</Grid>
								}
								downloadData={roleParam === 'editor' ? true : false}
								showAllToggle={true}
								downloadFileName={'Editing Dashboard'}
								// showTotal={roleParam === 'editor' ? true : false}
								// lowerContent = {null}
								// filterPlaceHolder = {'Orders'}
								getRowProps={this.getRowProps}
							/>
							{/*
							<ReactTable
								noDataText='No order found'
								data={ordersData}
								pages={pages}
								loading={loadingOrder}
								columns={columns}
								filtered={filtered}
								defaultPageSize={10}
								className="-striped listing"
								pageData={this.ordersPaginationData}
								manual
								onFetchData={(state, instance) => {
								var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
								state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
								}}
							/>

							*/}
							</Col>
						</Row>
						</Grid>
					</div>
					} />
				</Col>
			</Row>
			</Grid>
			{/* <Modal size="sm" backdrop={'static'} show={unassignOrderModal} id="" onHide={closeModal.bind(this, "unassignOrderModal")} aria-labelledby="ModalHeader" className="" > */}
			<Modal size="sm" backdrop={'static'} show={unassignOrderModal} id="" onHide={this.unassignOrderModal} aria-labelledby="ModalHeader" className="" >
			<Modal.Header closeButton>
				<Modal.Title id='ModalHeader' className="headerTitle">Orders Assignment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="clearfix">
				{
					'user' in unassign &&
					`${currentUser.user.id === unassign.id ? 'You are' : unassign.user.name + ' is'} already assigned to ${unassign.type} this order`
				}
				<br />
				{
					currentUser.user.id === unassign.id
					?
					<button log={console.log("keyssssssss",unassign.type)} onClick={() => this.continueEditing(unassign.type)} className="customBtn btn  btn-primary btn btn-default"> Continue {unassign.type === 'mozaix' ? 'Printing' : (unassign.type === 'edit' ? 'Editing' : unassign.type === 'qa' ? 'QC On' : 'Producing') }  This Order</button>
					:
					<button onClick={closeModal.bind(this, "unassignOrderModal")} className="customBtn btn  btn-primary btn btn-default"> Back</button>
				}

				<button onClick={() => this.handleChangeModerator()} className="customBtn btn ml-sm btn-primary btn btn-default">Unassign Order</button>
				</div>
			</Modal.Body>
			</Modal>
		</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
  return ({
    getOrdersPaginationCall: (page, filter, sort, pageSize, searchData, role) => { return dispatch(getOrdersModeratorPagination(page, filter, sort, pageSize, searchData, role)) },
    assignUserToOrderCall: (orderId, data) => { return dispatch(assignUserToOrder(orderId, data)) },
    updateOrderModeratorCall: (orderUserData, orderId) => { return dispatch(updateOrderModerator(orderUserData, orderId)) },
  });
};

function mapStateToProps(state) {
  const { order_details } = state.ordersReducer;
  const { loading, userAdmin } = state.adminReducer;
  return {
    loading, userAdmin, order_details
  };
};

const OrderListingForModerator = connect(mapStateToProps, mapDispatchToProps)(OrderListingForModeratorPage);
export default withRouter(OrderListingForModerator);
