import React, { Component } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { Card } from '../../Card/Card.jsx';

class UserPreferences extends Component {
	render() {
		const { userPreferences, isOpen } = this.props;
		return (
			<Card bsClass={["innerCard relativeCard"]} lazyRender collapseTrigger={isOpen} collapsable={true} collapsHandler={() => this.props.collapseHandle('isOpenUserPreferences')} title="User Preferences"
				content={
					<React.Fragment>

						<div className="clearfix mt-md fullCalendar">
							<div className="col-md-4">
								<CustomCheckbox
									tooltipText="Agecny Name of order"
									label="Agency Name"
									value={userPreferences.agency.name}
									indexArray={['agency', 'name']}
									name="agency_name"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Agency Tour Code"
									label="Agency Tour Code"
									value={userPreferences.client_tour_code}
									indexArray={['client_tour_code']}
									name="client_tour_code"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Agency Sales Rep"
									label="Agency Sales Rep"
									value={userPreferences.agency_sales_rep}
									indexArray={['agency_sales_rep']}
									name="agency_sales_rep"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Group name"
									label="Group Name"
									value={userPreferences.group_name}
									indexArray={['group_name']}
									name="group_name"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Group Contact Name"
									label="Group Contact Name"
									value={userPreferences.group_leader_contact.name}
									indexArray={['group_leader_contact', 'name']}
									name="group_leader_contact_name"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Group Contact Email"
									label="Group Contact Email"
									value={userPreferences.group_leader_contact.email}
									indexArray={['group_leader_contact', 'email']}
									name="group_leader_contact_email"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Group Contact Phone"
									label="Group Contact Phone"
									value={userPreferences.group_leader_contact.phone1}
									indexArray={['group_leader_contact', 'phone1']}
									name="group_leader_contact_phone1"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Destination"
									label="Destination"
									value={userPreferences.destinations}
									indexArray={['destinations']}
									name="destinations"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>


								<CustomCheckbox
									tooltipText="Number Of Videos"
									label="Number Of Videos"
									value={userPreferences.numberOfVideos}
									indexArray={['numberOfVideos']}
									name="numberOfVideos"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Number Of Mozaix"
									label="Number Of Mozaix"
									value={userPreferences.numberOfMozaix}
									indexArray={['numberOfMozaix']}
									name="numberOfMozaix"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Total Cost"
									label="Total Cost"
									value={userPreferences.order_totals.total}
									indexArray={['order_totals', 'total']}
									name="order_totals"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Group Type"
									label="Group Type"
									value={userPreferences.agency_type.title}
									indexArray={['agency_type', 'title']}
									name="agency_type"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Program Type"
									label="Program Type"
									value={userPreferences.program_type.title}
									indexArray={['program_type', 'title']}
									name="program_type"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Inventory Assignment"
									label="Inventory Assignment"
									value={userPreferences.inventory_assignment}
									indexArray={['inventory_assignment']}
									name="inventory_assignment"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Number of Uploaded Photos"
									label="Number of Uploaded Photos"
									value={userPreferences.originalImages}
									indexArray={['originalImages']}
									name="originalImages"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="GTV Sales Rep"
									label="GTV Sales Rep"
									value={userPreferences.gtv_sales_rep}
									indexArray={['gtv_sales_rep']}
									name="gtv_sales_rep"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								{
									/*
									<CustomCheckbox 
										tooltipText="Client Tour Number" 
										label="Client Tour Number" 
										value={userPreferences.client_tour_number}
										indexArray={['client_tour_number']} 
										name="client_tour_number"
										parentState="userPreferences"
										handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
									/>
									*/
								}

							</div>

							<div className="col-md-4">
								<CustomCheckbox
									tooltipText="Pre Trip Materials Street"
									label="Pre Trip Materials Street"
									value={userPreferences.pre_trip_material_address.street_address_1}
									indexArray={['pre_trip_material_address', 'street_address_1']}
									name="pre_trip_material_address_street_address_1"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								{ /*
						<CustomCheckbox 
							tooltipText="Pre Trip Material Address street 2" 
							label="Pre Trip Material Address Street 2" 
							value={userPreferences.pre_trip_material_address.street_address_2}
							indexArray={['pre_trip_material_address', 'street_address_2']} 
							name="pre_trip_material_address_street_address_2"
							parentState="userPreferences"
							handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
						/> */}
								<CustomCheckbox
									tooltipText="Pre Trip Materials City"
									label="Pre Trip Materials City"
									value={userPreferences.pre_trip_material_address.city}
									indexArray={['pre_trip_material_address', 'city']}
									name="pre_trip_material_address_street_city"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Pre Trip Materials State"
									label="Pre Trip Materials State"
									value={userPreferences.pre_trip_material_address.state}
									indexArray={['pre_trip_material_address', 'state']}
									name="pre_trip_material_address_street_state"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Pre Trip Materials Zip"
									label="Pre Trip Materials Zip"
									value={userPreferences.pre_trip_material_address.zipcode}
									indexArray={['pre_trip_material_address', 'zipcode']}
									name="pre_trip_material_address_street_zipcode"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Pre Trip Materials Tracking"
									label="Pre Trip Materials Tracking"
									value={userPreferences.order_shipping_camera.tracking_number}
									indexArray={['order_shipping_camera', 'tracking_number']}
									name="camera_ship_status_tracking_number"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Video Shipping Street"
									label="Video Shipping Street"
									value={userPreferences.video_shipping_address.street_address_1}
									indexArray={['video_shipping_address', 'street_address_1']}
									name="video_shipping_address_street_address_1"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								{ /*
						<CustomCheckbox 
							tooltipText="Video Ship Address street 2" 
							label="Video Ship Address Street 2" 
							value={userPreferences.video_shipping_address.street_address_2}
							indexArray={['video_shipping_address', 'street_address_2']} 
							name="video_shipping_address_street_address_2"
							parentState="userPreferences"
							handleChandgeCheckBox = { this.props.handleSearchCheckBoxPro } 
						/>
					*/}
								<CustomCheckbox
									tooltipText="Video Shipping City"
									label="Video Shipping City"
									value={userPreferences.video_shipping_address.city}
									indexArray={['video_shipping_address', 'city']}
									name="video_shipping_address_street_city"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Video Shipping State"
									label="Video Shipping State"
									value={userPreferences.video_shipping_address.state}
									indexArray={['video_shipping_address', 'state']}
									name="video_shipping_address_street_state"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Video Shipping Zip"
									label="Video Shipping Zip"
									value={userPreferences.video_shipping_address.zipcode}
									indexArray={['video_shipping_address', 'zipcode']}
									name="video_shipping_address_street_zipcode"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Admin Username"
									label="Admin Username"
									value={userPreferences.photo_admin_username}
									indexArray={['photo_admin_username']}
									name="photo_admin_username"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Admin Password"
									label="Admin Password"
									value={userPreferences.photo_admin_password}
									indexArray={['photo_admin_password']}
									name="photo_admin_password"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Traveler Quick Login"
									label="Traveler Quick Login"
									value={userPreferences.quick_login}
									indexArray={['quick_login']}
									name="quick_login"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Going on the trip"
									label="Going on the trip"
									value={userPreferences.going_on_trip_count}
									indexArray={['going_on_trip_count']}
									name="going_on_trip_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Family At Home"
									label="Family At Home"
									value={userPreferences.following_from_home_count}
									indexArray={['following_from_home_count']}
									name="following_from_home_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								
								<CustomCheckbox
									tooltipText="Edit/Produced By"
									label="Edit/Produced By"
									value={userPreferences.edit_produced_by}
									indexArray={['edit_produced_by']}
									name="edit_produced_by"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Production Status"
									label="Production Status"
									value={userPreferences.production_status}
									indexArray={['production_status']}
									name="production_status"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

							</div>

							<div className="col-md-4">

								<CustomCheckbox
									tooltipText="Created Date"
									label="Created Date"
									value={userPreferences.created_at}
									indexArray={['created_at']}
									name="created_at"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>


								<CustomCheckbox
									tooltipText="Pre Trip Materials Ship Date"
									label="Pre Trip Materials Ship Date"
									value={userPreferences.order_shipping_camera.shipped_date}
									indexArray={['order_shipping_camera', 'shipped_date']}
									name="order_shipping_camera_shipped_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>


								<CustomCheckbox
									tooltipText="Departure Date"
									label="Departure Date"
									value={userPreferences.order_dates.departure_date.value}
									indexArray={['order_dates', 'departure_date', 'value']}
									name="departure_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Return Date"
									label="Return Date"
									value={userPreferences.order_dates.return_date.value}
									indexArray={['order_dates', 'return_date', 'value']}
									name="return_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Pre Trip Materials Special Need Date"
									label="Pre Trip Materials Special Need Date"
									value={userPreferences.order_dates.pre_trip_package_need_date.value}
									indexArray={['order_dates', 'pre_trip_package_need_date', 'value']}
									name="pre_trip_package_need_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Video Special Need Date"
									label="Video Special Need Date"
									value={userPreferences.order_dates.video_special_need_date.value}
									indexArray={['order_dates', 'video_special_need_date', 'value']}
									name="video_special_need_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Upload Cut Off Date(Traveler)"
									label="Upload Cut Off Date(Traveler)"
									value={userPreferences.order_dates.upload_cut_off_date.value}
									indexArray={['order_dates', 'upload_cut_off_date', 'value']}
									name="upload_cut_off_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Uploaded Photos Submitted Date"
									label="Uploaded Photos Submitted Date"
									value={userPreferences.settings.photos_submitted_for_video_production.value}
									indexArray={['settings', 'photos_submitted_for_video_production', 'value']}
									name="photos_submitted_for_video_production"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>
								<CustomCheckbox
									tooltipText="Receive Date"
									label="Receive Date"
									value={userPreferences.order_dates.receive_date.value}
									indexArray={['order_dates', 'receive_date', 'value']}
									name="receive_date"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>


								<CustomCheckbox
									tooltipText="Actual Video Ship Date"
									label="Actual Video Ship Date"
									value={userPreferences.order_shipping_video.created_at}
									indexArray={['order_shipping_video', 'created_at']}
									name="order_shipping_video"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Production Finish Date"
									label="Production Finish Date"
									value={userPreferences.settings.finalize_by_qa.value}
									indexArray={['settings', 'finalize_by_qa', 'value']}
									name="finalize_by_qa"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>


								<CustomCheckbox
									tooltipText="Last Day of School"
									label="Last Day of School"
									value={userPreferences.order_dates.last_day_of_school.value}
									indexArray={['order_dates', 'last_day_of_school', 'value']}
									name="last_day_of_school"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Online Options Submitted"
									label="Online Options Submitted"
									value={userPreferences.settings.online_options_page_submitted.value}
									indexArray={['settings', 'online_options_page_submitted', 'value']}
									name="online_options_page_submitted"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Full Video Streaming Count"
									label="Full Video Streaming Count"
									value={userPreferences.settings.full_video_view_count.value}
									indexArray={['settings', 'full_video_view_count', 'value']}
									name="full_video_view_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Full Video Download Count"
									label="Full Video Download Count"
									value={userPreferences.settings.full_video_download_count.value}
									indexArray={['settings', 'full_video_download_count', 'value']}
									name="full_video_download_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Highlight Video Streaming Count"
									label="Highlight Video Streaming Count"
									value={userPreferences.settings.highlight_video_view_count.value}
									indexArray={['settings', 'highlight_video_view_count', 'value']}
									name="highlight_video_view_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

								<CustomCheckbox
									tooltipText="Highlight Video Download Count"
									label="Highlight Video Download Count"
									value={userPreferences.settings.highlight_video_download_count.value}
									indexArray={['settings', 'highlight_video_download_count', 'value']}
									name="highlight_video_download_count"
									parentState="userPreferences"
									handleChandgeCheckBox={this.props.handleSearchCheckBoxPro}
								/>

							</div>

							<div className="col-md-12 text-right">
								{/*<button onClick={() => this.props.hide()} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>*/}
								<button onClick={ ()=>this.props.performSearch() } className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>
								<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeStatusForSearch(true, 'userPreferences')}>Select All</button>
								<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeStatusForSearch(false, 'userPreferences')}>Unselect All</button>
								<button className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm" onClick={() => this.props.changeToDefault()}>Default</button>


							</div>
						</div>
					</React.Fragment>
				}
			/>
		);
	};
}



export default UserPreferences;
