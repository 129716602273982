import React, { Component } from "react";
import { Grid, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { hasPermission } from "helper/hasPermission";
import { themeCSSConstants } from "redux/constant/themeCSS";
import Pagination from "components/ThemeComponents/Pagination";
import MyImage from "components/Upload/MyImage";
import { ThemeFunctions as TF } from "helper/ThemeFunctions";
import { helperFunctions as HF } from 'helper/helperFunctions';
import { openModal, hasRole } from "helper/helperFunctions";
import { Card } from "components/Card/Card.jsx";
import { connect } from "react-redux";
import { getAllSMS, addSMS } from "../../../redux/actions/comment-actions";
import { withRouter } from "react-router-dom";
import { VscDiffAdded } from "react-icons/vsc";
import ReactValidator from "simple-react-validator";
import { FaImage } from "react-icons/fa";
import SimpleReactValidator from 'simple-react-validator';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import replyIcon from '../../../assets/media/forward.png';
import dummy from '../../../assets/media/dummy.png';
var dateFormat = require("dateformat");
class SMSMessages extends Component {
    constructor(props) {
        super(props);
        const { pathname } = this.props.location;
        this.validator = new ReactValidator();
        this.validatorBrand = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            showMessageButton: false,
            replyToReply: false,
            replyTo: null,
            groupLeaderId: 0,
            receiverNumber: 0,
            groupLeaders: [],
            isReply: null,
            orderId: this.props.order_id,
            sms: "",
            filtered: "",
            pageSize: 20,
            to: "",
            from: "",
            commentAddFormModal: false,
            commentEditFormModal: false,
            comment: {
                comment: "",
                comment_category_id: this.props.comment_category_id,
                slug: "",
                uuid: this.props.withUuid ? this.props.order_details.uuid : ""
            },
            brand: {
				resource_file: "",
			},
            smsImage: "",
            showSendMessageModal: false,

            collapseLog:
                this.props.collapseCard !== undefined
                    ? this.props.collapseCard
                    : true,
            collapsable: this.props.collapseCard !== undefined ? true : false,
            customerServiceDetailShow:
                pathname === `/Admin/${this.props.order_id}/order-edit` ? true : false,
            selectedOption: null,
            stepContentModal: false,
            formType: this.props.formType || "",
            smsData: [],
        };
        this.handleShowSendMessageModal = this.handleShowSendMessageModal.bind(
            this
        );
        this.getAllSMS = this.getAllSMS.bind(this);
        this.setResourceFile = this.setResourceFile.bind(this);
        this.handleClickReply = this.handleClickReply.bind(this);
        this.displayName = this.displayName.bind(this);
    }

    smsChangeHandler = e => {
        const value = e.target.value;
        this.setState({sms: value});
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.collapseLog !== prevState.collapseLog) {
            
            if (this.state.collapseLog) {
                this.setState({ showMessageButton: true})
            } else {
                this.setState({ showMessageButton: false})
            }
        }
    }


    removeFile = (key, value) => {
		this.setState(prevState => ({
			brand: {
				...prevState.brand,
				resource_file: ''
			}
		}));
	}

    handleGroupLeaderSelect = (event) => {
        if(event.target.value != 0){
            const leaderId = event.target.value;
            const leaderInfo = this.state.groupLeaders.find(item => item.id === parseInt(leaderId));
            if(leaderInfo.phone2 && leaderInfo.phone2 !== "") {
                this.setState({receiverNumber: leaderInfo.phone2, groupLeaderId: leaderId});
            }
            console.log('Selected Group Leader Info:', leaderInfo);
        }else{
            this.setState({groupLeaderId: 0});
        }
    };
    handleShowSendMessageModal() {
        this.setState({
            brand: {
                resource_file: "",
            }
        })
        this.setState({sms: ""});
        this.setState({groupLeaderId: 0});
        this.setState({replyTo: null});
        this.setState({replyToReply: false});
        this.setState({
            showSendMessageModal: !this.state.showSendMessageModal
        });
    }

    handleClickReply(messageId) {
        console.log(messageId);
        this.setState({
            brand: {
                resource_file: "",
            }
        })
        this.setState({sms: ""});
        this.setState({groupLeaderId: 0});
        this.setState({
            showSendMessageModal: false
        });
        this.setState({replyTo: null});
        this.setState({ replyTo: this.state.replyTo === messageId ? null : messageId });
        this.setState({replyToReply: true});
        this.setState({ isReply: messageId });
    }

    commentEditSubmitHandler = (e = null) => {
        e.preventDefault();
        const { receiverNumber, groupLeaderId, brand, replyTo, replyToReply } = this.state;
        if (this.validator.allValid()) {
            this.setState({is_reply : null});
            this.props
                    .addSMSCall({
                        "groupLeaderId": groupLeaderId,
                        "to_phone_number" : receiverNumber,
                        "image" : brand.resource_file,
                        "text_content" : this.state.sms,
                        "is_reply" : this.state.isReply,
                        "reply_to_reply" : replyToReply
                    }, this.state.orderId)
                    .then(response => {
                        if (this.pagination) {
                            this.pagination.dataCall();
                        }
                        this.getAllSMS();
                        this.setState(prevState => ({
                            sms: "",
                            brand: {
                                ...prevState.brand,
                                resource_file: ""
                            }
                        }));
                });
            } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    collapseHandle(field) {
        this.setState({
            collapseLog: !this.state.collapseLog
        });
    }

    getAllSMS() {
        const orderId = this.state.orderId;
        this.props.getAllSMSCall(orderId).then(response => {
            if (response && response.data) {
                console.log(response.data);
                this.setState({ smsData: response.data.pagination });
                this.setState({groupLeaders: response.data.group_leaders})
            }
        });
    }

    extractImageData =  (value) =>  {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
    setResourceFile = (key, value) => {
		this.setState({ brand: { resource_file: this.extractImageData(value) } })
	}

    displayName = (message) => {
        if(message.gl_id === 1){
            let userData = localStorage.getItem("user");
            console.log(userData);
            if (userData) {
                    const user = JSON.parse(userData).user;
                    console.log(user.name);
                    return user.name;
            }
        }
        // let leader = this.state.groupLeaders.find(group => {
        //     return group.id === message.gl_id;
        // });
        // let leaderFirstName = (leader && leader.first_name) ? leader.first_name : "";
        // let leaderLastName = (leader && leader.last_name) ? leader.last_name : "";
        let leaderFirstName = "";
        let leaderLastName = "";
        if(message.group_leader_contact && message.group_leader_contact.first_name.length >= 1) {
            leaderFirstName = message.group_leader_contact.first_name;
        }
        if(message.group_leader_contact && message.group_leader_contact.first_name.length >= 1) {
            leaderLastName = " " + message.group_leader_contact.last_name;
        }
        return leaderFirstName + leaderLastName ;
    }

    componentDidMount() {
        if (this.props.code === "ContactLog") {
            this.props.getContactLogTypesCall(this.props.modelId);
        }
        console.log("Mounted");
        this.getAllSMS();

        if (this.state.collapseLog) {
            this.setState({showMessageButton: true});
        }
    }
    render() {
        const { collapseLog, collapsable, showSendMessageModal, groupLeaders, groupLeaderId, showMessageButton } = this.state;
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const { brand } = this.state;
        const { replyTo } = this.state;
        return (
            <Row
                id="sms-messages"
                style={{
                    marginTop: "10px"
                }}
            >
                <Col md={12}>
                    <div
                        style={{
                            float: "right",
                            marginRight: "18px",
                            position: "absolute",
                            right: "5px",
                            top: "12px",
                            zIndex: "12",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                    {
                        showMessageButton &&
                        <button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "inherit",
                                gap: 5
                            }}
                            id="add_message_button"
                            onClick={this.handleShowSendMessageModal}
                        >
                            <VscDiffAdded
                            style={{
                                fontSize: "x-large"
                            }}
                        />
                            New Message
                        </button>
                    }
                    </div>
                    <Card
                        ctTableResponsive
                        bsClass={[
                            "messagesCard",
                            "relativeCard",
                            "messages-head"
                        ]}
                        collapsHandler={() => {
                                // this.setState({
                                //     showMessageButton: !showMessageButton
                                // });
                                this.collapseHandle("collapseLog")
                            }
                        }
                        collapsable={collapsable}
                        title="SMS Messages"
                        collapseTrigger={collapseLog}
                        content={
                            <div>
                                <Row>
                                    <Col md={12}>
                                    {showSendMessageModal && (
                                        <Col md={12}>
                                            <div
                                                className={
                                                    "flexElem alignCenter mt-md"
                                                }
                                            >
                                                <div
                                                    style={{
                                                        flexBasis: "100%"
                                                    }}
                                                >
                                                    <textarea
                                                        name="comment"
                                                        onChange={
                                                            this
                                                                .smsChangeHandler
                                                        }
                                                        className="form-control "
                                                        value={
                                                            this.state.sms
                                                        }
                                                    />
                                                    {this.validator.message(
                                                        "comment",
                                                        this.state.sms,
                                                        "required"
                                                    )}
                                                </div>
                                                <select
                                                    style={{
                                                        height: "70px",
                                                        color: "white",
                                                        borderRadius: "10px",
                                                        background: "rgb(47, 69, 118)",
                                                        marginLeft: "12px"
                                                    }}
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    onChange={this.handleGroupLeaderSelect}
                                                >
                                                    <option value="0" selected>Select Group Leader</option>
                                                    {
                                                        groupLeaders && groupLeaders.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id}>{item.first_name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between"
                                                        }}
                                                        className="text-center"
                                                    >

                                                        <button
                                                            ref={btn =>
                                                                (this.addBtn = btn)
                                                            }
                                                            style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0
                                                            }}
                                                            name="add"
                                                            type="button"
                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                            onClick={
                                                                this
                                                                    .commentEditSubmitHandler
                                                            }
                                                        >
                                                            <div className="pofilePicContainer smsAvatar">
                                                                {
                                                                    !brand.resource_file ?
                                                                        <div className='form-group'>
                                                                            <FileUpload
                                                                                files={brand.resource_file}
                                                                                setResourceFile={this.setResourceFile}
                                                                                allowedFormatsStringMimePro={'image/jpeg, image/png'}
                                                                                allowedFormatsStringExtPro={'jpg, png'}
                                                                                multiple={false}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <ImagePreview
                                                                            filesToPreview={brand.resource_file}
                                                                            removeFile={this.removeFile}
                                                                        />
                                                                }
                                                                {
                                                                    this.validatorBrand.message('Logo', brand.resource_file, 'required')
                                                                }
                                                            </div>
                                                        </button>
                                                        <button
                                                            disabled={groupLeaderId === 0}
                                                            ref={btn =>
                                                                (this.addBtn = btn)
                                                            }
                                                            style={{
                                                                height: "70",
                                                                borderRadius:
                                                                    "9px"
                                                            }}
                                                            name="add"
                                                            type="button"
                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                            onClick={
                                                                this
                                                                    .commentEditSubmitHandler
                                                            }
                                                        >
                                                            Send MMS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                                </Col>
                                            )}
                                                <div class="container">
                                                    <div class="content-wrapper">
                                                        <div class="row gutters">
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="card messages-card m-0">
                                                                    <div class="row no-gutters" style={{
                                                                                    marginBottom: "30px"
                                                                                }}>
                                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                            <div class="chat-container" >
                                                                                <ul class="chat-box chatContainerScroll">
                                                                                {
                                                                                    this.state.smsData.length > 0 && this.  state.smsData.map((message, index) => (
                                                                                        <>
                                                                                <li key={index} className="chat-box-list-item">
                                                                                <div  className="chat-left">
                                                                                        <div className="chat-avatar">
                                                                                            {
                                                                                                message.s_m_s_resources ?
                                                                                                <img
                                                                                                src={message.s_m_s_resources.file_path}
                                                                                                alt="Retail Admin"
                                                                                            />
                                                                                                :
                                                                                                <img
                                                                                                src={dummy}
                                                                                                alt="Retail Admin"
                                                                                            />
                                                                                            }

                                                                                            <div className="chat-name">
                                                                                                {this.displayName(message)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="chat-text">
                                                                                            <div className="message-content">
                                                                                                <p>
                                                                                                    {this.displayName(message)}{" "}{"("}{message.to}{")"}
                                                                                                </p>
                                                                                                <p>
                                                                                                    {message.content}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="chat-hour">
                                                                                            <div>
                                                                                                {HF.getFullDate(message.created_at)}{" "}
                                                                                            </div>
                                                                                            <div>
                                                                                                <button onClick={()=>{this.handleClickReply(message.id)}} style={{
                                                                                                    textDecoration: "none",
                                                                                                    border: "none",
                                                                                                    background: "inherit",
                                                                                                    height: "40px",
                                                                                                    width: "47px"
                                                                                                }}>
                                                                                                    <img
                                                                                                        style={{width:20}}
                                                                                                        className="forward-icon"
                                                                                                        src={replyIcon}
                                                                                                        alt="Reply"
                                                                                                        />
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                </div>
                                                                                        {replyTo === message.id && (
                                                                                            <div
                                                                                                className={
                                                                                                    "flexElem alignCenter replyMessage"
                                                                                                }>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexBasis: "100%"
                                                                                                    }}
                                                                                                >
                                                                                                    <textarea
                                                                                                        name="comment"
                                                                                                        onChange={
                                                                                                            this
                                                                                                                .smsChangeHandler
                                                                                                        }
                                                                                                        className="form-control "
                                                                                                        value={
                                                                                                            this.state.sms
                                                                                                        }
                                                                                                    />
                                                                                                    {this.validator.message(
                                                                                                        "comment",
                                                                                                        this.state.sms,
                                                                                                        "required"
                                                                                                    )}
                                                                                                </div>

                                                                                                <div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent:
                                                                                                                "space-between"
                                                                                                        }}
                                                                                                        className="text-center"
                                                                                                    >
                                                                                                        <button
                                                                                                            ref={btn =>
                                                                                                                (this.addBtn = btn)
                                                                                                            }
                                                                                                            style={{
                                                                                                                height: "70",
                                                                                                                borderRadius:
                                                                                                                    "9px"
                                                                                                            }}
                                                                                                            name="add"
                                                                                                            type="button"
                                                                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .commentEditSubmitHandler
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="pofilePicContainer smsAvatar">
                                                                                                                {
                                                                                                                    !brand.resource_file ?
                                                                                                                        <div className='form-group'>
                                                                                                                            <FileUpload
                                                                                                                                files={brand.resource_file}
                                                                                                                                setResourceFile={this.setResourceFile}
                                                                                                                                allowedFormatsStringMimePro={'image/jpeg, image/png'}
                                                                                                                                allowedFormatsStringExtPro={'jpg, png'}
                                                                                                                                multiple={false}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <ImagePreview
                                                                                                                            filesToPreview={brand.resource_file}
                                                                                                                            removeFile={this.removeFile}
                                                                                                                        />
                                                                                                                }
                                                                                                                {
                                                                                                                    this.validatorBrand.message('Logo', brand.resource_file, 'required')
                                                                                                                }
                                                                                                            </div>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            ref={btn =>
                                                                                                                (this.addBtn = btn)
                                                                                                            }
                                                                                                            style={{
                                                                                                                height: "70",
                                                                                                                borderRadius:
                                                                                                                    "9px"
                                                                                                            }}
                                                                                                            name="add"
                                                                                                            type="button"
                                                                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .commentEditSubmitHandler
                                                                                                            }
                                                                                                        >
                                                                                                            Send Reply
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                </li>

                                                                                {message.replies.length > 0 && message.replies.map((reply, index) => (
                                                                                    <div>
                                                                                    <li class="chat-left">
                                                                                        <div class="chat-avatar">
                                                                                            {
                                                                                                        message.s_m_s_resources ?
                                                                                                        <img
                                                                                                        src={message.s_m_s_resources.file_path}
                                                                                                        alt="Retail Admin"
                                                                                                    />
                                                                                                        :
                                                                                                        <img
                                                                                                        src={dummy}
                                                                                                        alt="Retail Admin"
                                                                                                    />
                                                                                                    }
                                                                                                {/* <img
                                                                                                    src={reply.created_by.user_resources.file_path}
                                                                                                    alt="Retail Admin"
                                                                                                /> */}
                                                                                                <div class="chat-name">
                                                                                                    {this.displayName(message)}
                                                                                                </div>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                textAlign:
                                                                                                    "left"
                                                                                            }}
                                                                                            class="chat-text"
                                                                                        >

                                                                                            <div className="message-content">
                                                                                                <p>
                                                                                                {this.displayName(message)}{" "}{"("}{reply.to}{")"}
                                                                                                </p>
                                                                                                <p>
                                                                                                    {reply.content}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="chat-hour">
                                                                                            <div>
                                                                                                {HF.getFullDate(reply.created_at)}{" "}
                                                                                            </div>
                                                                                            <div>
                                                                                                <button onClick={()=>{this.handleClickReply(reply.id, true)}} style={{
                                                                                                    textDecoration: "none",
                                                                                                    border: "none",
                                                                                                    background: "inherit",
                                                                                                    height: "40px",
                                                                                                    width: "47px"
                                                                                                }}>
                                                                                                    <img
                                                                                                        style={{width:20}}
                                                                                                        className="forward-icon"
                                                                                                        src={replyIcon}
                                                                                                        alt="Reply"
                                                                                                        />
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                <div>
                                                                                {replyTo === reply.id && (
                                                                                            <div
                                                                                                className={
                                                                                                    "flexElem alignCenter replyMessage"
                                                                                                }>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexBasis: "100%"
                                                                                                    }}
                                                                                                >
                                                                                                    <textarea
                                                                                                        name="comment"
                                                                                                        onChange={
                                                                                                            this
                                                                                                                .smsChangeHandler
                                                                                                        }
                                                                                                        className="form-control "
                                                                                                        value={
                                                                                                            this.state.sms
                                                                                                        }
                                                                                                    />
                                                                                                    {this.validator.message(
                                                                                                        "comment",
                                                                                                        this.state.sms,
                                                                                                        "required"
                                                                                                    )}
                                                                                                </div>

                                                                                                <div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent:
                                                                                                                "space-between"
                                                                                                        }}
                                                                                                        className="text-center"
                                                                                                    >
                                                                                                        <button
                                                                                                            ref={btn =>
                                                                                                                (this.addBtn = btn)
                                                                                                            }
                                                                                                            style={{
                                                                                                                height: "70",
                                                                                                                borderRadius:
                                                                                                                    "9px"
                                                                                                            }}
                                                                                                            name="add"
                                                                                                            type="button"
                                                                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .commentEditSubmitHandler
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="pofilePicContainer smsAvatar">
                                                                                                                {
                                                                                                                    !brand.resource_file ?
                                                                                                                        <div className='form-group'>
                                                                                                                            <FileUpload
                                                                                                                                files={brand.resource_file}
                                                                                                                                setResourceFile={this.setResourceFile}
                                                                                                                                allowedFormatsStringMimePro={'image/jpeg, image/png'}
                                                                                                                                allowedFormatsStringExtPro={'jpg, png'}
                                                                                                                                multiple={false}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <ImagePreview
                                                                                                                            filesToPreview={brand.resource_file}
                                                                                                                            removeFile={this.removeFile}
                                                                                                                        />
                                                                                                                }
                                                                                                                {
                                                                                                                    this.validatorBrand.message('Logo', brand.resource_file, 'required')
                                                                                                                }
                                                                                                            </div>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            ref={btn =>
                                                                                                                (this.addBtn = btn)
                                                                                                            }
                                                                                                            style={{
                                                                                                                height: "70",
                                                                                                                borderRadius:
                                                                                                                    "9px"
                                                                                                            }}
                                                                                                            name="add"
                                                                                                            type="button"
                                                                                                            className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm"
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .commentEditSubmitHandler
                                                                                                            }
                                                                                                        >
                                                                                                            Send Reply
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    </div>


                                                                                ))}
                                                                                </>

                                                                            ))
                                                                        }
                                                                        {
                                                                            this.state.smsData.length <= 0 && (<p style={{
                                                                                textAlign: 'center'
                                                                            }}>No SMS Found</p>)
                                                                        }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                    />
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllSMSCall: ( page, filter, sort, pageSize, cat_id, modelId, from, to, uuid ) => { return dispatch( getAllSMS( page, filter, sort, pageSize, cat_id, modelId, from, to, uuid ) );},
        addSMSCall: (data, orderId) => { return dispatch(addSMS(data, orderId)); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(SMSMessages));
