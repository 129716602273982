import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { avatar, searchIcon } from "../../../assets/media/chat/ChatIcons";
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function TravelersList(props) {
    const [travelersList, setTravelersList] = useState([]);
    const [travelersListClone, setTravelersListClone] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsCLone, setGroupsClone] = useState([]);
    const [selectedTab, setTab] = useState(0);
    const [searchQuery, setQuery] = useState('');

    useEffect(() => {
        setTravelersList([...props.travelersList]);
        setTravelersListClone([...props.travelersList]);
    }, [props.travelersList]);

    useEffect(() => {
        if (props.groups) {
            setGroups([...props.groups]);
            setGroupsClone([...props.groups]);
        }
    }, [props.groups]);

    function searchUser(e) {
        const { value } = e.target;
        setQuery(value);
        if (value) {
            const temp = travelersListClone.filter(tvl => {
                const name = tvl.firstName.toLowerCase() + ' ' + tvl.lastName.toLowerCase();
                if (name.includes(value.toLowerCase().trim()))
                    return true;
            })
            setTravelersList(temp);
        } else {
            setTravelersList(travelersListClone);
        }
    }

    function travelerSelected(traveler) {
        traveler.type === 'sender' ? props.setSelectedSender(traveler) : props.setSelectedReceiver(traveler);
    }

    function groupSelected(group) {
        props.setSelectedGroup(group);
    }

    function searchGroups(e) {
        const { value } = e.target;
        setQuery(value);
        if (value) {
            const temp = groupsCLone.filter(group => {
                const name = group.group_name.toLowerCase();
                if (name.includes(value.toLowerCase().trim()))
                    return true;
            })
            setGroups(temp);
        } else {
            setGroups(groupsCLone);
        }
    }

    function setTabIndex(index) {
        setTab(index);
        if (index !== selectedTab) {
            setQuery('');
            setGroups(groupsCLone);
            setTravelersList(travelersListClone);
        }
    }

    return (
        <div id="travelers-list-container">
            <h2 id="chat-header">{props.header}</h2>
            <div className="chat-search-field">
                <input type="text"
                    placeholder="Search Travelers"
                    className="form-control"
                    onChange={(e) => { selectedTab === 0 ? searchUser(e) : searchGroups(e) }}
                    value={searchQuery}
                />
            </div>
            <Tabs selectedIndex={selectedTab} onSelect={index => { setTabIndex(index); if (index !== selectedTab) setQuery('') }} >
                <TabList className="chat-tabs-list" style={{paddingLeft: 0}}>
                    <Tab id="details" style={{ fontSize: 17 }}>
                        <span>All Chat</span>
                    </Tab>
                    {
                        props.type === 'receivers' &&
                        <Tab id="details" style={{ fontSize: 17 }}>
                            <span>Groups</span>
                        </Tab>
                    }
                </TabList>
                <TabPanel>
                    <div>
                        <div>
                            {
                                travelersList.length > 0 ?
                                    travelersList.map(tvl => {
                                        return <div
                                            className={`chat-user-info ${tvl.selected ? 'selected' : ''}`}
                                            key={tvl.id}
                                            onClick={() => { travelerSelected(tvl) }}>
                                            <div>
                                                <img src={avatar} alt="avatar" />
                                            </div>
                                            <div>{tvl.firstName} {tvl.lastName}</div>
                                        </div>
                                    })
                                    : 'No User Found!'
                            }
                        </div>
                    </div>
                </TabPanel>
                {
                    props.type === 'receivers' &&
                    <TabPanel>
                        <div className="">
                            <div>
                                {
                                    groups.length > 0 ?
                                        groups.map(group => {
                                            return <div
                                                className={`chat-user-info ${group.selected ? 'selected' : ''}`}
                                                key={group.id}
                                                onClick={() => { groupSelected(group) }}>
                                                <div>
                                                    <img src={avatar} alt="avatar" />
                                                </div>
                                                <div>{group.group_name}</div>
                                            </div>
                                        })
                                        :
                                        'No Groups Found!'
                                }
                            </div>
                        </div>
                    </TabPanel>
                }
            </Tabs>
            <div>
                {
                    props.type !== 'receivers' &&
                    // <div>
                    //     <div>
                    //         <div>
                    //             <input type="text" placeholder="Search Travelers" className="form-control" onChange={searchUser} />
                    //         </div>
                    //         <div>
                    //             {
                    //                 travelersList.length > 0 &&
                    //                 travelersList.map(tvl => {
                    //                     return <div className="flex"
                    //                         style={{ background: `${tvl.selected ? 'tomato' : ''}` }} key={tvl.id}
                    //                         onClick={() => { travelerSelected(tvl) }}>
                    //                         <div>
                    //                             <img src={avatar} alt="avatar" />
                    //                             {/* <img src={require('../../../assets/media/chat/avatar.svg').default} alt="avatar" /> */}
                    //                         </div>
                    //                         <div>{tvl.firstName} {tvl.lastName}</div>
                    //                     </div>
                    //                 })
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                    <span></span>
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return ({
        // errorAlert: (e) => {
        //     dispatch(alertActions.error(e))
        // },
        // successAlert: (msg) => {
        //     dispatch(alertActions.success(msg))
        // },
    });
};

export default withRouter(connect(null, mapDispatchToProps)(TravelersList));
