import { SC } from 'helper/ServerCall';

export const smsServices = {
    getSMSPagination,
    getOrderWithSMS
}
function getSMSPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType, is_active, searchData) {
    filterCompanyType = (typeof filterCompanyType !== 'undefined') && (filterCompanyType !== null) ? filterCompanyType : '';
    filterProgramType = (typeof filterProgramType !== 'undefined') && (filterProgramType !== null) ? filterProgramType : '';
    return SC.postCall('get_sms?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&agency_type_id=' + filterCompanyType + '&program_type_id=' + filterProgramType + '&is_active='+is_active, searchData);
}

function getOrderWithSMS(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) {
    return SC.postCall(`get_order_with_sms?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&brand_id=${brand_id}&orders_type=${orders_type}`, advancedSearchFilters);
}
