import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../Card/Card'
import { getReOrdersForProduction } from '../../../redux/actions/order-actions'
import { toggleModal, selectChangeHandler, createSortStringReOrder } from '../../../helper/helperFunctions'
import Pagination from '../../ThemeComponents/Pagination'
import { getAllShippingMethods } from '../../../redux/actions/shippingMethod-actions'
import { cloneDeep, orderBy } from 'lodash'
import { baseUrl } from '../../../helper/ApiCall'
import DownloadFile from 'components/ThemeComponents/downloadFile';
import { orderService } from '../../../services/order'
import { alertActions } from '../../../redux/actions/alert-actions'
import { editComment, addComment, getCommentCategoryByTitle } from '../../../redux/actions/comment-actions'
import ThemeModal from '../../ThemeComponents/ThemeModal'
import ShipStatusForm from '../Order/ShipStatusForm'
var moment = require("moment");
class ReorderListForProduction extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageSize: 15,
            shipModal: false,
            orderId: '',
            showAll: false,
            ReOrders_total: 0,
            shippindMethods: [],
            editModal: false,
            editComment: {},
            reorder_type: 1,
            shippingDetails: {
                shipping_method_id: 4
            },
            comment: {
                comment: '',
                comment_category_id: 0
            },
            addCommentModel: false,
            orderCommentId: ''
        }
        this.toggleModal = toggleModal.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAll !== this.state.showAll) {
            this.paginationCall(1, '', '', this.state.pageSize);
        }
        if (this.props.ReOrders !== prevProps.ReOrders) {
            this.setState({
                ReOrders_total: this.props.ReOrders_total
            })
        }
        if (this.props.singleCommentCategory !== prevProps.singleCommentCategory) {
            const comment = cloneDeep(this.state.comment);
            comment.comment_category_id = this.props.singleCommentCategory.comment_category_id;
            this.setState({
                comment: comment
            })
        }
    }

    componentDidMount() {
        this.props.getAllShippingMethodsCall().then(
            res => {
                this.setState({
                    shippindMethods: res.data
                })
            }
        );
        this.props.getCommentCategoryByTitle(['Admin']);
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = (data.sort.length == 0 || data.sort == '') ? createSortStringReOrder(sorted,'id','desc'): createSortStringReOrder(sorted);

    return this.props.getReOrdersForProduction(data.page, data.pageSize, data.filter, sort, orderType, this.props.orderId, this.state.reorder_type);
}

    getRowProps = (rowInfo) => {
        let data = {
            color: 'black'
        }
        if (rowInfo !== undefined) {
            // if (rowInfo.note !== null && rowInfo.note) {
            //     data = {
            //         color: 'red'
            //     }
            // } 
            // else if (rowInfo.settings.length) {
            //     if (rowInfo.settings[0].value === '1') {
            //         data = {
            //             color: '#777'
            //         }
            //     }
            // } else {
            //     data = {
            //         color: '#999'
            //     }
            // }
        }
        return data;
    }

    downloadEditedImages = (id) => {
        const link = `${baseUrl}downloadEditedImages/${id}`;
        window.open(link);
    }

    finalize = (status, flag, id) => {
        var settingData = {
            value: status,
            flag: flag,
            id: id
        };
        orderService.reorderFinalized(id, settingData).then(response => {
            if (response.status === 'success') {

                this.props.success(response.message);
                this.pagination.dataCall();

            } else if (response.status === 'error') {
                this.props.error(response.status);
            }
        });
	}

    addComment = (id) => {
        this.setState({
            addCommentModel: true,
        },()=> {
            this.setState({
                orderCommentId: id
            })
        })
    }

    storeCommentChangehandler = (e) => {
        const comment = { ...this.state.comment}
        comment.comment = e.target.value;
        this.setState({
            comment: comment
        })
    }

    storeComment = () => {
        const comment = { ...this.state.comment};
        if (comment.comment !== '') {
            this.props.addCommentCall(comment, this.state.orderCommentId).then(
                res => {
                    this.setState({
                        addCommentModel: false
                    });
                    this.pagination.dataCall();
                }
            );
        }
    }

    editComment = (comment) => {
        this.setState({
            editComment: comment,
        }, () => {
            this.setState({
                editModal: true
            })
        })
    }


    commentChangeHandler = (e) => {
        const comment = { ...this.state.editComment }
        comment.comment = e.target.value;
        this.setState({
            editComment: comment
        })
    }

    saveComment = () => {
        const data = this.state.editComment;
        this.props.editComment(data, data.id).then(
            res => {
                this.setState({
                    editModal: false
                });
                this.pagination.dataCall();
            }
        );
    }

    render() {
        const { ReOrders, shippingSettings } = this.props;
        const columns = [
            {
                Header: 'Reorders List',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Reorder #',
                        accessor: 'id',
                        className: 'action-center flex-wrap',
                        excExp: true,
                        Cell: ({ value }) => {
                            return (
                                <div id={value}>
                                    {
                                        !('inTab' in this.props) ?
                                            <span>{value} </span> :
                                            <Link className='blue_link' target='_blank' to={{
                                                pathname: `/Admin/reorders-list`,
                                                hash: `#${value}`
                                            }}>{value}</Link>
                                    }
                                    <br />
                                </div>
                            )
                        },
                        width: 170,
                        order: 1
                    },
                    {
                        Header: 'Original Barcode#',
                        accessor: 'parent_order.barcode',
                        className: 'action-center',
                        width: 200,
                        excExp: true,
                        Cell: ({ original, value }) => {
                            return (
                                <Link target='_blank' className='blue_link' to={`/Admin/${original.parent_order.id}/order-edit?orderDetailCode=production&imageCode=qa`}>{value}</Link>
                            )
                        },
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: 'Shipping Information',
                        accessor: 'video_shipping_address, group_leader_contact',
                        className: 'action-center',
                        excExp: true,
                        sortable: false,
                        Cell: ({ original }) => {
                            return <span>{original.group_leader_contact.first_name}<br />{original.video_shipping_address.street_address_1}<br />{original.video_shipping_address.city}, {original.video_shipping_address.state && original.video_shipping_address.state.title ? original.video_shipping_address.state.title : ''} {original.video_shipping_address.zipcode ? original.video_shipping_address.zipcode : ''}</span>
                        },
                        csvMod: true,
                        show: !('inTab' in this.props),
                        width: 220
                    },
                    // {
                    //     Header: "Contact Info",
                    //     accessor: 'group_leader_contact',
                    //     className: 'action-center',
                    //     excExp: true,
                    //     Cell: ({ value }) => {
                    //         return (
                    //             ('inTab' in this.props) ?
                    //                 <span>{value.first_name} {value.last_name}</span>
                    //                 :
                    //                 <span>{value.phone1} <br />{value.email}</span>
                    //         )
                    //     },
                    //     csvMod: true,
                    //     sortable: false,
                    //     order: 2,
                    //     width: 200
                    // },
                    {
                        Header: 'Products',
                        accessor: 'order_items',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            return <div style={{ flexWrap: 'wrap' }}>
                                {value.map(item => {
                                    let is_ttk = original.agency.accounts[0].domain_slug === 'ttk' ? true : false;
                                    if ((!is_ttk && item.item.title !== 'Flash Drive with Gallery Photos') ||
                                        (is_ttk && item.item.title !== 'Flash Drive with Video and Photos' &&
                                            item.item.title !== 'Keepsake Video on DVD')){
                                        return (
                                            <React.Fragment key={item.id}>
                                                <span>{item.item.title} = <span className='dangerText'>{item.quantity}</span></span> <br />
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </div>
                        },
                        id: 'order_items.total_count',
                        csvMod: true,
                        width: 200
                    },
                    {
                        Header: 'Production Time',
                        accessor: 'orderTotals.production',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            const item = shippingSettings.find(item => {
                                return parseFloat(item.value) === parseFloat(original.orderTotals.production.value)
                            });
                            return (
                                <div style={{ flexWrap: 'wrap' }} className={(item.key !== 'normal_production' ? ' dangerText' : '')}>
                                    <span className={`text-uppercase ` + (item.key !== 'normal_production' ? 'font-weight-bold' : '')}>{item.key.replace(/_/g, ' ')}</span>
                                    <br />
                                    <span>{item.description}</span>
                                </div>
                            )
                        },
                        csvMod: true,
                        sortable: false,
                        width: 200
                    },
                    {
                        Header: 'Overnight',
                        accessor: 'shippingSettings',
                        className: 'action-center',
                        excExp: true,
                        Cell: ({ value, original }) => {
                            const item = shippingSettings.find(item => {
                                let res
                                original.orderTotals.overnight ?
                                    res = parseFloat(item.value) === parseFloat(original.orderTotals.overnight.value)
                                :
                                    res = ''
                                return res
                            });
                            if (('overnight' in original.orderTotals) && Number(original.orderTotals.overnight.value) > 0) {
                                return (
                                    ('overnight' in original.orderTotals) ?
                                        <div style={{ flexWrap: 'wrap' }} className={Number('payment_made' in original.orderSettings && Number(original.orderSettings.payment_made.value) === 1) ? 'dangerText' : ''}>
    
                                            <span className="text-uppercase font-weight-bold">
                                                
                                                {   item.key === 'overnight_production' ?
                                                        'Overnight Shipping'
                                                    :
                                                        item.key.replace(/_/g, ' ')}
                                            </span>
                                            <br />
                                            <span>{item.description}</span>
                                        </div>
                                    :
                                        ''
    
                                )
                            } else  {
                                return null;
                            }
                        },
                        csvMod: true,
                        sortable: false,
                        // show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: 'Original Producer',
                        accessor: 'parent_order.producer.name',
                        className: 'action-center',
                        excExp: true,
                        id: 'parentOrder.producer',
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: 'Date Ordered',
                        accessor: 'payment_made',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            if ('payment_made' in original.orderSettings) {
                                return moment(original.orderSettings.payment_made.created_at).format('MMMM D Y')
                            } else {
                                return moment(original.created_at).format('MMMM D Y')
                            }
                        },
                        sortable: false,
                        excExp: true,
                        order: 4
                    },
                    {
                        Header: 'Video Download',
                        accessor: 'date_shipped',
                        className: 'action-center',
                        Cell: ({ original }) => {
                            const { full_video } = original.order_resources;
                            if (full_video) {
                                return (
                                    <DownloadFile
                                    title="Download"
                                    multiple={false}
                                    files={{
                                        'file_title': full_video.file_title,
                                        'file_path': full_video.file_path
                                    }}
                                    extraClass="ml-sm"
                                    />
                                );
                            } else {
                                return null; // or an alternative component/message
                            }
                        },
                        order: 5,
                        width: 170
                    },
                    {
                        Header: 'Edited Images Download',
                        accessor: 'tracking_url',
                        className: 'action-center ',
                        minWidth: 220,
                        Cell: ({ value, original }) => {
                            return (
                                <button className='orderDetailsAction downloadIcon' onClick={() => {this.downloadEditedImages(original.parent_order.id)}}>Download</button>
                            )
                        },
                        show: !('inTab' in this.props)
                    },
                    {
                        Header: 'Mozaix Download',
                        accessor: 'id',
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            const { mosaic_file } = original.order_resources;
                            if (mosaic_file) {
                                return (
                                    <DownloadFile
                                    title="Download"
                                    multiple={false}
                                    files={{
                                        'file_title': mosaic_file.file_title,
                                        'file_path': mosaic_file.file_path
                                    }}
                                    extraClass="ml-sm"
                                    />
                                );
                            } else {
                                return null; // or an alternative component/message
                            }
                        },
                        show: !('inTab' in this.props),
                        width: 200
                    },
                    {
                        Header: 'Finalize button',
                        className: 'action-center',
                        accessor: 'paid_online',
                        Cell: ({ value, original }) => {
                            return (
                                <button type="button" className="orderDetailsAction finalizeIcon ml-md" onClick={() => this.finalize(1, 'reorder_finalized', original.id)} >Finalize</button>
                            )
                        },
                        order: 3
                    },
                    {
                        Header: 'Reorder Admin Comments',
                        accessor: 'comments[0].comment',
                        className: 'action-center',
                        excExp: true,
                        sortable: false,
                        getProps: () => {
                            return {
                                style: {
                                    flexDirection: 'column'
                                }
                            }
                        },
                        Cell: ({ value, original }) => {
                            if (value) {
                                return (
                                    <React.Fragment>
                                        <div>{value}</div>
                                        <button className='customBtn btn btn-primary btn btn-default' onClick={() => { this.editComment(original.comments[0]) }}>Edit Comments</button>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment>

                                        <button className='customBtn btn btn-primary btn btn-default' onClick={() => { this.addComment(original.id) }}>Add Comment</button>
                                    </React.Fragment>
                                )
                            }
                        },
                        show: !('inTab' in this.props),
                        width: 200
                    }
                ]
            }
        ]
        let reOrderedColumns;
        if ('inTab' in this.props) {
            reOrderedColumns = cloneDeep(columns);
            reOrderedColumns = orderBy(reOrderedColumns[0].columns, 'order', 'asc')
        } else {
            reOrderedColumns = cloneDeep(columns);
        }
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className="secHeading">Reorders For Production</div>
                                    {!('inTab' in this.props) &&
                                        <p>Your query resulted in {ReOrders.total} reorders.</p>
                                    }
                                    {/* <div className='text-center'>
                                        {
                                            !('inTab' in this.props) &&
                                            <React.Fragment>
                                                <h4><strong>Top 15 Most Recent Reorders From GTV.com(Additional Copies of DVD, PhotoMozaix and CDs of Pictures)</strong></h4>
                                                <strong style={{ display: 'block', color: '#777' }}>Total Reorder Sum ...so far ${this.props.reorders_total_sum}</strong>
                                            </React.Fragment>
                                        }
                                    </div> */}
                                    <div className='flexElem' style={{ justifyContent: 'end' }}>
                                        <div className='my-20 col-md-4'>
                                            {/* <input onChange={this.inputChangeHandler} type='text' name='filtered' className='form-control' placeholder='Search...' /> */}
                                        </div>
                                        <div className='col-md-8'>
                                        </div>
                                    </div>
                                    <div className='row reorder'>
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={reOrderedColumns}
                                            pageSize={20}
                                            getDataCall={this.paginationCall}
                                            filterView={!('inTab' in this.props) ? true : false}
                                            downloadData={!('inTab' in this.props) ? true : false}
                                            defaultSorted={
                                                [
                                                    {
                                                        id: 'id',
                                                        desc: true
                                                    }
                                                ]
                                            }
                                            downloadFileName={'Reorders For Production'}
                                            // lowerContent = {null}
                                            filterPlaceHolder={'Orders'}
                                            getRowProps={this.getRowProps}
                                            showAllToggle={!('inTab' in this.props) ? true : false}
                                           
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                    
                    <ThemeModal title='Edit Comment' show={this.state.editModal} hide={() => { this.toggleModal('editModal') }}>
                        <textarea onChange={this.commentChangeHandler} className='form-control' value={this.state.editComment.comment} />
                        <div className='text-right'>
                            <button className='customBtn btn btn-primary btn btn-default mt-md' onClick={this.saveComment}>Save</button>
                        </div>
                    </ThemeModal>

                    <ThemeModal title='Add Comment' show={this.state.addCommentModel} hide={() => { this.toggleModal('addCommentModel') }}>
                        <textarea onChange={this.storeCommentChangehandler} className='form-control' value={this.state.comment.comment} />
                        <div className='text-right'>
                            <button className='customBtn btn btn-primary btn btn-default mt-md' onClick={this.storeComment}>Save</button>
                        </div>
                    </ThemeModal>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { ReOrders, shippingSettings, ReOrders_total, reorders_total_sum } = state.ordersReducer;
    const { singleCommentCategory } = state.commentReducer;
    return {
        ReOrders,
        shippingSettings,
        ReOrders_total,
        reorders_total_sum,
        singleCommentCategory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReOrdersForProduction: (page, pageSize, filter, sort, orderType, orderId, reorder_type) => { return dispatch(getReOrdersForProduction(page, pageSize, filter, sort, orderType, orderId, reorder_type)) },
        getAllShippingMethodsCall: () => dispatch(getAllShippingMethods()),
        error: (message) => { return dispatch(alertActions.error(message)) },
        success: (message) => { return dispatch(alertActions.success(message)) },
        editComment: (data, id) => { return dispatch(editComment(data, id)) },
        addCommentCall: (data, modelId) => dispatch(addComment(data, modelId)),
        getCommentCategoryByTitle: (titles) => { dispatch(getCommentCategoryByTitle(titles)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReorderListForProduction)
