import React, { Component } from 'react';
import $ from 'jquery';
import { s3StaticPath } from '../../helper/helperFunctions';
import {Link} from 'react-router-dom';

import Slider from 'react-slick';
import { Parallax,
 // Background
  } from 'react-parallax';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from 'react-animate-on-scroll';
import "./../../assets/css/animate.min.css";

class GtvHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
        <div {...props}>{children}</div>
        );


    let sliderSettings = {
        dots: true,
        autoplay: true,
        infinite: true,
        fade: true,
        speed: 1000,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        prevArrow: <SlickButtonFix><FaChevronLeft /></SlickButtonFix>,
        nextArrow: <SlickButtonFix><FaChevronRight /></SlickButtonFix>,
    };

    return (
        <main id="gtv_home">
            <section className="bgVideo">
                <div className="videoContainer">
                    <video src={s3StaticPath('video.mp4')} autoPlay muted loop type="video/mp4" className="video"></video>
                    <div className="elementor-background-overlay"></div>
                </div>

                <div className="text-center bannerContent">
                    <div className="inlineGridElem">
                        <div className="text_right_center_991">
                            <img src={s3StaticPath('img/home/banner_phone.png')} alt="Banner phone"/>
                        </div>
                        <div className="text_left_center_991">
                            <div className="text_left_center_991">
                                <div style={{display: "inline-block", textAlign: "center"}}>
                                    <img width="190" className="gtvLogo" src={s3StaticPath('img/home/admin_icon.png')} alt="GTV Logo"/> <br />
                                    <h1 style={{display: "inline-block"}}>THE BEST APP FOR
                                    <br />&nbsp;GROUP TRAVEL</h1>
                                </div>
                            </div>
                        </div>

                        <div className="text_left_center_991">
                            <div className="mt-xxlg">
                                <div className="itsLike">It's Like a Private Social Network</div>
                                <p>That connects your group &amp; their families in one private and secure place.</p>
                            </div>

                            <div>
                                <Link to="/Contact_Us" className="wordpressButton getInTouch">Get In Touch</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="featuresWhile hasDivider">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h2>
                                    Features While Traveling
                                </h2>
                            </div>

                            <div className="featuresSlider">
                                <Slider {...sliderSettings}>
                                    <div><img src={s3StaticPath('img/home/feature_gallery.png')} alt="Feature Gallery" /></div>
                                    <div><img src={s3StaticPath('img/home/feature_map.png')} alt="Feature map" /></div>
                                    <div><img src={s3StaticPath('img/home/feature_group.png')} alt="Feature group" /></div>
                                    <div><img src={s3StaticPath('img/home/feature_docs.png')} alt="Feature Docs" /></div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="afterTrip flexElem alignCenter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <div className="divBox">
                                    <h3>After the Trip...</h3>

                                    <p>
                                        Relive your travel experience with a <strong>storytelling video</strong> made from your group’s photographs.
                                    </p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="howItWorks">
                <div className="computers text-center">
                    <img src={s3StaticPath('img/home/multi_devices.png')} alt="Multi Devices"/>
                </div>

                <div className="btnContainer">
                    <Link to="/gtv-how-it-works" className="wordpressButton howWorks">
                        See How It Works
                    </Link>
                </div>
            </section>

            <section className="feedbacks">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>OVER THE LAST 22 YEARS...</h3>
                            <div>
                                We've provided over 2 Million Keepsake Videos for Travelers and their Families.
                            </div>
                        </div>
                    </div>

                    <div className="row messages">
                        <div className="col-md-6 mt-xlg">
                            <div className="message">
                            We just got our downloadable link from Group Travel Videos and it is fantastic! From start to finish, the good folks at this company communicated promptly, offered exceptional customer service, and a wonderful product. Please, please add this on to every trip of the SLHS orchestras from now on. They did FANTASTIC work! Thanks! Dez
                            </div>
                            <div className="author mt-md">
                                <div className="inlineFlexElem alignCenter">
                                    <div>
                                        <img src={s3StaticPath('img/user_avatar.jpg')} alt="Avatar"/>
                                    </div>
                                    <div className="ml-md">
                                        <div className="name">
                                            Desiree Overree
                                        </div>
                                        <div className="Desig">
                                            SLHS Orchestra
                                        </div>
                                        <div className="Desig">
                                            May 19, 2020
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-xlg">
                            <div className="message">
                            This is the third time that my school group has used Group Travel Videos and I am always
                            amazed by the finished product. This company does an astonishing job at combining our photos and videos to make a one of a kind souvenir. It also allows parents at home to see the pictures real time. This video is also a great recruiting tool, getting the younger grades excited about Spanish! I can`t imagine taking a student trip and not using Group Travel Videos in the future!
                            </div>
                            <div className="author mt-md">
                                <div className="inlineFlexElem alignCenter">
                                    <div>
                                        <img src={s3StaticPath('img/user_avatar.jpg')} alt="Avatar"/>
                                    </div>
                                    <div className="ml-md">
                                        <div className="name">
                                            Chris B.
                                        </div>
                                        <div className="Desig">
                                            Appleseed Expeditions
                                        </div>
                                        <div className="Desig">
                                            Mar 22, 2019
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row messages">
                        <div className="col-md-6 mt-xlg">
                            <div className="message">
                            Our band, choir, and orchestra traveled to London and the students had an absolutely wonderful time. This DVD was a great way for them to capture and share their incredible experiences. Additionally, the DVD has served as a useful recruiting tool. Students in the younger grades are so excited to see what exciting opportunities await them in high school music classes!
                            </div>
                            <div className="author mt-md">
                                <div className="inlineFlexElem alignCenter">
                                    <div>
                                        <img src={s3StaticPath('img/user_avatar.jpg')} alt="Avatar"/>
                                    </div>
                                    <div className="ml-md">
                                        <div className="name">
                                            Monica H
                                        </div>
                                        <div className="Desig">
                                            Lincoln HS Music
                                        </div>
                                        <div className="Desig">
                                            Feb 22, 2019
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-xlg">
                            <div className="message">
                            We have been working with Group Travel Videos for years and it has been an exceptional experience.  Not only is the ordering process very easy.  The end product is fantastic and students, teachers and parents love it.  Band and Choir Director can use the video to recruit new band members You provide GTV with the contact information of your group and they do the rest.  It could not be more convenient for the Tour Operator or end consumer.  Dave and his team are amazing and follow up quickly and professionally. Over all 5*****
                            </div>
                            <div className="author mt-md">
                                <div className="inlineFlexElem alignCenter">
                                    <div>
                                        <img src={s3StaticPath('img/user_avatar.jpg')} alt="Avatar"/>
                                    </div>
                                    <div className="ml-md">
                                        <div className="name">
                                            Urte Land, President
                                        </div>
                                        <div className="Desig">
                                            Advantage Tours
                                        </div>
                                        <div className="Desig">
                                            November 2020
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Parallax
                bgImage={s3StaticPath('img/home/bus_background.jpg')}
                bgImageAlt="Personal Privacy"
                strength={500}
                className="securityParallax"
            >
                <section className="securityPoints flexElem flexResponsive alignCenter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 text_left_center_767">
                                <div>
                                    <div><img src={s3StaticPath('img/home/lock_icon.png')} alt="lock icon"/></div>
                                    <div><h4>Personal Privacy</h4></div>
                                    <div><p>No phone numbers, emails, or any personal information required to use.</p></div>
                                </div>
                            </div>

                            <div className="col-md-4 text_left_center_767">
                                <div>
                                    <div><img src={s3StaticPath('img/home/private_photo.png')} alt="private"/></div>
                                    <div><h4>Share Privately</h4></div>
                                    <div><p>Share your travel pictures in a private group gallery without making them public.</p></div>
                                </div>
                            </div>

                            <div className="col-md-4 text_left_center_767">
                                <div>
                                    <div><img src={s3StaticPath('img/home/vault_icon.png')} alt="vault icon"/></div>
                                    <div><h4>Secure Storage</h4></div>
                                    <div><p>Access your memories for up to 3 years in our password protected vault.</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>

            <section className="ourTeams">
                <div className="container">
                    <div className="row flexElem flexResponsive alignCenter justifyCenter">
                        <div className="col-md-5 text_left_center_991">
                            <h5>Our Team</h5>
                            <p>
                                Meet the people 'behind the scenes' that make the magic happen.
                            </p>

                            <div className="mt-lg">
                                <Link to="/About_Us" className="wordpressButton">
                                    Get to Know Us
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <img src={s3StaticPath('img/home/team-example.png')} alt="Team"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ourClients">
                <div className="text-center">
                    <a href="#" className="link">We work with over 200 Tour Operators</a>
                </div>
                <div className="text-center">
                    and are proud members of
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="flexElem">
                                <div><img className="mt-lg" src={s3StaticPath('img/home/nta-transparent.png')} alt="NTA" /></div>
                                <div><img className="mt-lg" src={s3StaticPath('img/home/SYTA-Transparent.png')} alt="SYTA" /></div>
                                <div><img className="mt-lg" src={s3StaticPath('img/home/ABA-Transparent.png')} alt="ABA" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="subFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="flexElem text-center flexResponsive alignCenter">
                                <div>
                                    Are you a Tour Operator?
                                </div>

                                <div>
                                    <Link to="/gtv-tour-operators" className="wordpressButton">
                                        See more information
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
  }
}

export default GtvHome;
