import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Row , Col} from 'react-bootstrap';
import { getOrderUploadInfo, orderImages, getSingleImage } from 'redux/actions/order-actions';
import User from 'helper/User';
import { getOrdersResourcesByRole } from 'redux/actions/agency-resource-actions';
import ImageControls from 'components/FrontEnd/PhotoGallery/ImageControls';
import Image from 'components/FrontEnd/PhotoGallery/Image';
import { SortableContainer, arrayMove, SortableElement } from 'react-sortable-hoc';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "components/FrontEnd/PhotoGallery/SliderStyle"; // example render components - source below

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import { rotateImage, deleteImage } from 'redux/actions/imageActions';
import { Roles } from 'constants/Roles';
import { isMobile } from 'react-device-detect'
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import { toggleModal, s3StaticPath } from 'helper/helperFunctions';
import { deleteImages } from 'services/order';
import { cloneDeep } from 'lodash';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import { Link } from 'react-router-dom';
var FileSaver = require('file-saver');


const SortableItem = SortableElement(({ image, openPopup, item_index, showData, imageSize, selectImageHandler, selectedImages }) => {
    return (
        <Image showData={showData} selected={selectedImages.includes(item_index) ? 'selected' : 'not selected'} selectImageHandler={selectImageHandler} imageSize={imageSize} openPopup={openPopup} image={image} index={item_index} />)
}
);

const ImagesList = SortableContainer(({ imagesData, openPopup, disabled, imageSize, showData, selectImageHandler, selectedImages }) => {
    return (
        <div>
            {
                imagesData.map((image, i) => {
                    return (
                        <SortableItem showData={showData} selectedImages={selectedImages} selectImageHandler={selectImageHandler} imageSize={imageSize} disabled={(disabled === 1 || disabled === true) ? true : false} openPopup={openPopup} key={image.id} index={i} item_index={i} image={image} />
                    );
                })
            }
        </div>
    )
});




class ImageGallery extends Component {

    constructor(props) {
        super(props)

        const { orderId } = this.props.match.params;

        this.state = {
            imagesData: this.props.imagesData,
            pageSize: '',
            photoIndex: 0,
            isOpen: false,
            is_mozaix_open: false,
            saveDragDrop: false,
            singleImageOpen: false,
            imageSize: 3,
            selectedImages: [],
            saveAlert: false,
            showImgData: false,
            imageVersion: 'original',
            enableSave: false,
            sizes: ['thumb', 'thumb_mid', 'thumb_large', 'thumb_xlarge', 'thumb_xxlarge', 'thumb_xxxlarge', 'full'],
            orderId:orderId,
            fullIndex:6
        }
        this.toggleModal = toggleModal.bind(this);
        this.props.getOrderUploadInfoCall(this.state.orderId);
        this.props.getOrderVideos(this.state.orderId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.imagesData !== prevProps.imagesData) {
            this.setState({
                imagesData: this.props.imagesData,
                enableSave: false
            });
        }
        if ((this.props.orderUploadInfo.finalize_by_editor.value !== prevProps.orderUploadInfo.finalize_by_editor.value)) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
    }

    componentDidMount() {
        var self = this;
        setInterval(function (ind = 0) {
            if (self.state.isOpen && self.state.singleImageOpen === false) {
                if (self.props.imagesData.length > 1) {
                    $(".ril-next-button").trigger("click");
                }
            }
        }, 8000);
        if (this.props.orderUploadInfo.finalize_by_editor.value !== undefined) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
        // this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
    }

    openPopup = (index, singleImage = false) => {
        if (singleImage) {
            this.setState({ isOpen: true, photoIndex: index, singleImageOpen: singleImage })
        } else {
            this.setState({ isOpen: true, photoIndex: index })
        }
    }

    sortStart = ({ index }) => {
        let sizes = this.state.sizes;
        $('.moving').find('.uploadedImageSize').find('img').after(() => {
            return (this.state.selectedImages.map(item => {
                if (item !== index)
                    return `<img class="appendedImgs" src=${this.state.imagesData[item].resized_image_path.replace('SIZE', sizes[this.state.imageSize - 1])} />`
                else
                    return null
            }) + (this.state.selectedImages.length > 0 ? `<span class="selectedCount purple_blue_gradient text_18">${this.state.selectedImages.length}</span>` : ''))
        })
        return null;
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let imagesData = this.state.imagesData
            if (this.state.selectedImages.length > 0) {
                const newElement = imagesData[newIndex];
                const tempArr = [];
                this.state.imagesData.forEach((item, index) => {
                    if (!this.state.selectedImages.includes(index)) {
                        tempArr.push(item)
                    }
                });
                let newElementIndex = tempArr.findIndex(item => {
                    return item.id === newElement.id
                })
                if (newIndex > oldIndex) {
                    console.log('increasing index')
                    newElementIndex += 1
                }
                this.state.selectedImages.forEach((item, index) => {
                    tempArr.splice(newElementIndex + index, 0, imagesData[item]);
                })
                imagesData = tempArr
            } else {
                imagesData = arrayMove(imagesData, oldIndex, newIndex)
            }
            this.setState({
                imagesData: imagesData,
                // saveAlert: true
            }, () => {
                if (this.state.selectedImages.length > 0) {
                    this.setState({
                        selectedImages: [],
                    })
                }
            });
        }
        this.setState({
            enableSave: true
        })
    }

    selectImageHandler = (index) => {
        let selectedImages = this.state.selectedImages;
        if (selectedImages.includes(index)) {
            selectedImages = selectedImages.filter(imageIndex => {
                return imageIndex !== index
            })
        } else {
            selectedImages.push(index);
        }
        this.setState({
            selectedImages: selectedImages
        })
    }

    showMoreImagesPagination = (e, page) => {
        e.preventDefault();
        this.setState({
            selectedImages: []
        });
        this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', page, '', '', this.state.pageSize, User.user.role.name);
    }

    changePageSize = (e) => {
        this.setState({
            // pageSize: e.target.value
            pageSize: e.target.value
        }, () => {
            this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
        })
    }

    saveDragDropHandler = (e) => {
        this.setState({
            saveDragDrop: true
        }, () => {
            this.setState({
                saveDragDrop: false
            })
        })
    }

    deleteImage = (id, photoIndex) => {
        this.props.deleteImageCall(id, this.state.imageVersion).then(
            res => {
                if (photoIndex === this.state.imagesData.length - 1) {
                    this.setState({
                        photoIndex: photoIndex - 1
                    })
                }
            }
        );
    }

    deleteSelected = () => {
        const images = [];
        this.state.selectedImages.forEach(item => {
            images.push(this.state.imagesData[item].id);
        });
        const data = { images, version: this.state.imageVersion }
        deleteImages(data).then(
            res => {
                this.props.orderImagesCall(this.state.orderId, this.state.imageVersion, 'Uploaded-images', this.props.current_page, '', '', this.state.pageSize, User.user.role.name);
                this.setState({
                    selectedImages: []
                })
            }
        )
    }

    rotateImage = (id, direction) => {
        this.props.rotateImageCall(id, direction);
    }

    sliderUpdate = (update) => {
        const newSize = update[0];
        if (this.state.imageSize !== newSize) {
            this.setState({
                imageSize: newSize
            })
        }
    }

    sliderChange = (values) => {
        const newSize = values[0];
        if (this.state.imageSize !== newSize) {
            this.setState({
                imageSize: newSize
            })
        }
    }

    closeLightBox = () => {
        if (this.state.singleImageOpen) {
            this.setState({ isOpen: false, singleImageOpen: false })
        } else {
            this.setState({ isOpen: false })
        }
    }

    confirmImagesDelete = () => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Images' onClosePro={onClose} deleteType={() => { this.deleteSelected() }} />
        })
    }

    confirmImageDelete = (id, photoIndex) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Image' onClosePro={onClose} deleteType={() => { this.deleteImage(id, photoIndex) }} />
        })
    }

    downloadImage = (file) => {
        // const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.imageSize]) + '?updated_at=' + file.updated_at;
        const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
    }

    getImagePath = (src) => {
        if (Number(this.props.orderUploadInfo.finalize_by_qa.value) === 1) {
            const images = cloneDeep(this.state.imagesData);
            const image = images.find(item => item.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.imageSize]) === src.replace(`?updated_at=${item.updated_at}`, ''));
            image.image_versions.file_path = src.replace(/\.jpg/, '.JPG');
            this.setState({
                imagesData: images
            })
        } else {
            return src
        }
    }

    handleShowDialog = () => {
        this.setState({ is_mozaix_open: !this.state.is_mozaix_open });
        console.log("cliked");
      };

      closeMozaixLightBox = () => {
        this.setState({
            is_mozaix_open: false
        })
    }

    downloadMozaixImage = (orderResources) => {
        // const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const downloadUrl = orderResources['20x30_mosaic_preview'].file_path+ '?updated_at=' + orderResources['20x30_mosaic_preview'].updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        console.log("clicker", orderResources);

        // FileSaver.saveAs(orderResources, '.jpg');
    }

    render() {
        const { photoIndex, isOpen, is_mozaix_open } = this.state;
        const { imagesData } = this.state;
        const { orderUploadInfo, pages, current_page, total_images, from_page, orderResources } = this.props;
        const paginationLinks = [];
        const sliderStyle = {
            position: "relative",
            width: "100%"
        };
        const domain = [1, 6];
        const defaultValues = [this.state.imageSize];
        let sizes = this.state.sizes;
        for (let index = 1; index <= pages; index++) {
            paginationLinks.push(index);
        }

        console.log('orderUploadInfo', orderUploadInfo);
        return (
            <React.Fragment>

                {
                    (Number(orderUploadInfo.finalize_by_qa.value)===1 && !('allow_online_reorder' in orderUploadInfo)  && !('cleared_date' in orderUploadInfo)) &&
                    <div className='container'>
                                <h1 className='text_18 mb-md'><strong>Your Photo Gallery</strong></h1>
                                <h2 className='text_18 mb-md'>{ orderUploadInfo.group_name }</h2>
                                <h4 className='text_18 mb-md'>{ orderUploadInfo.destinations }</h4>
                                <h1 className='text_18 mb-md'><strong>Your Full Video</strong></h1>
                                <div className='fullVideo'>
                                    <div className=''>
                                {
                                    orderResources.full_video &&
                                   <React.Fragment>
                                        <video style={{ maxWidth: '100%' }} controls>
                                            <source src={orderResources.full_video.file_path} type='video/mp4' />
                                        </video>

                                    </React.Fragment>
                                }

                                    </div>
                                    <div className=''>
                                    <button onClick={() => { this.toggleModal('showHighlightVideo') }} className='themeBtn_12 red_gradient mr-md'><i className='fa fa-play'></i> Highlight Video</button>
                                {
                                    orderResources.full_video &&
                                    <DownloadFile extraClass='themeBtn_12 limegreen_gradient' multiple={false} title={'Download Full Video'} files={{
                                        'file_title': orderResources.full_video.file_title,
                                        'file_path': orderResources.full_video.file_path
                                    }} />
                                }
                                <Link to='/keep_sake' className='themeBtn_12 blue_theme_button ml-md'>Keepsake ReOrder</Link>

                                <br/>
                                {/* <ImagePreview
                                            filesToPreview={{
                                                preview: orderResources.mosaic_file.file_path + '?rand=' + returnTimeStamp(orderResources.mosaic_file.updated_at),
                                                name: orderResources.mosaic_file.file_title,
                                                type: orderResources.mosaic_file.mime_type,
                                                id: orderResources.mosaic_file.id,
                                            }}
                                        /> */}
                                {
                                orderResources['20x30_mosaic_preview'] ?

                                    <div className='mt-25p'>
                                        <Row>
                                            <Col md={8} style={{padding: '0'}}>
                                                <img style={{width: '93%', border: '1px solid #eedddd', marginLeft: '14px', cursor: 'pointer'}} onClick={this.handleShowDialog} className='mr-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic"/>
                                                {/* <img style={{width: '100%', border: '1px solid #eedddd'}} onClick={this.handleShowDialog} className='mr-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic"/> */}
                                            </Col>
                                            <Col md={4} style={{padding: '0'}} className="gtvIcons">
                                                <img className='' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                                <img className='' src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/>
                                            </Col>
                                        </Row>
                                    </div>
                                :
                                <div className='mt-md' style={{display: 'flex', alignItems: 'start'}}>
                                        <img className='mr-md' src={s3StaticPath('img/gtv_products_mosaic.jpg')}alt=""/>
                                        <img className='mr-md' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                        <img src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/>
                                </div>
                            }
                                </div>
                                </div>
                        {/* <div className='row flexElem align-items-center pt-md pb-md'>
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                                <button onClick={() => { this.toggleModal('showHighlightVideo') }} className='themeBtn_12 red_gradient mr-md'><i className='fa fa-play'></i> Highlight Video</button>
                                {
                                    orderResources.full_video &&
                                    <DownloadFile extraClass='themeBtn_12 limegreen_gradient' multiple={false} title={'Download Full Video'} files={{
                                        'file_title': orderResources.full_video.file_title,
                                        'file_path': orderResources.full_video.file_path
                                    }} />
                                }
                                <Link to='/keep_sake' className='themeBtn_12 blue_theme_button ml-md'>Keepsake ReOrder</Link>

                                <br/>
                                {/* <ImagePreview
                                            filesToPreview={{
                                                preview: orderResources.mosaic_file.file_path + '?rand=' + returnTimeStamp(orderResources.mosaic_file.updated_at),
                                                name: orderResources.mosaic_file.file_title,
                                                type: orderResources.mosaic_file.mime_type,
                                                id: orderResources.mosaic_file.id,
                                            }}
                                        /> */}
                                {/* {
                                orderResources['20x30_mosaic_preview'] ?

                                    <div className='mt-md'>
                                        <Row>
                                            <Col md={8} style={{padding: '0'}}>
                                                <img style={{width: '93%', border: '1px solid #eedddd', marginLeft: '14px', cursor: 'pointer'}} onClick={this.handleShowDialog} className='mr-md' src={s3StaticPath('img/gtv_products_mosaic.jpg')} alt="mosaic"/>
                                                <img style={{width: '100%', border: '1px solid #eedddd'}} onClick={this.handleShowDialog} className='mr-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic"/>
                                            </Col>
                                            <Col md={4} style={{padding: '0'}} className="gtvIcons">
                                                <img className='' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                                <img className='' src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/>
                                            </Col>
                                        </Row>
                                    </div>
                                :
                                <div className='mt-md' style={{display: 'flex', alignItems: 'start'}}>
                                        <img className='mr-md' src={s3StaticPath('img/gtv_products_mosaic.jpg')}alt=""/>
                                        <img className='mr-md' src={s3StaticPath('img/product_sample_DVD.png')} alt=""/>
                                        <img src={s3StaticPath('img/product_sample_picture_disc.png')} alt=""/>
                                </div>
                            } */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                }
                <div className="container mt-25p" id="companyImagesInfo">
                    <div>
                        {/*<h2 style={{ textAlign: 'left !important' }} className="panel_heading small text-left">{this.props.extraHtml.galleryHeading}</h2>*/}
                    </div>
                    {
                        Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && User.hasRole(Roles.PHOTO_ADMIN) &&
                        <ImageControls imageVersion={this.state.imageVersion} saveDrag={this.state.saveDragDrop} images={this.state.imagesData} />
                    }

                    <div className="row mb-xlg">
                        <div className="col-md-12">
                            <div className="alignCenter spaceBetween flexElem respFlex">
                                <div className="flexElem justify_left_center_991 zoomerContainer">
                                    <span className="mr-md text_16">Zoom</span>
                                    <div className="flexElem alignCenter zoomer">
                                        <div className="mr-md"><img src={s3StaticPath('img/zoom_out.png')} alt="Zoom Out" /></div>
                                        <div style={{ maxWidth: "100%", width: "200px" }}>
                                            <Slider
                                                mode={1}
                                                step={1}
                                                domain={domain}
                                                rootStyle={sliderStyle}
                                                onUpdate={this.sliderUpdate}
                                                onChange={this.sliderChange}
                                                values={defaultValues}
                                            >
                                                <Rail>
                                                    {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                </Rail>
                                                <Handles>
                                                    {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                                <Handle
                                                                    key={handle.id}
                                                                    handle={handle}
                                                                    domain={domain}
                                                                    getHandleProps={getHandleProps}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Handles>
                                                <Tracks left={false} right={false}>
                                                    {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                                <Track
                                                                    key={id}
                                                                    source={source}
                                                                    target={target}
                                                                    getTrackProps={getTrackProps}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Tracks>
                                                <Ticks count={5}>
                                                    {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                                <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Ticks>
                                            </Slider>
                                        </div>
                                        <div className="ml-lg"><img src={s3StaticPath('img/zoom_in.png')} alt="Zoom In" /></div>
                                    </div>
                                </div>

                                <div className="inlineFlexElem alignCenter">
                                    <div className="mr-md inlineFlexElem alignCenter">
                                        <div className="text_16 mr-sm">
                                            Show Gallery Info
                                        </div>
                                        <div>
                                            <input type="checkbox" onChange={() => this.setState({ showImgData: !this.state.showImgData })} id="switch" checked={this.state.showImgData ? 'checked' : ''} className="cssSwitch" /><label className="cssSwitch" htmlFor="switch">Toggle</label>
                                        </div>
                                    </div>
                                    {
                                        imagesData.length > 0 &&
                                        <button onClick={() => this.setState({ isOpen: true, photoIndex: 0 })} className="themeBtn_14 pl-xlg pr-xlg mr-sm blue_theme_button hover_dark">Photo Slideshow</button>
                                    }
                                    <select className='form-control' value={this.state.pageSize} style={{ width: 'auto', 'marginLeft': 'auto' }} onChange={this.changePageSize} name="pageSize" id="">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value=''>Show All</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                            <React.Fragment>
                                <div className="col-md-3  mb-xlg">
                                    <div className="text-center mt-xlg">
                                        <button style={{
                                            cursor: this.state.enableSave === false ? 'not-allowed' : 'default'
                                        }} disabled={this.state.enableSave === false} onClick={this.saveDragDropHandler} type='button' className="themeBtn_14 block blue_theme_button hover_dark">
                                            Save Photo Changes
                                    </button>
                                    </div>
                                </div>
                                {
                                    this.state.selectedImages.length > 1 &&
                                    <div className="col-md-3  mb-xlg">
                                        <div className="text-center mt-xlg">
                                            <button onClick={this.confirmImagesDelete} type='button' className="themeBtn_14 block blue_theme_button hover_dark">
                                                Delete Selected
                                    </button>
                                        </div>
                                    </div>
                                }
                                <br />
                            </React.Fragment>
                        }
                    </div>

                    <div className={`companyImagesGrid hasMargin grid_${this.state.imageSize - 1}`}>
                        <ImagesList showData={this.state.showImgData} onSortStart={this.sortStart} distance={5} selectedImages={this.state.selectedImages} selectImageHandler={this.selectImageHandler} imageSize={sizes[this.state.imageSize - 1]} disabled={Number(orderUploadInfo.photos_submitted_for_video_production.value) === 1 || isMobile || User.hasRole([Roles.TRAVELER])} helperClass='moving' transitionDuration={400} lockToContainerEdges={true} openPopup={this.openPopup} axis='xy' imagesData={imagesData} onSortEnd={this.onSortEnd} />
                    </div>

                    <div className="text-center">
                        <ul className="pagination mt-lg">
                            {
                                paginationLinks.length > 1 &&
                                paginationLinks.map(page =>
                                    <li key={page} className={current_page === page ? 'active' : ''}>
                                        <a href={`/?page=${page}`} type='button' onClick={(e) => { this.showMoreImagesPagination(e, page) }}>{page}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="row mb-xlg">
                        {
                            User.hasRole(Roles.PHOTO_ADMIN) && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                            <div className="col-md-3  mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    <button style={{
                                        cursor: this.state.enableSave === false ? 'not-allowed' : 'default'
                                    }} disabled={this.state.enableSave === false} onClick={this.saveDragDropHandler} type='button' className="themeBtn_14 block blue_theme_buttonhover_dark">
                                        Save Photo Changes
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            (Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && User.hasRole(['PHOTO_ADMIN'])) &&
                            <div className="col-md-3 mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    <a onClick={this.props.toggleUploader ? null : this.props.uploadMore} href="#top" className="themeBtn_14 block blue_theme_button hover_dark">
                                        Upload More Photos
                                </a>
                                </div>
                            </div>
                        }
                        {/*
                            this.props.extraHtml.submitBtn &&
                            <div className="col-md-4 mb-xlg">
                                <div className="text-center mt-xlg mb-xlg">
                                    {this.props.extraHtml ? this.props.extraHtml.submitBtn : ""}
                                </div>
                            </div>
                        */}
                    </div>
                    <ThemeModal title='Save Drag Drop' modalClass='section-frontEnd frontEndModal' show={this.state.saveAlert} hide={() => { this.toggleModal('saveAlert') }}>
                        <button className='themeBtn_14 no_radius dull_blue_gradient hover_dark'>Save Drag Drop</button>
                        <button className='themeBtn_14 no_radius blue_theme_buttonhover_dark ml-md'>Continue Dragging</button>
                    </ThemeModal>
                </div>

                {(isOpen && imagesData.length > 0) && (
                    <Lightbox
                        mainSrc={imagesData[photoIndex].image_versions.file_path.replace('SIZE', sizes[this.state.imageSize]) + "?updated_at=" + imagesData[photoIndex].updated_at}
                        nextSrc={photoIndex + 1 == imagesData.length ? null : imagesData[photoIndex + 1].image_versions.file_path.replace('SIZE', sizes[this.state.imageSize]) + '?updated_at=' + imagesData[photoIndex + 1].updated_at}
                        prevSrc={photoIndex == 0 ? null : imagesData[photoIndex - 1].image_versions.file_path.replace('SIZE', sizes[this.state.imageSize]) + '?updated_at=' + imagesData[photoIndex - 1].updated_at}
                        onCloseRequest={this.closeLightBox}
                        onImageLoadError={this.getImagePath}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: photoIndex - 1,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: photoIndex + 1,
                            })
                        }
                        toolbarButtons={
                            [
                                <i onClick={() => { this.downloadImage(imagesData[photoIndex]) }} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                            ]
                        }
                        imageCaption={
                            this.state.singleImageOpen &&
                            <React.Fragment>
                                <div className='text-center'>
                                    <p>Photos {from_page + photoIndex} of {total_images}</p>
                                </div>

                            </React.Fragment>
                        }
                    />
                )}

            {is_mozaix_open && (
                    <Lightbox
                        mainSrc={orderResources['20x30_mosaic_preview'].file_path}
                        onCloseRequest={this.closeMozaixLightBox}
                        toolbarButtons={
                            [
                                <div className="fb-share-button"
                                        data-href={orderResources['20x30_mosaic_preview'].file_path}
                                        data-layout="button" data-size="large">
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path}`, 'popup', 'height=600,width=600')
                                    }} style={{
                                        color: '#fff',
                                        background: '#3b5998',
                                        padding: '5px',
                                        borderRadius: '5px'
                                    }}
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path }`}
                                        className="fb-xfbml-parse-ignore"><i className='fa fa-facebook'></i> Share</a>
                                </div>,

                                <i onClick={() => { this.downloadMozaixImage(orderResources)}} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                            ]
                        }

                        />
                )
            }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { orderUploadInfo } = state.frontEndUserReducer;
    const { loadingOrder, imagesData, pages, current_page, perPage, order_details, total_images, from_page, orderResources } = state.ordersReducer;
    return {
        orderUploadInfo,
        loadingOrder,
        imagesData,
        pages,
        current_page,
        perPage,
        order_details,
        total_images,
        from_page,
        orderResources,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderUploadInfoCall: (orderId) => { dispatch(getOrderUploadInfo(orderId)) },
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => { dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole)) },
        getOrderVideos: (orderId) => { dispatch(getOrdersResourcesByRole(orderId)) },
        // getSingleImageCall: (imageId) => { dispatch(getSingleImage(imageId)) },
        // rotateImageCall: (imageId, direction) => { dispatch(rotateImage(imageId, direction)) },
        // deleteImageCall: (id) => { return dispatch(deleteImage(id)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery)
