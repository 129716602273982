import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { s3StaticPath, scrollLogin, s3VideoPath } from '../../helper/helperFunctions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import SlickSlider from "react-slick";
import Slider from "components/FrontEnd/Header/Slider";
import MainClients from './MainClients';

class GtvApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
    // this.handleHover = this.handleHover.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
  }


  handleVideo() {
    let demoVideo = document.getElementById("demoVideo");
    demoVideo.play();
  }

  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
      <div {...props}>{children}</div>
    );
    let sliderSettings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      className: 'benefits',
      prevArrow: <SlickButtonFix><MdArrowBack /></SlickButtonFix>,
      nextArrow: <SlickButtonFix><MdArrowForward /></SlickButtonFix>,
    };

    return (
      <React.Fragment>
        <Slider />
        {/* <section className="bgVideo">
          <div className="videoContainer">
            <video src={s3StaticPath('video.mp4')} autoPlay muted loop type="video/mp4" className="video"></video>
            <div className="elementor-background-overlay"></div>
          </div>

          <div className="text-center bannerContent">
            <div className="inlineGridElem">
              <div className="text_right_center_991">
                <img src={s3StaticPath('img/home/banner_phone.png')} alt="Banner phone" />
              </div>
              <div className="text_left_center_991">
                <div className="text_left_center_991">
                  <div style={{ display: "inline-block", textAlign: "center" }}>
                    <img width="190" className="gtvLogo" src={s3StaticPath('img/home/admin_icon.png')} alt="GTV Logo" /> <br />
                    <h1 style={{ display: "inline-block" }}>THE BEST APP FOR
                                    <br />&nbsp;GROUP TRAVEL</h1>
                  </div>
                </div>
              </div>

              <div className="text_left_center_991">
                <div className="mt-xxlg">
                  <div className="itsLike">It's Like a Private Social Network</div>
                  <p>That connects your group &amp; their families in one private and secure place.</p>
                </div>

                <div>
                  <Link to="/Contact_Us" className="wordpressButton getInTouch">Get In Touch</Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="video" className="section">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="section_heading">
                  Watch this short video to learn how the Group Travel Videos&trade; App works!!
                </div>
              </div>

              <div className="col-md-8 col-md-offset-2">
                <div className="video_placeholder">
                  <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={s3StaticPath('gtv_appDemo_apple.mp4')} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video>
                  <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="since" className="section bg_alter">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 text-center">
                <div className="since_icon">
                  <img src={s3StaticPath('img/since_icon.png')} alt="Since 1999" />
                </div>
                <h2 className="panel_heading mt-md">Get the full story</h2>

                <p className="since_text smaller">
                  As you and your group travel, the app is designed to allow everyone to upload and share photos with family and friends on a commonly shared website back home as you travel. When the trip is over, we'll use your photos to create a very cool video for your group. You will be able to watch a highlight video and the full length video right on your mobile device, tablet, or computer. Also, you will be able to view everyone's photos and share them on Facebook, as well as your highlight video. And all with this App!
                </p>

                <p className="since_text smaller">
                  Your group leader will be provided with a username and password unique to your group. Each traveler will use to login to the app. If you do not have Group Travel Videos™ included in your trip package, please contact your tour operator or group leader and ask them to include it as part of your trip.
                </p>

                <div className="sinceBtns text-center">
                  <h2 className='panel_heading mb-md'>Questions?</h2>
                  <Link to="/Contact_Us" className="gradient_btn themeBtn_14 red_gradient hover_dark">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="benefits" className="section bg_alter">
          <div className="slider_container blue_gradient">
            <div className="container flexElem respFlex">
              <div className="flexCol text_left_center_991">
                <div className="smaller_heading">
                  Features While You Travel
                </div>
                <ul className="featuresList diamond text-left">
                  <li className="text_16">Upload your mobile device pictures as you travel.</li>
                  <li className="text_16">Family &amp; friends can enjoy the group's pictures as the trip takes place by logging into www.grouptravelvideos.com with the group's username &amp; password.</li>
                  <li className="text_16">Great way to see everyone's elses pictures on the trip.</li>
                </ul>
                <div>
                  <a href="/" onClick={(e) => { e.preventDefault(); scrollLogin() }} className="benefitsBtn hover_dark">Login</a>
                </div>
              </div>

              <div className="flexCol">
                <div className="side_slider">
                  <SlickSlider {...sliderSettings}>
                    <img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" />
                    <img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" />
                    <img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" />
                  </SlickSlider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="uses_of_gtv" className="section bg_alter">
          <h2 className="panel_heading">Features After You Travel</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_1.png')} alt="We create a group travel video from your uploaded pictures." />

                  <div className="boxHeading">We create a group travel video from your uploaded pictures.</div>
                </div>
              </div>

              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_2.png')} alt="Watch your full group travel video!" />

                  <div className="boxHeading">Watch your full group travel video!</div>
                </div>
              </div>

              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_3.png')} alt="View &amp; download your favorite trip pictures." />

                  <div className="boxHeading">View &amp; download your favorite trip pictures.</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_4.png')} alt="Share your trip pictures on facebook." />

                  <div className="boxHeading">Share your trip pictures on facebook.</div>
                </div>
              </div>

              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_5.png')} alt="Download &amp; share your highlight video on facebook." />

                  <div className="boxHeading">Download &amp; share your highlight video on facebook.</div>
                </div>
              </div>

              <div className="col-md-4 mt-20">
                <div className="featureAfter text-center">
                  <img src={s3StaticPath('img/feature_after_6.png')} alt="Everyone on the trip gets a keepsake DVD!" />

                  <div className="boxHeading">Everyone on the trip gets a keepsake DVD!</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default GtvApp;
