// import React, { Component } from "react";
// import { connect } from 'react-redux';
// import { storeAgencyInventory, editAgencyInventory, updateAgencyInventory, deleteAgencyInventory, getAllAgencies, updateInventoryLocation } from 'redux/actions/agency-actions';
// import { getSMSPagination } from 'redux/actions/sms-actions';
// import 'react-table/react-table.css';
// import { Grid, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
// import { Card } from "components/Card/Card.jsx";
// import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
// import { confirmAlert } from 'react-confirm-alert';
// import qs from 'qs';
// import Pagination from "../../ThemeComponents/Pagination";

// class InventoryListPage extends Component {
// 	constructor(props) {
// 		super(props);
// 		const { search } = this.props.location;
// 		var parsedQSData = qs.parse(search.slice(1));

// 		this.state = {
// 			createAgencyInvModal: false,
// 			editAgencyInvModal: false,
// 			editInvFormType: null,
// 			sorted: '',
// 			filtered: '',
// 			pageSize: 10,
// 			page: 0,
// 			where: search ? parsedQSData : [],
// 		};

// 		this.agencyInventoryPaginationData = this.agencyInventoryPaginationData.bind(this);
// 	};
// 	handleChangeLocation = key => (selectedOptionType, info) => {
// 		if (info['action'] === 'select-option') {
// 			this.props.updateInventoryLocationCall(key, { inventory_location_id: selectedOptionType['id'] }, 'updatedInvLocation').then(
// 				response => {
// 					this.agencyInventoryPaginationData();
// 				}
// 			);
// 		}
// 	}
// 	agencyInventoryPaginationData(page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) {
// 		this.props.getSMSPaginationCall(page, filtered, sorted, pageSize, { where: this.state.where });
// 	}
// 	filterSearchHandle(e) {
// 		this.setState({
// 			'filtered': e.target.value
// 		});
// 	}
// 	handleInventorySubmit(InvData, formType) {
// 		if (formType === 'create') {
// 			this.props.storeAgencyInventoryCall(InvData).then(
// 				response => {
// 					this.setState({
// 						createAgencyInvModal: false
// 					}, function () {
// 						this.agencyInventoryPaginationData();
// 					});
// 				}
// 			);
// 		}
// 		else if (formType === 'edit') {
// 			const { editInvFormType } = this.state;
// 			this.props.updateAgencyInventoryCall(InvData, this.props.editAgencyInv.id, editInvFormType).then(
// 				response => {
// 					this.setState({
// 						editAgencyInvModal: false
// 					}, function () {
// 						this.agencyInventoryPaginationData();
// 					});
// 				}
// 			);
// 		}
// 	}


// 	componentDidUpdate(prevProps, nextProps) {
// 		if ((prevProps.editAgencyInv !== this.props.editAgencyInv) && this.props.editAgencyInv) {
// 			this.setState({ editAgencyInvModal: true });
// 		}
// 		if (prevProps.currentPageInv !== this.props.currentPageInv) {
// 			this.setState({ page: this.props.currentPageInv })
// 		}
// 	}

// 	paginationCall = (data) => {
// 		return this.props.getSMSPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.where);
// 	}

// 	render() {
// 		const columns = [
// 			{
// 				Header: "SMS Listing",
// 				headerClassName: 'headerPagination',
// 				columns: [
// 					{
// 						Header: "From Phone Number",
// 						accessor: "from",
// 						className: "action-center",
// 					},
// 					{
// 						Header: "Error",
// 						accessor: "error",
// 						className: "action-center",
// 					},
// 					{
// 						Header: "Status",
// 						accessor: "status",
// 						className: "action-center",
// 					},
// 					{
// 						Header: "Content",
// 						accessor: "purchased_price",
// 						className: "action-center",
// 					},
// 				]
// 			}
// 		];
// 		if (this.pagination) {
// 			console.log(this.pagination.state);
// 		}
// 		return (
// 			<div className="content">
// 				<Grid fluid>
// 					<Row>
// 						<Col md={12}>
// 							<Card
// 								ctTableResponsive
// 								content={
// 									<div>
// 										<Grid fluid>
// 											<Row>
// 												<Col md={12}>
// 													<div className="secHeading">SMS List</div>
// 												</Col>
// 												<Pagination
// 													ref={(p) => this.pagination = p}
// 													showPagination={false}
// 													noDataText='No SMS found'
// 													columns={columns}
// 													pageSize={10}
// 													getDataCall={this.paginationCall}
// 													filterView={true}
// 													filterPlaceHolder={'Inventory'}
// 													getRowProps={this.getRowProps}
// 												/>
// 											</Row>
// 										</Grid>
// 									</div>
// 								} />
// 						</Col>
// 					</Row>
// 				</Grid>
// 			</div>
// 		);
// 	};
// }

// const mapDispatchToProps = dispatch => {
// 	return ({
// 		getSMSPaginationCall: (page, filter, sort, pageSize, where) => { return dispatch(getSMSPagination(page, filter, sort, pageSize, where)) },
// 		getAgenciesCall: () => { dispatch(getAllAgencies()) },
// 	});
// };

// function mapStateToProps(state) {
// 	const { loadingAgency, agencyInventories, lastPageInv, currentPageInv, editAgencyInv, agenciesOption } = state.agenciesReducer;
// 	const { loadingItem, items } = state.itemReducer;
// 	return {
// 		loadingAgency, agencyInventories, lastPageInv, currentPageInv, editAgencyInv, agenciesOption,
// 		loadingItem, items,
// 	};
// };

// const InventoryList = connect(mapStateToProps, mapDispatchToProps)(InventoryListPage);
// export default InventoryList;






























import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getOrderWithSMS } from "redux/actions/sms-actions";
import Card from "components/Card/Card";
import { returnColumnValueWithColumnMatch, openModal, closeModal, hasRole } from "helper/helperFunctions";
import ThemeModal from "components/ThemeComponents/ThemeModal";
import OpenModalButton from "components/ThemeComponents/openModelButton";
import DatePicker from "react-datepicker";
import ProcessOrder from "components/Admin/Order/ProcessOrder";
import moment from 'moment';
import Calendar from "components/ThemeComponents/Calendar";
import { getBrandsCamerasToShip } from "redux/actions/account-action";
import { Link } from 'react-router-dom';
import Pagination from "components/ThemeComponents/Pagination";
import { alertActions } from "../../../redux/actions/alert-actions";
import { orderService } from "../../../services/order";

class ProcessOrderList extends React.Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 50,
            orders: [],
            filtered: '',
            processOrderModal: false,
            advancedSearchModal: false,
            advancedSearchFilters: {
                barcode: '',
                group_name: '',
                agency: '',
                departure_date: '',
                camera_need_date: '',
                projected_date: ''
            },
            brand_id: hasRole(['gm admin']) ? 23 : 1,
            orders_type: '2',
            brands: [],
        }

        this.props.getBrandsCall().then(response => {
            this.setState({ brands: response.data });
        });
    }
    clearAdvanceSearch = () => {
        this.setState({
            advancedSearchFilters: {
                barcode: '',
                group_name: '',
                agency: '',
                departure_date: '',
                camera_need_date: '',
                projected_date: ''
            }
        }, () => {
            this.pagination.dataCall();;
        })
    }

    componentDidUpdate(prevProps) {

    }

    getRowProps = (state, rowInfo) => {
        let data = {}
        if (rowInfo !== undefined) {
            const date = rowInfo.original.order_dates.projected_camera_ship_date.date_diff

            if (date === 1 || date < 1) {
                data = {
                    color: 'green'
                }
            }
            if (date > 1) {
                data = {
                    color: 'black'
                }
            }
            if (rowInfo.original.agency.agency_logo === null) {
                data = {
                    ...data,
                    background: 'yellow'
                }
            }
        }
        return {
            style: data
        };
    }

    paginationCall = (data) => {
        const { brand_id, orders_type } = this.state;
        const newData = { ...this.state.advancedSearchFilters };
        newData.departure_date = newData.departure_date !== '' ? moment(newData.departure_date).format() : '';
        newData.projected_date = newData.projected_date !== '' ? moment(newData.projected_date).format() : '';
        newData.camera_need_date = newData.camera_need_date !== '' ? moment(newData.camera_need_date).format() : '';
        return this.props.getOrderWithSMS(data.page, data.filter, data.sort, data.pageSize, brand_id, orders_type, newData);
    }

    sendPtmToPhotoAdmins = () => {
        orderService.sendPtmToPhotoAdmins(this.state.orders).then(response => {
            this.props.success(response.message);
            this.setState({ processOrderModal: false });
        });
    }
    render() {
        const columns = [
            {
                Header: 'Order Process',
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: 'Departure Date',
                        accessor: 'departure_date',
                        className: 'action-center',
                    },
                    {
                        Header: 'Barcode',
                        accessor: 'barcode',
                        width: 200,
                        className: 'action-center',
                        Cell: ({ value, original }) => {
                            return <a rel="noopener noreferrer" target='_blank' style={{ color: 'inherit', textDecoration: 'underline' }} href={`${original.id}/order-edit`} className="blue_link" >{value}</a>
                        }
                    },
                    {
                        Header: 'Agency',
                        accessor: 'agency.name',
                        className: 'action-center',
                        width: 140,
                    },
                    {
                        Header: 'Group Name',
                        accessor: 'group_name',
                        className: 'action-center',
                    },
                    {
                        Header: 'Username',
                        accessor: 'username',
                        className: 'action-center'
                    },
                    {
                        Header: "SMS Log",
                        accessor: "id",
                        Cell: ({ value }) => (
                            <div>
                                <Link target='_blank' className="orderDetailsAction editIcon" to={'/Admin/' + value + '/order-edit?commentCategoryCode=ContactLog&sms=true&collapseCard=true#messages_list_div'}>&nbsp;</Link>
                            </div>
                        ),
                        className: "action-center",
                        sortable: false,
                    },
                ]
            }
        ]
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    <div className='row' >
                                        <Pagination
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={50}
                                            noDataText='No Order Found'
                                            getDataCall={this.paginationCall}
                                            filterView={false}
                                            getRowProps={this.getRowProps}
                                        />
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { camerasToShip, countAllOrders } = state.ordersReducer;
    return { camerasToShip, countAllOrders };
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderWithSMS: (page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) => { return dispatch(getOrderWithSMS(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters)) },
        getBrandsCall: () => { return dispatch(getBrandsCamerasToShip()) },
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderList);
