import React from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { getOrdersResourcesByRole } from 'redux/actions/agency-resource-actions';
import { getOrderSettings, updateOrderUploadCutOffDate, updateOrderSettings, updateOrderSettingsForTtk, allowReorderStatus, getAllowReorder } from "redux/actions/order-actions";
import SimpleReactValidator from "simple-react-validator";
import { Card } from "components/Card/Card.jsx";
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { history } from 'helper/history';
import { closeModal, dateFormatMy, dateTimeWithAMPM, getFormattedDate, hasRole } from 'helper/helperFunctions';
import {
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { orderService } from "../../../services/order";

var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class OrderSettings extends React.Component {

    constructor(props) {
        super(props);
        this.orderId = this.props.orderId;
        this.state = {
            hashTag: '',
            do_not_allow_mapping_messaging: '',
            travelerMapUse: '',
            allowTravelerSeeAllTrip: '',

            do_not_allow_anyone_to_use_mapping: {
                key: 'do_not_allow_anyone_to_use_mapping',
                value: ''
            },
            do_not_allow_anyone_to_use_messaging: {
                key: 'do_not_allow_anyone_to_use_messaging',
                value: ''
            },
            do_not_allow_travelers_to_use_mapping: {
                key: 'do_not_allow_travelers_to_use_mapping',
                value: ''
            },
            do_not_allow_travelers_to_use_messaging: {
                key: 'do_not_allow_travelers_to_use_messaging',
                value: ''
            },

            maxPhotosUpload: '',
            uploadCutOffDate: new Date(),
            reOpenModal: false,
            allow_reorder: false,
            is_ai_job_in_process: null
        }
        this.style = {
            border: '2px solid #ccc',
            padding: '15px',
            marginBottom: '10px'
        }
        this.props.getOrderSettingsCall(this.orderId);
        this.props.getAllowReorderCall(this.orderId);
        this.props.getOrderResourcesByRoleCall(this.orderId);
        this.updateOrderSettingsHandler = this.updateOrderSettingsHandler.bind(this);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.orderSettings !== this.props.orderSettings) {
            this.setState({
                hashTag: this.props.orderSettings.hash_tag.value,
                uploadCutOffDate: new Date(this.props.orderSettings.uploadCutOffDate.value),
                maxPhotosUpload: this.props.orderSettings.max_allowed_photos.value
            })
        }
    }

    componentDidMount() {
        orderService.getAiJobProcessFlag(this.orderId).then(response => {
            this.setState({
                is_ai_job_in_process: response.data.is_ai_job_in_process
            })
        })
    }

    openReOpenModal = (orderId, id, user, type) => {
        this.setState({ reOpenModal: true });
    }


    hashTagFormHandler = (e) => {
        e.preventDefault();
        if (this.validator.fieldValid('hashTag')) {
            this.props.updateOrderSettingsCall(this.orderId, this.state.hashTag, this.props.orderSettings.hash_tag.key);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    inputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    maxPhotosUploadFormHandler = (e) => {
        e.preventDefault();
        if (this.validator.fieldValid('Max Photos Upload')) {
            this.props.updateOrderSettingsCall(this.orderId, +this.state.maxPhotosUpload, this.props.orderSettings.max_allowed_photos.key);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    uploadCutOffDateChangeHandler = (value) => {
        let ucd = moment(new Date(value)).format('YYYY-MM-DD 00:00:00');
        this.setState({
            uploadCutOffDate: ucd
        });
    }

    uploadCutOffDateFormHandler = (e) => {
        e.preventDefault();
        this.props.updateOrderUploadCutOffDateCall(this.orderId, { value: this.state.uploadCutOffDate });
    }

    updateOrderSettingsHandler(key, value) {

        if (this.props.is_ttk) {
            this.props.updateOrderSettingsForTtkCall(this.orderId, value, key).then(response => {
                if (key === 'photos_submitted_for_video_production') {
                    history.push(`/Admin/${this.orderId}/order-edit?orderDetailCode=settings`);
                    history.go();
                }
            });
        } else {
            this.props.updateOrderSettingsCall(this.orderId, value, key).then(response => {
                if (key === 'photos_submitted_for_video_production') {
                    history.push(`/Admin/${this.orderId}/order-edit?orderDetailCode=settings`);
                    history.go();
                }
            });
        }

    }

    allowReorderHandler = (orderId, value) => {
        let tempValue = !value;
        tempValue = tempValue ? 1 : 0;
        console.log("e.target", tempValue);
        this.props.allowReorderStatusCall(orderId, { allow_reorder: tempValue }).then(
          response => {
            this.props.getOrderSettingsCall(this.orderId);
          }
        );
    }

    doNotProcessAiJobStatus = (value) => {
        const val = value ? 0 : 1;
        console.log("item selected", val);
        const data = {
            do_not_process_ai_job: val
        }
        orderService.doNotProcessAiJobStatus(this.orderId, data).then(response => {
            console.log("response",response);
            this.props.getOrderSettingsCall(this.orderId);
        });
    }

    render() {
        const { orderSettings, order_details, allow_reorder_days_diff, orderResources, is_ttk } = this.props;
        const { reOpenModal, is_ai_job_in_process } = this.state;
        const { is_5_years_old_order } = allow_reorder_days_diff;
        return (
            <div className='content'>
                <div className='secHeading' >Order Settings</div>
                <div className='row' >
                    <div className='col-md-6'>

                        {/* <Card title='Custom Hashtag' bsClass={["innerCard orderSettingText"]} content={
                            <form className='form-inline' onSubmit={this.hashTagFormHandler} >
                                <div className="flexElem">
                                    <input type='text' name='hashTag' className='form-control' placeholder='Enter hashtag' value={this.state.hashTag} onChange={this.inputChangeHandler} />
                                    <Button type='submit' className="customBtn" bsStyle='primary' >Change hashtag</Button>
                                </div>
                                {this.validator.message('hashTag', this.state.hashTag, 'required')}
                                <p className='text-muted' >
                                    If no custom hashtag is set, the default hashtag is the Tour Operator Name
                                    <br />
                                    (Spaces removed )in company profile
                                    <br />
                                    This order default hashtag is {order_details.agency.name.replaceAll(' ', '')}
                                </p>
                            </form>
                        } /> */}
                        {/* <Card title='Mapping Messaging Feature' bsClass={["innerCard orderSettingText"]} content={
                            <form onSubmit={this.allowMessagingFormHandler} >
                                <div className='container-check' >
                                    <label>
                                        Do not allow anyone to see or use the mapping/messaging feature
                                    <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_mapping_messaging.key, +orderSettings.do_not_allow_mapping_messaging.value)} checked={+orderSettings.do_not_allow_mapping_messaging.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='container-check' >
                                    <label>
                                        Do not allow travelers to see or use the mapping/messaging feature only
                                    <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_traveler_to_use_map.key, +orderSettings.do_not_allow_traveler_to_use_map.value)} checked={+orderSettings.do_not_allow_traveler_to_use_map.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='container-check' >
                                    <label>
                                        Do not allow travelers to see anyone on the map
                                    <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_traveler_to_see_all_on_trip.key, +orderSettings.do_not_allow_traveler_to_see_all_on_trip.value)} checked={+orderSettings.do_not_allow_traveler_to_see_all_on_trip.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </form>
                        } /> */}

                        <Card title='Mapping Messaging Feature' bsClass={["innerCard orderSettingText"]} content={
                            <form onSubmit={this.allowMessagingFormHandler} >
                                <div className='container-check' >
                                    <label>Do not allow anyone to see or use the mapping feature
                                        <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_anyone_to_use_mapping.key, +orderSettings.do_not_allow_anyone_to_use_mapping.value)} checked={+orderSettings.do_not_allow_anyone_to_use_mapping.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='container-check' >
                                    <label>Do not allow anyone to use the messaging feature
                                        <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_anyone_to_use_messaging.key, +orderSettings.do_not_allow_anyone_to_use_messaging.value)} checked={+orderSettings.do_not_allow_anyone_to_use_messaging.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='container-check' >
                                    <label>Do not allow travelers to see or use the mapping feature
                                        <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_travelers_to_use_mapping.key, +orderSettings.do_not_allow_travelers_to_use_mapping.value)} checked={+orderSettings.do_not_allow_travelers_to_use_mapping.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='container-check' >
                                    <label>Do not allow travelers to use the messaging feature
                                        <input type='checkbox' onChange={() => this.updateOrderSettingsHandler(orderSettings.do_not_allow_travelers_to_use_messaging.key, +orderSettings.do_not_allow_travelers_to_use_messaging.value)} checked={+orderSettings.do_not_allow_travelers_to_use_messaging.value} className='checkbox' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </form>
                        } />

                        <Card bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                <div className='header' style={{ padding: 0 }}>
                                    <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                        <React.Fragment>
                                            {!+orderSettings.approved_for_video_upload.value ?
                                                <h4 className='headerTitle title m-0'>Audio/Video Uploading Disabled</h4>
                                                :
                                                <h4 className='headerTitle title m-0'>Audio/Video Uploading Approved</h4>
                                            }
                                        </React.Fragment>
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={themeCSSConstants.THUMBICONSTYLE}
                                            trackStyle={{ height: '10px', width: '30px' }}
                                            thumbStyle={themeCSSConstants.THUMBSTYLE}
                                            thumbAnimateRange={[0, 15]}
                                            value={+orderSettings.approved_for_video_upload.value}
                                            onToggle={() => this.updateOrderSettingsHandler(orderSettings.approved_for_video_upload.key, +orderSettings.approved_for_video_upload.value)}
                                        />
                                    </div>
                                </div>
                                <p className='text-muted' >This approves user for this order to upload music and videos</p>

                                    {/* ---------- Show Audio Video Files and downloadable --------*/}

                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table_tile mt-md">
                                                <thead>
                                                <tr className="border-bottom">
                                                    <th width="35%">File Name</th>
                                                    <th width="25%">Uploaded Date</th>
                                                    <th width="20%">Action</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    orderResources &&
                                                    'audio_video' in orderResources &&
                                                    orderResources.audio_video.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td><a style={{wordBreak: 'break-all'}}
                                                                    className='blue_link' target="_blank"
                                                                    href={item.file_path}
                                                                    rel="noopener noreferrer">{item.original_file_name}</a>
                                                            </td>
                                                            <td>{getFormattedDate(item.created_at)}</td>
                                                            <td>
                                                                <a className='ml-md' href={item.file_path}><i
                                                                    className='fa fa-download'></i></a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        } />
                        <Card bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                <div className='header' style={{ padding: 0 }}>
                                    <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                        <React.Fragment>
                                            {!+orderSettings.approved_for_upload.value ?
                                                <h4 className='headerTitle title m-0'>Photo Uploading is Disabled</h4>
                                                :
                                                <h4 className='headerTitle title m-0'>Photo Uploading is Approved</h4>
                                            }
                                        </React.Fragment>
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={themeCSSConstants.THUMBICONSTYLE}
                                            trackStyle={{ height: '10px', width: '30px' }}
                                            thumbStyle={themeCSSConstants.THUMBSTYLE}
                                            thumbAnimateRange={[0, 15]}
                                            value={+orderSettings.approved_for_upload.value}
                                            onToggle={() => this.updateOrderSettingsHandler(orderSettings.approved_for_upload.key, +orderSettings.approved_for_upload.value)}
                                        />
                                    </div>
                                </div>
                                <React.Fragment>
                                    {!+orderSettings.approved_for_upload.value ?
                                        <p className='text-muted' >This unapproves users for this order to upload photos to the website and the mobile App</p>
                                        :
                                        <p className='text-muted' >This approves users for this order to upload photos to the website and the mobile App</p>
                                    }
                                </React.Fragment>

                            </React.Fragment>
                        } />
                        <Card bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                <div className='header' style={{ padding: 0 }}>
                                    <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                        <React.Fragment>
                                        {!+orderSettings.disable_traveler_for_upload.value ?
                                            <h4 className='headerTitle title m-0'>Traveler Upload is Approved</h4>
                                            :
                                            <h4 className='headerTitle title m-0'>Traveler Upload is Disabled</h4>
                                        }
                                        </React.Fragment>
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={themeCSSConstants.THUMBICONSTYLE}
                                            trackStyle={{ height: '10px', width: '30px' }}
                                            thumbStyle={themeCSSConstants.THUMBSTYLE}
                                            thumbAnimateRange={[0, 15]}
                                            value={!+orderSettings.disable_traveler_for_upload.value}
                                            onToggle={() => this.updateOrderSettingsHandler(orderSettings.disable_traveler_for_upload.key, +orderSettings.disable_traveler_for_upload.value)}
                                        />

                                    </div>
                                </div>
                                <React.Fragment>
                                    {!+orderSettings.disable_traveler_for_upload.value ?
                                        <p className='text-muted' >(PA and Traveler can upload photos)</p>
                                    :
                                        <p className='text-muted'>(Only Photo Admins would be allowed to upload photos)</p>
                                    }
                                </React.Fragment>
                            </React.Fragment>
                        } />
                        <Card bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                <div className='header' style={{ padding: 0 }}>
                                    <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                        <React.Fragment>
                                            {!+orderSettings.auto_approve_photos.value ?
                                                <h4 className='headerTitle title m-0'>Auto Post is Disabled</h4>
                                                :
                                                <h4 className='headerTitle title m-0'>Auto Post is Approved</h4>
                                            }
                                        </React.Fragment>

                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={themeCSSConstants.THUMBICONSTYLE}
                                            trackStyle={{ height: '10px', width: '30px' }}
                                            thumbStyle={themeCSSConstants.THUMBSTYLE}
                                            thumbAnimateRange={[0, 15]}
                                            value={+orderSettings.auto_approve_photos.value}
                                            onToggle={() => this.updateOrderSettingsHandler(orderSettings.auto_approve_photos.key, +orderSettings.auto_approve_photos.value)}
                                        />
                                    </div>
                                </div>
                                <React.Fragment>
                                    {!+orderSettings.auto_approve_photos.value ?
                                        <p className='text-muted' >
                                            Uploaded photos will NOT be auto posted to the gallery for traveler viewing. The GL/Admin will have to approve each photo for viewing in the gallery.
                                        </p>
                                        :
                                        <p className='text-muted' >
                                            Uploaded photos will be auto posted to the gallery for traveler viewing.
                                        </p>
                                    }
                                </React.Fragment>
                            </React.Fragment>
                        } />
                        {
                            is_5_years_old_order &&
                            <Card bsClass={["innerCard orderSettingText"]} content={
                                <React.Fragment>
                                    <div className='header' style={{ padding: 0 }}>
                                        <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                            <React.Fragment>
                                            {orderSettings.allow_reorder ?
                                                <h4 className='headerTitle title m-0'>Allow Online Reorder Approved</h4>
                                                :
                                                <h4 className='headerTitle title m-0'>Allow Online Reorder Disabled</h4>
                                            }
                                            </React.Fragment>
                                            <ToggleButton
                                                inactiveLabel={''}
                                                activeLabel={''}
                                                colors={themeCSSConstants.THUMBICONSTYLE}
                                                trackStyle={{ height: '10px', width: '30px' }}
                                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                thumbAnimateRange={[0, 15]}
                                                value={orderSettings.allow_reorder}
                                                onToggle={() => this.allowReorderHandler(this.orderId, orderSettings.allow_reorder)}
                                            />

                                        </div>
                                    </div>
                                    <p className='text-muted' >
                                        (If this is checked then more than 5 years old order will allow ReOrders  )
                                </p>
                                </React.Fragment>
                            } />
                        }
                    </div>
                    <div className='col-md-6' >
                        <Card title={(is_ttk && !+this.props.orderSettings.photos_submitted_for_video_production.value) ? 'Submit Order/Upload Complete' :(!is_ttk && !+this.props.orderSettings.photos_submitted_for_video_production.value) ? 'Submit for video production' : 'Reopen Photo Upload'} bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                {!+this.props.orderSettings.photos_submitted_for_video_production.value ?
                                    <React.Fragment>
                                        <div className="flexElem">

                                            <Button onClick={() => this.updateOrderSettingsHandler(this.props.orderSettings.photos_submitted_for_video_production.key, +this.props.orderSettings.photos_submitted_for_video_production.value)} type='submit' className="customBtn" bsStyle='primary' >{is_ttk ? 'Submit Order/Upload Complete' : 'Submit for video production'}</Button>

                                            <div className='container-check' >
                                                <label>Do not Process Ai Job
                                                    <input type='checkbox' onChange={() => this.doNotProcessAiJobStatus(orderSettings.do_not_process_ai_job)} checked={+orderSettings.do_not_process_ai_job} className='checkbox' />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <p className='text-muted'>
                                            Photos have NOT Been Submitted. Uploading is Still Open.
                                    </p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {/*
                                            <Button onClick={() => this.updateOrderSettingsHandler(this.props.orderSettings.photos_submitted_for_video_production.key, +this.props.orderSettings.photos_submitted_for_video_production.value)} type='submit' className="customBtn" bsStyle='primary' >Reopen photo upload </Button>
                                        */}
                                        <Button onClick={() => this.openReOpenModal()} className="customBtnRedTxt" bsStyle='primary' >Reopen photo upload</Button>
                                        <p className='text-muted' >
                                            This will reopen photo uploading for this order
                                        <br />
                                            Photos were submitted at {dateTimeWithAMPM(this.props.orderSettings.photos_submitted_for_video_production.updated_at)} by {this.props.orderSettings.photos_submitted_for_video_production.updated_by_user ? this.props.orderSettings.photos_submitted_for_video_production.updated_by_user.name : ''}. Uploading has closed.
                                    <br />
                                            Photos are ready for production.
                                    </p>
                                    </React.Fragment>
                                }
                                {
                                    +this.props.orderSettings.photos_submitted_for_video_production.value === 1 &&
                                        'finalize_by_qa' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_qa.value, 10) === 0 &&
                                        Number(order_details.do_not_process_ai_job) === 1 &&
                                        <p style={{ color: 'tomato' }}>
                                            Ai will not process this job. Please Handle LPR!!
                                        </p>
                                }
                                {
                                    +this.props.orderSettings.photos_submitted_for_video_production.value === 1 &&
                                        'finalize_by_qa' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_qa.value, 10) === 0 &&
                                        is_ai_job_in_process === 1 &&
                                            <p style={{ color: 'tomato' }}>
                                                The AI task is currently underway. Kindly await for approximately 10 minutes, and you'll receive an email once the task is completed.
                                            </p>
                                }
                                {
                                    +this.props.orderSettings.photos_submitted_for_video_production.value === 1 &&
                                        'finalize_by_qa' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_qa.value, 10) === 0 &&
                                        is_ai_job_in_process === 2 &&
                                            <p style={{ color: '#21b521' }}>
                                                The AI task has finished. Please Handle LPR!!
                                            </p>
                                }
                                <Card title='Upload Cut Off Date' bsClass={["innerCard orderSettingText"]} content={
                                    <form onSubmit={this.uploadCutOffDateFormHandler} className='form-inline' >
                                        <div className="flexElem">
                                            <DatePicker autoComplete='off' dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} className=' form-control calender_icon' onChange={this.uploadCutOffDateChangeHandler} selected={new Date(this.state.uploadCutOffDate) || ''} />
                                            <Button type='submit' className="customBtn" bsStyle='primary' >Change upload cut off date</Button>
                                        </div>
                                    </form>
                                } />
                            </React.Fragment>
                        } />
                        <Card title='Max Photos Allowed For Upload' bsClass={["innerCard orderSettingText"]} content={
                            <form className='form-inline' onSubmit={this.maxPhotosUploadFormHandler} >
                                <div className="flexElem">
                                    <strong>Max Upload:</strong>
                                    <input type='text' name='maxPhotosUpload' onChange={this.inputChangeHandler} className='form-control' value={this.state.maxPhotosUpload} placeholder='max files upload' />
                                    <Button type='submit' className="customBtn" bsStyle='primary' >Apply</Button>
                                </div>
                                {this.validator.message('Max Photos Upload', this.state.maxPhotosUpload, 'required|integer')}
                            </form>
                        } />
                        <Card bsClass={["innerCard orderSettingText"]} content={
                            <React.Fragment>
                                <div className='header' style={{ padding: 0 }}>
                                    <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>
                                        <React.Fragment>
                                            <h4 className='headerTitle title m-0'>Do Not Send SMS</h4>
                                        </React.Fragment>
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={themeCSSConstants.THUMBICONSTYLE}
                                            trackStyle={{ height: '10px', width: '30px' }}
                                            thumbStyle={themeCSSConstants.THUMBSTYLE}
                                            thumbAnimateRange={[0, 15]}
                                            value={+orderSettings.do_not_send_sms.value}
                                            onToggle={() => this.updateOrderSettingsHandler(orderSettings.do_not_send_sms.key, +orderSettings.do_not_send_sms.value)}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        } />
                        {
                            !orderSettings.is_itk_order &&
                        
                            <Card bsClass={["innerCard orderSettingText"]} content={
                                <React.Fragment>
                                    <div className='header' style={{ padding: 0 }}>
                                        <div className='flexElem flexResponsive align-items-center' style={{ justifyContent: 'flex-start' }}>                                        
                                            <React.Fragment>
                                                <h4 className='headerTitle title m-0'>Enable Traveler-to-Traveler Messaging</h4>
                                            </React.Fragment>
                                            {                                            
                                                orderSettings.is_allow_one_to_one_chat ?
                                                <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={''}
                                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                                    trackStyle={{ height: '10px', width: '30px' }}
                                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                    thumbAnimateRange={[0, 15]}
                                                    value={+orderSettings.is_allow_one_to_one_chat.value}
                                                    // onToggle={() => this.updateOrderSettingsHandler(orderSettings.is_allow_one_to_one_chat.key, +orderSettings.is_allow_one_to_one_chat.value)}
                                                    onToggle={() => {
                                                        if (!orderSettings.is_itk_order) { // Only allow toggling if not disabled
                                                            this.updateOrderSettingsHandler(orderSettings.is_allow_one_to_one_chat.key, +orderSettings.is_allow_one_to_one_chat.value);
                                                        }
                                                    }}
                                                />
                                                :
                                                <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={''}
                                                    colors={themeCSSConstants.THUMBICONSTYLE}
                                                    trackStyle={{ height: '10px', width: '30px' }}
                                                    thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                    thumbAnimateRange={[0, 15]}
                                                    value={0}
                                                    onToggle={() => this.updateOrderSettingsHandler ('is_allow_one_to_one_chat', 0)}
                                                />
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            } />
                        }

                        {
                            !is_ttk && 'finalize_by_qa' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_qa.value, 10) === 1 &&                            
                                <Card title='Video Tracks' bsClass={["innerCard orderSettingText"]} content={
                                    <div>
                                        <table className="table customTable table-bordered">
                                            <thead>
                                            <tr className="border-bottom">
                                                <th width="30%">Video Type</th>
                                                <th width="25%">Number of Streams</th>
                                                <th width="25%">Number of Downloads</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>Full Video</td>
                                                    <td className="text-center">{orderSettings.full_video_view_count}</td>
                                                    <td className="text-center">{orderSettings.full_video_download_count} </td>
                                                </tr>
                                                <tr>
                                                    <td>Highlight Video</td>
                                                    <td className="text-center">{orderSettings.highlight_video_view_count} </td>
                                                    <td className="text-center">{orderSettings.highlight_video_download_count} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                } />
                        }
                        {/* <Card title='Upload Cut Off Date' bsClass={["innerCard orderSettingText"]} content={
                            <form onSubmit={this.uploadCutOffDateFormHandler} className='form-inline' >
                                <div className="flexElem">
                                    <DatePicker autoComplete='off' className=' form-control calender_icon' onChange={this.uploadCutOffDateChangeHandler} selected={this.state.uploadCutOffDate || ''} />
                                    <Button type='submit' className="customBtn" bsStyle='primary' >Change upload cut off date</Button>
                                </div>
                            </form>
                        } /> */}
                    </div>
                </div>

                <Modal size="sm" backdrop={'static'} show={reOpenModal} id="" onHide={closeModal.bind(this, "reOpenModal")} aria-labelledby="ModalHeader" className="" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Reopen Photo Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="clearfix">
                            <div className="flexElem">
                                <div style={{ 'width': '100%' }}>
                                    {
                                        'finalize_by_editor' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_editor.value, 10) === 1
                                        &&
                                        <React.Fragment><span>Finalized By Editor</span><br /></React.Fragment>
                                    }
                                    {
                                        'finalize_by_pm' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_pm.value, 10) === 1
                                        &&
                                        <React.Fragment><span>Finalized By Production Manager</span><br /></React.Fragment>
                                    }
                                    {
                                        'finalize_by_qa' in this.props.orderSettings && parseInt(this.props.orderSettings.finalize_by_qa.value, 10) === 1
                                        &&
                                        <React.Fragment><span>Finalized By QA</span><br /></React.Fragment>
                                    }
                                </div>
                                <div style={{ 'width': '100%' }}>
                                    <span>Upload cut off Date : {dateFormatMy(order_details.order_dates.upload_cut_off_date.value)}</span>
                                </div>
                            </div>

                            <button onClick={closeModal.bind(this, "reOpenModal")} className="customBtn btn  btn-primary btn btn-default"> Back</button>
                            <OverlayTrigger placement="right" overlay={
                                <Tooltip id="tooltip" className="static" >
                                    Unprocess all production and Continue with reopen photo upload
                                </Tooltip>
                            }>
                                <Button onClick={() => this.updateOrderSettingsHandler(this.props.orderSettings.photos_submitted_for_video_production.key, +this.props.orderSettings.photos_submitted_for_video_production.value)} type='submit' className="customBtn ml-md" bsStyle='primary' > Unprocess all production </Button>
                            </OverlayTrigger>


                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { orderSettings, order_details, allow_reorder_days_diff, orderResources } = state.ordersReducer;
    return { orderSettings, order_details, allow_reorder_days_diff, orderResources }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderSettingsCall: (orderId) => { dispatch(getOrderSettings(orderId)) },
        updateOrderUploadCutOffDateCall: (orderId, value) => { dispatch(updateOrderUploadCutOffDate(orderId, value)) },
        allowReorderStatusCall: (orderId, value) => { return dispatch(allowReorderStatus(orderId, value)) },
        getAllowReorderCall: (orderId) => { return dispatch(getAllowReorder(orderId)) },
        updateOrderSettingsCall: (orderId, value, key) => { return dispatch(updateOrderSettings(orderId, value, key)) },
        updateOrderSettingsForTtkCall: (orderId, value, key) => { return dispatch(updateOrderSettingsForTtk(orderId, value, key)) },
        getOrderResourcesByRoleCall: (orderId) => { dispatch(getOrdersResourcesByRole(orderId))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSettings);
