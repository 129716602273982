import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { editedImagesAi, producedImagesAi, replaceImageAi, getOrder, assignUserToOrder } from 'redux/actions/order-actions';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import ImagesAi from './ImagesAi';
import { Modal, OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import { orderService } from '../../../services/order';
import { alertActions } from '../../../redux/actions/alert-actions';
import { baseUrl } from '../../../helper/ApiCall';
import {rotateImageAi} from 'redux/actions/imageActions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { hasRole, s3StaticPath, closeModal, orderSumamaryAi } from '../../../helper/helperFunctions';
import ReactImageMagnify from 'react-image-magnify';
import AiModalTraining from './AiModalTraining';
import { updateSettingsData } from 'redux/actions/settings-actions';
import { history } from 'helper/history';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertAi from 'components/ThemeComponents/ConfirmAlertAi';
import SimilarImagesAi from './SimilarImagesAi';

const SortableItem = SortableElement(({ image, item_index, selectImageHandler, section, flag, imageSize, rotateImageAi, handleSetAiImageFlag, openSimilarImageModal, setMoveImageId, movedImage, moveImageHandler, handleIncludeInFullVideo, showImgData, gridView }) => {
        return (
            <ImagesAi 
                selectImageHandler={selectImageHandler}
                openSimilarImageModal={openSimilarImageModal}
                imageSize={imageSize}
                movedImage={movedImage}
                handleSetAiImageFlag={handleSetAiImageFlag}
                setMoveImageId={setMoveImageId}
                moveImageHandler={moveImageHandler}
                handleIncludeInFullVideo={handleIncludeInFullVideo}
                rotateImageAi={rotateImageAi}
                showImgData={showImgData} 
                gridView={gridView} 
                section={section}   
                image={image}
                index={item_index} 
                rand={image.rand ? image.rand : null}
                time={new Date().toISOString()}
                flag={flag}
            />
        )
    }
);

const ImagesList = SortableContainer(({ images, selectImageHandler,  section, flag, disabled, imageSize, rotateImageAi, handleSetAiImageFlag, openSimilarImageModal, setMoveImageId, movedImage, moveImageHandler, handleIncludeInFullVideo, showImgData, gridView }) => {
    return (
        <div className='child-grid' tabIndex={-1} style={{paddingTop: '20px'}}>
            {
                images.map((image, i) => {
                    return (
                        <SortableItem 
                            selectImageHandler={selectImageHandler}
                            handleSetAiImageFlag={handleSetAiImageFlag}
                            openSimilarImageModal={openSimilarImageModal}
                            setMoveImageId={setMoveImageId}
                            movedImage={movedImage}
                            moveImageHandler={moveImageHandler}
                            handleIncludeInFullVideo={handleIncludeInFullVideo}
                            rotateImageAi={rotateImageAi}
                            showImgData={showImgData}
                            gridView={gridView}
                            imageSize={imageSize}
                            disabled={disabled}
                            section={section}  
                            key={i}
                            image={image}
                            flag={flag} 
                            index={i}
                            item_index={i}
                        />
                    );
                })
            }
        </div>
    )
});

class ImageGalleryAi extends Component {
    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
            editedImagesData: this.props.editedImagesAi,
            producedImagesData: this.props.producedImagesAi,
            pageSize: '',
            sizes: ['thumb', 'thumb_mid', 'thumb_large', 'thumb_xlarge', 'thumb_xxlarge', 'thumb_xxxlarge', 'full'],
            fullIndex: 6,
            imageSize: 3,
            section: '',
            flag:'',
            movePositionProdcuer: '',
            movePositionEdited: '',
            openImageModal: false,
            selectedImage: null,
            toPosition: '',
            orderId: this.props.orderId,
            modalName:'',
            imageLoading: true,
            tabElement: 'tabs',
            showImgData: false,
            uploadFile: false,
            file: null,
            replaceImageCollapse: false,
            gridView: false,
            movedImage: null,
            textVal: '',
            openSimilarImageModal: false,
            similarImage: null,
        };
        this.props.editedImagesAiCall(this.state.orderId);
        this.props.producedImagesAiCall(this.state.orderId);

    }

    componentDidMount() {
        // Add event listeners for keyboard hotkeys
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (this.props.editedImagesAi !== prevProps.editedImagesAi) {
            this.setState({
                editedImagesData: this.props.editedImagesAi,
            });
        }
        if (this.props.producedImagesAi !== prevProps.producedImagesAi) {
            this.setState({
                producedImagesData: this.props.producedImagesAi,
            });
        }
        if (this.state.openImageModal !== prevState.openImageModal) {
            if (this.state.openImageModal === false) {
                console.log('openImageModal calls must be true');
                if (this.state.movePositionEdited || this.state.movePositionProdcuer){
                    this.handleGoToPosition();
                }
            }
            this.setState({ toPosition: '', imageLoading: true, file: null, replaceImageCollapse: false});
        }
    }

    componentWillUnmount() {
        // Remove event listeners when component unmounts
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        // Check if the right arrow key is pressed
        if (event.key === 'ArrowRight') {
            this.showNextImage();
        }
        // Check if the left arrow key is pressed
        else if (event.key === 'ArrowLeft') {
            this.showPreviousImage();
        }
    };

    inputPositionChangeHandler = (e) => {
        const inputValue = e.target.value;

        // Ensure the entered value is within the desired range
        const sanitizedValue = Math.min(inputValue, Object.keys(this.state.producedImagesData).length);
        this.setState({toPosition: sanitizedValue});
    }
  
    handleImageClick = (event, image) => {
        console.log("handleImageClick", image);
        let name = '';
        // if (image.type === "Producer") {
        if (image.use_in_full_video === 1) {
            name = 'Producer';
        } else {
            name = 'Editor';
        }
        this.setState({ modalName: name});
        this.setState({ openImageModal: true, selectedImage: image });
    };
  
    executeScroll = (e) => {
        const { producedImagesData, editedImagesData, movePositionProdcuer, movePositionEdited, modalName } = this.state;
        // const flag = e.target.name;
        const flag = modalName;
        let foundImage;
        let elementId;
        let lastIndex;
        let lastImagePostFix;
        console.log("flag", flag);
        if (flag === "Producer") {
            foundImage = producedImagesData.find(image => image.producer_sort_order == movePositionProdcuer);
            lastIndex = producedImagesData.length - 1;
            lastImagePostFix = producedImagesData[lastIndex].producer_sort_order

        } else if (flag === "Edited") {
            foundImage = editedImagesData.find(image => image.user_sort_order == movePositionEdited);
            lastIndex = editedImagesData.length - 1;
            lastImagePostFix = editedImagesData[lastIndex].user_sort_order
        }
  
        if (foundImage) {
           const key = flag === "Producer" ? "producer_sort_order" : "user_sort_order";
           const type = flag === "Producer" ? "Producer" : "Edited";
           elementId = `image-row-${type}-${foundImage[key]}`;
           console.log("if elementId: " , elementId);
        }else{
            const type = flag === "Producer" ? "Producer" : "Edited";
            const inputPosition = flag === "Producer" ? movePositionProdcuer : movePositionEdited;
            const inputPositionInt = parseInt(inputPosition);
            const nextImage = flag === "Producer" ? producedImagesData.find(image => image.producer_sort_order > inputPositionInt) : editedImagesData.find(image => image.user_sort_order > inputPositionInt);
            const key = flag === "Producer" ? "producer_sort_order" : "user_sort_order";
            const nextPosition = nextImage ? nextImage[key] : lastImagePostFix;
            elementId = `image-row-${type}-${nextPosition}`;
            // const type = flag === "Producer" ? "Producer" : "Edited";

            // elementId = `image-row-${type}-${lastImagePostFix}`;
        }
        if (elementId) {
            console.log("before getElementById: " , elementId);
            const element = document.getElementById(elementId);
            console.log("after getElementById", elementId);
            console.log("element", element);
            const tabElement = document.getElementById('p_goto');
            const e_goto_element = document.getElementById('e_goto');

            if (element) {
                element.classList.add("highlight-scroll-ai");
                    element.scrollIntoView(true);

                    setTimeout(() => {
                        element.classList.remove("highlight-scroll-ai");
                    }, 3000);

                    if (!this.state.gridView) {
                        
                        setTimeout(() => {
                            if (flag === "Producer") {
                                tabElement.scrollIntoView({ behavior: "smooth" });
                            }
                            if (flag === "Edited") {
                                e_goto_element.scrollIntoView({ behavior: "smooth" });
                            }
                        }, 2000);
                    }
            }
        }
    };

    handleGoToPosition = (e) => {
        this.executeScroll(e);
        this.setState({
            movePositionProdcuer: '',
            movePositionEdited: '',
        });
        
    };
    
    onSortEndWorking = ({ oldIndex, newIndex }) => {
        console.log('onSortEndWorking', oldIndex, newIndex);
        const {orderId} = this.state;
        if (oldIndex !== newIndex) {
            let producedImagesData = this.state.producedImagesData;

            producedImagesData[oldIndex] = { ...producedImagesData[oldIndex], moved: 1, highlighted:false };
            producedImagesData = arrayMove(producedImagesData, oldIndex, newIndex);

            this.setState({
                producedImagesData: producedImagesData,
            }, () => {
                this.setState({openImageModal: false})
                const data = {
                    images: this.state.producedImagesData,
                    sortOrder: "drag"
                }
                orderService.moveAiImages(orderId, data).then((response) => {
                    this.props.success(response.message);
                    this.props.producedImagesAiCall(orderId);
                    this.props.editedImagesAiCall(orderId);
                })
            });
        }
    };
  
    onSortEndOriginal = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let editedImagesData = this.state.editedImagesData;

            editedImagesData[oldIndex] = { ...editedImagesData[oldIndex], moved: 1, highlighted:false };
            editedImagesData = arrayMove(editedImagesData, oldIndex, newIndex);

            this.setState({
                editedImagesData: editedImagesData
            });
        }
    };

    sortStart = ({index}) => {
        console.log("Sort start", index);
    }

    handleSetAiImageFlag = (image, key) => {
        const { orderId } = this.state; 
        const url = new URL(image.edited_image_path);
        const pathname = url.pathname.slice(1);
        const data = {
            image_id: image.id,
            key: key,
            value: image[key] === 1 ? 0 : 1,
            pathname: pathname,
            file_name: image.file_title
        }
        orderService.setAiImageFlag(orderId, data).then((response) => {
            this.props.editedImagesAiCall(orderId);
            this.props.producedImagesAiCall(orderId).then((response) => {
                if (this.state.selectedImage) {
                    setTimeout(() => {
                        const images = response.producedImagesAi.images;
                        const selectedImage = images.filter(item => {
                            return Number(item.producer_sort_order) === this.state.selectedImage.producer_sort_order;
                        });
                        this.setState({ selectedImage: null, imageLoading: true });
                        
                        this.setState({ selectedImage: selectedImage[0]});
                    }, 1000);
                }
            });
        })
    }

    handleProducerMoveAiImages = (image) => {
        const { orderId, toPosition, producedImagesData } = this.state; 
        let newIndex = Number(toPosition);
        let oldIndex = image.producer_sort_order;
        
        
        if (oldIndex !== newIndex) {

            producedImagesData[oldIndex-1] = { ...producedImagesData[oldIndex-1], moved: 1 };
            this.setState((prevState) => {
                const producedImagesData = arrayMove(prevState.producedImagesData, oldIndex - 1, newIndex - 1);
                return {
                    producedImagesData: producedImagesData,
                };
            }, () => {
                const data = {
                    images: this.state.producedImagesData,
                    sortOrder: "drag",
                }
                orderService.moveAiImages(orderId, data).then((response) => {
                    this.props.success(response.message);
                    this.props.producedImagesAiCall(orderId).then((response) => {
                        setTimeout(() => {
                            const images = response.producedImagesAi.images;
                            const selectedImage = images.filter(item => {
                                return Number(item.id) === this.state.selectedImage.id;
                            });
                            this.setState({ selectedImage: null, imageLoading: true });
                            
                            this.setState({ selectedImage: selectedImage[0]});
                        }, 1000);
                    });
                    this.props.editedImagesAiCall(orderId);
                })
            });
        }
    }

    handleEditedMoveAiImages = (image, flag, is_similar = false) => {
        const { toPosition, orderId } = this.state;
        let newIndex = toPosition;
        if (!newIndex) {
            newIndex = image.user_sort_order;
        }
        this.setState((prevState) => {

            // Insert the new image object at the specified position
            let producedImagesData = [...prevState.producedImagesData];
            producedImagesData.splice(newIndex - 1, 0, image);

            // updating in_appropriate value
            producedImagesData = producedImagesData.map((item, index) => {
                if (item.id === image.id && item.is_inappropriate === 1) {
                    return { ...item, is_inappropriate: null };
                }
                // if (item.id === image.id && is_similar) {
                //     return { ...item, duplicate_group_of: null, is_duplicate_group: 0 }
                // }
                return item;
            });

            // Update the producer_sort_order values based on the new array order
            producedImagesData.forEach((item, index) => {
                item.producer_sort_order = index + 1;
            });

            return {
                producedImagesData: producedImagesData,
            };
        }, () => {
            const data = {
                images: this.state.producedImagesData,
                sortOrder: "drag",
                flag: flag,
                image_id: image.id
            }
            orderService.moveAiImages(orderId, data).then((response) => {
                // if (is_similar) {
                //     this.handleGetSimilarImages();
                // }
                this.props.success(response.message);
                this.setState({ openImageModal: false, selectedImage: null });
                this.props.producedImagesAiCall(orderId);
                this.props.editedImagesAiCall(orderId);
            })
        });
    }
    handleIncludeInFullVideo = (image, flag, is_similar = false) => {
        const { toPosition, orderId } = this.state;
        let newIndex = toPosition;
        if (!newIndex) {
            newIndex = image.user_sort_order;
        }
        this.setState((prevState) => {

            // Insert the new image object at the specified position
            let producedImagesData = [...prevState.producedImagesData];
            producedImagesData.splice(newIndex - 1, 0, image);

            // updating in_appropriate value
            producedImagesData = producedImagesData.map((item, index) => {
                if (item.id === image.id && item.is_inappropriate === 1) {
                    return { ...item, is_inappropriate: null };
                }
                // if (item.id === image.id && is_similar) {
                //     return { ...item, duplicate_group_of: null, is_duplicate_group: 0 }
                // }
                return item;
            });

            // Update the producer_sort_order values based on the new array order
            producedImagesData.forEach((item, index) => {
                item.producer_sort_order = index + 1;
            });

            return {
                producedImagesData: producedImagesData,
            };
        }, () => {
            const data = {
                images: this.state.producedImagesData,
                sortOrder: "drag",
                flag: flag,
                image_id: image.id
            }
            orderService.includeInFullVideo(orderId, data).then((response) => {
                if (is_similar) {
                    this.handleGetSimilarImages();
                }
                this.props.success(response.message);
                this.setState({ openImageModal: false, selectedImage: null });
                this.props.producedImagesAiCall(orderId);
                this.props.editedImagesAiCall(orderId);
            })
        });
    }
    handleGetSimilarImages = () => {
        if (this.childRef.current) {
            this.childRef.current.getSimilarImages();  // Call the child's function
        }
    };
    rotateImageAi = async (id, direction, version_type = 'working', orderId = null) => {
		const res = await this.props.rotateImageAiCall(id, direction, version_type, this.state.orderId);
        if (res) {
            this.props.producedImagesAiCall(this.state.orderId).then((response) => {
                setTimeout(() => {
                    const images = response.producedImagesAi.images;
                    const selectedImage = images.filter(item => {
                        return Number(item.id) === id;
                    });
                    console.log("selectedImage", selectedImage[0]);
                    this.setState({ selectedImage: null, imageLoading: true });
                    
                    this.setState({ selectedImage: selectedImage[0]});
                }, 500);
            });
        }
	}

    handleImageLoad = () => {
        this.setState({ imageLoading: false });
    };

    showNextImage = () => {

        if (this.state.modalName === 'Producer') {
            // Find the index of the current image in the array
            const currentIndex = this.state.producedImagesData.findIndex(image => image.id === this.state.selectedImage.id);
            
            // Get the index of the next image
            const nextIndex = currentIndex + 1;
            // Check if the next index is within the bounds of the array
            if (nextIndex >= 0 && nextIndex < this.state.producedImagesData.length) {
                // First setState null of selectedImage to show loading image
                this.setState({ selectedImage: null, imageLoading: true });
                // Set the state to show the next image
                this.setState({ selectedImage: this.state.producedImagesData[nextIndex], movePositionProdcuer: this.state.producedImagesData[nextIndex].producer_sort_order  });
            }
        } else {
            // Find the index of the current image in the array
            const currentIndex = this.state.editedImagesData.findIndex(image => image.id === this.state.selectedImage.id);
            
            // Get the index of the next image
            const nextIndex = currentIndex + 1;
            // Check if the next index is within the bounds of the array
            if (nextIndex >= 0 && nextIndex < this.state.editedImagesData.length) {
                // First setState null of selectedImage to show loading image
                this.setState({ selectedImage: null, imageLoading: true });
                // Set the state to show the next image
                this.setState({ selectedImage: this.state.editedImagesData[nextIndex], movePositionEdited: this.state.editedImagesData[nextIndex].user_sort_order });
            }
        }
    };

    showPreviousImage = () => {
        
        if (this.state.modalName === 'Producer') {
            // Find the index of the current image in the array
            const currentIndex = this.state.producedImagesData.findIndex(image => image.id === this.state.selectedImage.id);
            
            // Get the index of the next image
            const nextIndex = currentIndex - 1;
            
            // Check if the next index is within the bounds of the array
            if (nextIndex >= 0 && nextIndex < this.state.producedImagesData.length) {
                // First setState null of selectedImage to show loading image
                this.setState({ selectedImage: null, imageLoading: true });
                // Set the state to show the next image
                this.setState({ selectedImage: this.state.producedImagesData[nextIndex], movePositionProdcuer: this.state.producedImagesData[nextIndex].producer_sort_order });
            }
        } else {
            // Find the index of the current image in the array
            const currentIndex = this.state.editedImagesData.findIndex(image => image.id === this.state.selectedImage.id);
            
            // Get the index of the next image
            const nextIndex = currentIndex - 1;
            
            // Check if the next index is within the bounds of the array
            if (nextIndex >= 0 && nextIndex < this.state.editedImagesData.length) {
                // First setState null of selectedImage to show loading image
                this.setState({ selectedImage: null, imageLoading: true });
                // Set the state to show the next image
                this.setState({ selectedImage: this.state.editedImagesData[nextIndex], movePositionEdited: this.state.editedImagesData[nextIndex].user_sort_order });
            }
        }
    };

    setResourceFile = (key, value) => {
		this.setState({ file: value, uploadFile: false })
	}
	removeFile = (key, value) => {
		this.setState({
			file:  null,
            uploadFile: false
		});
	}

    submitFile = async() => {
        const {file, selectedImage} = this.state;
        let img_id = selectedImage.id;
        const fd = new FormData();
        fd.append('value', file);
        fd.append('imageId', selectedImage.id);
        fd.append('orderId', this.props.orderId);
        fd.append('folder', 'working');

        const res = await this.props.replaceImageAiCall(fd, this.props.orderId);
        if (res) {
            this.setState({file: null});
            setTimeout(() => {
                const images = this.state.producedImagesData;
                const selectedImage = images.filter(item => {
                    return Number(item.id) === img_id;
                });
                this.setState({ selectedImage: null, imageLoading: true });
                    
                        this.setState({ selectedImage: selectedImage[0]});
                console.log("selectedImage", selectedImage);
            }, 2000);
        }
    }

    finalize = () => {
		const { orderId } = this.props;
		var settingData = {
			value: 1,
		};
		this.props.updateSettingsDataCall(orderId, 'finalize_by_editor', settingData).then(response => {
			this.props.getOrderCall(orderId);
			if (hasRole(['editor','Remote Editor','Remote Editor/Producer'])) {
				history.push(`dashboard`);
			} else if (hasRole('admin', 'gm admin')) {
				history.push(`/Admin/dashboard/editor`);
			}

		});
	}

    assignProducerToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.props.userAdmin.user.id, role: 'producer' }).then(response => {
			if (hasRole(['admin', 'gm admin'])) {
				history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`);
				history.go();
			} else {
				history.push(`/Admin/${orderId}/order-edit?imageCode=productionManager`);
				history.go();
			}
		});
	}

    restoreAiImages = () => {
        const { orderId } = this.state;
        orderService.resortAiImages(orderId).then((response) => {
            console.log("Restoring response", response);
            
            this.props.producedImagesAiCall(orderId);
        })
    }

    setMoveImageId = (image) => {
        console.log("setMoveImageId", image.id);
        this.setState({ movedImage: image})
        
    }

    moveImageHandler = (image) => {
        if (this.state.movedImage) {
            console.log("moveImageHandler", image.id);
            const { orderId, movedImage, producedImagesData } = this.state; 
            const movedImg = producedImagesData.filter(item => {
                return Number(item.id) === movedImage.id;
            });
            
            let oldIndex = movedImg[0].producer_sort_order;
            let newIndex = image.producer_sort_order;

            if (oldIndex !== newIndex) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <ConfirmAlertAi
                                textAreaChangeHandler={(e) => {this.textAreaChangeHandler(e)}}
                                val={this.state.textVal} 
                                header="Confirmation"
                                description="Are you sure you want to move the selected image here?"
                                yesLabel='Yes'
                                noLabel='No'
                                onClosePro={onClose}
                                onYes={() => {this.saveMovedImages(newIndex, oldIndex, image)}}
                            />
                         )
                    }
                  })
            }
        }
    }

    saveMovedImages = (newIndex, oldIndex, image) => {
        console.log("function callllll");

        const {textVal, orderId, movedImage, producedImagesData} = this.state;
        if (this.state.textVal) {
            let correct_image_id = null;
            let wrong_image_id = null;
            const classes = [];
            let temp = { id: 7, value: textVal }
            classes.push(temp);

            console.log("condition true", movedImage, image.producer_sort_order -1);
            
            const c_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === Number(movedImage.producer_sort_order) - 1;
            });
            console.log("Correct image", c_image);
            
            const w_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === image.producer_sort_order - 1;
            });

            console.log("Wrong image", w_image);
            if (c_image && w_image) {
                correct_image_id = c_image[0] ? c_image[0].id : null;
                wrong_image_id = w_image[0] ? w_image[0].id : null;
            }

            const data = {
                image_id: movedImage.id,
                correct_image_id: wrong_image_id,
                wrong_image_id: correct_image_id,
                order_id: image.model_id,
                file_path: image.file_path + image.image_versions.original_file_name + '.' + image.image_versions.extension,
                model_id: 7,
                classes: classes ? classes : null,
                value: textVal ? textVal : null
            }

            orderService.storeModelTrainingData(data).then((response) => {
                console.log("Saving model response", response);
                if (response) {
                    this.setState({ textVal: ''})
                    this.props.success(response.message);
                }
            })
            
        }

        producedImagesData[oldIndex-1] = { ...producedImagesData[oldIndex-1], moved: 1 };
            this.setState((prevState) => {
                const producedImagesData = arrayMove(prevState.producedImagesData, oldIndex - 1, newIndex - 1);
                return {
                    producedImagesData: producedImagesData,
                };
            }, () => {
                const data = {
                    images: this.state.producedImagesData,
                    sortOrder: "drag",
                }
                orderService.moveAiImages(orderId, data).then((response) => {
                    this.props.success(response.message);
                    this.setState({ movedImage: null});
                    this.props.producedImagesAiCall(orderId);
                })
            });
    }

    textAreaChangeHandler = (e) => {
        const val = e.target.value;
        this.setState({ textVal: val });
    };

    downloadImage = async (image) => {
        const downloadUrl = image.edited_image_path + '?updated_at=' + image.updated_at;
        
        try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', image.image_versions.original_file_name + '.' + image.image_versions.extension); // Set file name and extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            console.log("Image download initiated");
        } catch (error) {
            console.error("Image download failed:", error);
        }
    };
    
    openSimilarImageModal = (image) => {
        console.log("openSimilarImageModal called");
        
        this.setState({openSimilarImageModal: !this.state.openSimilarImageModal, similarImage: image});
    };

    openImage = (path) => {
        const link = document.createElement('a');
        link.href = path;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    render() {
        const { editedImagesData, producedImagesData, gridView,
                openImageModal, selectedImage, toPosition, modalName,
                orderId, sizes, showImgData, imageLoading, openSummaryModal, openSimilarImageModal } = this.state;
        const { order_details, userAdmin } = this.props;
        let imageTagsArray = [
            { condition: selectedImage && selectedImage.is_cropped === 1, text: 'Cropped' },
            { condition: selectedImage && selectedImage.is_inappropriate === 1, text: 'Inappropriate' },
            { condition: selectedImage && selectedImage.is_backlit === 1, text: 'Backlit' },
            { condition: selectedImage && selectedImage.is_duplicate_group === 1, text: 'Duplicate Group' },
            { condition: selectedImage && selectedImage.is_rotated === 1, text: 'Rotated' },
            { condition: selectedImage && selectedImage.copy_of === 1, text: 'Copy for End' },
            { condition: selectedImage && selectedImage.is_backlit_fixed === 1, text: 'Backlit Fixed' },
            { condition: selectedImage && selectedImage.is_low_quality === 1, text: 'Low Quality' },
            { condition: selectedImage && selectedImage.is_tilted === 1, text: 'Tilt' }
        ];
        return (
            <div className=''>
                    <div id="p_goto" className='ai_uper_content_main flexElem flexGap10'>
                        <div className='ai_goto_content'>
                            <span style={{whiteSpace: 'nowrap'}}>
                                {Object.keys(producedImagesData).length + ' Ready For Video'}
                            </span>
                            <div style={{margin: '0 25px 0 15px', cursor: 'pointer', whiteSpace:'nowrap'}} onClick={(e) => {
                                this.handleGoToPosition(e);
                                }}>
                                    <img name="Producer" src={"/images/goto.svg"} className='mr-sm'/>
                                    Go To
                            </div>
                            <input placeholder='Goto position #' type="number" name="Producer" min="0" className='form-control' style={{width: '30%'}} value={this.state.movePositionProdcuer}
                                onChange={(e) => this.setState({ movePositionProdcuer: e.target.value })}
                                onKeyPress={(e) => e.key === 'Enter' && this.handleGoToPosition(e)} />
                            <span className='ml-sm'>
                                <button name='resort_ai_images' className="customBtn btn btn-primary btn-default" onClick={() => {this.setState({openSummaryModal: !this.state.openSummaryModal})}} >Summary</button>
                            </span>
                        </div>
                        <div className="pl-sm flexElem flexWrap" style={{maxWidth:260}}>                            
                            {
                                Number(order_details.settings.on_hold.value) !== 1 && Object.keys(producedImagesData).length > 0
                                    ?
                                    "finalize_by_editor" in order_details.settings &&
                                        (
                                            hasRole(['admin', 'gm admin']) ||
                                            (
                                                hasRole(['editor', 'Remote Editor','Remote Editor/Producer']) && (order_details.editor_id === userAdmin.user.id)
                                            )
                                        )
                                        ?
                                        parseInt(order_details.settings.finalize_by_editor.value, 10) === 0
                                            ?
                                            <button type="button" className="orderDetailsAction finalizeIcon" onClick={this.finalize} >Finalize</button>
                                            :
                                            null
                                            // order_details.settings.finalize_by_editor.updated_by_user != null &&
                                            // <span style={{ 'fontSize': '10px', 'fontWeight': 'bold' }} >Finalized by {order_details.settings.finalize_by_editor.updated_by_user.name} and uploaded {Object.keys(producedImagesData).length}</span>
                                        :
                                        null
                                    :
                                    null
                            }
                            {
                                (order_details.editor && order_details.editor_id !== null) &&
                                <span className='mr-md' style={{ 'fontWeight': 'bold' }} >Editor : {order_details.editor.name}</span>
                            }<br />
                            {/* {
                                Object.keys(producedImagesData).length > 0 &&
                                <span style={{ 'color': 'red' }} >Total Uploaded Edited Photos: {Object.keys(producedImagesData).length}</span>
                            } */}

                            
                            {
                                Number(order_details.settings.finalize_by_editor.value) !== 1 &&
                                this.props.userAdmin.user.id !== order_details.editor_id &&
                                Number(order_details.settings.on_hold.value) !== 1 &&
                                order_details.editor_id !== null &&
                                hasRole(['admin', 'gm admin']) &&
                                order_details.originalImages > 0 &&
                                parseInt(order_details.settings.photos_renamed.value, 10) === 1 &&
                                <React.Fragment>


                                    <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for editing process'}</Popover>}>
                                        <button style={{ 'fontSize': '10px',textAlign:'start' }} className="orderDetailsAction assignIcon" onClick={() => this.assignEditorToOrder(orderId)} >Assign Me For Editing Process</button>
                                    </OverlayTrigger>
                                </React.Fragment>
                            }

                            {

                                "finalize_by_editor" in order_details.settings && hasRole(['producer', 'gm admin', 'admin','Remote Producer','Remote Editor/Producer']) && order_details.producer_id === null
                                    ?
                                    parseInt(order_details.settings.finalize_by_editor.value, 10) === 1
                                        ?
                                        <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for production process'}</Popover>}>
                                            <button className="orderDetailsAction assignIconAI" onClick={() => this.assignProducerToOrder(orderId)} >Assign Me For Production Process</button>
                                        </OverlayTrigger>
                                        :
                                        null
                                    :
                                    null

                            }

                        </div>
                        <div className='aiGalleryPos'>
                            <div className="flexElem flexColumn" >                                
                                <div className='flexElem alignCenter flexGap5'>
                                    <div className="">
                                        <input type="checkbox"
                                            onChange={() => this.setState({gridView: !this.state.gridView})}
                                            id="switchgridView" checked={this.state.gridView ? 'checked' : ''}
                                            className="cssSwitch mr-md"/>
                                        <label className="cssSwitch-sm" htmlFor="switchgridView">Toggle</label>
                                    </div>
                                    <div className="">
                                        Grid View
                                    </div>
                                </div>                                
                                <div className='flexElem alignCenter flexGap5'>
                                    <div className="">
                                        <input type="checkbox"
                                            onChange={() => this.setState({showImgData: !this.state.showImgData})}
                                            id="switchShowImgData" checked={this.state.showImgData ? 'checked' : ''}
                                            className="cssSwitch mr-md"/>
                                        <label className="cssSwitch-sm" htmlFor="switchShowImgData">Toggle</label>
                                    </div>    
                                    <div className="">
                                        Gallery Info
                                    </div>
                                </div>
                            </div>
                            <span className='flexElem ml-auto'>
                                <button name='resort_ai_images' className="customBtn btn btn-primary btn-default" onClick={this.restoreAiImages} >Reposition</button>
                                {/* <a href={`${baseUrl}downloadProducedImagesAi/${orderId}`} target="_blank" className="downloadIcon" style={{fontSize: '20px'}}>Download Images</a> */}
                            </span>
                        </div>
                    </div>
                <div className={`companyImagesGrid hasMargin grid_${this.state.imageSize - 1}`} id="working-images">

                    <div className={`vscroll${gridView ? " img-grid":" img-scroll"}`}>
                        <ImagesList 
                            handleSetAiImageFlag={this.handleSetAiImageFlag}
                            setMoveImageId={this.setMoveImageId}
                            movedImage={this.state.movedImage}
                            moveImageHandler={this.moveImageHandler}
                            imageSize={sizes[this.state.imageSize - 1]} 
                            selectImageHandler={this.handleImageClick}
                            executeScroll={this.executeScroll}
                            rotateImageAi={this.rotateImageAi}
                            onSortEnd={this.onSortEndWorking} 
                            onSortStart={this.sortStart}
                            images={producedImagesData} 
                            showImgData={showImgData} 
                            gridView={gridView} 
                            lockToContainerEdges={true}
                            openPopup={this.openPopup} 
                            transitionDuration={400} 
                            flag={this.state.flag}
                            helperClass='moving' 
                            section="Producer"
                            disabled={false}
                            distance={5}
                            axis='xy' 
                        />
                    </div>
                </div>
                <div id="e_goto" className='ai_uper_content_main flexElem' style={{padding: 20, backgroundColor: 'khaki'}}>
                    <div className='ai_goto_content'>
                        <span style={{whiteSpace: 'nowrap'}}>
                            {Object.keys(editedImagesData).length + ' Omitted Images'}
                        </span>
                        <div style={{margin: '0 10px 0 30px', cursor: 'pointer', whiteSpace:'nowrap'}} onClick={(e) => {
                            this.handleGoToPosition(e);
                            }}>
                                <img name="Edited" src={"/images/goto.svg"} className='mr-sm'/>
                                Go To
                        </div>
                        <input placeholder='Goto position #' type="number" name="Edited" min="0" className='form-control' style={{width: '30%'}} value={this.state.movePositionEdited}
                            onChange={(e) => this.setState({ movePositionEdited: e.target.value })}
                            onKeyPress={(e) => e.key === 'Enter' && this.handleGoToPosition(e)} />
                    </div>
                    <div style={{marginLeft: 'auto'}}>
                        <span className=''>
                            <a href={`${baseUrl}downloadEditedImagesAi/${orderId}`} target="_blank" className="downloadIcon" style={{fontSize: '20px'}}>Download Images</a>
                        </span>
                    </div>
                </div>
                <div className={`companyImagesGrid hasMargin grid_${this.state.imageSize - 1}`} id="original-images">
                    <div className={`vscroll ${gridView ? " img-grid":" img-scroll"}`}>
                        <ImagesList 
                            handleEditedMoveAiImages={this.handleEditedMoveAiImages}
                            handleIncludeInFullVideo={this.handleIncludeInFullVideo}
                            openSimilarImageModal={this.openSimilarImageModal} 
                            imageSize={sizes[this.state.imageSize - 1]} 
                            selectImageHandler={this.handleImageClick}
                            openPopup={this.openPopup} axis='xy' 
                            onSortEnd={this.onSortEndOriginal} 
                            executeScroll={this.executeScroll}
                            onSortStart={this.sortStart} 
                            lockToContainerEdges={true}
                            images={editedImagesData}
                            showImgData={showImgData}  
                            gridView={gridView}  
                            transitionDuration={400} 
                            flag={this.state.flag}
                            helperClass='moving' 
                            section="Edited"
                            disabled={true}
                            distance={5}
                        />
                    </div>
                </div>

                <Modal show={openImageModal}
                    onHide={() => { this.setState({openImageModal: false, selectedImage: null }) }}
                    dialogClassName="ai-custom-modal"
                    aria-labelledby="ModalHeader"
                    backdrop={false}
                    >
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div style={{position: 'relative'}}>
                                    <a className='ai_image_left_move' onClick={this.showPreviousImage}>
                                        <button className='ai_image_arrows' onClick={this.showPreviousImage}>
                                            <img src={"/images/lessthan2.svg"} width={30} alt="previous" />
                                            </button>
                                    </a>
                                    {
                                        imageLoading && (
                                            <div className='modal-img flexElem flex-center alignCenter' style={{ minHeight: '60vh' }}>
                                                <img src={"/images/loading200.gif"} alt="Loading..." />
                                            </div>
                                        )
                                    }
                                    {
                                        selectedImage &&
                                        <img className='modal-img' src={selectedImage.edited_image_path + "?updated_at=" + selectedImage.updated_at}
                                        alt="Image" 
                                        onClick={() => {this.openImage(selectedImage.edited_image_path + "?updated_at=" + selectedImage.updated_at)}}
                                        onLoad={this.handleImageLoad} 
                                        style={{ display: imageLoading ? 'none' : '' }} />
                                        // <ReactImageMagnify
                                        //     {...{
                                        //         smallImage: {
                                        //             alt: 'Image',
                                        //             isFluidWidth: true,
                                        //             src: `${selectedImage.image_versions.file_path}?updated_at=${selectedImage.updated_at}`,
                                        //             onLoad: this.handleImageLoad,
                                        //             style: { display: imageLoading ? 'none' : '' }
                                        //         },
                                        //         largeImage: {
                                        //             src: `${selectedImage.image_versions.file_path}?updated_at=${selectedImage.updated_at}`,
                                        //             width: 1200,
                                        //             height: 1800
                                        //         },
                                        //         isHintEnabled: true,
                                        //         shouldUsePositiveSpaceLens: true
                                        //     }}
                                        // />
                                    }
                                    <a className='ai_image_right_move' onClick={this.showNextImage}>
                                        <button className='ai_image_arrows' onClick={this.showNextImage}>
                                            <img src={"/images/greaterthan2.svg"} width={30} alt="next" />
                                            </button>
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='vidImageOverflow scrollStyle'>
                                    <Modal.Header closeButton style={{marginBottom: '15px'}} >
                                        <Modal.Title id='ModalHeader' className="headerTitle">
                                            {
                                                modalName === 'Producer' ? 'Ready For Video ' : 'AI Edited '
                                            }Image
                                        </Modal.Title>
                                    </Modal.Header>
                                    {
                                        selectedImage &&
                                        <div className='flexElem spaceBetween mb-md flex-center alignCenter'>
                                            <span className="headerTitle">
                                                Position {
                                                    modalName === 'Producer' ? 
                                                    selectedImage.producer_sort_order :
                                                    selectedImage.user_sort_order
                                                }
                                                <small>
                                                    {
                                                        modalName === 'Producer' ? 
                                                        ` (of ${Object.keys(producedImagesData).length})` :
                                                        ` (of ${Object.keys(editedImagesData).length})`
                                                    }
                                                </small>
                                            </span>
                                            {
                                                modalName === 'Producer' &&
                                                <div className='flexElem ' style={{justifyContent: 'center'}}>
                                                    <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                                        this.downloadImage(selectedImage)
                                                        }}>
                                                        <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Dowload Image</Tooltip>}>
                                                            <i style={{fontSize: '14px', width: '100%'}} className="fa fa-download"
                                                            aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                                        this.rotateImageAi(selectedImage.id, 'left')
                                                        }}>
                                                        <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Rotate Left</Tooltip>}>
                                                            <i style={{fontSize: '14px', width: '100%'}} className="fa fa-undo"
                                                            aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                                        this.rotateImageAi(selectedImage.id, 'right')
                                                        }}>
                                                        <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Rotate Right</Tooltip>}>
                                                            <i style={{fontSize: '14px', width: '100%'}} className="fa fa-repeat"
                                                            aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                                        this.handleSetAiImageFlag(selectedImage, 'use_in_full_video')
                                                        }}>
                                                        <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Do not include in video</Tooltip>}>
                                                            <i style={{fontSize: '15px', width: '100%'}} className="fa fa-ban"
                                                            aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        selectedImage &&
                                        <div className='row'>
                                            <div className='col-md-12 text-center mt-lg'>
                                                {
                                                    imageTagsArray.map((tag, index) => (
                                                    tag.condition && <small key={index} className="ai-tags">{tag.text}</small>
                                                ))}
                                            </div>
                                            <div className='col-md-12 text-center'>
                                                <hr style={{borderStyle: 'dashed'}} />
                                        
                                            </div>
                                            {
                                                modalName === 'Producer' &&
                                                <React.Fragment>
                                                    <div className='col-md-12 text-center'>
                                                        <button className='btn btn-info imageModalBtnAi' style={{fontSize: '16px'}} onClick={() => this.handleProducerMoveAiImages(selectedImage)}> Move to </ button>
                                                        <input onChange={(e) => this.inputPositionChangeHandler(e)} value={toPosition} type="number" placeholder="000" className="input-ai" />
                                                    </div>

                                                    <div className='col-md-12 text-center mt-lg'> 
                                                        <button className='btn btn-info imageModalBtnAi mb-md' style={{fontSize: '16px'}} onClick={() => this.handleSetAiImageFlag(selectedImage, 'use_in_full_video')}>Do Not Include in Video</ button>
                                                        {
                                                            selectedImage && !selectedImage.is_inappropriate ?
                                                            <button className={'btn btn-info imageModalBtnAi text-center mb-md'} style={{fontSize: '16px'}} onClick={() => this.handleSetAiImageFlag(selectedImage, 'is_inappropriate')}> Mark Inappropriate </ button>
                                                            : null
                                                        }
                                                        <button className={'btn btn-info imageModalBtnAi mb-md'} style={{fontSize: '16px'}} onClick={() => this.handleSetAiImageFlag(selectedImage, 'is_backlit')}> Fix Backlit </ button>
                                                            
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                // selectedImage.type === 'Edited' &&
                                                <React.Fragment>
                                                    {
                                                        selectedImage.use_in_full_video !== 1 &&
                                                        <div className='col-md-12 text-center mt-lg'> 
                                                            <button className='btn btn-info imageModalBtnAi' style={{fontSize: '16px'}} onClick={() => this.handleEditedMoveAiImages(selectedImage, 'use_in_full_video')}>Include in Video</ button>

                                                            <input onChange={(e) => this.inputPositionChangeHandler(e)} value={toPosition} type="number" placeholder="Position #" className="text-center form_tile_input input-ai mb-md" style={{width: '116px'}} />
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                    {
                                        modalName === 'Producer' && 
                                        <div className="section-frontEnd">
                                            <div className="form_tile">
                                                <div className="form_tile_heading flexElem alignCenter spaceBetween" id="" style={{cursor: 'pointer'}} onClick={() => {this.setState({replaceImageCollapse: !this.state.replaceImageCollapse})}}>
                                                    <div className="flexElem alignCenter">
                                                        <img src={s3StaticPath('img/documents.png')} alt="Documents" />
                                                    
                                                            <span>Replace File</span>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.replaceImageCollapse ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                                                    </div>
                                                </div>
                                                    { this.state.replaceImageCollapse &&
                                                    <React.Fragment>
                                                        <div className="mt-xlg pt-lg">
                                                            <div id="File">
                                                                <div className={"pofilePicContainer " + (this.state.uploadFile ? 'disableDropzone' : '')}>
                                                                    {
                                                                        !this.state.file ?
                                                                            <div className='form-group dropzone_profileImage'>
                                                                                <FileUpload
                                                                                    files={this.state.file}
                                                                                    setResourceFile={this.setResourceFile}
                                                                                    allowedFormatsStringMimePro={'image/jpeg, image/png, video/quicktime, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                                                                    allowedFormatsStringExtPro={'jpg, png, docx, doc, Jpeg, pdf, xls, xlsx, mov, mp4'}
                                                                                    multiple={false}
                                                                                    askToUpdate={true}
                                                                                    updateWithoutAsking={false}
                                                                                />
                                                                            </div>
                                                                            :
                                                                                <ImagePreview
                                                                                    hideVideoControls
                                                                                    filesToPreview={this.state.file}
                                                                                    removeFile={this.removeFile}
                                                                                    resetImage={true}
                                                                                />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row flexElem flex-center alignCenter'>
                                                            {
                                                                this.state.file &&
                                                                <button className={'btn btn-info imageModalBtnAi mb-md'} onClick={this.submitFile} style={{width: 'calc(100% - 50px)', color: 'white'}}>
                                                                Upload
                                                            </ button>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                    }
                                            </div>
                                            {/* <div className='col-md-6'>  */}
                                                <AiModalTraining image={selectedImage} producedImagesData={producedImagesData}/>
                                            {/* </div> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal bsSize="lg" backdrop={'static'} show={openSummaryModal} id="" onHide={closeModal.bind(this, "openSummaryModal")} aria-labelledby="ModalHeader" className="" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Order Summary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container table-responsive mt-xlg">
                            {
                                orderSumamaryAi(order_details, Object.keys(producedImagesData).length, this.props.non_exif_images_count)
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                {
                    openSimilarImageModal &&
                    <SimilarImagesAi
                        openSimilarImageModal={openSimilarImageModal}
                        handleIncludeInFullVideo={this.handleIncludeInFullVideo}
                        onHide={closeModal.bind(this, "openSimilarImageModal")}
                        parentImage={this.state.similarImage}
                        order_id={orderId}
                        ref={this.childRef}
                    />
                }
            </div>
        );
    }
}
  
function mapStateToProps(state) {
    const { editedImagesAi, producedImagesAi, non_exif_images_count } = state.ordersReducer;
	const { userAdmin } = state.adminReducer;
    return {
        producedImagesAi,
        editedImagesAi,
        non_exif_images_count,
        userAdmin
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        editedImagesAiCall: (orderId) => { dispatch(editedImagesAi(orderId)); },
        producedImagesAiCall: (orderId) => { return dispatch(producedImagesAi(orderId)); },
        rotateImageAiCall: (imageId, direction, version_type, orderId) => { return dispatch(rotateImageAi(imageId, direction, version_type, orderId)) },
		replaceImageAiCall: (imagesData, orderId) => { return dispatch(replaceImageAi(imagesData, orderId)) },
        success: (message) => { return dispatch(alertActions.success(message)) },
		updateSettingsDataCall: (model_id, key, settingData) => { return dispatch(updateSettingsData(model_id, key, settingData)) },
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
		assignUserToOrderCall: (orderId, data) => { return dispatch(assignUserToOrder(orderId, data)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageGalleryAi);