import React, { Component } from "react";
import Select from 'react-select';
import { connect } from 'react-redux';
import { getProgramItem } from 'redux/actions/settings-actions';
import { getIsInvoiced } from 'redux/actions/order-actions';
import { toggleModal, inputChangeHandler, selectChangeHandler, returnLabelWithValueGroup, returnDollars, returnPounds, returnLabelWithValue } from 'helper/helperFunctions';
import Card from "components/Card/Card";
import { confirmAlert } from "react-confirm-alert";
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import SimpleReactValidator from 'simple-react-validator';
import { getItems } from 'redux/actions/item-actions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import {
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import { cloneDeep, isEmpty } from 'lodash'
import { hasRole } from "../../../helper/helperFunctions";
class PricingDetailPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pricing_details: { ...this.props.pricing_details },
			order_details: { ...this.props.order_details },
            state_updated: false,
			discounts: [
				{ value: "%", label: "%" },
				{ value: "$", label: "$" }
			],
			additionalServiceModal: false,
			item: OC.ITEMADDITIONALSERVICE,
			selectProgramItemCatTitle: this.props.formType === 'edit' ? this.props.pricing_details.program_type.item_category.title : '',
            newOrderDiscountPrice: 30.00
		};
		this.handleChangePricingDetail = this.handleChangePricingDetail.bind(this);
		this.handleChangePricingOT = this.handleChangePricingOT.bind(this);
		this.handleChangePricingOI = this.handleChangePricingOI.bind(this);
		this.handleChangePricingDetailDropDown = this.handleChangePricingDetailDropDown.bind(this);
		this.total = this.total.bind(this);
		this.subTotal = this.subTotal.bind(this);
		this.totalSR = this.totalSR.bind(this);
		this.grandTotalWithDis = this.grandTotalWithDis.bind(this);
		this.grandTotalWithoutDis = this.grandTotalWithoutDis.bind(this);
		this.getMax = this.getMax.bind(this);
		this.percentage = this.percentage.bind(this);
		this.updatePricingDetail = this.updatePricingDetail.bind(this);
		this.updateOrderTotalDefault = this.updateOrderTotalDefault.bind(this);
		this.returnPricingDetailData = this.returnPricingDetailData.bind(this);
		this.confirmCheckChange = this.confirmCheckChange.bind(this);
		this.ValidatorAS = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.toggleModal = toggleModal.bind(this);
		this.inputChangeHandler = inputChangeHandler.bind(this);
		this.selectChangeHandler = selectChangeHandler.bind(this);
	}
	returnPricingDetailData() {
		return { ...this.state.pricing_details };
	}
	handleChangePricingDetail(e) {
		const { name, value } = e.target;
		const pricingDetailState = cloneDeep(this.state.pricing_details);
		if (e.target.type === 'checkbox') {
			pricingDetailState[name] = e.target.checked;
		}
		else {
			pricingDetailState[name] = value ? parseFloat(value) : 0;
		}
		this.updatePricingDetail(pricingDetailState);
	}
	//Order Tax
	handleChangePricingOT(e, key) {
		const { value } = e.target.value !== "" ? e.target : { ...e.target, value: "" };
		const pricingDetailState = cloneDeep(this.state.pricing_details);
		var tempOT = {
			code: key,
			value: value,
		};
		pricingDetailState.order_totals[key] = tempOT;
		this.updatePricingDetail(pricingDetailState);
	}
	//Order Item
	handleChangePricingOI(e, key) {
        console.log("key: ", key);
		const { name, value } = e.target;
		const orderItemsState = cloneDeep(this.state.pricing_details);
        console.log("key: ", orderItemsState['order_items'][key]);
        console.log("name: ", name);
        console.log("value: ", value);
		orderItemsState['order_items'][key][name] = value !== '' ? parseFloat(Math.round(value)) : '';
        if (this.props.is_ttk) {
            console.log("ttk part execute =========================");
			if (key === 'Videos/Travelers' && value >= 1){
				orderItemsState['order_items'][key]['total'] = (parseFloat(orderItemsState['order_items'][key]['price']));
			} else if (orderItemsState['order_items'][key]['title'] === 'Videos/Travelers' &&  orderItemsState['order_items'][key]['quantity'] == 0 ) {
                orderItemsState['order_items'][key]['total'] = orderItemsState['order_items'][key]['quantity'] * orderItemsState['order_items'][key]['price'];

            }
			else { 
				if(key === '20x30 PhotoMozaix') {
					let itemPrice = parseFloat(orderItemsState['order_items'][key]['price']);
					let quantity = value;
					if (quantity > 1 && quantity < 5) {
						const slabs = ["1,4,44.00"];
						slabs.forEach(slab => {
							slab = slab.split(',');
							if (quantity >= parseInt(slab[0], 10) && quantity <= parseInt(slab[1], 10)) {
								itemPrice = parseFloat(slab[2]);
							}
						})
					} else if (quantity >= 5) {
						itemPrice = this.state.newOrderDiscountPrice;
					}
					console.log("ItemPrice--------------------------------", itemPrice);
					orderItemsState['order_items'][key][name] = quantity;
					orderItemsState['order_items'][key]['price'] = itemPrice.toFixed(2);
					orderItemsState['order_items'][key]['total'] = orderItemsState['order_items'][key][name] * itemPrice;
				}
			}
        } else {
            console.log("else part execute ..........................................");
            orderItemsState['order_items'][key]['total'] = orderItemsState['order_items'][key][name] * (parseFloat(orderItemsState['order_items'][key]['price']));
        }
		if (key === 'Videos/Travelers') {
			orderItemsState['number_of_cameras'] = Math.ceil(value / 55);
		}
		console.log('number_of_sdcard', orderItemsState);
		console.log('test', this.state.selectProgramItemCatTitle);
		this.updatePricingDetail(orderItemsState);
	}

	updatePricingStaticProp = (object, dropDownObject) => {
		// console.log('priging detail', dropDownObject);
		// console.log('priging detail state', object);
		// console.log('value test', object['order_items']);

		// const VidTrav = 'Videos/Travelers' in object['order_items']?parseInt(object['order_items']['Videos/Travelers']['quantity']):0;

		if (dropDownObject.title && dropDownObject.title.includes('USB Flash Drive')) {
			console.log('USB Flash Drive');
			object['number_of_flashdrive'] = 1;
		}
        if ( dropDownObject.label === "%" || dropDownObject.label === "$")
        {

        }
        else if ( dropDownObject.label.includes('ITK@ $20.00') ||  dropDownObject.label.includes('ITK@ £20.00') || dropDownObject.title.includes('Upload DEMO') || dropDownObject.title.includes('Uploading to GTV')) {
            object['number_of_flashdrive'] = 0;
            object['number_of_sdcard'] = 0;
		}
        else{
            object['number_of_flashdrive'] = 1;
			object['number_of_sdcard'] = 1;
        }
		// else if ( dropDownObject.title.includes('SD') ) {
		// 	console.log('SD');
		// 	object['number_of_sdcard'] = Math.ceil( VidTrav/ 35);
		// } else if ( dropDownObject.title.includes('PhotoVision Digital camera') ) {
		// 	console.log('PhotoVision Digital camera');
		// 	object['number_of_cameras'] = Math.ceil( VidTrav/ 35);
		// }
		console.log('object', object);
		return object;
	}
	handleChangePricingDetailDropDown(selectedOptionType, info) {
		if (info['action'] === 'select-option') {
			let pricingDetailDropDown = cloneDeep(this.state.pricing_details);
			pricingDetailDropDown[info['name']] = selectedOptionType['value'];
			pricingDetailDropDown = this.updatePricingStaticProp(pricingDetailDropDown, selectedOptionType);

			if (info['name'] === 'program_type_id') {
				console.log('call program type call');
				this.props.getProgramItemCall(selectedOptionType['value']);
				this.setState({ pricing_details: pricingDetailDropDown, selectProgramItemCatTitle: selectedOptionType.item_category.title });
			} else {
				this.updatePricingDetail(pricingDetailDropDown);
			}
		}
	}
	total(key) {
        console.log("total call");
		var orderItems = cloneDeep(this.state.pricing_details.order_items);
		if (this.props.is_ttk) {
            console.log("ttk part execute =========================");
			
			if (key === '20x30 PhotoMozaix') {
				let itemPrice = parseFloat(orderItems[key]['price']);
				let quantity = orderItems[key]['quantity'];
				if (quantity > 1 && quantity < 5) {
					const slabs = ["1,4,44.00"];
					slabs.forEach(slab => {
						slab = slab.split(',');
						if (quantity >= parseInt(slab[0], 10) && quantity <= parseInt(slab[1], 10)) {
							itemPrice = parseFloat(slab[2]);
						}
					})
				} else if (quantity >= 5) {
					itemPrice = this.state.newOrderDiscountPrice;
				}
				console.log("ItemPrice--------------------------------", itemPrice);
				return orderItems[key]['quantity'] * itemPrice;
				// orderItemsorderItems[key]['quantity'] = quantity;
				// orderItems[key]['total'] = orderItems[key]['quantity'] * itemPrice;
			}
        }
		return orderItems[key]['quantity'] * orderItems[key]['price'];
	}
	subTotal(ObjectPD) {
		var orderItems = ObjectPD.order_items;
		var subTotal = 0;
		for (var key in orderItems) {
			if (key !== 'cams/cards' ) {
                if (key === 'Videos/Travelers' && this.props.is_ttk) {
                    subTotal = Number(orderItems[key]['total']) + subTotal;
                } else if (key === '20x30 PhotoMozaix' && this.props.is_ttk) {
                    subTotal = Number(orderItems[key]['total']) + subTotal;
                } else if (!this.props.is_ttk) {
                    subTotal = Number(orderItems[key]['total']) + subTotal;
                }
            }
		}
		return Number(subTotal.toFixed(2));
	}
	totalSR(ObjectPD) {
		var additional_feesTemp = "additional_fees" in ObjectPD.order_totals ? ObjectPD.order_totals.additional_fees.value : 0;
		var shippingTemp = "shipping" in ObjectPD.order_totals ? ObjectPD.order_totals.shipping.value : 0;
		return Number(additional_feesTemp) + Number(shippingTemp);
	}
	totalDiscount(ObjectPD) {
		var discount = 0;
		var discountTemp = ObjectPD.discount_value;
		var subtotal = this.subTotal(ObjectPD);
		if (ObjectPD.discount_type === "%") {
			discount = Number(this.percentage(subtotal, discountTemp));
		} else if (ObjectPD.discount_type === "$") {
			discount = Number(discountTemp);
		}
		return Number(discount);
	}
	grandTotalWithDis(ObjectPD) {
		var tax = 0;
		var subtotal = this.subTotal(ObjectPD);
		var discount = this.totalDiscount(ObjectPD);
		console.log('ObjectPD', ObjectPD);
		console.log('this.subTotal(ObjectPD)', subtotal);
		if (ObjectPD.taxable) {
			tax = Number(this.calcTax(ObjectPD));
			// tax = Number(this.subTotal(ObjectPD)) + (ObjectPD.order_totals.additional_fees ? Number(ObjectPD.order_totals.additional_fees.value) : 0);
			// tax = this.percentage(tax, taxTemp);
		}
		var grandTotalWithDis = Number(this.totalSR(ObjectPD)) + Number(subtotal) + Number(tax) - Number(discount);
		// var grandTotalWithDis = this.totalSR(ObjectPD) + parseFloat(this.subTotal(ObjectPD) - parseFloat(discount) + parseFloat(tax));
		//change update 4
		// var grandTotalWithDis = this.totalSR(ObjectPD) + parseFloat(this.subTotal(ObjectPD) + parseFloat(tax));
		console.log('grandTotalWithDis = this.totalSR(ObjectPD) + Number(subtotal + tax - discount)',
		grandTotalWithDis+' = '+this.totalSR(ObjectPD)+' + '+subtotal+' + '+tax+' - '+discount

		);
        if (grandTotalWithDis < 0){
            grandTotalWithDis = 0;
        }
		return Number(grandTotalWithDis.toFixed(2));
	}
	grandTotalWithoutDis(ObjectPD) {
		var tax = 0;
		// var taxTemp = "tax" in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value : 0;
		if (ObjectPD.taxable) {
			tax = Number(this.calcTax(ObjectPD));
			// tax = Number(this.subTotal(ObjectPD)) + (ObjectPD.order_totals.additional_fees ? Number(ObjectPD.order_totals.additional_fees.value) : 0);
			// tax = this.percentage(tax, taxTemp);
		}
		var subtotal = this.subTotal(ObjectPD);
		var grandTotalWithoutDis = this.totalSR(ObjectPD) + subtotal + tax;
        if (grandTotalWithoutDis < 0){
            grandTotalWithoutDis = 0;
        }
		console.log('grandTotalWithoutDis', grandTotalWithoutDis);
		return Number(grandTotalWithoutDis.toFixed(2));
	}
	getMax(ObjectPD) {
		var option = ObjectPD.discount_type;
		// var tax = 0;
		var max = 0;
		// var taxTemp = "tax" in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value : 0;
		// if (ObjectPD.taxable) {
		// 	tax = this.percentage(this.subTotal(ObjectPD), taxTemp);
		// }
		if (option === "%") {
			max = parseInt(100, 10);
		} else if (option === "$") {
			max = Number(this.subTotal(ObjectPD)).toFixed(2);
			// max = parseFloat(this.subTotal(ObjectPD) + this.totalSR(ObjectPD) + parseFloat(tax)).toFixed(2);
		}
		return max;
	}
	percentage(num, per) {
		console.log('percentage(num, per)', num, per);
		return (per / 100) * num;
	}
	updatePricingDetail(ObjectPD) {
		var pricing_details = this.updateOrderTotalDefault(ObjectPD);
		this.setState({ pricing_details: pricing_details });
		// this.props.returnState(ObjectPD);
		// this.props.pricingDetailUpdate(ObjectPD);
	}
	updateOrderTotalDefault(ObjectPD) {
		var subTem = {
			code: 'sub_total',
			value: Number(this.subTotal(ObjectPD)),
		}
		var discountTem = {
			code: 'discount',
			value: Number(this.totalDiscount(ObjectPD)),
		}

		var grandTem = {
			code: 'grand_total',
			value: Number(this.grandTotalWithDis(ObjectPD)),
		}

		if (hasRole(['gm admin'])) {
			var shippingTem = {
				code: 'shipping',
				value: 0,
			}
			const order_items = cloneDeep(ObjectPD.order_items);
			for (let key in order_items) {
				if (order_items.hasOwnProperty(key)) {
					let item = order_items[key];
					if (Number(item.quantity) > 0) {
						shippingTem = {
							code: 'shipping',
							value: 4,
						}
						// One item has quantity > 0, so no need to check further
						break;
					} 
				}
			}
		}


		// let OldtaxTemp = 0;
		// if (ObjectPD.taxable) {
		// 	OldtaxTemp = this.percentage(subTem.value, 'tax' in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value : 0);
		// }
		// grandTem.value -= OldtaxTemp;

		// let additional_feesTemp = "additional_fees" in ObjectPD.order_totals ? ObjectPD.order_totals.additional_fees.value : 0;
		// subTem.value -= discountTem.value
		// subTem.value = subTem.value
		// let newtaxTemp = 0;
		// if (ObjectPD.taxable) {
		// 	newtaxTemp = this.percentage(subTem.value, 'tax' in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value : 0);
		// }
		// grandTem.value += newtaxTemp
		// subTem.value += Number(additional_feesTemp)
		// subTem.value = subTem.value

		// console.log(grandTem -);
		// subTem.value += taxTemp
		// subTem.value -= discountTem.value
		// subTem.value += parseFloat(additional_feesTemp)
		if (subTem.value >= 0)
			ObjectPD.order_totals['sub_total'] = subTem;
		else
			delete ObjectPD.order_totals['sub_total'];

		if (grandTem.value >= 0)
			ObjectPD.order_totals['grand_total'] = grandTem;
		else
			delete ObjectPD.order_totals['grand_total'];

		if (discountTem.value >= 0 && ObjectPD['discount_value'] >= 0)
			ObjectPD.order_totals['discount'] = discountTem;
		else
			delete ObjectPD.order_totals['discount'];
		
		if (hasRole(['gm admin'])) {
			if (shippingTem.value >= 0)
				ObjectPD.order_totals['shipping'] = shippingTem;
			else
				delete ObjectPD.order_totals['shipping'];
		}

		return ObjectPD;
	}
	handlePricingDetailCheckBoxSettings = (e) => {
		// console.log('check box 789');
		const { name, checked } = e.target;

		this.setState(prevState => ({
			...prevState, pricing_details: {
				...prevState.pricing_details, settings: {
					...prevState.pricing_details.settings, [name]: {
						value: checked ? 1 : 0
					}
				}
			}
		}), () => {
			if (name === 'no_gl_copy' || name === 'send_gl_fd_copy') {
				var TempDigital = '';
				if (name === 'no_gl_copy')
					TempDigital = 'send_gl_fd_copy';
				else
					TempDigital = 'no_gl_copy';

				this.setState(prevState => ({
					...prevState, pricing_details: {
						...prevState.pricing_details, settings: {
							...prevState.pricing_details.settings, [TempDigital]: {
								value: 0
							}
						}
					}
				}));
			}
		});
	}
	confirmCheckChange(e) {
		const target = e.target
		if (target.checked) {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='custom-ui'>
							<div className="react-confirm-alert-body text-center">
								<h3 style={{ "color": "#4f7bea", "fontSize": "20px" }}>Agency Tax Collect</h3>
								<p className="marginFromTopPopUp" >Agency does not want to collect tax. Do you still want to collect tax?</p>
								<div className="react-confirm-alert-button-group alignFlexButtons">
									<button onClick={onClose} className="btn btn-danger"><i className="pe-7s-close-circle"></i> No</button>
									<button name={e.target.name} type={e.target.type} value={e.target.value ? 1 : 0} checked={e.target.checked} className="btn btn-success" onClick={(e) => {
										this.handleChangePricingDetail(e);
										onClose();
									}}>
										<i className="pe-7s-check"></i> Yes</button>
								</div>
							</div>
						</div>
					);
				}
			})
		} else {
			this.handleChangePricingDetail(e);
		}

	}
	componentDidMount() {
            this.props.provideCtrlPD({
                returnPricingDetailData: () => this.returnPricingDetailData()
            });
	
	}
	componentWillUnmount() {
		this.props.provideCtrlPD(null);
		this.props.getIsInvoicedCall(this.props.orderId);
	}
	componentDidUpdate(prevProps, prevState) {
		if ((this.props.programItems !== prevProps.programItems) && !this.props.loadingSettings) {
			// console.log('this props programItems', this.props.programItems);
			const orderItemsState = cloneDeep(this.state.pricing_details.order_items);
			for (var key in this.props.programItems) {
				if (key !== 'cams/cards') {
                    // if ( !this.props.is_ttk || !this.props.formType === 'edit') {
                        var priceTemp = this.props.programItems[key].item_program_price ? parseFloat(this.props.programItems[key].item_program_price.pivot.price) : this.props.programItems[key].price;
                        priceTemp = parseFloat(priceTemp);
                        console.log("price updateddd...................");
                        console.log('before ', orderItemsState[this.props.programItems[key].title]);

                        orderItemsState[this.props.programItems[key].title] = {
                            ...orderItemsState[[this.props.programItems[key].title]],
                            item_id: this.props.programItems[key].id,
                            title: this.props.programItems[key].title,
                            quantity: orderItemsState[this.props.programItems[key].title] ? orderItemsState[this.props.programItems[key].title].quantity : 0,
                            price: priceTemp.toFixed(2),
                            total: orderItemsState[this.props.programItems[key].title] ? orderItemsState[this.props.programItems[key].title].quantity * priceTemp : 0,
                            // total: orderItemsState[this.props.programItems[key].title] ? (this.props.is_ttk ?  priceTemp : orderItemsState[this.props.programItems[key].title].quantity * priceTemp) : 0,
                            item: {
                                item_type: this.props.programItems[key].item_type ? this.props.programItems[key].item_type : { 'title': '' }
                            },
                        };

                        console.log('orderItemsState[this.props.programItems[key].title]6666666666666', orderItemsState[this.props.programItems[key].title]);
                    // }
				}
			}
			this.setState(prevState => ({
				...prevState, pricing_details: {
					...prevState.pricing_details, order_items: orderItemsState
				}
			}), function () {
				this.setState(prevState => ({
					...prevState, pricing_details: this.state.pricing_details
				}));
			});
		}
		if (prevState.pricing_details !== this.state.pricing_details) {
			this.setState({
				pricing_details: this.updateOrderTotalDefault(this.state.pricing_details)
			})
		}
		if (prevProps.pricing_details !== this.props.pricing_details) {
			this.setState({ pricing_details: this.props.pricing_details }, function () {
				// console.log('test two', this.props.programItems);
				// console.log('test one', this.props.pricing_details.program_type_id);
				// console.log('test one', this.state.pricing_details);
				// if ((this.props.pricing_details.program_type_id !== prevProps.pricing_details.program_type_id) && this.props.pricing_details.program_type_id !== 0) {
				// console.log('test two', this.state.pricing_details.order_items);
				// this.props.getProgramItemCall(this.props.pricing_details.program_type_id)
				// }
			});
		}

		if (prevProps.order_details !== this.props.order_details) {
			this.setState({ order_details: this.props.order_details }, () => {
                if (this.props.order_details.agency_id !== 0) {
					this.props.getProgramItemCall(this.props.pricing_details.program_type_id)
                    .then(result => {
                        if (this.props.programType){
                            const tempValue = cloneDeep(this.state.pricing_details);
                            const option = returnLabelWithValueGroup(tempValue.program_type_id, this.props.programType);
                            if (option !== null) {
                                if (option.label.includes('Upload DEMO') || option.label.includes('ITK@ $20.00') || option.label.includes('ITK@ £20.00') || option.label.includes('Uploading to GTV')){
                                    tempValue['number_of_flashdrive'] = 0;
                                    tempValue['number_of_sdcard'] = 0;

                                    this.setState({
                                        pricing_details: tempValue
                                    })
                                }
                            }
                        }
                    })
				// if ((this.props.order_details.agency_id !== prevProps.order_details.agency_id) && this.props.order_details.agency_id !== 0) {
				// 	this.props.getProgramItemCall(this.props.pricing_details.program_type_id)
				}
			});
		}


		if (this.state.item.item_id !== prevState.item.item_id) {
			const item = this.props.items.find(item => {
				return item.value === this.state.item.item_id
			});
			if (item !== undefined) {
				console.log('item', item);
				this.setState(prevState => ({
					item: {
						...prevState.item,
						title: item.label,
						price: parseFloat(item.price),
						item: {
							title: item.label,
							id: item.value,
							price: parseFloat(item.price),
							item_type: item.item_type,
						}
					}
				}));
			}
		}

		if (prevState.pricing_details.settings.send_gl_fd_copy.value !== this.state.pricing_details.settings.send_gl_fd_copy.value) {
			if (this.state.pricing_details.settings.send_gl_fd_copy.value) {
				const agencyCheckBoxTemp = cloneDeep(this.state.pricing_details);
				agencyCheckBoxTemp['free_additional_dvds_to_client'] = 0;
				agencyCheckBoxTemp['settings']['free_fash_drive_to_client']['value'] = 1;
				this.setState({ pricing_details: agencyCheckBoxTemp });
			}
		}
		// if ( prevProps.programType !== this.props.programType) {
		// 	this.setState({programType: this.props.programType});
		// }


	}

	openAdditionalServiceModal = () => {
		// if (this.props.items.length === 0) {
		this.props.getItems('Additional Services');
		// }

		this.setState({ item: OC.ITEMADDITIONALSERVICE }, () => {
			this.toggleModal('additionalServiceModal');
		});


	}

	addAdditionalService = () => {
		console.log('item state', this.state.item);
		if (this.ValidatorAS.allValid()) {
			const item = { ...this.state.item, total: this.state.item.price * this.state.item.quantity };
			this.setState(prevState => ({
				...prevState,
				pricing_details: {
					...prevState.pricing_details,
					order_items: {
						...prevState.pricing_details.order_items,
						[item.title]: item
					}
				}
			}), () => {
				this.toggleModal('additionalServiceModal');
				this.ValidatorAS.hideMessages();
			})
		} else {
			this.forceUpdate();
			this.ValidatorAS.showMessages();
		}
	}

	orderSettingCheckBox(label, name, tooltip, divWidth) {
		return (
			<div className={"col-md-" + divWidth}>
				<div className='form-group'>
					<label className="container-check">{label}
						<input type="checkbox" name={name} className="checkbox" value={this.state.pricing_details.settings[name] ? parseInt(this.state.pricing_details.settings[name]['value'], 10) ? true : false : false} onChange={this.handlePricingDetailCheckBoxSettings} checked={this.state.pricing_details.settings[name] ? parseInt(this.state.pricing_details.settings[name]['value'], 0) : 0} />
						<span className="checkmark"></span>
					</label>
					<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
						<span className="question_mark">?</span>
					</OverlayTrigger>
				</div>
			</div>
		);
	}
	disableOption = (option) => {
		if (option.price !== this.props.pricing_details.program_type.price)
			return true;
	}
	returnOrderStatus = () => {
		let orderStatus = '';
		const { pricing_details, order_details } = this.state;
		if (!isEmpty(pricing_details) && !isEmpty(order_details)) {
			if (pricing_details.settings) {
				if (order_details.order_dates.receive_date) {
					if (order_details.order_dates.receive_date.value) {
						orderStatus = 'Received';
					}
					else {
						orderStatus = 'Not Received';
					}
				}
				if (!isEmpty(pricing_details.settings.photos_submitted_for_video_production)) {
					if (pricing_details.settings.photos_submitted_for_video_production.value === '1' && orderStatus === 'Received') {
						orderStatus = 'In Production';
					}
				}
				if (!isEmpty(pricing_details.settings.finalize_by_qa)) {
					if (pricing_details.settings.finalize_by_qa.value === '1' && orderStatus === 'In Production') {
						orderStatus = 'Order Complete'
					}
				}
			}
		}
		else {
			orderStatus = 'Not Received';
		}

		return orderStatus;
	}

	calcTax = (ObjectPD) => {
		const pricing_details = cloneDeep(this.state.pricing_details);
		console.log('pricing_details.order_totals',pricing_details);
		// const pricing_details = this.state.pricing_details;
		var tax = 0;
		if("tax" in pricing_details.order_totals) {
			var sub_total = Number(this.subTotal(ObjectPD));
			// var sub_total = 0;
			// if("sub_total" in pricing_details.order_totals) {
			// 	sub_total = Number(pricing_details.order_totals.sub_total.value);
			// }
			var additional_fees = 0;
			if("additional_fees" in pricing_details.order_totals) {
				additional_fees = Number(pricing_details.order_totals.additional_fees.value);
			}
			var discount = 0;
			if("discount" in pricing_details.order_totals) {
				discount = Number(pricing_details.order_totals.discount.value);
			}
			let new_subtotal = sub_total + additional_fees - discount;

			var tax_percentage = pricing_details.order_totals.tax.value;
			tax = this.percentage(new_subtotal, tax_percentage).toFixed(2)
            if (tax < 0) {
                tax = 0;
            }
		}
		return Number(tax).toFixed(2);
	}
	// const option = (props: OptionProps<any>) => (
	//   <div {...props.innerProps}>
	//     {props.data.shouldBeDisplayed? props.label : null}
	//   </div>
	// );
	render() {
		const { validatorInstance, programType, is_ttk } = this.props;
        // console.log("programType", programType);
		// validatorInstance.purgeFields();
		const { discounts, pricing_details, item, order_details, selectProgramItemCatTitle } = this.state;
		// var t = returnColumnValueWithColumnMatch(programType, 'itemCategoryTitle', ['Digital Camera'], 'label');
		let photomozaixLink = <a style={{color: 'blue', fontWeight: 'bold'}} href={'https://traveltripkit.com/mozaix'} target='_blank' >Photomozaix™</a>;
        let editOrderTravelerText = (
            <span>
				&nbsp;
                {/* Note: 1 included {photomozaixLink} and any additional ordered. */}
            </span>
        );
        let newOrderTravelerText = (
            <span>
                Your group will receive ONE-20x30 {photomozaixLink} made from the images uploaded into the gallery.
            </span>
        );

		return (
			<React.Fragment>
				<div>
					<div className={!this.props.hasCard ? 'clearfix' : 'row customHalf lessSpace'}>
						<div className="col-md-6">
							<Card bsClass={!this.props.hasCard ? ['notCard'] : ['innerCard mb-none']} content={
								<React.Fragment>
									<div className={!this.props.hasCard ? 'clearfix' : "row"}>
										<span></span>
										<div className={!this.props.hasCard ? null : "col-md-12"} id="ProgramType">
											<label htmlFor="programtype">Select Program Type<span className="requiredClass">*</span></label>
											<span style={{ float: 'right' }}> {this.returnOrderStatus()}</span>
											<Select
												value={returnLabelWithValueGroup(pricing_details.program_type_id, programType)}
												onChange={this.handleChangePricingDetailDropDown}
												options={programType}
												isSearchable={true}
												isMulti={false}
												name='program_type_id'
												placeholder={'Select Program'}
											// isOptionDisabled={ (option) => option.disable === true }
											// isOptionDisabled={ (option)=>this.disableOption(option)}
											// components={{ Option: option }}
											/>
											{
												validatorInstance.message('ProgramType', pricing_details.program_type_id, 'required|integer')
											}
										</div>
										<span></span>
									</div>
									{
                                        !is_ttk &&
                                        <div className={!this.props.hasCard ? 'clearfix mt-xs' : "row mt-xs"}>
                                            <div className="col-md-12" id="sales_rep_id">
                                                <div className='form-group'>
                                                    <div className="clearfix">
                                                        <label style={{
                                                            verticalAlign: 'top'
                                                        }} className="container-check">
                                                            <input onChange={this.handlePricingDetailCheckBoxSettings} type='checkbox' name='no_gl_copy' checked={parseInt(pricing_details.settings.no_gl_copy.value, 0) ? true : false} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className='' htmlFor="name">Digital Only-NO DVDs for travelers, No GL Copy</label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12" id="sales_rep_id">
                                                <div className='form-group'>
                                                    <div className="clearfix">
                                                        <label style={{ verticalAlign: 'top' }} className="container-check">
                                                            <input onChange={this.handlePricingDetailCheckBoxSettings} type='checkbox' name='send_gl_fd_copy' checked={parseInt(pricing_details.settings.send_gl_fd_copy.value, 0) ? true : false} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label htmlFor="name">Digital Only-NO DVDs for travelers, send GL FD Copy</label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className="col-md-12" id="sales_rep_id">
                                                <div className='form-group'>
                                                    <div className="clearfix">
                                                        <label style={{ verticalAlign: 'top' }} className="container-check">
                                                            <input onChange={this.handlePricingDetailCheckBoxSettings} type='checkbox' name='free_fash_drive_to_client' checked={parseInt(pricing_details.settings.free_fash_drive_to_client.value, 0) ? true : false} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span></span>
                                                        <label htmlFor="name">Free Flash Drive to Client</label>
                                                        <small> (Check box will equal 1 flash drive)</small>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    }
									<div className="pull-right" >
										<button type="button" onClick={this.openAdditionalServiceModal} className='customBtn btn ml-sm btn-primary btn btn-default mb-md'>Add Additional Service</button>
									</div>
									<div className="row">
										<span></span>
										<div className="col-md-12">
											<table className="table table-responsive table-striped mt-md">
												<thead>
													<tr>
														<th width="20%" className="action-center">QTY</th>
														<th width="40%">ITEM</th>
														<th width="20%" className="action-center">PRICE</th>
														<th width="20%" className="action-center">TOTAL</th>
													</tr>
												</thead>
												<tbody>
													{
														Object.keys(pricing_details.order_items).length > 0 &&
														Object.keys(pricing_details.order_items).map((keyName, i) => (
															<React.Fragment key={i}>
                                                                { is_ttk ?
                                                                    (keyName === 'Videos/Travelers' ||
                                                                    keyName === '20x30 PhotoMozaix') &&
                                                                    <React.Fragment key={i}>
                                                                        <tr key={i} style={{ backgroundColor: pricing_details.order_items[keyName].item.item_type.title === 'Additional Services' ? 'yellow' : '' }}>
                                                                            <td className="action-center"  >
                                                                                <input
                                                                                    type="number"
                                                                                    pattern='\d*'
                                                                                    step='1'
                                                                                    name="quantity"
                                                                                    className="form-control"
                                                                                    min="0"
                                                                                    onChange={(event) => this.handleChangePricingOI(event, keyName)} value={pricing_details.order_items[keyName].quantity}
                                                                                />

                                                                            </td>
                                                                            <td>{pricing_details.order_items[keyName].title === 'Videos/Travelers' ? 'Travelers' : pricing_details.order_items[keyName].title === '20x30 PhotoMozaix' ? <><strong>Additional</strong> PhotoMozaix</> : pricing_details.order_items[keyName].title}<span className="requiredClass">{pricing_details.order_items[keyName].title === 'Videos/Travelers' ? '*' : null}</span></td>
                                                                            <td className="action-center">{returnDollars(pricing_details.order_items[keyName].price)}</td>
                                                                            <td className="action-center">{returnDollars(is_ttk && keyName === 'Videos/Travelers' && pricing_details.order_items[keyName].quantity >= 1 ? pricing_details.order_items[keyName].price : 
																			this.total(keyName).toFixed(2)
																			// pricing_details.order_items[keyName].total.toFixed(2)
																			)
																			}</td>
                                                                        </tr>

                                                                        {
                                                                            pricing_details.order_items[keyName].title === 'Videos/Travelers'
                                                                                ?
                                                                                <tr id={'itemVideoTravelers_' + i}>
                                                                                    <td colSpan="4">
                                                                                        {
                                                                                            validatorInstance.message('itemVideoTravelers_' + i, pricing_details.order_items[keyName].quantity, ['min:1,num', 'required'])
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                <tr id={'itemVideoTravelers_' + i}>
                                                                                    <td colSpan="4">
                                                                                        {
                                                                                            validatorInstance.message('itemVideoTravelers_' + i, pricing_details.order_items[keyName].quantity, ['min:0,num', 'required'])
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                        }

                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment key={i}>
                                                                        <tr key={i} style={{ backgroundColor: pricing_details.order_items[keyName].item.item_type.title === 'Additional Services' ? 'yellow' : '' }}>
                                                                            <td className="action-center"  >
                                                                                <input
                                                                                    type="number"
                                                                                    pattern='\d*'
                                                                                    step='1'
                                                                                    name="quantity"
                                                                                    className="form-control"
                                                                                    min="0"
                                                                                    onChange={(event) => this.handleChangePricingOI(event, keyName)} value={pricing_details.order_items[keyName].quantity}
                                                                                />

                                                                            </td>
                                                                            <td>{pricing_details.order_items[keyName].title}<span className="requiredClass">{pricing_details.order_items[keyName].title === 'Videos/Travelers' ? '*' : null}</span></td>

                                                                            <td className="action-center">{hasRole(['gm admin']) ? returnPounds(pricing_details.order_items[keyName].price) : returnDollars(pricing_details.order_items[keyName].price)}</td>

                                                                            <td className="action-center">{hasRole(['gm admin']) ? returnPounds(is_ttk && keyName === 'Videos/Travelers' && pricing_details.order_items[keyName].quantity >= 1 ? pricing_details.order_items[keyName].price : this.total(keyName).toFixed(2)) : returnDollars(is_ttk && keyName === 'Videos/Travelers' && pricing_details.order_items[keyName].quantity >= 1 ? pricing_details.order_items[keyName].price : this.total(keyName).toFixed(2))}</td>
                                                                        </tr>

                                                                        {
                                                                            pricing_details.order_items[keyName].title === 'Videos/Travelers'
                                                                                ?
                                                                                <tr id={'itemVideoTravelers_' + i}>
                                                                                    <td colSpan="4">
                                                                                        {
                                                                                            validatorInstance.message('itemVideoTravelers_' + i, pricing_details.order_items[keyName].quantity, ['min:1,num', 'required'])
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                <tr id={'itemVideoTravelers_' + i}>
                                                                                    <td colSpan="4">
                                                                                        {
                                                                                            validatorInstance.message('itemVideoTravelers_' + i, pricing_details.order_items[keyName].quantity, ['min:0,num', 'required'])
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
														))
													}
                                                </tbody>
                                            </table>
                                                    {/* <table className="table table-responsive table-striped mt-md">
                                                        <thead>
                                                            <tr>
                                                                <th width="100%" colSpan="6">Equipment Needed</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="action-center"  >
                                                                    <input
                                                                        type="number"
                                                                        pattern='\d*'
                                                                        step='1'
                                                                        name="number_of_cameras"
                                                                        className="form-control"
                                                                        min="0"
                                                                        value={pricing_details.number_of_cameras}
                                                                        readOnly={selectProgramItemCatTitle === 'Digital Camera' ? false : true}
                                                                        onChange={this.handleChangePricingDetail}
                                                                    />

                                                                </td>
                                                                <td>Cams/Cards</td>
                                                                <td className="action-center"  >
                                                                    <input
                                                                        type="number"
                                                                        pattern='\d*'
                                                                        step='1'
                                                                        name="number_of_flashdrive"
                                                                        className="form-control"
                                                                        min="0"
                                                                        value={pricing_details.number_of_flashdrive}
                                                                        onChange={this.handleChangePricingDetail}
                                                                    />

                                                                </td>
                                                                <td>Flash Drives</td>
                                                                <td className="action-center"  >
                                                                    <input
                                                                        type="number"
                                                                        pattern='\d*'
                                                                        step='1'
                                                                        name="number_of_sdcard"
                                                                        className="form-control"
                                                                        min="0"
                                                                        value={pricing_details.number_of_sdcard}
                                                                        onChange={this.handleChangePricingDetail}
                                                                    />

                                                                </td>
                                                                <td>Sd Cards</td>
                                                            </tr>
                                                        </tbody>
                                                    </table> */}
											{
												this.props.is_ttk &&
												<React.Fragment>
													<div className=''>
														<p style={{fontSize: 'medium', color: 'red'}}>
															{   
																this.props.formType === 'edit' ? editOrderTravelerText : newOrderTravelerText
															}
														</p>
													</div>
													<div>
														<strong>*Additional Mozaix Quantity Discounts</strong>
														<ul style={{listStyle: 'none'}}>
															<li>1-4 <strong>$44.00</strong> each</li>
															<li>5 or more <strong>$30.00</strong> each</li>
														</ul>
														<p className="text_12 text-muted dark">
															Note: Order 5 or more and save!
														</p>
													</div>
												</React.Fragment>
											}
										</div>
										<span></span>
									</div>
								</React.Fragment>
							}
							/>
						</div>
						<div className="col-md-6 pull-right">
							<Card bsClass={!this.props.hasCard ? ['notCard'] : ['innerCard mb-none']} content={
								<React.Fragment>
									{
										!hasRole(['gm admin']) &&
										<div className="row">
											<div className="col-md-6">
												<div className='form-group' id="discount">
													<label htmlFor="glemail">Discount</label>
													<input
														type="number"
														name="discount_value"
														className="form-control"
														min="0"
														max={this.getMax(pricing_details)}
														step="any"
														onChange={this.handleChangePricingDetail}
														value={pricing_details.discount_value}
													/>
													{
														/*validatorInstance.message('discount', pricing_details.discount , 'required')*/
													}
												</div>
											</div>

											<div className="col-md-6" id="optionsDiscount">
												<label htmlFor="glemail">Select Discount Type<span className="requiredClass">*</span></label>
												<Select
													value={returnLabelWithValue(pricing_details.discount_type, discounts)}
													onChange={this.handleChangePricingDetailDropDown}
													options={discounts}
													isSearchable={true}
													isMulti={false}
													name='discount_type'
													placeholder={'Select Discount'}
												/>
												{
													validatorInstance.message('optionsDiscount', pricing_details.discount_type, 'required')
												}
											</div>
											<span></span>
										</div>
									}
									<div className="row">
										<div className="col-md-6" id="shipping">
											<div className='form-group'>
												<label htmlFor="shipping">Shipping</label>
												<input
													type="number"
													name="shipping"
													className={hasRole(['gm admin']) ? "form-control pound_icon" : "form-control dollar_icon"}
													min="0"
													onChange={(e) => this.handleChangePricingOT(e, 'shipping')}
													value={"shipping" in pricing_details.order_totals ?
														'value' in pricing_details.order_totals.shipping ?
															pricing_details.order_totals.shipping.value
															: 0
														: 0
													}
												/>
												{
													'shipping' in pricing_details.order_totals ?
														validatorInstance.message('shipping', pricing_details.order_totals.shipping.value, 'required|numeric|min:0,num')
														: null
												}
											</div>
										</div>

										<div className="col-md-6" id="additional_fees">
											<div className='form-group'>
												<label htmlFor="additional_fees">Additional fees</label>
												<input
													type="number"
													name="additional_fees"
													className={hasRole(['gm admin']) ? "form-control pound_icon" : "form-control dollar_icon"}
													min="0"
													onChange={(e) => this.handleChangePricingOT(e, 'additional_fees')}
													value={"additional_fees" in pricing_details.order_totals ? pricing_details.order_totals.additional_fees.value : 0}
												/>
												{
													/*validatorInstance.message('additional_fees', pricing_details.additional_fees , 'required')*/
												}
											</div>
										</div>
										<span></span>
									</div>

									{
										!this.props.frontEnd
											?
											'tax' in this.props.pricing_details.order_totals
												?
												order_details.agency.primary_address.state.code === 'NC' && order_details.agency.tax_exempt === 0
													?
													<div className="row">
														<div className="col-md-4">
															<div className='form-group' >
																<label className="container-check checkbox_down">Tax
																	({"tax" in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value + '%' : 0 + '%'})
																	({hasRole(['gm admin']) ? returnPounds(this.calcTax(this.state.pricing_details)) : returnDollars(this.calcTax(this.state.pricing_details))} )
																	<input type="checkbox" name="taxable" className="checkbox" value={pricing_details.taxable} onChange={this.confirmCheckChange} checked={pricing_details.taxable} />
																	<span className="checkmark"></span>
																</label>
															</div>
														</div>
													</div>
													:
													null
												:
												null
											:
											null
									}
									{
										!this.props.frontEnd && hasRole(['gm admin'])
											?
												hasRole(['gm admin']) && order_details.agency.tax_exempt === 0
													?
													<div className="row">
														<div className="col-md-4">
															<div className='form-group' >
																<label className="container-check checkbox_down">Tax
																	({"tax" in this.props.pricing_details.order_totals ? this.props.pricing_details.order_totals.tax.value + '%' : 0 + '%'})
																	({hasRole(['gm admin']) ? returnPounds(this.calcTax(this.state.pricing_details)) : returnDollars(this.calcTax(this.state.pricing_details))} )
																	<input type="checkbox" name="taxable" className="checkbox" value={pricing_details.taxable} onChange={this.confirmCheckChange} checked={pricing_details.taxable} />
																	<span className="checkmark"></span>
																</label>
															</div>
														</div>
													</div>
												:
												null
											:
											null
									}

									<div className={!this.props.hasCard ? 'clearfix' : 'row customHalf lessSpace'}>
										<div className="col-md-6">
											<Card bsClass={!this.props.hasCard ? ['notCard clearfix'] : ['innerCard clearfix mb-none']} content={
												<div className="col-md-12">
													<div className='form-group pull-right' id="subTotal">
														<strong style={{ fontSize: '17px' }}>Sub Total : {hasRole(['gm admin']) ? returnPounds("sub_total" in pricing_details.order_totals ? parseFloat(pricing_details.order_totals.sub_total.value).toFixed(2) : 0) : returnDollars("sub_total" in pricing_details.order_totals ? parseFloat(pricing_details.order_totals.sub_total.value).toFixed(2) : 0)}</strong>
														{
															'settings' in order_details.agency
															?
																'disable_minimum_price' in order_details.agency.settings
																?
																	//validatorInstance.message('subTotal', parseFloat("sub_total" in pricing_details.order_totals ? pricing_details.order_totals.sub_total.value : 0), `numeric|min:${!Number(order_details.agency.settings.disable_minimum_price.value) ? OC.MINORDER : '0'},num`)
																	validatorInstance.message('subTotal', parseFloat("sub_total" in pricing_details.order_totals ? pricing_details.order_totals.sub_total.value : 0), `numeric|min:0,num`)
																:
																	null
															:
																null
														}
													</div>
												</div>
											} />
										</div>

										<div className="col-md-6 pull-right">
											<Card bsClass={!this.props.hasCard ? ['notCard clearfix'] : ['innerCard clearfix mb-none']} content={
												<div className="col-md-12">
													<div className='form-group pull-left' >
														<strong style={{ fontSize: '17px' }}>Grand Total : {hasRole(['gm admin']) ? returnPounds("grand_total" in pricing_details.order_totals ? Number(pricing_details.order_totals.grand_total.value).toFixed(2) : 0) : returnDollars("grand_total" in pricing_details.order_totals ? Number(pricing_details.order_totals.grand_total.value).toFixed(2) : 0)} </strong>
													</div>
												</div>
											} />
										</div>
									</div>



								</React.Fragment>
							}
							/>
						</div>
					</div>



					{
                        !is_ttk &&
                        <div className={!this.props.hasCard ? 'clearfix' : 'row customHalf lessSpace'}>
                            <span></span>
                            <div className="col-md-6">
                                <Card bsClass={!this.props.hasCard ? ['notCard'] : ['innerCard mb-none']} content={
                                    <div className="row">
                                        <div className="col-md-6" id="freeAdditionalDvdsToClient">
                                            <div className='form-group'>
                                                <label htmlFor="freeadditionaldvdstoclient">Additional DVDs to Client</label>
                                                <input type="number" min="0" name="free_additional_dvds_to_client" className="form-control" value={pricing_details.free_additional_dvds_to_client ? pricing_details.free_additional_dvds_to_client : 0} onChange={this.handleChangePricingDetail} style={{ width: '100px' }} />
                                                {
                                                    /*validatorInstance.message('freeAdditionalDvdsToClient', pricing_details.free_additional_dvds_to_client , 'required')*/
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6" id="freeAdditionalDvdsToAgency">
                                            <div className='form-group'>
                                                <label htmlFor="freeadditionaldvdstoagency">Additional DVDs to Agency</label>
                                                <input type="number" min="0" name="free_additional_dvds_to_agency" className="form-control" value={pricing_details.free_additional_dvds_to_agency ? pricing_details.free_additional_dvds_to_agency : 0} onChange={this.handleChangePricingDetail} style={{ width: '100px' }} />
                                                {
                                                    /*validatorInstance.message('freeAdditionalDvdsToAgency', pricing_details.free_additional_dvds_to_agency , 'required')*/
                                                }
                                            </div>
                                        </div>

                                    </div>
                                } />
                            </div>
                            <span></span>
                        </div>
                    }
				</div>
				<ThemeModal size='lg' hide={() => { this.toggleModal('additionalServiceModal') }} title='Add Additional Service' show={this.state.additionalServiceModal} modalClass="editOrderDetail" >
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label htmlFor="">Select Item</label>
								<Select
									options={this.props.items}
									placeholder='Select Item'
									onChange={this.selectChangeHandler}
									name='item.item_id'
								/>
								{this.ValidatorAS.message('Item', item.item_id, 'required')}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label htmlFor="">Quantity</label>
								<input value={item.quantity} onChange={(e) => { this.inputChangeHandler(e, /[\d]$/) }} type="number" name="item.quantity" id="" className='form-control' />
								{this.ValidatorAS.message('quantity', item.quantity, 'min:1,num')}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='form-group'>
								<label htmlFor="">Price</label>
								<input className='form-control' type="text" readOnly value={item.price} />
							</div>
						</div>
					</div>
					<button type="button" onClick={this.addAdditionalService} className='customBtn btn ml-sm btn-primary btn btn-default mb-md'>Add</button>
				</ThemeModal>
			</React.Fragment>
		);
	};
}
const mapDispatchToProps = dispatch => {
	return ({
		getProgramItemCall: (id) => {return dispatch(getProgramItem(id)) },
		getIsInvoicedCall: (id) => {return dispatch(getIsInvoiced(id)) },
		getItems: (type) => { dispatch(getItems(type)) },
	});
};
function mapStateToProps(state) {
	const { loadingSettings, programItems } = state.settingsReducer;
	// console.log('programItems map state to props', programItems);
	const { items } = state.itemReducer;

	return {
		loadingSettings, programItems,
		items,
	};
}

const PricingDetail = connect(mapStateToProps, mapDispatchToProps)(PricingDetailPage);

export default PricingDetail;
