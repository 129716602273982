import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { editedImagesAi, producedImagesAi, } from 'redux/actions/order-actions';
import { Modal, OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import { orderService } from '../../../services/order';
import { LazyLoadImage } from 'react-lazy-load-image-component';
class SimilarImagesAi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            similarImages: [],
            parentImage: {}
        };
    }

    componentDidMount() {
        this.getSimilarImages();
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    getSimilarImages = () => {
        const data = {
            order_id: this.props.order_id,
            image_id: this.props.parentImage.id
        }
        orderService.getSimilarImages(data).then((response) => {
            console.log("res", response);
            
            this.setState({ similarImages: response.data.similar_images, parentImage: response.data.parent_image });
        })
    }

    openImage = (path) => {
        const link = document.createElement('a');
        link.href = path;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }
        
    render() {
        const { similarImages, parentImage } = this.state;
        const { openSimilarImageModal } = this.props;
        
        return (
            <div className=''>
                <Modal bsSize="lg"  backdrop={'static'} show={openSimilarImageModal} id="" onHide={this.props.onHide} aria-labelledby="ModalHeader" className="similarImgModal" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Similar Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="similar-img-grid" style={{marginBottom:15}}>
                            <div>
                                <span style={{whiteSpace: 'nowrap'}}>
                                    {'Selected Image'}
                                </span>
                            </div>
                            {
                                parentImage &&
                                <div className="similar-img-rows" style={{backgroundColor: 'lemonchiffon'}}>
                                    <LazyLoadImage
                                        section='parent-image'
                                        src={parentImage && parentImage.resized_image_path && parentImage.resized_image_path.replace('SIZE', 'thumb_xlarge') + "?updated_at=" + parentImage.updated_at}
                                        alt='image'
                                        // onClick={(e) => {this.props.selectImageHandler(e, parentImage)}}
                                        onClick={() => {this.openImage(parentImage.edited_image_path + "?updated_at=" + parentImage.updated_at)}}
                                        className='image-style-similar'
                                        placeholder={<div><img src={"/images/loading200.gif"} alt="Loading..." /></div>}
                                        visibleByDefault={parentImage.src === '/images/' + parentImage.placeholder}
                                        effect="blur"
                                        threshold='1'
                                    />

                                    <div className='image-btn' >
                                        <span className='position-ai'>Position {+parentImage.producer_sort_order }</span>
                                        
                                    </div>
                                    <div className="galleryInfo p-sm">
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip id={parentImage.id}>
                                                Date
                                            </Tooltip>
                                        }>
                                            <span className='text-muted dark text_14'>{parentImage.exif_date_taken}</span>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip id={parentImage.id}>
                                                Photo Name
                                            </Tooltip>
                                        }>
                                            <span className='galleryId text_14'>{parentImage.id}</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            }
                            <div/>
                        </div>
                        <span style={{whiteSpace: 'nowrap'}}>
                            {'Omitted Image'}
                        </span>
                        <div className="similar-img-grid">                        
                           {similarImages.map((image, i) => {
                                return (
                                    <div className="similar-img-rows">
                                        <div className='mb-sm mt-sm' style={{cursor: 'pointer'}} onClick={() => {
                                            this.props.handleIncludeInFullVideo(image, 'use_in_full_video', true)
                                            }}>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Include in video</Tooltip>}>
                                                <i style={{fontSize: '15px', width: '100%'}} className="fa fa-check"
                                                aria-hidden="true"></i>
                                            </OverlayTrigger>
                                            <div>similarity {image.similarity_percentage ? (image.similarity_percentage * 100) + '%' : 'unknown'}</div>
                                        </div>
                                        <LazyLoadImage
                                            section='similar-images'
                                            src={image.resized_image_path.replace('SIZE', 'thumb_xlarge') + "?updated_at=" + image.updated_at}
                                            alt='image'
                                            // onClick={(e) => {this.props.selectImageHandler(e, image)}}
                                            onClick={() => {this.openImage(image.edited_image_path + "?updated_at=" + image.updated_at)}}
                                            className='image-style-similar'
                                            placeholder={<div><img src={"/images/loading200.gif"} alt="Loading..." /></div>}
                                            visibleByDefault={image.src === '/images/' + image.placeholder}
                                            effect="blur"
                                            threshold='1'
                                        />
                                        <div className='image-btn' >
                                            <span className='position-ai'>Position {+image.user_sort_order }</span>
                                            
                                        </div>
                                        <div className="galleryInfo p-sm">
                                            <OverlayTrigger placement="bottom" overlay={
                                                <Tooltip id={image.id}>
                                                    Date
                                                </Tooltip>
                                            }>
                                                <span className='text-muted dark text_14'>{image.exif_date_taken}</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="bottom" overlay={
                                                <Tooltip id={image.id}>
                                                    Photo Name
                                                </Tooltip>
                                            }>
                                                <span className='galleryId text_14'>{image.id}</span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                );
                            })}                        
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
  
export default SimilarImagesAi;