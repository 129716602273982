import React, {Component} from "react";
import {CSVLink} from "react-csv";
import _get from 'lodash.get'
import {returnDynamicURL} from "../../helper/helperFunctions";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { renderToString } from 'react-dom/server';
var { isEqual } = require('lodash');

class reactDataToCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToDownload: []
        }
        this.download = this.download.bind(this);
    }

    download(event) {
        //OLD CODE
        const {currentRecordsRef, columnsData} = this.props;
        let currentRecordsData = currentRecordsRef.resolvedData;

        //NEW CODE
        const DataToDownload = [];
        const columnsToDownload = {};

        columnsData.forEach(column => {
            if (column.excExp === true) {
                let accessor = column.accessor;
                const splitAccessor = accessor.split('.');
                const accessorLength = splitAccessor.length;
                if (accessorLength > 2 && !(column['Header'] === 'Pre Trip Material Address State' || column['Header'] === 'Video Shipping Address State' || column['Header'] === 'Agency Primary State')) {
                    console.log(" line 31", column['Header']);
                    if (splitAccessor[1] in columnsToDownload) {
                        console.log(" line 33", column['Header']);
                        columnsToDownload[splitAccessor[1]].push(column);
                    } else {
                        console.log(" line 36", column['Header']);
                        columnsToDownload[splitAccessor[1]] = [column]
                    }
                } else if (accessorLength > 1) {
                    console.log(" line 40", column['Header']);
                    if (splitAccessor[0] in columnsToDownload) {
                        console.log(" line 43", column['Header']);
                        columnsToDownload[splitAccessor[0]].push(column);
                    } else {
                        console.log(" line 45", column['Header']);
                        columnsToDownload[splitAccessor[0]] = [column]
                    }
                } else {
                    console.log(" line 49", column['Header'], accessorLength);
                    columnsToDownload[splitAccessor[0]] = column;
                }
            }
        });
        let i = 0;
        let record = currentRecordsData[i];
        while (record !== undefined) {
            let currentRecord = {}
            Object.keys(columnsToDownload).forEach(column => {
                if (columnsToDownload[column] instanceof Array) {
                    columnsToDownload[column].forEach(nestedItem => {
                        this.makeColumn(nestedItem, currentRecord, record)
                    })
                } else {
                    this.makeColumn(columnsToDownload[column], currentRecord, record)
                }
            })
            DataToDownload.push(currentRecord);
            i += 1;
            record = currentRecordsData[i]
        }
        this.setState({dataToDownload: DataToDownload}, () => {
            this.csvLink.link.click()
        })
    }

    makeColumn(item, currentRecord, record) {
        if (item.csvMod) {
            if (item instanceof Array) {
                let arr = item;
                let output = '';
                arr.forEach(item => {
                    if (item.csv_value)
                        output += item.csv_value + '\n';
                })
                currentRecord[item.Header] = output;
            } else {
                if (item.Header === 'Traveler Quick Login') {
                    let match, result;
                    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1]
                        let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'tech', 'aom'];
                        if (domainSlugs.includes(result.split('.')[0])) {
                            result = result.split('.').slice(1).join('.');
                        }
                    }
                    let link = returnDynamicURL(record.accounts[0].domain_slug, record.username, record.password)
                    currentRecord[item.Header] = link
                } else if (item.Header === 'Shipping Information') {
                    let original = {
                        group_leader_contact : record.group_leader_contact,
                        video_shipping_address : record.video_shipping_address
                    }
                    let shippingInfo = this.returnShippingAddress(original);
                    currentRecord[item.Header] = shippingInfo;

                } else if (item.Header === 'Contact Info') {
                    
                    let contactInfo = record.group_leader_contact.phone1 + "\n" + record.group_leader_contact.email
                    currentRecord[item.Header] = contactInfo;

                } else if (item.Header === 'Products') {
                    
                    let products = this.returnReorderProducts(record.order_items, record);
                    currentRecord[item.Header] = products;

                } else if (item.Header === 'Production Time') {

                    let productionTime = this.returnProductionTime(record, record.shippingSettings);
                    
                    currentRecord[item.Header] = productionTime;
                } else if (item.Header === 'Overnight') {
               
                    let overnight = this.returnOvernight(record, record.shippingSettings);
                    
                    currentRecord[item.Header] = overnight;
                } else {
                    currentRecord[item.Header] = item.csv_value;
                }
            }
        } else {
            let value = _get(record, item.accessor);
            // console.log("record", record);
            if (item.accessor === 'pre_trip_material_address.city' || item.accessor === 'video_shipping_address.city') {
                // agency csv_value
                let agency_csv_value = this.returnAddressObjectForComparison(record.agency.primary_address);

                // order video address csv_value
                let order_video_csv_value = this.returnAddressObjectForComparison(record.video_shipping_address);

                // order ptm address csv_value
                let order_ptm_csv_value = this.returnAddressObjectForComparison(record.pre_trip_material_address);

                if (item.accessor === 'pre_trip_material_address.city') {
                    if (isEqual(agency_csv_value, order_ptm_csv_value)) {
                        value = "***" + " " + value
                    }
                }
                if (item.accessor === 'video_shipping_address.city') {
                    if (isEqual(agency_csv_value, order_video_csv_value)) {
                        value = "***" + " " + value
                    }
                }
            }
            if (item.accessor === "edit_produced_by") {
                value = this.getEditProducedByValue(record.edit_produced_by);
            }
            if (item.accessor === "production_status") {
                value = TF.productionStatus(record.settings, record.status, record.csr_steps, record.on_hold_last_comment && record.on_hold_last_comment, record.cancel_last_comment && record.cancel_last_comment, record.orderDates, record.shippingDetails, 'left');
                // Render the value element to string
                value = renderToString(value);
                // Extract the text content using a regular expression
                value = value.replace(/<\/?[^>]+(>|$)/g, "");
                if (value.includes('Cancelled?')) {
                    value = record.cancel_last_comment ? value  + "(Comment: " + record.cancel_last_comment.comment + " )" : value;
                }
            }
            // if (item.accessor.match(/[\w\W]+\.[\w\W]+\.[\w\W]+/)) {
            //     let newAcc = item.accessor.split('.');
            //     if (record[newAcc[0]][newAcc[1]].value === '0') {
            //         value = ''
            //     }
            // } else {
            //     if (item.accessor === 'barcode') {
            //         // value = `=""${value}""`
            //         value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            //     }
            //     if (item.accessor === 'orderShippingCameraShippedDate') {
            //         value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            //     }
            // }
            if (item.Header === "Agency All User Email")
            {
                let text = "Click here";
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/agency-users"",""${text}"")`
            }
            if (item.Header === "Agency All User Name")
            {
                let text = "Click here";
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/agency-users"",""${text}"")`
            }
            if (item.accessor === 'barcode') {
                // value = `=""${value}""`
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            }
            if (item.accessor === 'orderShippingCameraShippedDate') {
                value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            }
            currentRecord[item.Header] = value;
        }
    }
    returnAddressObjectForComparison = (obj) => {
		const newObj = Object.assign({},
			{
				street_address_2: obj.street_address_2,
				city: obj.city,
				state_id: obj.state_id,
				country_id: obj.country_id,
				zipcode: obj.zipcode,
				country: obj.country,
				state: obj.state,
                street_address_1: obj.street_address_1,
			})
		return newObj;
	}

    getEditProducedByValue = (obj) => {
        let editor = obj.editor;
        let producer = obj.producer;
    
        let text = (editor ? editor.name : '-- ') + "/" + (producer ? producer.name : ' --');
        return text;
    }

    returnShippingAddress = (original) => {
        let shippingInfo = (original.group_leader_contact.first_name) + "\n" + (original.video_shipping_address.street_address_1);

        let city_state_zip = (original.video_shipping_address.city) + ", " + (original.video_shipping_address.state && original.video_shipping_address.state.title ? original.video_shipping_address.state.title : '') + " " + (original.video_shipping_address.zipcode ? original.video_shipping_address.zipcode : '');

        shippingInfo = shippingInfo  + "\n" + city_state_zip;
        return shippingInfo;
    }

    returnReorderProducts = (value, original) => {
        let products = '';
        value.map(item => {
            let is_ttk = original.agency.accounts[0].domain_slug === 'ttk' ? true : false;
            if ((!is_ttk && item.item.title !== 'Flash Drive with Gallery Photos') ||
                (is_ttk && item.item.title !== 'Flash Drive with Video and Photos' &&
                    item.item.title !== 'Keepsake Video on DVD')){
                
                    products += (item.item.title) + " = " + (item.quantity) + "\n";
                }
        })
        return products;
    }

    returnProductionTime = (original, shippingSettings) => {
        const item = shippingSettings.find(item => {
            return parseFloat(item.value) === parseFloat(original.orderTotals.production.value)
        });
    
        let productionTimeKey = item.key.toUpperCase(); // Convert key to uppercase
        let productionTime = (
            <span className={`text-uppercase ` + (productionTimeKey !== 'NORMAL_PRODUCTION' ? 'font-weight-bold' : '')}>
                {productionTimeKey.replace(/_/g, ' ')}
            </span>
        );
    
        productionTime = renderToString(productionTime);
    
        // Extract the text content using a regular expression
        productionTime = productionTime.replace(/<\/?[^>]+(>|$)/g, "");
    
        productionTime = (productionTime)  + "\n" + (item.description);
        return productionTime;
    }

    returnOvernight = (original, shippingSettings) => {
        const item = shippingSettings.find(item => {
            let res
            original.orderTotals.overnight ?
                res = parseFloat(item.value) === parseFloat(original.orderTotals.overnight.value)
            :
                res = ''
            return res
        });
        let overnight = ''; 
        if (Number('payment_made' in original.orderSettings && Number(original.orderSettings.payment_made.value) === 1)) {
            if ('overnight' in original.orderTotals) {
                let overnightKey = item.key.toUpperCase(); // Convert key to uppercase
                overnight = (
                    <span className={`text-uppercase ` + (overnightKey !== 'NORMAL_PRODUCTION' ? 'font-weight-bold' : '')}>
                        {overnightKey === 'OVERNIGHT_PRODUCTION' ? 'OVERNIGHT SHIPPING' : overnightKey.replace(/_/g, ' ')}
                    </span>
                );
            
                overnight = renderToString(overnight);
            
                // Extract the text content using a regular expression
                overnight = overnight.replace(/<\/?[^>]+(>|$)/g, "");
            
                overnight = (overnight)  + "\n" + (item.description);
            }
        }
        return overnight;
    }

    render() {
        const {fileName} = this.props;
        const {dataToDownload} = this.state;
        return (
            <React.Fragment>
                <button onClick={this.download}>
                    Download
                </button>
                <CSVLink
                    data={dataToDownload}
                    filename={fileName + ".csv"}
                    className="hidden"
                    ref={(r) => this.csvLink = r}
                    target="_blank"
                />
            </React.Fragment>
        );
    }
}

export default reactDataToCSV;
