import React, { Component } from "react";
import { adminLabels } from '../../redux/constant/admin-label-constant';
import {BsCheckCircle, BsExclamationTriangle} from 'react-icons/bs';

class confirmAlertForDelete extends Component {
	render(){
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
                    {
                        this.props.typeName.match(/^(Date|Itinerary Image|Pro Show Destination)$/) ?

                            <BsExclamationTriangle style={{"fontSize":"40px", "color":"red"}}/>
                        :
                        <>
                            {
                              this.props.agencyAction === 'Activate' ?
                              <BsCheckCircle className="mt-sm" style={{"fontSize":"40px", "color":"#049f0c"}}/>
                              :
                              <div><i className="pe-7s-trash" style={{"fontSize":"40px", "color":"red"}}></i></div>
                            }
                            
                            <h3 style={{"color":"#4f7bea", "fontSize":"20px"}}>{ this.props.typeName == "Agency" ? this.props.agencyAction : 'Delete' } { this.props.typeName }</h3>
                        </>
                    }
                      {
                          this.props.typeName.match(/^(Date|Itinerary Image|Pro Show Destination|Previous Edited Images)$/) ?
                            <p className="marginFromTopPopUp" >{  this.props.description }</p>
                            :
	              	        <p className="marginFromTopPopUp" >{ this.props.typeName == "Agency" ? this.props.description : adminLabels.DELETE + this.props.typeName }?</p>
                      }
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
                          {
                              this.props.typeName == "Date" ?
                                <button onClick={this.props.onClosePro} className='btn btn-success'><i className="pe-7s-check"></i>Ok</button>
                              :
	                	        <button onClick={this.props.onClosePro} className="btn btn-danger"><i className="pe-7s-close-circle"></i>No</button>
                          }
                        {
                            this.props.deleteType &&
                            <button className="btn btn-success" onClick={() => {
                                this.props.deleteType()
                                this.props.onClosePro()
                            }}>
	                	    <i className="pe-7s-check"></i> Yes</button>
                        }
	              </div>
	            </div>
	        </div>
		);
	};
}



export default confirmAlertForDelete;
