import React from "react";
import { connect } from "react-redux";
import {
    // Grid,
    Row, Col
} from "react-bootstrap";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";
import CommentList from "components/Admin/Order/CommentList";
import { getCommentCategories } from "redux/actions/comment-actions";
import { s3StaticPath, hasRole } from 'helper/helperFunctions';
import MyImage from 'components/Upload/MyImage';
const queryString = require('query-string');

class OrderComments extends React.Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
            index: 0,
            defaultActiveTabName: parsed.commentCategoryCode ? parsed.commentCategoryCode : '',
            formType: this.props.formType || '',
        }
        this.smsMessagesRef = React.createRef();
        this.addClassToListItem = this.addClassToListItem.bind(this);
        this.scrollToSmsMessages = this.scrollToSmsMessages.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.defaultActiveTabName && this.props.commentCategories !== prevProps.commentCategories) {
            const index = this.props.showOnly.findIndex(item => {
                return item === this.state.defaultActiveTabName
            })
            if (index !== -1) {
                this.setState({
                    index: index
                }, () => {
                    console.log('comment category tab', this.state.index);
                    console.log('comment category tab one', this.props.commentCategories);
                })
            }
        }
    }

    scrollToSmsMessages = () => {
        setTimeout(() => {
          const smsMessagesElement = this.smsMessagesRef.current;
          console.log(smsMessagesElement);
          if (smsMessagesElement) {
            smsMessagesElement.scrollIntoView({ behavior: 'smooth' });
            this.clickSmsButton();
          }
        }, 2000);
      };

      clickSmsButton = () => {
        setTimeout(() => {
          const smsButton = this.smsMessagesRef.current.querySelector('button');
          if (smsButton) {
            smsButton.click();
          }
        }, 1000);
      };

    addClassToListItem = () => {
        setTimeout(() => {
          const listItem = document.getElementById('react-tabs-28');
          if (listItem) {
            listItem.classList.add('react-tabs__tab--selected');
            listItem.click();
            this.scrollToSmsMessages();
          }
        }, 2000);
      };

    componentDidMount() {
        this.props.getCommentCategoriesCall();
        setTimeout(function () {
            const myId = window.location.hash.slice(1)
            const elem = document.getElementById(myId)
            if (elem)
                elem.scrollIntoView();
        }, 2000);
        const urlParams = new URLSearchParams(window.location.search);
        const sms = urlParams.get('sms');
        if (sms && sms === 'true') {
            this.addClassToListItem();
        }
    }

    updateTabClass() {
        const tabElement = this.tabRef.current;

        if (tabElement) {
          if (this.props.selectedTab === 'react-tabs-28') {
            tabElement.classList.add('react-tabs__tab--selected');
          } else {
            tabElement.classList.remove('react-tabs__tab--selected');
          }
        }
      }

    render() {
        const tabIcons = [
            s3StaticPath('img/chat_icon.png'),
            s3StaticPath('img/admin_icon.png'),
            s3StaticPath('img/production_icon.png'),
            s3StaticPath('img/qa_icon.png'),
            s3StaticPath('img/shipping_icon.png'),
            s3StaticPath('img/dvd_mosaix_icon.png'),
            s3StaticPath('img/to_gl_icon.png'),
            s3StaticPath('img/contact_log_icon.png'),
        ]
        const { order_details, showSteps } = this.props;
        const { formType } = this.state;
        const tabs = this.props.commentCategories.map((item, index) => {
            if ('showOnly' in this.props) {
                if (this.props.showOnly.includes(item.comment_category.code)) {
                    return <Tab key={item.id}  className={(hasRole(['Remote Editor','Remote Editor/Producer']) && item.comment_category.code !== 'Production') ? "tabDisabled" : ''} disabled={(hasRole(['Remote Editor','Remote Editor/Producer', 'Remote Producer']) && item.comment_category.code !== 'Production') ? true : false}>
                        <MyImage src={tabIcons[index]} alt={item.comment_category.code} />
                        &nbsp;
                {item.comment_category.title}
                    </Tab>;
                } else {
                    return null;
                }
            } else {
                return <Tab key={item.id} className={hasRole(['Remote Editor','Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
                    <MyImage src={tabIcons[index]} alt={item.comment_category.code} />
                    &nbsp;
                {item.comment_category.title}
                </Tab>;
            }
        });

        const tabPanels = this.props.commentCategories.map(item => {
            if ('showOnly' in this.props) {
                if (this.props.showOnly.includes(item.comment_category.code)) {
                    return (
                        <TabPanel key={item.id} >
                            <CommentList smsMessagesRef={this.smsMessagesRef} updateOrderSettingsCall={this.props.updateOrderSettingsCall} showSteps={showSteps} formType={formType} order_details={order_details} code={item.comment_category.code} title={item.comment_category.title} modelId={this.props.modelId} comment_category_id={item.comment_category_id} withUuid={this.props.withUuid} addExceptTab={this.props.addExceptTab} />
                        </TabPanel>);
                } else {
                    return null;
                }

            } else {
                return (
                    <TabPanel key={item.id} >
                        <CommentList updateOrderSettingsCall={this.props.updateOrderSettingsCall} showSteps={showSteps} formType={formType} order_details={order_details} code={item.comment_category.code} title={item.comment_category.title} modelId={this.props.modelId} comment_category_id={item.comment_category_id} withUuid={this.props.withUuid} addExceptTab={this.props.addExceptTab} />
                    </TabPanel>);
            }
        });
        return (
            <Row>
                <Col md={12} className="mt-xlg commentTabs orderTabs" >
                    {
                        !('hideTitle' in this.props) &&
                        <div className="secHeading">Comments</div>
                    }
                    <div id="commentTabs">
                        <Tabs selectedIndex={this.state.index} onSelect={index => this.setState({ index: index })}>
                            <TabList className="list-unstyled">
                                {tabs}
                            </TabList>
                            <div className='orderTabs orderContent commentsContainer mt-lg' >
                                {tabPanels}
                            </div>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    const { commentCategories } = state.commentReducer;

    return { commentCategories };
}

const mapDispatchToProps = dispatch => {
    return {
        getCommentCategoriesCall: () => { dispatch(getCommentCategories()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderComments);
