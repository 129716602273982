import Home from "components/FrontEnd/Home";
import React from "react";
import HowItWorks from "components/FrontEnd/HowItWorks";
import AboutUs from "components/FrontEnd/AboutUs";
import SchedulerPage from "components/FrontEnd/SchedulerPage";
import ShowMessage from "components/FrontEnd/ShowMessage";
import Login from "components/FrontEnd/Login"
import UserHome from "components/FrontEnd/UserHome/UserHome";
import Uploader from "components/FrontEnd/Uploader/Uploader";
import Order from "components/FrontEnd/Order/container/Order";
import Account from "components/FrontEnd/Account/Account";
import OrderHistory from "components/FrontEnd/Order/OrderHistory";
import { Roles } from "constants/Roles";
import OrderOptions from "components/FrontEnd/Order/OrderOptions/OrderOptions";
import CustomizeTitles from "components/FrontEnd/Order/CustomizeTitles";
import GenerateAppCard from "components/Admin/Order/GenerateAppCard";
import { baseUrl } from "helper/ApiCall";
import User from "helper/User";
import ContactUs from "components/FrontEnd/ContactUs";
import Terms from "components/FrontEnd/Terms";
import Privacy from "components/FrontEnd/Privacy";
import GtvApp from "components/FrontEnd/GtvApp";
import KeepsakeVideo from "components/FrontEnd/KeepsakeVideo";
import Samplevideo from "components/FrontEnd/demo_container.php";
import ImageGallery from "components/FrontEnd/PhotoGallery/ImageGallery";
import Advertise from "components/FrontEnd/Advertise";
import Mozaix from "components/FrontEnd/Mozaix";
import CustomerTestimonials from "components/FrontEnd/Testimonials/CustomerTestimonials";
import MemberAffiliations from "components/FrontEnd/MemberAffiliations";
import Clients from "components/FrontEnd/Clients";
import TourOperatorBranding from "components/FrontEnd/TourOperatorBranding";
import OrderSummary from "components/FrontEnd/Order/OrderSummary";
import OrderSummaryPA from "components/FrontEnd/Order/OrderSummaryPA";
import GtvHome from "components/FrontEnd/GtvHome";
import HowWorks from "components/FrontEnd/HowWorks";
import TourOperator from "components/FrontEnd/TourOperator";
import Checkout from "components/FrontEnd/Checkout";
import ForgotPassword from "components/FrontEnd/ForgotPassword";
import Reset from "components/FrontEnd/Reset";
import ThanksConfirmation from "components/FrontEnd/Order/ThanksConfirmation";
import IncreaseUploadCutOffDate from "components/FrontEnd/Order/IncreaseUploadCutOffDate";
import MyImage from 'components/Upload/MyImage';
import Maintainance from 'components/FrontEnd/Maintainance';
import { s3StaticPath } from "helper/helperFunctions";
import Dummy from "components/FrontEnd/Dummy";
import CSRAutomatedCheckInEmail from "components/Admin/Order/CSRAutomatedCheckInEmail";
import firstEmailNotReceived from "components/Admin/Order/firstEmailNotReceived";
import AddItinerary from "../components/Admin/Itinerary/AddItinerary";
import ChatWrapper from "../components/Admin/Chat/ChatWrapper";

const FrontEndRoutes = [

  {
    path: `/Home`,
    name: `Home`,
    component: GtvHome,
    showMenu: false,
    number: 1,
  },
  {
    path: `/dummy`,
    name: `Dummy`,
    component: Dummy,
    showMenu: false,
    number: 1,
  },
  {
    path: `/Tour_Operators`,
    name: `Tour Operators`,
    component: Home,
    showMenu: false,
    number: 1,
  },
  // {
  //   path: `/Group_Leaders`,
  //   name: `Group Leaders & Educators`,
  //   component: Home,
  //   showMenu: true,
  //   number: 1,
  // },
  // {
  //   path: `/Students_Travelers`,
  //   name: `Students & Travelers`,
  //   component: Home,
  //   showMenu: true,
  //   number: 1,
  // },
  {
    path: `/Family_Staying`,
    name: `Family Staying at Home`,
    component: Home,
    showMenu: false,
    number: 1,
  },
  {
    path: `/How_It_Works`,
    name: `How it works`,
    component: HowItWorks,
    showMenu: false,
    number: 2,
  },
  {
    name: `Product & Services`,
    showMenu: true,
    number: 2,
    childNav: [
      {
        path: `/gtv-how-it-works`,
        name: `Group Travel Videos App`,
        component: HowWorks,
        showMenu: true,
        number: 0,
      },
      {
        path: `/Keepsake_Video`,
        name: `Keepsake Video`,
        component: KeepsakeVideo,
        showMenu: true,
        number: 1,
      },
      {
        path: `/Mozaix`,
        name: <React.Fragment>PhotoMozaix&trade;</React.Fragment>,
        component: Mozaix,
        showMenu: true,
        number: 2,
      },
      // {
      //   path: `/Photo_Contest`,
      //   name: `Photo of the Month Contest`,
      //   component: Mozaix,
      //   showMenu: true,
      //   number: 3,
      // },
      {
        path: `/Advertise_GTV`,
        name: `Advertise GTV on Your Website`,
        component: Advertise,
        showMenu: true,
        number: 4,
      },
      {
        path: `/Tour_Operator_Branding`,
        name: `Tour Operator Branding`,
        component: TourOperatorBranding,
        showMenu: true,
        number: 5,
      },
      // {
      //   path: `/gtv-home`,
      //   name: `GTV Home`,
      //   component: GtvHome,
      //   showMenu: true,
      //   number: 6,
      // },
      {
        path: `/gtv-tour-operators`,
        name: `Tour Operators`,
        component: TourOperator,
        showMenu: true,
        number: 7,
      },
    ]
  },
  {
    path: `/demo_container.php`,
    name: `Demo Video`,
    component: Samplevideo,
    showMenu: false,
    number: 4,
  },
  {
    name: `Company Info`,
    showMenu: true,
    number: 3,
    childNav: [
      {
        path: `/About_Us`,
        name: `About Us`,
        component: AboutUs,
        showMenu: true,
        number: 0,
      },
      {
        path: `/Contact_Us`,
        name: <React.Fragment>Contact Us &amp; FAQ</React.Fragment>,
        component: ContactUs,
        showMenu: true,
        number: 1,
      },
      {
        path: `/Customer_Testimonials`,
        name: `Testimonials`,
        component: CustomerTestimonials,
        showMenu: true,
        number: 2,
      },
      {
        path: `/Member_Affiliations`,
        name: `Member Affiliations`,
        component: MemberAffiliations,
        showMenu: true,
        number: 3,
      },
      {
        path: `/Clients`,
        name: `Clients`,
        component: Clients,
        showMenu: true,
        number: 4,
      },
      {
        path: `/Terms_and_Conditions`,
        name: `Terms`,
        component: Terms,
        showMenu: true,
        number: 5,
      },
      {
        path: `/Privacy_Policy`,
        name: `Privacy`,
        component: Privacy,
        showMenu: true,
        number: 6,
      },
      // {
      //   path: `/Print_Report`,
      //   name: `Print Report`,
      //   component: PrintReport,
      //   showMenu: false,
      //   number: 6,
      // },
    ]
  },
  {
    path: `/Login/:username?/:password?`,
    name: `Login`,
    component: Login,
    showMenu: true,
    number: 4,
    navClass: `loginIcon`,
  },
  {
    path: '/logout',
    name: `Logout`,
    showMenu: true,
    number: 4,
    auth: true,
  },
  {
    path: `/dashboard`,
    name: `Dashboard`,
    component: UserHome,
    auth: true,
    role: [...Object.values(Roles)],
    navIcon: `flaticon-home`,
    showMenu: false,
  },
  // {
  //   path: `/Signup`,
  //   name: `Sign Up`,
  //   component: Signup,
  //   showMenu: true,
  //   number: 3,
  //   navClass: `signUp`,
  // },
  {
    path: `/customize_titles`,
    name: `Customize Your Titles and Music`,
    component: CustomizeTitles,
    auth: true,
    role: [Roles.PHOTO_ADMIN],
    navIcon: `flaticon-checklist`,
    needCameraShipment: true,
    needUploadApproved: true,
    hideOnSubmitedOrder: true,
    showInSidebar: !Number(User.orderSettingValue('photos_submitted_for_video_production')),
    // showInSidebar : !Number(User.orderSettingValue('finalize_by_qa')),
  },
  {
    path: `/uploader`,
    name: `Uploader`,
    component: Uploader,
    auth: true,
    role: [Roles.TRAVELER, Roles.PHOTO_ADMIN],
    navIcon: `flaticon-upload`,
    needCameraShipment: true,
    needUploadApproved: true
  },
  {
    path: `/photo-gallery`,
    name: `Photo Gallery`,
    component: Uploader,
    auth: true,
    role: [Roles.TRAVELER, Roles.PHOTO_ADMIN],
    navIcon: `flaticon-upload`,
    needCameraShipment: true,
    needUploadApproved: true,
    showInSidebar: false,
  },
  {
    path: `/new-order`,
    name: `New Order`,
    component: Order,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-shopping-cart`
  },
  {
    path: `/edit_order/:id`,
    name: `Order Edit`,
    component: Order,
    auth: true,
    showMenu: false,
    showInSidebar: false,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
  },
  {
    path: `/keep_sake`,
    name: `Keep Sake Reorder`,
    component: Order,
    auth: true,
    role: [Roles.PHOTO_ADMIN, Roles.TRAVELER],
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/order-history`,
    name: `Order History`,
    component: OrderHistory,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-clock-shop`
  },
  {
    path: `/manage-itineraries`,
    name: `Manage Itineraries`,
    component: AddItinerary,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    showInSidebar: User.brand('has_itinerary') ? true : false,
    navIcon: `flaticon-clock-shop`
  },
  {
    path: `/order-summary/:id`,
    name: `Order Summary`,
    component: OrderSummary,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-clock-shop`,
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/order-summary`,
    name: `Order Summary`,
    component: OrderSummaryPA,
    auth: true,
    role: [Roles.PHOTO_ADMIN],
    navIcon: `flaticon-clock-shop`,
    showMenu: false,
    showInSidebar: true
  },
  // {
  //   path: `/select-options`,
  //   name: `Order Options`,
  //   component: OrderOptions,
  //   auth: true,
  //   role: [Roles.PHOTO_ADMIN],
  //   navIcon: `flaticon-settings`,
  //   needCameraShipment: false,
  //   needUploadApproved: true,
  // },
  {
    path: `/account`,
    name: `Account`,
    component: Account,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-user`
  },
  {
    path: `/scheduler`,
    name: `Scheduler`,
    component: SchedulerPage,
  },
  {
    path: `/app_card`,
    name: `Your Password App Card`,
    render: GenerateAppCard,
    auth: true,
    role: [Roles.PHOTO_ADMIN],
    props: {
      className: 'd-inline-block',
      text: 'Your Password App Card',
      base_url: baseUrl.slice(0, -1),
      orders: [User.getProperty('order.id')],
      btnIcon: <MyImage src={s3StaticPath('img/appCard_4.png')} alt="" />,
      navIcon: `flaticon-credit-card`,
    },
    navIcon: `flaticon-upload`,
    needCameraShipment: true,
    needUploadApproved: true,
    hideOnCompletedOrder: true,
    showInSidebar: !Number(User.orderSettingValue('finalize_by_qa')),
  },

  {
    path: `/order-options/:id`,
    name: `Order Options`,
    component: CustomizeTitles,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-settings`,
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/thank-you`,
    name: `Thanks Order Confirmation`,
    component: ThanksConfirmation,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-settings`,
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/increase_ucd`,
    name: `Increase Upload CutOff Date`,
    component: IncreaseUploadCutOffDate,
    role: [Roles.PHOTO_ADMIN],
    auth: true,
    showInSidebar: false
  },
  {
    path: `/increase_ucd?days=2`,
    name: `Current Traveler Upload Cutoff Date ${User.getProperty('upload_cut_off_date_formatted')}`,
    component: IncreaseUploadCutOffDate,
    auth: true,
    needTravelerUcdPassed: true,
    navIcon: `flaticon-checklist`,
    role: [Roles.PHOTO_ADMIN],
    showInSidebar: (!Number(User.orderSettingValue('photos_submitted_for_video_production')) && User.getProperty('traveler_upload_date_passed')),
    disable: true
  },
  {
    path: `/increase_ucd?days=2`,
    name: `Increase Date By 2 Days`,
    component: IncreaseUploadCutOffDate,
    auth: true,
    needTravelerUcdPassed: true,
    navIcon: `flaticon-checklist`,
    role: [Roles.PHOTO_ADMIN],
    showInSidebar: (!Number(User.orderSettingValue('photos_submitted_for_video_production')) && User.getProperty('traveler_upload_date_passed')),
  },
  {
    path: `/increase_ucd?days=7`,
    name: `Increase Date By 7 Days`,
    component: IncreaseUploadCutOffDate,
    navIcon: `flaticon-checklist`,
    auth: true,
    needTravelerUcdPassed: true,
    role: [Roles.PHOTO_ADMIN],
    showInSidebar: (!Number(User.orderSettingValue('photos_submitted_for_video_production')) && User.getProperty('traveler_upload_date_passed')),
  },
  {
    path: `/message/:slug/:orderId/:glName`,
    name: `Order Options`,
    component: ShowMessage,
    auth: true,
    // role: [Roles.AGENCY_USER],
    navIcon: `flaticon-settings`,
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/checkout`,
    name: `Checkout`,
    component: Checkout,
    auth: true,
    // role: [Roles.AGENCY_USER],
    showMenu: false,
    showInSidebar: false
  },
  {
    path: `/image-gallery/:orderId`,
    name: `image gallery`,
    component: ImageGallery,
    auth: true,
    role: [Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U],
    navIcon: `flaticon-settings`,
    showMenu: false,
    showInSidebar: false
  },
  // {
  //   path: `/gtv-reorder-entry`,
  //   name: `Order Options`,
  //   component: ReOrderEntry,
  //   auth: true,
  //   role: [Roles.PHOTO_ADMIN],
  //   navIcon: `flaticon-settings`,
  //   showMenu: false,
  //   showInSidebar: false
  // },
  {
    path: `/logout`,
    name: `Logout`,
    auth: true,
    role: [...Object.values(Roles)],
    navIcon: `flaticon-log-out`,
  },
  {
    path: `/forgot-password`,
    name: `Forgot Password`,
    showMenu: false,
    component: ForgotPassword,
  },
  {
    path: `/reset/:token`,
    name: `Reset`,
    showMenu: false,
    component: Reset,
  },
  {
    path: `/maintainance`,
    name: `Reset`,
    showMenu: false,
    component: Maintainance,
  },
  {
    path: "/CSRAutomatedCheckInEmail",
    name: "Print Order Options",
    component: CSRAutomatedCheckInEmail,
    exactPro: false,
  },
  {
    path: "/firstEmailNotReceived",
    name: "First Email Not Received",
    component: firstEmailNotReceived,
    exactPro: false,
  },
  {
    path: '/create-itinerary/:brand_id/:order_id?',
    name: 'Create Itinerary',
    component: AddItinerary,
    exactPro: false,
    showMenu: false,
  },
  {
    path: "/chat/:order_id",
    name: "Travelers List",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ChatWrapper,
    permission: "assign-order-destinations"
  },
  {
    path: `/`,
    name: `Home`,
    component: GtvHome,
    showMenu: false,
    number: 0,
  },
];
export default FrontEndRoutes;
